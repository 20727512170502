import React from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import './GetStarted.scss';
import style from './GetStarted.scss.json';
import { useTranslate } from '@tolgee/react';
import GoToButton from '../components/atoms/GoToButton';
import { useNavigate, Link } from 'react-router-dom';
import { ROUTES } from '../routes';
import StepHeader from '../components/molecules/StepHeader';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

export const GetStartedContent = () => {
  let { t } = useTranslate();

  return (
    <>
      {/* <div className={style.videoContainer}>
        <iframe
          className={style.video}
          src={'https://poe.earth/hardLink-GetStarted'}
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          title="Video Player"
        ></iframe>
      </div> */}
      <h3 className={style.ContentTitle}>
        {t('word.sevenActionsWelcome', '7 Actions Creating Peace on Earth')}
      </h3>
      <p>
        {t(
          'word.sevenActionsWelcomeText',
          'The seven actions of the Peace Game — empowerment, oneness, unity, cooperation, abundance, love and faith — are transformational practices that when collectively applied evolve a person into an emanation of peace and everything around them into manifestations of peace. They are practices which mean their impact deepens as one invests in them over time.',
        )}
        <br />
        <br />
        {t(
          'word.sevenActionsWelcomeText2',
          'The Peace Game is divided into four parts that represent the different facets of creating peace in one’s life and world. Action 1 on Empowerment focuses on capacity building. Actions 2 to 4 on Oneness, Unity and Cooperation focus on peace building. Actions 5 and 6 on Abundance and Love focus on field building, and Action 7 on Faith focuses on movement building.',
        )}
        <br />
        <br />
        {t(
          'word.sevenActionsWelcomeText3',
          'At its essence the Peace Game is an empowerment platform enabling people to envision and create a life of peace for themselves and influence those in their world to do the same. At scale it can reweave the social fabric of a community.',
        )}
        <br />
        <br />
        {t(
          'word.sevenActionsWelcomeText4',
          'Playing the Peace Game will empower, enlighten and transform you if you wholeheartedly engage with it. Thank you for your commitment to creating peace in your life and the world. We wish you well on your journey!',
        )}
        <br />
        <br />
        {t('word.sevenActionsWelcomeText5', 'When you press the')}{' '}
        <span className={style.bold}>
          {t('word.sevenActionsWelcomeText6', '“Start”')}
        </span>{' '}
        {t(
          'word.sevenActionsWelcomeText7',
          'button below, you will be taken to the 7 Peace Actions.',
        )}
        <br />
        <br />
        {t(
          'word.sevenActionsWelcomeText7',
          'You will take each of these actions weekly in alignment with the online facilitated meetings.',
        )}
      </p>
    </>
  );
};

export const GetStartedNote = () => {
  let { t } = useTranslate();

  return (
    <>
      <p>
        {t(
          'word.sevenActionsNote',
          'Note: While navigating the Peace Game App is intuitive, if you feel a need for further guidance, click',
        )}{' '}
        <Link className={style.blue} to="/resources/peace-game-app">
          {t('word.learnMorePlanLink', 'here')}
        </Link>{' '}
        {t(
          'word.sevenActionsNote3',
          'for a short video that helps you navigate the site.',
        )}
      </p>
    </>
  );
};

function Register() {
  let { t } = useTranslate();
  const navigate = useNavigate();
  return (
    <DefaultLayout backgroundColor={'white'}>
      <div className={style.GetStartedContainer}>
        <StepHeader
          step={3}
          title={t('word.GetStarted', 'GET STARTED')}
          white={true}
        />
        <div className={style.banner}>
          <img src={asset('/images/GetStarted.webp')} alt="Circle fo Friends" />
        </div>
        <div className={style.Content}>
          {/* <h3 className={style.ContentTitle}>
            {t('word.HowToWelcome', 'How to Play')}
          </h3> */}
          <GetStartedContent />
        </div>
        <GoToButton
          text={'Start'}
          color="primary"
          onClick={() => navigate(ROUTES.index.path)}
        />
        <div className={style.Content}>
          {/* <h3 className={style.ContentTitle}>
            {t('word.HowToWelcome', 'How to Play')}
          </h3> */}
          <GetStartedNote />
        </div>
      </div>
    </DefaultLayout>
  );
}

export default Register;
