/**
 * Table Account Component, mostly currently using on Admin Page
 */
import React, { useState } from 'react';
import './TableAccount.scss';
import style from './TableAccount.scss.json';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { packageName } from '../../package';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { AccountInAdmin } from '../../types/account';
import { cl } from 'lincd/lib/utils/ClassNames';

interface TableAccountProps {
  data: AccountInAdmin[];
  onSelectionChange?: (accounts: AccountInAdmin[]) => void;
}
export const TableAccount = ({
  data,
  onSelectionChange,
}: TableAccountProps) => {
  const [selectedAccounts, setSelectedAccounts] = useState<AccountInAdmin[]>(
    [],
  );

  // go to user detail page with new tab
  const onUserDetail = async (accountId: string) => {
    const userPath = generatePath(ROUTES.user.path, { accountId });
    window.open(userPath, '_blank');
  };

  const onDeleteUser = async (user) => {
    const { id, teams } = user;
    const teamIds = teams.map((team) => team.identifier);

    try {
      await Server.call(packageName, 'deleteUser', id, teamIds);
      window.location.reload();
    } catch (error) {
      console.error('failed to delete user:', error);
    }
  };

  const onSelectAccount = (account: AccountInAdmin) => {
    console.log('account:', account);

    // if no onSelectionChange callback, return
    // for the table can't selected
    if (!onSelectionChange) {
      return;
    }

    // toggle selected account
    setSelectedAccounts((prev) => {
      let updateAccount: AccountInAdmin[] = [];
      if (prev.includes(account)) {
        updateAccount = prev.filter((item) => item.id !== account.id);
      } else {
        if (prev.length >= 2) {
          alert('You can only select 2 accounts');
          return prev;
        }
        updateAccount = [...prev, account];
      }

      console.log('updateAccount:', updateAccount);

      // send selected accounts to parent component
      onSelectionChange(updateAccount);

      return updateAccount;
    });

    console.log('selectedAccounts:', selectedAccounts.length);
  };

  return (
    <table className={style.usersTable}>
      <thead>
        <tr>
          <th>Account ID</th>
          <th>Player ID</th>
          <th>Given Name</th>
          <th>Family Name</th>
          <th>Email</th>
          <th>Telephone</th>
          <th>Google</th>
          <th>Password</th>
          <th>Apple</th>
          <th>Current Team</th>
          <th>Teams</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data?.length === 0 && (
          <tr>
            <td colSpan={12}>No data</td>
          </tr>
        )}
        {data?.map((user: AccountInAdmin, i) => {
          const isSelected = selectedAccounts.includes(user);
          const selectedIndex = selectedAccounts.indexOf(user);
          const topOffset = isSelected
            ? `${(selectedIndex + 1) * 41}px`
            : 'auto';

          // generate user detail page link
          let link: string;
          if (user.id) {
            link = generatePath(ROUTES.user.path, {
              accountId: user.id,
            });
          }

          return (
            <tr
              key={i}
              className={cl(
                selectedAccounts.includes(user) ? style.selected : '',
                onSelectionChange ? style.select : '',
              )}
              onClick={() => onSelectAccount(user)}
              style={{
                top: topOffset,
              }}
            >
              <td>
                <Link to={link ? link : '#'} target="_blank">
                  {user.id}
                </Link>
              </td>
              <td>{user.identifier}</td>
              <td>{user.givenName}</td>
              <td>{user.familyName}</td>
              <td>{user.email}</td>
              <td>{user.telephone}</td>
              <td>{user.hasGoogle ? 'Yes' : ''}</td>
              <td>{user.hasPassword ? 'Yes' : ''}</td>
              <td>{user.hasApple ? 'Yes' : ''}</td>
              <td>{user.currentTeam}</td>
              <td>{user.teams?.map((team) => team?.identifier).join(', ')}</td>
              <td>
                <div className={style.btnGroup}>
                  <button
                    aria-label="Detail"
                    onClick={() => {
                      onUserDetail(user.id);
                    }}
                  >
                    <Eye />
                  </button>
                  <button
                    disabled
                    className={style.btnDelete}
                    aria-label="Delete"
                    onClick={() => onDeleteUser(user)}
                  >
                    <Trash />
                  </button>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const Eye = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-eye"
    {...props}
  >
    <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
    <circle cx={12} cy={12} r={3} />
  </svg>
);

const Trash = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-trash"
    {...props}
  >
    <path d="M3 6h18" />
    <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
    <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
  </svg>
);
