import React from 'react';
import Headline from '../../components/atoms/Headline';
import './Section.scss';
import style from './Section.scss.json';
import { Topic } from 'lincd-irlcg/lib/shapes/Topic';
import {
  topic1,
  topic2,
  topic3,
  topic4,
  topic5,
  topic6,
  topic7,
} from '../../data-references';
import { CoreMap } from 'lincd/lib/collections/CoreMap';
import { NamedNode } from 'lincd/lib/models';
import { useTranslate } from '@tolgee/react';

interface SectionProps {
  title: string;
  data: CoreMap<NamedNode, number>;
  medalion?: CoreMap<NamedNode, number>;
  renderCardFunction: (
    topic: Topic,
    score: number,
    medal: number,
  ) => React.ReactNode;
  headlineStyle?: string;
}

const Section: React.FC<SectionProps> = ({
  title,
  data,
  medalion,
  renderCardFunction,
  headlineStyle,
}) => {
  const topicsArray = [topic1, topic2, topic3, topic4, topic5, topic6, topic7];
  let totalAction = 0;
  let { t } = useTranslate();
  let prefix = 'section';
  return (
    <div>
      <Headline
        title={t(prefix + '.' + title, title)}
        inverted={true}
        lined={'blue'}
        className={headlineStyle && headlineStyle}
      />
      {data && (
        <>
          <div>
            {topicsArray.map((topic) => {
              // Find the corresponding data entry for the current topic
              const dataEntry = [...data.entries()].find(
                ([dataTopic]) =>
                  dataTopic && new Topic(dataTopic).name === topic?.name,
              );

              // Find the corresponding medalion entry for the current topic
              const medalionEntry = medalion
                ? [...medalion.entries()].find(
                    ([medalionTopic]) =>
                      medalionTopic &&
                      new Topic(medalionTopic).name === topic?.name,
                  )
                : null;

              // Total score for all the topics get from key value
              totalAction = [...data.entries()].reduce(
                (total, [, dataScore]) => {
                  return total + (dataScore || 0);
                },
                0,
              );

              // Set default scores to 0 if no corresponding entry is found
              const dataScore = dataEntry ? dataEntry[1] : 0;
              const medalionScore = medalionEntry ? medalionEntry[1] : 0;

              // Render the Card component for the current topic
              return renderCardFunction(topic, dataScore, medalionScore);
            })}
          </div>
          <div className={style.peaceTotal}>
            <h2>{t('word.total', 'Total')}</h2>
            <h2>{totalAction}</h2>
          </div>
        </>
      )}
    </div>
  );
};

export default Section;
