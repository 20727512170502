import { useEffect, useState } from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import './Signin.scss';
import style from './Signin.scss.json';
import Divider from '../components/atoms/Divider';
import { SigninWithPasswordForm } from 'lincd-auth/lib/components/SigninWithPasswordForm';
import { Link, useNavigate } from 'react-router-dom';
import { Person } from 'profile-plus/lib/shapes/Person';
import { UserAccount } from 'profile-plus/lib/shapes/UserAccount';
import { SigninWithGoogleButton } from 'lincd-capacitor/lib/components/SigninWithGoogleButton';
import { SigninWithWhatsappButton } from 'lincd-capacitor/lib/components/SignInWithWhatsapp';
import { SigninWithAppleButton } from 'lincd-capacitor/lib/components/SigninWithAppleButton';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { ROUTES } from '../routes';
import { initializeApp, FirebaseOptions, getApps } from 'firebase/app';
import 'firebase/auth';
import { Capacitor } from '@capacitor/core';
import { Spinner } from '../components/atoms/Spinner';
import { LogoSpinning } from '../components/atoms/LogoSpinning';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { useTranslate } from '@tolgee/react';
import { SwitchLanguage } from '../components/molecules/SwitchLanguage';

export default function Signin() {
  let { t } = useTranslate();
  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'signin';
  const navigate = useNavigate();
  const auth = useAuth<Player, UserAccount>();
  const [loading, setLoading] = useState(true);

  const onLoggedIn = () => {
    navigate(ROUTES.index.path);
  };

  useEffect(() => {
    if (auth.userAccount) {
      navigate(ROUTES.index.path);
    } else {
      setLoading(false);
    }
  }, [auth.userAccount]);

  return (
    <DefaultLayout
      backgroundColor="dark"
      safeareaBackground={'var(--ld-app-body-background-color)'}
    >
      <div className={style.SigninContainer} aria-labelledby="signin-container">
        {loading ? (
          <LogoSpinning centered={true} aria-label="Loading spinner" />
        ) : (
          <>
            <div className={style.Title} aria-labelledby="title">
              <img
                src={asset('/images/peace-game-logo-spinning-2.gif')}
                alt="Peace Game Icon"
              />
              <h2>{t('app-name', 'Peace Game')}</h2>
            </div>
            <div className={style.Form} aria-labelledby="signin-form">
              <div className={style.Signin}>
                <h2>{t(prefix + '.playerSignIn', 'Player Sign In')}</h2>
                <SigninWithPasswordForm
                  // className={style.SocialSignin}
                  onLoadingChange={(loading) => setLoading(loading)}
                  startWithInputField={true}
                  showEmailIcon={true}
                  onLoggedIn={onLoggedIn}
                  aria-label={t(
                    'password-signin-form',
                    'Sign in with password form',
                  )}
                />
              </div>
              <div className={style.Divider} aria-hidden="true">
                <Divider />
              </div>
              <div
                className={style.SocialSignin}
                aria-label={t(
                  'whatsapp-signin-button',
                  'Sign in with WhatsApp button',
                )}
              >
                <SigninWithWhatsappButton
                  onSuccess={onLoggedIn}
                  aria-label="Sign in with WhatsApp button"
                />
              </div>
              <div
                className={style.SocialSignin}
                aria-labelledby="signin-with-google"
              >
                <SigninWithGoogleButton
                  onCallback={onLoggedIn}
                  onLoadingChange={(loading) => setLoading(loading)}
                  aria-label={t(
                    'google-signin-button',
                    'Sign in with Google button',
                  )}
                />
              </div>
              {process.env.NODE_ENV === 'production' &&
              Capacitor.getPlatform() === 'web' ? null : (
                <div
                  className={style.SocialSignin}
                  aria-labelledby="signin-with-apple"
                >
                  <SigninWithAppleButton
                    onCallback={onLoggedIn}
                    aria-label={t(
                      'apple-signin-button',
                      'Sign in with Apple button',
                    )}
                  />
                </div>
              )}
              <div className={style.Divider} aria-hidden="true">
                <Divider />
              </div>
            </div>
            <div className={style.Terms} aria-labelledby="terms-and-conditions">
              <p>
                {t(
                  prefix + '.terms.1',
                  'By selecting "Create Player Account" or "Player Sign In" you agree to our',
                )}{' '}
                <Link to={ROUTES.terms.path} aria-label={t('terms', 'Terms')}>
                  {t(prefix + '.terms', 'Terms')}
                </Link>
                .
                <br />
                {t(
                  prefix + '.privacy.1',
                  'Learn how the platform uses your data in our',
                )}{' '}
                <Link
                  to={ROUTES.privacy_policy.path}
                  aria-label={t('privacy-policy', 'Privacy Policy')}
                >
                  {t(prefix + '.privacyPolicy', 'Privacy Policy')}
                </Link>
                .
              </p>
              <Link
                to={ROUTES.contact.path}
                className={style.contactSupport}
                aria-label={t('contact-support', 'Contact support')}
              >
                <img width={24} src={asset('/images/icon-support-white.svg')} />
                <p>{t(prefix + '.contactSupport', 'Contact support')}</p>
              </Link>
              <SwitchLanguage className={style.switchLanguage} />
            </div>
          </>
        )}
      </div>
    </DefaultLayout>
  );
}
