import React from 'react';
import './DefaultLayout.scss';
import style from './DefaultLayout.scss.json';
import { SafeAreaContainer } from 'lincd-capacitor/lib/components/SafeAreaContainer';
import { cl } from 'lincd/lib/utils/ClassNames';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../routes';

export function DefaultLayout({
  children,
  backgroundColor = 'gray',
  safeareaBackground = '#FFFFFF',
  className,
}: {
  children;
  className?: string;
  safeareaBackground?: string;
  backgroundColor?: 'white' | 'gray' | 'dark';
}) {
  const location = useLocation();

  /**
   * disable safearea background color to transparent, so that make the background will be transparent.
   * but the padding for safearea still available.
   *
   * normally use this when you want to make background image/color fullscreen
   */
  const isFullscreen = location.pathname === ROUTES.signin.path;

  return (
    <main className={cl(style.main, style[backgroundColor])}>
      <SafeAreaContainer
        color={isFullscreen ? 'transparent' : safeareaBackground}
      >
        <div>{children}</div>
      </SafeAreaContainer>
    </main>
  );
}
