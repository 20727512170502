import React, { useState } from 'react';
import Headline from '../../atoms/Headline';
import style from './2_OnenessPlan.scss.json';
import './2_OnenessPlan.scss';
import GoToButton from '../../atoms/GoToButton';
import { topic2 } from '../../../data-references';
import ActionContent from '../../molecules/ActionContent';
import ActionPlan from '../../molecules/ActionPlan';
import { useTranslate } from '@tolgee/react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import {
  BronzeGoal,
  GoldGoal,
  SetYourGoal,
  SilverGoal,
} from '../../molecules/SetYourGoal';
import { PlanPurpose } from '../../molecules/PlanPurpose';

const OnenessPlan = () => {
  let topic = topic2;
  let { t } = useTranslate();
  const [actionPlanTime, setActionPlanTime] = useState(0);
  let prefix = 'topic' + topic.identifier;

  const navigate = useNavigate();

  const handleTimeSelected = (minutes) => {
    setActionPlanTime(minutes);
  };

  return (
    <>
      <div className={style.content}>
        <PlanPurpose topic={topic} />

        <Headline title={t('word.gameplay', 'Gameplay')} lined="black" />

        <ActionContent
          subtitle={t('word.howToKeepScore', 'How to Keep Score')}
          content={
            <>
              <p>
                <p>
                  <span className={style.bold}>
                    {t(prefix + '.plan.subtitle', 'Befriending the Other')}
                  </span>
                  :{' '}
                  {t(
                    prefix + '.plan.subtitleText',
                    'Each person befriended counts as 1 Peace Action.',
                  )}
                  <br />
                  <br />
                  <span className={style.underline}>
                    {t('word.bronzePeaceMedal', 'Bronze Peace Medal')}
                  </span>
                  :{' '}
                  <span className={style.bold}>
                    {t(prefix + '.plan.bronzePeaceMedalText1', '1 - 3')}
                  </span>{' '}
                  {t(
                    prefix + '.plan.bronzePeaceMedalText2',
                    'people befriended.',
                  )}{' '}
                  <span className={style.blue}>
                    (
                    {t(
                      prefix + '.plan.bronzePeaceMedalText3',
                      '1-3 Peace Actions',
                    )}
                    )
                  </span>
                  <br />
                  <br />
                  <span className={style.underline}>
                    {' '}
                    {t('word.silverPeaceMedal', 'Silver Peace Medal')}
                  </span>
                  :{' '}
                  <span className={style.bold}>
                    {t(prefix + '.plan.silberPeaceMedalText1', '4 - 8')}
                  </span>{' '}
                  {t(
                    prefix + '.plan.silverPeaceMedalText2',
                    'people befriended.',
                  )}
                  {'  '}
                  <span className={style.blue}>
                    (
                    {t(
                      prefix + '.plan.silverPeaceMedalText3',
                      '4-8 Peace Actions',
                    )}
                    )
                  </span>
                  <br />
                  <br />
                  <span className={style.underline}>
                    {t('word.goldPeaceMedal', 'Gold Peace Medal')}
                  </span>
                  :{' '}
                  <span className={style.bold}>
                    {t(prefix + '.plan.goldPeaceMedalText1', '9 or more')}
                  </span>{' '}
                  {t(prefix + '.plan.goldPeaceMedalText2', 'people befriended')}{' '}
                  <span className={style.blue}>
                    (
                    {t(
                      prefix + '.plan.goldPeaceMedalText3',
                      '9+ Peace Actions',
                    )}
                    )
                  </span>
                </p>
              </p>
            </>
          }
        />

        <SetYourGoal topic={topic} onTimeSelected={handleTimeSelected}>
          <BronzeGoal actions={'1-3'} minutes={15} average={true} />
          <SilverGoal actions={'4-8'} minutes={60} average={true} />
          <GoldGoal actions={9} minutes={135} average={true} />
        </SetYourGoal>
        <Headline
          subtitle={t(
            'word.createPeaceActionPlan',
            'Create Your Peace Action Plan',
          )}
          lined="black"
        />
        <ActionPlan action={'oneness'} topic={topic} time={actionPlanTime} />
        <GoToButton
          text={t('word.goToShare', 'Go to Share')}
          aria-label={t('word.goToShare', 'Go to Share')}
          onClick={() => {
            navigate(
              generatePath(ROUTES.peace_action.path, {
                id: topic.name.toLowerCase() + '?step=3',
              }),
            );
          }}
        />
      </div>
    </>
  );
};

export default OnenessPlan;
