import React from 'react';
import './Meeting08.scss';
import { default as style } from './Meeting08.scss.json';
import { registerPackageModule, linkedComponent } from '../../../package';
import { useTranslate } from '@tolgee/react';
import { Video } from '../../atoms/Video';
import { UnsortedList } from '../../atoms/UnsortedList';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { OrderedList } from '../../atoms/OrderedList';
import Headline from '../../atoms/Headline';
import { getActiveLanguage } from '../../../utils/tolgee';

export function Meeting08() {
  let { t } = useTranslate();
  const navigate = useNavigate();
  const activeLanguage = getActiveLanguage();

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'resource-Meeting08';
  return (
    <>
      <div className={style.content}>
        <Headline
          subtitle={t('word.Pre-Meeting', 'Pre-Meeting Preparation')}
          lined="black"
        />
        <p>
          {t(
            prefix + '.paragraph01',
            'Here are elements to help you prepare to lead the meeting. Ignore those that apply for in-person meetings if your team is meeting virtually.',
          )}
        </p>
        <UnsortedList>
          <li>
            <u>{t('word.roomSetup', 'Room Setup')}</u>
            {': '}
            {t(
              prefix + '.bullet01',
              'Arrange the room in a U shape with you in front. This allows people to see one another and you, as the facilitator, increasing the quality of the sharing and community building.',
            )}
          </li>
          <li>
            <u>{t('word.timing', 'Timing')}</u>
            {': '}
            {t(
              prefix + '.bullet02',
              'Tell the group at the start of the meeting what time you propose to end and check that everyone can stay until the end. Make sure you end on time. Each element in the meeting agenda has an approximate time associated with it. It is rare that any meeting element will go for exactly that amount of time, so use it as a guideline. If one meeting element runs longer, you will need to make up the time by shortening another. Give the team shared responsibility for timekeeping. If any point seems likely to take a lot longer than you planned, tell the team and ask them to decide whether to extend the meeting or hurry to finish the point.',
            )}
          </li>
          <li>
            <u>{t('word.ShareActionPlan', 'Share Action Plan')}</u>
            {': '}
            {t(
              prefix + '.bullet03',
              'As the meeting facilitator, share your Action Plan when you get to the “Plan” section to role model the process.',
            )}
          </li>
          <li>
            <u>{t('word.practice', 'Practice')}</u>
            {': '}
            {t(
              prefix + '.bullet04',
              'To get the full value from this script, take time to study it so you are fluent and understand what you are trying to achieve with each meeting element.',
            )}
          </li>
        </UnsortedList>
        <Headline
          subtitle={t(
            'word.AgendaHeader',
            'Agenda Summary and Approximate Times',
          )}
          lined="black"
        />
        <OrderedList type={1}>
          <li>
            {t('word.InnerPeaceMed', 'Inner Peace Meditation')} - 5{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t('word.checkin', 'Check In')} - 20 {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t(prefix + '.shareActionsTaken', 'Share Faith Actions Taken')} - 30{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t(prefix + '.goldenAge', 'Golden Age Meditation')} - 60{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t('word.teamPerformance', 'Check in on Team Performance')} - 10{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t('word.actionSupportCom', 'Action Support Communication')} - 5{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t('word.reviewNextSteps', 'Review Next Steps')} - 5{' '}
            {t('word.mintues', 'minutes')}
          </li>
        </OrderedList>
        <p>{t('word.total2hours', 'Total Time 2 - hours')}</p>
        <Headline
          subtitle={t('word.meetingScript', 'Meeting Script')}
          lined="black"
        />
        <OrderedList type={1}>
          <li>
            <u>{t('word.InnerPeaceMed', 'Inner Peace Meditation')}</u> - 5{' '}
            {t('word.mintues', 'minutes')}{' '}
            <UnsortedList>
              <li>
                {t(
                  prefix + '.a1_bullet01',
                  'Inner Peace Meditation: Action Leader starts the meeting either through playing video or guiding it.',
                )}
                <Video
                  src={`https://poe.earth/hardLink-inner-peace`}
                  // src={`https://poe.earth/hardLink-inner-peace_${activeLanguage}`}
                />
              </li>
              <li>
                {t(prefix + '.a1_bullet02', 'Guided meditation')}
                {': '}
                <UnsortedList>
                  <li>
                    {t(
                      prefix + '.a1_bullet03',
                      'This inner peace work complements the outer peace work of the game.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet04',
                      'Take a few deep breaths to quiet your mind.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet05',
                      'Now become present to this moment of profound opportunity for rapid evolution of your life and planet Earth.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet06',
                      'Experience peace within yourself. Affirm: I am in harmony with self!',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet07',
                      'Experience yourself at peace with all the people in your life. Affirm: I am in harmony with others.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet08',
                      'Experience yourself at peace with the Earth and all living beings who inhabit it. Affirm: I am in harmony with the Earth and all living beings.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet09',
                      'Deepen this state of harmony with self, others and the Earth for the next few minutes.',
                    )}
                  </li>
                </UnsortedList>
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t('word.checkin', 'Check In')}</u> - 20{' '}
            {t('word.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + '.a2_bullet01',
                  'Time for any questions or comments from team members about playing the Peace Game.',
                )}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t(prefix + '.AgendaItem03', 'Share Faith Actions Taken')}</u> -
            30 {t('word.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + '.a3_bullet01',
                  'Have each person share the Peace Medal achieved and four Faith questions from the “Share” section of the Peace Game App.',
                )}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>
              {t(prefix + '.AgendaItem04', 'Golden Age Meditation Exercise')}
            </u>{' '}
            - 45 {t('word.mintues', 'minutes')}
            <UnsortedList>
              <li>
                <u>{t(prefix + '.a4_bullet01', 'Meditation')}</u> - 20{' '}
                {t('word.mintues', 'minutes')}
                <br />
                <p>
                  {t(
                    prefix + '.a4_bullet02',
                    'This meditation will help you synthesize your journey and step into Golden Age Consciousness.',
                  )}
                </p>
                <p>
                  {t(
                    prefix + '.a4_bullet03',
                    'As you embody the Peace on Earth frequencies and practices, this heightened state of consciousness becomes available to you.',
                  )}
                </p>
                <p>
                  {t(
                    prefix + '.a4_bullet04',
                    'Peace on Earth is the portal to the Golden Age which is the future of humanity, but it’s available to you now through playing the Peace Game. You don’t need to wait for humanity to get there.',
                  )}
                </p>
                <p>
                  {t(
                    prefix + '.a4_bullet05',
                    'Your golden age consciousness will raise the frequency of humanity while leading the way for others.',
                  )}
                </p>
                <p>
                  {t(
                    prefix + '.a4_bullet06',
                    'This is how humanity will get there. As you lead, others will follow. You are the wayshower.',
                  )}
                </p>
                <p>
                  {t(
                    prefix + '.a1_bullet01',
                    'Note: Action Leader either leads the meditation or plays the video.',
                  )}
                  <Video
                    src={`https://poe.earth/hardLink-golden-age`}
                    // src={`https://poe.earth/hardLink-golden-age_${activeLanguage}`}
                  />
                </p>
                <OrderedList type={1}>
                  <li>
                    {t(prefix + '.a4_bullet06a', 'Empowerment:')}
                    <p>
                      {t(
                        prefix + '.a4_bullet06b',
                        'Visualize your heart filled with the violet light of empowerment.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet06c',
                        'Visualize yourself as a sovereign spiritual being.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet06d',
                        'Affirm: I am an enlightened spiritual being.',
                      )}
                    </p>
                  </li>
                  <li>
                    {t(prefix + '.a4_bullet07a', 'Oneness:')}
                    <p>
                      {t(
                        prefix + '.a4_bullet07b',
                        'Visualize your heart filled with the golden light of oneness.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet07c',
                        'Visualize yourself at one with each human being and all life on the planet.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet07d',
                        'Affirm: I am one with all of humanity.',
                      )}
                    </p>
                  </li>
                  <li>
                    {t(prefix + '.a4_bullet08a', 'Unity:')}
                    <p>
                      {t(
                        prefix + '.a4_bullet08b',
                        'Visualize your heart filled with the white light of unity.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet08c',
                        'Visualize yourself in harmony with all life on Earth.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet08d',
                        'Affirm: I am in harmony with all life.',
                      )}
                    </p>
                  </li>
                  <li>
                    {t(prefix + '.a4_bullet09a', 'Cooperation:')}
                    <p>
                      {t(
                        prefix + '.a4_bullet09b',
                        'Visualize your heart filled with the green light of cooperation.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet09c',
                        'Visualize yourself cooperating with others for the common good of all.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet09d',
                        'Affirm: I cooperate for the common good.',
                      )}
                    </p>
                  </li>
                  <li>
                    {t(prefix + '.a4_bullet10a', 'Abundance:')}
                    <p>
                      {t(
                        prefix + '.a4_bullet10b',
                        'Visualize your heart filled with the indigo light of abundance.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet10c',
                        'Visualize yourself living in a universe of abundance and offering it to the world.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet10d',
                        'Affirm: I live a life of abundance and generosity.',
                      )}
                    </p>
                  </li>
                  <li>
                    {t(prefix + '.a4_bullet11a', 'Love:')}
                    <p>
                      {t(
                        prefix + '.a4_bullet11b',
                        'Visualize your heart filled with the pink light of love.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet11c',
                        'Visualize yourself as a peace ambassador for the world.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet11d',
                        'Affirm: I love humanity, pray for and visualize its well being.',
                      )}
                    </p>
                  </li>
                  <li>
                    {t(prefix + '.a4_bullet12a', 'Faith:')}
                    <p>
                      {t(
                        prefix + '.a4_bullet12b',
                        'Visualize your heart filled with the blue light of faith.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet12c',
                        'Visualize yourself filled with faith in humanity’s ability to create Peace on Earth by 2030.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet12d',
                        'Affirm: I have faith in humanity and we create Peace on Earth by 2030.',
                      )}
                    </p>
                  </li>
                  <li>
                    {t(
                      prefix + '.a4_bullet13a',
                      'Now visualize these seven frequencies: empowerment, oneness, unity, cooperation, abundance, love and faith blending together and alchemizing into the Golden Age Light.',
                    )}
                    <p>
                      {t(
                        prefix + '.a4_bullet13b',
                        'See and feel this golden age light permeating your body… heart… mind… and soul…',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet13c',
                        'See and feel yourself now being transported into golden age consciousness.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet13d',
                        'From this golden age consciousness you are now empowered to live and create Peace on Earth by 2030.',
                      )}
                    </p>
                  </li>
                  <li>
                    {t(
                      prefix + '.a4_bullet13e',
                      'See and feel your golden age light spreading from your heart to the hearts of your family members who spread it to others.',
                    )}
                    <p>
                      {t(
                        prefix + '.a4_bullet13f',
                        'See and feel your golden age light spreading from your heart to the hearts of your friends who spread it to others.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet13g',
                        'See and feel your golden age light spreading from your heart to the hearts of your co-workers who spread it to others.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet13h',
                        'See and feel your golden age light spreading from your heart to the hearts of your community members who spread it to others.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet13i',
                        'See and feel your golden age light spreading from your heart to the hearts of all humanity who spread it across the entire planet.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet13j',
                        'See and feel your golden age light spreading from your heart to the hearts of your spirit guides who spread it across the entire universe.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet13k',
                        'See and feel your golden age light spreading across the planet and universe and back to you in an ever-evolving spiral.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet13l',
                        'See and feel yourself and every part of your circle being lifted into higher and higher levels of golden age consciousness.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet13m',
                        'See and feel your community becoming a Peace on Earth Zone and transforming into golden age consciousness.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet13n',
                        'See and feel your Peace on Earth Zone inspiring other communities to become  Peace on Earth Zones and transform into golden age consciousness who in turn do the same.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet13o',
                        'See and feel thousands of Peace on Earth Zones all over the planet radiating golden age consciousness.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet13p',
                        'See and feel humanity and the Earth vibrating with golden age consciousness.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet13q',
                        'See and feel humanity now crossing the evolutionary threshold into Peace on Earth.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet13r',
                        'See and feel yourself now in 2030 looking back at this moment when you attained golden age consciousness and began consciously walking the Peace on Earth path.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet13s',
                        'See and feel all you did to help humanity achieve Peace on Earth and begin walking the path of an enlightened species in the universe.',
                      )}
                    </p>
                    <p>
                      {t(
                        prefix + '.a4_bullet13t',
                        'Allow this fully into your body… heart... mind... and soul....',
                      )}
                    </p>
                    <p>
                      {t(prefix + '.a4_bullet13u', 'Now bask in this vision.')}
                    </p>
                  </li>
                </OrderedList>
              </li>
              <li>
                <u>{t(prefix + '.a4_bullet14', 'Partner Share')}</u> - 20{' '}
                {t(prefix + '.mintues', 'minutes')}
              </li>
              <li>
                <u>{t(prefix + '.a4_bullet15', 'Large Group Share')}</u> - 20{' '}
                {t(prefix + '.mintues', 'minutes')}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t('word.teamPerformance', 'Check in on Team Performance')}</u> -
            10 {t(prefix + '.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + '.a5_bullet01',
                  'If individuals did not take the actions they had planned, ask if the team can be helpful in supporting them to carry it out. If they did not get around to doing it, kindly ask if they will be able to take the actions before the next meeting.',
                )}
              </li>
              <li>
                {t(
                  prefix + '.a5_bullet02',
                  'If any team members came late, request that they come on time in the future so the meeting can be run without interruptions. If a number of people came late, requiring you to delay the start of the meeting, request that they come on time so you can end on time. If people can’t get there at the agreed-on time, determine if you should start the meeting later.',
                )}
              </li>
              <li>
                {t(
                  prefix + '.a5_bullet03',
                  'Communicate with any team members who did not attend the meeting and ask if they are still committed to participating in the Peace Game. If so, request that they attend the following meetings. Explain what happened at this meeting.',
                )}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t('word.reviewNextSteps', 'Review Next Steps')}</u> - 5{' '}
            {t(prefix + '.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + '.a7_bullet01',
                  'Set up an Information Meeting as a Peace Team (see Information Meeting Script) to invite community members in a POE Zone to participate in the in-person version.',
                )}
              </li>
              <li>
                {t(
                  prefix + '.a7_bullet02',
                  'Alternatively invite community members to participate in the global online game. ',
                )}
                <p>
                  {t(
                    prefix + '.a7_bullet02a',
                    'Registration for Peace Game Information Meetings',
                  )}
                  {': '}
                  <a href="https://peace2030.earth/events">
                    {t(
                      prefix + '.a7_bullet02b',
                      'https://peace2030.earth/events',
                    )}
                  </a>
                </p>
                <p>
                  {t(
                    prefix + '.a7_bullet02c',
                    'Registration for the Global Online Peace Game',
                  )}
                  {': '}
                  <a href="https://peace2030.earth/events">
                    {t(
                      prefix + '.a7_bullet02d',
                      'https://peace2030.earth/events',
                    )}
                  </a>
                </p>
              </li>
              <li>
                {t(
                  prefix + '.a7_bullet03',
                  'Complete any incomplete actions from the previous action areas and report on any additional actions taken.',
                )}
              </li>
              <li>
                {t(
                  prefix + '.a7_bullet04',
                  'Express your appreciation to team members for what they have accomplished. State in a sentence or two what was most meaningful for you.',
                )}
              </li>
            </UnsortedList>
          </li>
        </OrderedList>
      </div>
    </>
  );
}
