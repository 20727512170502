import React, { useState } from 'react';
import Headline from '../../atoms/Headline';
import style from './4_CooperationPlan.scss.json';
import './4_CooperationPlan.scss';
import GoToButton from '../../atoms/GoToButton';
import { Medal } from '../../molecules/Medal';
import { useTranslate } from '@tolgee/react';
import { topic4 } from '../../../data-references';
import ActionContent from '../../molecules/ActionContent';
import ActionPlan from '../../molecules/ActionPlan';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import {
  GoldGoal,
  SilverGoal,
  SetYourGoal,
  BronzeGoal,
} from '../../molecules/SetYourGoal';
import { PlanPurpose } from '../../molecules/PlanPurpose';

const CooperationPlan = () => {
  let topic = topic4;
  let { t } = useTranslate();
  const navigate = useNavigate();
  const [actionPlanTime, setActionPlanTime] = useState(0);

  const handleTimeSelected = (minutes) => {
    setActionPlanTime(minutes);
  };
  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'topic' + topic.identifier;
  return (
    <>
      <div className={style.content}>
        <PlanPurpose topic={topic} />

        <Headline title={t('word.gameplay', 'Gameplay')} lined="black" />

        <ActionContent
          subtitle={t('word.howToKeepScore', 'How to Keep Score')}
          content={
            <>
              <p>
                <p>
                  {t(
                    prefix + '.plan.howToKeepScoreText',
                    'Although you only get one peace medal based on the type of effort required, you will be recognized for all of the peace actions you take.',
                  )}{' '}
                </p>
                <br />
                <span className={style.underline}>
                  {t('word.bronzePeaceMedal', 'Bronze Peace Medal')}
                </span>
                :{' '}
                {t(prefix + '.plan.bronzePeaceMedalText', 'Participate only.')}{' '}
                <span className={style.blue}>
                  ({t(prefix + '.plan.bronzePeaceMedalText1', '1 Peace Action')}
                  )
                </span>
                <br />
                <br />
                <span className={style.underline}>
                  {' '}
                  {t('word.silverPeaceMedal', 'Silver Peace Medal')}
                </span>
                :{' '}
                {t(
                  prefix + '.plan.silverPeaceMedalText',
                  'Invite and Participate.',
                )}{' '}
                <span className={style.blue}>
                  (
                  {t(prefix + '.plan.silverPeaceMedalText1', '2 Peace Actions')}
                  )
                </span>
                <br />
                <br />
                <span className={style.underline}>
                  {t('word.goldPeaceMedal', 'Gold Peace Medal')}
                </span>
                :{' '}
                {t(
                  prefix + '.plan.goldPeaceMedalText',
                  'Create, Invite, and Participate.',
                )}{' '}
                <span className={style.blue}>
                  ({t(prefix + '.plan.goldPeaceMedalText1', '3 Peace Actions')})
                </span>
                <p>
                  <span className={style.blue}>
                    {t('word.example', 'Example')}
                    {': '}
                  </span>
                  {t(
                    prefix + '.plan.exampleText',
                    'If you create a cooperation event (3 peace actions), participate in another cooperation event and invite friends (2 peace actions), and participate 4 more cooperation events on your own (4 peace actions).This would represent 9 peace actions. ',
                  )}
                </p>
              </p>
            </>
          }
        />

        <SetYourGoal topic={topic} onTimeSelected={handleTimeSelected}>
          <BronzeGoal
            actions={1}
            minutes={60}
            average={true}
            text={t(
              prefix + '.plan.bronzePeaceAction',
              'Participate in an experience that furthers cooperation for the common good between individuals or groups.',
            )}
          />
          <SilverGoal
            actions={2}
            minutes={90}
            average={true}
            text={t(
              prefix + '.plan.silverPeaceAction',
              'Participate and Invite people to participate in an experience that furthers cooperation for the common good between individuals or groups. ',
            )}
          />
          <GoldGoal
            actions={3}
            minutes={120}
            average={true}
            text={t(
              prefix + '.plan.goldPeaceAction',
              'Participate, Invite and Create an experience of cooperation for the common good between individuals or groups.',
            )}
          />
        </SetYourGoal>

        <Headline
          subtitle={t(
            'word.createPeaceActionPlan',
            'Create Your Peace Action Plan',
          )}
          lined="black"
        />
        <ActionPlan action="cooperation" topic={topic} time={actionPlanTime} />

        <GoToButton
          text={t('word.goToShare', 'Go to Share')}
          onClick={() => {
            navigate(
              generatePath(ROUTES.peace_action.path, {
                id: topic.name.toLowerCase() + '?step=3',
              }),
            );
          }}
        />
      </div>
    </>
  );
};

export default CooperationPlan;
