import Headline from '../atoms/Headline';
import { TopicMedal } from './TopicMedal';
import React, { useEffect } from 'react';
import { useTranslate } from '@tolgee/react';
import { TopicScore } from 'lincd-irlcg/lib/shapes/TopicScore';
import { linkedComponent } from '../../package';
import { Topic } from 'lincd-irlcg/lib/shapes/Topic';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { Spinner } from '../atoms/Spinner';

export const PeaceMedalAndActions = linkedComponent<
  Topic,
  { topicScore?: TopicScore }
>(Topic, ({ source, topicScore }) => {
  // let [tScore, setTScore] = React.useState<TopicScore>();
  let { t } = useTranslate();
  let auth = useAuth<Player>();
  // let player = auth.userAccount.accountOf as Player;

  // useEffect(() => {
  //   TopicScore.getOrCreateFor(
  //     source,
  //     player || auth.user,
  //     player.currentTeam,
  //   ).then((topicScore) => {
  //     setTScore(topicScore);
  //   });
  // }, [source.namedNode]);
  return (
    <>
      <Headline
        subtitle={t('word.peaceMedalAndActions', 'Your Peace Medal & Actions')}
        lined="black"
      />
      {topicScore ? (
        <TopicMedal of={topicScore} topicScore={topicScore} />
      ) : (
        <Spinner />
      )}
    </>
  );
});
