import React, { useEffect, useState } from 'react';
import './PeaceGameDebrief.scss';
import { default as style } from './PeaceGameDebrief.scss.json';
import { registerPackageModule, linkedComponent } from '../../../package';
import { useTranslate } from '@tolgee/react';
import { Video } from '../../atoms/Video';
import { UnsortedList } from '../../atoms/UnsortedList';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { OrderedList } from '../../atoms/OrderedList';
import Headline from '../../atoms/Headline';
import ActionContent from '../../molecules/ActionContent';
import ActionDebrief from '../../molecules/ActionDebrief';
import { topic1, topic2 } from '../../../data-references';
import ActionInputField from '../../atoms/ActionInputField';
import { PeaceGameDebrief as PGDebrief } from 'lincd-irlcg/lib/shapes/PeaceGameDebrief';
import GoToButton from '../../atoms/GoToButton';
import { Container } from '../../../layout/Container';

export function PeaceGameDebrief() {
  let { t } = useTranslate();
  const navigate = useNavigate();
  const [gameDebrief, setGameDebrief] = useState<PGDebrief>();

  useEffect(() => {
    PGDebrief.loadPeaceGameDebrief()
      .then((loadedPlan) => {
        setGameDebrief(loadedPlan);
      })
      .catch((error) => {
        console.error('Error loading action debrief:', error);
      });
  }, []);

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'resource-PeaceGameDebrief';
  return (
    <div className={style.debriefContent}>
      <ActionInputField
        text={
          '1. ' +
          t(
            'word.gameDebrief.1',
            'What have you learned about the empowerment peace practice of becoming an agent of peace on the planet?',
          )
        }
        multiline={true}
        valueProperty={'empowermentDebrief1'}
        of={gameDebrief}
        className={style.textArea}
      />

      <ActionInputField
        text={
          '2. ' +
          t(
            'word.gameDebrief.2',
            'What have you learned about the oneness peace practice of befriending the other?',
          )
        }
        multiline={true}
        valueProperty={'onenessDebrief1'}
        of={gameDebrief}
        className={style.textArea}
      />

      <ActionInputField
        text={
          '3. ' +
          t(
            'word.gameDebrief.3',
            'What have you learned about the unity peace practice of celebrating our common humanity?',
          )
        }
        multiline={true}
        valueProperty={'unityDebrief1'}
        of={gameDebrief}
        className={style.textArea}
      />

      <ActionInputField
        text={
          '4. ' +
          t(
            'word.gameDebrief.4',
            'What have you learned about the cooperation peace practice of working with others for the common good?',
          )
        }
        multiline={true}
        valueProperty={'cooperationDebrief1'}
        of={gameDebrief}
        className={style.textArea}
      />

      <ActionInputField
        text={
          '5. ' +
          t(
            'word.gameDebrief.5',
            'What have you learned about the abundance peace practice of supporting the peacemakers?',
          )
        }
        multiline={true}
        valueProperty={'abundanceDebrief1'}
        of={gameDebrief}
        className={style.textArea}
      />

      <ActionInputField
        text={
          '6. ' +
          t(
            'word.gameDebrief.6',
            'What have you learned about the love peace practice of praying for and visualizing Peace on Earth?',
          )
        }
        multiline={true}
        valueProperty={'loveDebrief1'}
        of={gameDebrief}
        className={style.textArea}
      />

      <ActionInputField
        text={
          '7. ' +
          t(
            'word.gameDebrief.7',
            'What have you learned about the faith peace practice of engaging others to play the Peace Game?',
          )
        }
        multiline={true}
        valueProperty={'faithDebrief1'}
        of={gameDebrief}
        className={style.textArea}
      />
      <ActionInputField
        text={
          '8. ' +
          t(
            'word.gameDebrief.8',
            'What was your greatest learning and growth from playing the Peace Game?',
          )
        }
        multiline={true}
        valueProperty={'faithDebrief2'}
        of={gameDebrief}
        className={style.textArea}
      />
      <ActionInputField
        text={
          '9. ' +
          t(
            'word.gameDebrief.9',
            'If inspired, please provide a testimonial we can share with others.',
          )
        }
        multiline={true}
        valueProperty={'faithDebrief3'}
        of={gameDebrief}
        className={style.textArea}
      />
    </div>
  );
}
