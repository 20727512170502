import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { UserAccount } from 'profile-plus/lib/shapes/UserAccount';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../routes';

export function useProfileCompleted() {
  const auth = useAuth<Player, UserAccount>();
  const navigate = useNavigate();

  const user = auth.user;

  // check if user has completed profile setup
  // and redirect to join page if user has no team and profile setup is completed
  const checkIsCompleted = () => {
    if (!user?.profileSetupCompleted) {
      navigate(ROUTES.register.path);
    }

    if (user?.profileSetupCompleted && !user?.currentTeam) {
      navigate(ROUTES.join_team.path);
    }
  };

  return {
    checkIsCompleted,
  };
}
