import React from 'react';
import './Meeting01.scss';
import { default as style } from './Meeting01.scss.json';
import { registerPackageModule, linkedComponent } from '../../../package';
import { useTranslate } from '@tolgee/react';
import { Video } from '../../atoms/Video';
import { UnsortedList } from '../../atoms/UnsortedList';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { OrderedList } from '../../atoms/OrderedList';
import Headline from '../../atoms/Headline';

export function Meeting01() {
  let { t } = useTranslate();
  const navigate = useNavigate();

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'resource-Meeting01';
  return (
    <>
      <div className={style.content}>
        <Headline
          subtitle={t('word.introduction', 'Introduction')}
          lined="black"
        />
        <p>
          {t(
            prefix + '.paragraph01',
            'The Start-Up Meeting sets the stage for the success of the Peace Game. It helps a group of individuals to begin bonding and then mesh together into a team capable of achieving significant individual and collective results. This is no small feat to achieve in two hours. The meeting script has been developed over many years and various programs into a finely crafted process. If used as laid out, it will perform for you.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph02',
            'But it does more than build a team. It preempts the problems that can cause a team or individual team members to break down. Each time we debriefed a team and discovered a breakdown, we went back to the Start-Up Meeting and redesigned it so it could head off these issues.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph03',
            'The meeting starts by creating a team purpose statement to enable alignment and a shared commitment. You then review the structure so people know the specifics of what they will be doing over the next eight meetings. Next you prepare the team for the first action area. Then you move into scheduling the subsequent meetings and selecting people to lead them. You end the meeting with a process to enable mutual accountability among team members, the backbone of a high-performance team. Each meeting element is carefully scripted so that with preparation and practice you can easily implement it.',
          )}
        </p>
        <Headline
          subtitle={t('word.Pre-Meeting', 'Pre-Meeting Preparation')}
          lined="black"
        />
        <p>
          {t(
            prefix + '.paragraph07',
            'Note: You can do these meetings virtually using Zoom, Google Meet, and Facebook Messenger Rooms.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph08',
            'Here are elements to help you prepare to lead the meeting. Ignore those that apply for in-person meetings if your team is meeting virtually.',
          )}
        </p>
        <UnsortedList>
          <li>
            <u>{t('word.refreshments', 'Refreshments')}</u>
            {': '}
            {t(
              prefix + 'bullet03',
              'If the meeting is in your home or held locally provide a fifteen-minute window at the beginning and again at the end of the meeting for socializing. While it may seem obvious, it is important to serve light refreshments. This increases the experience of community building. It is also an interesting phenomenon of human nature how such social niceties strengthen our bonding with others.',
            )}
          </li>
          <li>
            <u>{t('word.roomSetup', 'Room Setup')}</u>
            {': '}
            {t(
              prefix + 'bullet04',
              'If meeting in person, arrange the room in a U shape with you at the front. This allows people to see one another and increases the sense of intimacy. It also makes it easier for others to see you.',
            )}
          </li>
          <li>
            <u>{t('word.attendance', 'Attendance')}</u>
            {': '}
            {t(
              prefix + 'bullet05',
              'If meeting in person create a sign-in sheet with room for the person’s name, address, e-mail, and phone number.',
            )}
          </li>
          <li>
            <u>{t('word.timing', 'Timing')}</u>
            {': '}
            {t(
              prefix + 'bullet08a',
              'Tell the group at the start of the meeting what time you propose to end and check that everyone can stay until the end. Make sure you end on time.',
            )}
            {<br></br>}
            {t(
              prefix + 'bullet08b',
              'Each element in the meeting agenda has an approximate time associated with it. It is rare that any meeting element will go for exactly that amount of time, so use it as a guideline. If one meeting element runs longer you will need to make up the time by shortening another.',
            )}
            {<br></br>}
            {t(
              prefix + 'bullet08c',
              'Give the team shared responsibility for timekeeping. If any point seems likely to take a lot longer than you planned, tell the team and ask them to decide whether to extend the meeting or quickly finish the point.',
            )}
          </li>
          <li>
            <u>{t('word.preWork', 'Pre-Work')}</u>
            {': '}
            {t(
              prefix + 'bullet09',
              'Along with building the team, a key part of this meeting is to prepare people for the next meeting. Go to the Peace Game app Action 1 and select your goal and create your action plan for implementing it. You will present your goal and plan at the meeting. There is a meeting guide for each of the Actions in the Resources Section of the Peace Game App.',
            )}
          </li>
        </UnsortedList>
        <Headline
          subtitle={t(
            'word.AgendaHeader',
            'Meeting Agenda and Approximate Times',
          )}
          lined="black"
        />
        <OrderedList type={1}>
          <li>
            {t('word.welcomPurpose', 'Welcome and Purpose')} - 10{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t('word.reviewHow', 'Review How the Peace Game Works')} - 5{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t('word.createStatement', 'Create Peace Team Purpose Statement')} -
            25 {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t('word.createEmopermentPlan', 'Create Empowerment Action Plan')} -
            45 {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t('word.SelectLeader', 'Select Action Leaders')} - 15{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t('word.MAA', 'Create Mutual Accountability Agreement')} - 15{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t('word.CloseandNextGoals', 'Close and Review Next Meeting Goals')}{' '}
            - 5 {t('word.mintues', 'minutes')}
          </li>
        </OrderedList>
        <p>{t('word.total2hours', 'Total Time 2 - hours')}</p>
        <Headline
          subtitle={t('word.meetingScript', 'Meeting Script')}
          lined="black"
        />
        <OrderedList type={1}>
          <li>
            <u>{t('word.welcomPurpose', 'Welcome and Purpose')}</u> - 10{' '}
            {t('word.mintues', 'minutes')}{' '}
            <UnsortedList>
              <li>
                {t(
                  prefix + 'a2_bullet01',
                  'Make sure everyone is acquainted with one another.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a2_bullet02',
                  'Explain that the Peace Game requires a team effort to accomplish its ambitious goals, and my job, as PeaceTeam Leader, is to keep the team on track so individuals can successfully accomplish the individual and collective actions they choose.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a2_bullet03',
                  'Share why you chose to start a Peace Team.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a2_bullet04',
                  'State the purpose of the Start-Up Meeting.',
                )}
                <OrderedList type={1}>
                  <li>
                    {t(
                      prefix + 'a2_bullet04a',
                      'Help build the group into a team.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a2_bullet04b',
                      'Create an Action Plan for the Empowerment Action.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a2_bullet04c',
                      'Create a coaching protocol for becoming a high performing team.',
                    )}
                  </li>
                </OrderedList>
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t('word.reviewHow', 'Review How the Peace Game Works')}</u> - 10{' '}
            {t('word.mintues', 'minutes')}
            {<br></br>}
            <u>
              {t(prefix + 'a3_bullet01', 'Seven Actions')}
              {': '}
            </u>
            <UnsortedList>
              <li>
                {t(
                  prefix + 'a3_bullet02',
                  'The seven actions of the Peace Game — empowerment, oneness, unity, cooperation, abundance, love and faith — are transformational practices that when collectively applied evolve a person into an emanation of peace and everything around them into manifestations of peace.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a3_bullet03',
                  'They are practices which mean their impact deepens as one invests in them over time. There is also a meditation associated with each action to help internalize it.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a3_bullet04',
                  "The Peace Game is divided into four parts that represent the different facets of creating peace in one's life and world. Action 1 on Empowerment focuses on capacity building, Actions 2 to 4 on Oneness, Unity and Cooperation focus on peace building, Actions 5 and 6 on Abundance and Love focus on field building, and Action 7 on Faith focuses on movement building.",
                )}
              </li>
              <li>
                {t(
                  prefix + 'a3_bullet05',
                  'At its essence the Peace Game is an empowerment platform enabling people to envision and create a life of peace for themselves and influence those in their world to do the same. At scale it can reweave the social fabric of a community.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a3_bullet06',
                  'There are three levels of play based on effort, time, or both. You’re awarded a Bronze, Silver or Gold Peace Medal commensurate with your level of play. This is complemented by the number of Peace Actions associated with your effort.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a3_bullet07',
                  'Playing the Peace Game empowers, enlightens and transforms those players and communities who wholeheartedly engage with it.',
                )}
              </li>
            </UnsortedList>
            <u>
              {t(prefix + 'a32_bullet01', 'Game Structure')}
              {': '}
            </u>
            <UnsortedList>
              <li>
                <u>{t(prefix + 'a32_bullet02', 'Peace Team')}</u>
                {': '}
                {t(
                  prefix + 'a32_bullet03',
                  'You play as part of a 5-8 person Peace Team which meets in person or on Zoom biweekly and serves as a support system.',
                )}
              </li>
              <li>
                <u>{t(prefix + 'a32_bullet04', 'Time Commitment')}</u>
                {': '}
                {t(
                  prefix + 'a32_bullet05',
                  'The time commitment is 2-hours every two weeks to attend the meetings and approximately 2-hours to take the action.',
                )}
              </li>
              <li>
                <u>{t(prefix + 'a32_bullet06', 'Peace Game App')}</u>
                {': '}
                {t(
                  prefix + 'a32_bullet07',
                  'You use a Peace Game app to take the actions.',
                )}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>
              {t('word.createStatement', 'Create Peace Team Purpose Statement')}
            </u>{' '}
            - 25 {t('word.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + 'a4_bullet02',
                  'Explain you will be creating a team purpose statement that will integrate the purpose of each individual member of the team. A collective purpose is fundamental to creating a team that sustains its commitment over time.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a4_bullet03',
                  'Ask each person to take up to a minute to share his or her purpose for joining the team. Write down the key points.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a4_bullet04',
                  'When complete, merge key phrases and words of each individual’s statement to create a joint purpose statement. Make sure some part of everyone’s purpose is included in the team purpose statement. If the team wishes to wordsmith it further, ask those who are interested to form a group and bring it back to the next meeting.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a4_bullet05',
                  'When created to everyone’s satisfaction, ask people to write down the team purpose statement. This serves as the North Star to guide each person and the group in playing the Peace Game.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a4_bullet06',
                  'Invite team members to give themselves a team name.',
                )}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>
              {t(
                'word.createEmopermentPlan2',
                'Create Action 1: Empowerment Action Plan',
              )}
            </u>{' '}
            - 45 {t('word.mintues', 'minutes')}
            <UnsortedList>
              <li>
                <u>{t(prefix + 'a5Bullet05a', 'Set up Action')}</u> - 10{' '}
                {t('word.mintues', 'minutes')}
                <OrderedList type={1}>
                  <li>
                    {t(
                      prefix + 'a5Bullet05a1',
                      'Explain that this action is about empowering you to become an effective agent of change.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a5Bullet05b',
                      'Ask everyone to open their Peace Game App and review the “Learn” and “Plan” sections of the Empowerment Action.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a5Bullet05c',
                      'In the “Plan” section share the bronze, silver, or gold Peace Medal you selected and why you chose it. Then share your peace action plan on how you will implement it.',
                    )}
                  </li>
                </OrderedList>
              </li>
              <li>
                <u>{t(prefix + 'a6Bullet05a', 'Create Action Plan')}</u> - 20{' '}
                {t(prefix + 'mintues', 'minutes')}
                <p>
                  {t(
                    prefix + 'a6Bullet05b',
                    'Invite them now to select their Empowerment Action 1 goal and create their Action Plan.',
                  )}
                </p>
              </li>
              <li>
                <u>{t(prefix + 'a6Bullet05c', 'Share Action Plan')}</u> - 20{' '}
                {t(prefix + 'mintues', 'minutes')}
                <p>
                  {t(
                    prefix + 'a6Bullet05d',
                    'Invite each team member to share their action plan.',
                  )}
                </p>
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t(prefix + 'a7Bullet06a', 'Select Action Leaders')}</u>
            {':'} - 20 {t('word.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + 'a7Bullet06b',
                  'Share that as Team Leader you will lead this meeting and the closing meeting. Read the titles and purpose to the seven actions below and ask after you have read them, who is willing to lead each of the Action Meetings.',
                )}
                {<br></br>}
                {<br></br>}
                <u>{t(prefix + 'a7Bullet06ci', 'Action 1 – Empowerment')}</u>
                {': '}
                {t(
                  prefix + 'a7Bullet06e',
                  'Empowering Yourself as an Agent of Change',
                )}
                {<br></br>}
                {<br></br>}
                <u>{t(prefix + 'a7Bullet06ei', 'Action 2 – Oneness')}</u>
                {': '}
                {t(prefix + 'a7Bullet06f', 'Befriending the Other')}
                {<br></br>}
                {<br></br>}
                <u>{t(prefix + 'a7Bullet06fi', 'Action 3 – Unity')}</u>
                {': '}
                {t(prefix + 'a7Bullet06g', 'Celebrating Our Shared Humanity')}
                {<br></br>}
                {<br></br>}
                <u>{t(prefix + 'a7Bullet06gi', 'Action 4 – Cooperation')}</u>
                {': '}
                {t(
                  prefix + 'a7Bullet06h',
                  'Working with Others for the Common Good',
                )}
                {<br></br>}
                {<br></br>}
                <u>{t(prefix + 'a7Bullet06hi', 'Action 5 – Abundance')}</u>
                {': '}
                {t(
                  prefix + 'a7Bullet06j',
                  'Supporting Organizations Creating Peace on Earth',
                )}
                {<br></br>}
                {<br></br>}
                <u>{t(prefix + 'a7Bullet06ji', 'Action 6 – Love')}</u>
                {': '}
                {t(
                  prefix + 'a7Bullet06k',
                  'Praying for and Visualizing Peace on Earth',
                )}
                {<br></br>}
                {<br></br>}
                <u>{t(prefix + 'a7Bullet06ki', 'Action 7 – Faith')}</u>
                {': '}
                {t(
                  prefix + 'a7Bullet06l',
                  'Engaging People to Play the Peace Game',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a7Bullet06m',
                  'If something unexpected occurs and someone cannot attend a meeting, request that the person notify you, as Peace Team Leader, in advance and provide you with his or her goal and what they achieved. You will communicate with those who miss the meeting afterward to let them know what happened.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a7Bullet06cn',
                  'Invite team members to enter the Action Leaders in the Team section of the Peace Game App where they previously entered the days/times for each of the 9 meetings.',
                )}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>
              {t(
                prefix + 'AgendaItem06',
                'Create Mutual Accountability Agreement',
              )}
            </u>{' '}
            - 15 {t('word.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + 'a8Bullet06a',
                  'The key to an effective team is an agreement among team members to be accountable to one another.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a8Bullet06b',
                  'Explain what a successful team looks like.',
                )}
                <OrderedList type={1}>
                  <li>
                    <u>{t(prefix + 'a8Bullet06b1', 'Commitment')}</u>
                    {': '}
                    {t(
                      prefix + 'a8Bullet06b2',
                      'Team members create their goal and action plan before each meeting, come on time to meetings, and take the actions they agreed to do.',
                    )}
                  </li>
                  <li>
                    <u>{t(prefix + 'a8Bullet06b3', 'Support')}</u>
                    {': '}
                    {t(
                      prefix + '86Bullet06b4',
                      'Team members provide coaching to help one another accomplish the actions they committed to do. A team member with a problem can count on the support of the team and is willing to accept feedback.',
                    )}
                  </li>
                </OrderedList>
              </li>
              <li>
                {t(
                  prefix + 'a8Bullet06b5',
                  'Explain the need for commitment to the game.',
                )}
                <OrderedList type={1}>
                  <li>{t(prefix + 'a8Bullet06b6', 'It takes time.')}</li>
                  <li>
                    {t(
                      prefix + 'a8Bullet067',
                      'It asks you to be accountable—to come to meetings, set goals, and follow through on them.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a8Bullet068',
                      'It asks you to help your teammates do the same.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a8Bullet069',
                      'It demands courage and a willingness to step out of your comfort zone.',
                    )}
                  </li>
                </OrderedList>
              </li>
              <li>
                {t(
                  prefix + 'a9Bullet06b5',
                  'Explain the process of providing coaching:',
                )}
                <UnsortedList>
                  <li>
                    {t(
                      prefix + 'a9Bullet06b6',
                      'A key to success for individuals and the team at-large is that people support one another to accomplish the goals they set for themselves. Specifically, this means giving one another permission to be coached if things break down. Without an agreement to coach one another, team members often hold back giving feedback and the support a teammate needs to achieve his or her goal.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a9Bullet067',
                      'Point out that we usually have a preference, although rarely articulated, for how we wish to receive feedback. Some people need to be reminded of something repeatedly until they have done it, others prefer to be reminded only once. Communicate how you like to receive feedback.',
                    )}
                  </li>
                </UnsortedList>
              </li>
              <li>
                {t(
                  prefix + 'a10Bullet09',
                  'Explain that we will now make all this concrete through a process that asks team members to agree to follow four guidelines to enable their and the team’s success. To succeed with this game, team members need to:',
                )}
                <OrderedList type={1}>
                  <li>
                    {t(
                      prefix + 'a10Bullet09a',
                      'Set goals, create plans for taking each action and tell this to the team.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a10Bullet09b',
                      'Take the actions to implement your plan.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a10Bullet09c',
                      'Respect the rest of the team by attending all the meetings and being on time.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a10Bullet09d',
                      'Coach one another if there is a breakdown in any of these commitments.',
                    )}
                  </li>
                </OrderedList>
              </li>
              <li>
                {t(
                  prefix + 'a11Bullet10',
                  'Ask each person one-by-one the following:',
                )}
                <OrderedList type={1}>
                  <li>
                    {t(
                      prefix + 'a11Bullet10a',
                      'Are you willing to commit to these four Peace Game guidelines?',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a11Bullet10b',
                      'What is your preferred style for receiving coaching?',
                    )}
                  </li>
                </OrderedList>
                <p>
                  {t(
                    prefix + 'a11Bullet10c',
                    'Note: If some people are hesitant, respectfully draw out their concerns and help them work through them. Thank each person who makes a commitment. At the completion of this exercise let the team know that having agreed to mutual accountability they have laid down the foundation for the success of their participation in the Peace Game. They have taken a small step for themselves as an individual and a large step for humanity.',
                  )}
                </p>
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t(prefix + 'AgendaItem07', 'Review Next Meeting Goals')}</u> - 5{' '}
            {t('word.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + 'a12Bullet07a',
                  'Remind team members to implement their Empowerment Action Plan, report their results in the Peace Game App and answer 4 questions in the Share section for the next meeting.',
                )}
              </li>
            </UnsortedList>
          </li>
        </OrderedList>
        <Headline
          subtitle={t('word.Post-Meeting', 'Post Meeting Responsibilities')}
          lined="black"
        />
        <p>
          {t(
            prefix + '.paragraph11',
            'Do the following tasks right after the Start-Up Meeting.',
          )}
          <OrderedList type={1}>
            <li>
              {t(
                prefix + '.paragraph12',
                'Check in with the Meeting 1 Action Leader to make sure the person is on track with their plan for the meeting. Offer your support if desired. Do this for each subsequent Action Leader.',
              )}
            </li>
            <li>
              {t(
                prefix + '.paragraph13',
                'If the team did not complete their team purpose statement, complete it and send it out.',
              )}
            </li>
            <li>
              {t(
                prefix + '.paragraph14',
                'Check in midway with all team members to see how they are doing in carrying out their action plan. Offer your support if they wish any help.',
              )}
            </li>
          </OrderedList>
        </p>
      </div>
    </>
  );
}
