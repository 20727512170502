import { linkedComponent } from '../../package';
import { useEffect, useState } from 'react';
import { CoreMap } from 'lincd/lib/collections/CoreMap';
import { NamedNode } from 'lincd/lib/models';
import './TeamTopicParticipationScores.scss';
import style from './TeamTopicParticipationScores.scss.json';
import { Team } from 'lincd-irlcg/lib/shapes/Team';
import { ActionThumbnail } from 'lincd-irlcg/lib/components/molecules/ActionThumbnail';
import { Topic } from 'lincd-irlcg/lib/shapes/Topic';
import Divider from '../atoms/Divider';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { Spinner } from '../atoms/Spinner';
import { useTranslate } from '@tolgee/react';

export const TeamTopicParticipationScores = linkedComponent<Team>(
  Team,
  ({ source }) => {
    const [teamParticipations, setTeamParticipations] =
      useState<CoreMap<NamedNode, number>>();
    const [loading, setLoading] = useState(true);
    const { t } = useTranslate();

    useEffect(() => {
      Team.getTeamParticipation(source)
        .then((result) => {
          setTeamParticipations(result);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }, [source]);

    let topics = (teamParticipations ? [...teamParticipations.keys()] : [])
      .map((topic) => {
        return new Topic(topic);
      })
      .sort((a, b) => {
        return a.uri > b.uri ? 1 : -1;
      });

    return (
      <div className={style.HouseholdScoreboard}>
        <div className={style.Header}>
          {/* <img src="/images/card_img.avif" alt="Card Image" width="80px" /> */}
          <h2 className={style.Title}>
            {t('word.teamProgress', 'Team Progress')}
          </h2>
          <Divider />
        </div>
        <div className={style.Content}>
          <div className={style.ScoreboardTable}>
            <div className={style.TableHeader}>
              <div>
                <h5>{t('word.actions', 'Actions')}</h5>
              </div>
              <div>
                <h5>{t('word.percentReporting', '% Reporting')}</h5>
              </div>
            </div>
            {loading ? (
              <Spinner
                color="white"
                aria-label={t('word.loading', 'Loading')}
              />
            ) : (
              teamParticipations && (
                <div className={style.TableContent}>
                  {topics.map((topic) => {
                    let participation = teamParticipations.get(topic.namedNode);
                    return (
                      <div key={topic.uri} className={style.scoreItem}>
                        <ActionThumbnail
                          of={topic}
                          aria-label={t(
                            'aria.actionThumbnail',
                            'Action Thumbnail',
                          )}
                        />
                        <h3>
                          {participation === 100 ? (
                            <img
                              src={asset('/images/icons/100-percent.svg')}
                              aria-label={t(
                                'aria.hundredPercentParticipation',
                                '100% Participation',
                              )}
                              className={style.percentIcon}
                            />
                          ) : (
                            participation + ' %'
                          )}
                        </h3>
                      </div>
                    );
                  })}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  },
);
