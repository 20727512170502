import React from 'react';
import { AVAILABLE_LANGUAGES } from '../../utils/tolgee';
import { useTolgee, useTranslate } from '@tolgee/react';
import { Person } from 'profile-plus/lib/shapes/Person';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import './SwitchLanguage.scss';
import style from './SwitchLanguage.scss.json';
import { cl } from 'lincd/lib/utils/ClassNames';

interface SwitchLanguageProps {
  className?: string;
}
export const SwitchLanguage = ({ className }: SwitchLanguageProps) => {
  const tolgee = useTolgee();
  let { t } = useTranslate();
  const [language, setLanguage] = React.useState(tolgee.getLanguage());
  const auth = useAuth<Person>();

  const onChangeLanguage = (e) => {
    let newLang = e.target.value;
    console.log('changing language to: ', newLang);
    setLanguage(newLang);

    //store language preference in the user profile
    if (auth.user) {
      auth.user.languagePreference = newLang;
    }

    tolgee.changeLanguage(newLang).then(() => {
      console.log('language updated to: ' + tolgee.getLanguage());
    });
  };

  return (
    <div className={cl(style.root, className)}>
      {t('word.language', 'Language')}
      {': '}
      <select onChange={onChangeLanguage} value={language}>
        {AVAILABLE_LANGUAGES.map((lang) => {
          return (
            <option key={lang} value={lang}>
              {lang}
            </option>
          );
        })}
      </select>
    </div>
  );
};
