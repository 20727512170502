import React from 'react';
import { Button } from 'lincd-mui-base/lib/components/Button';
import style from './GoToButton.scss.json';
import './GoToButton.scss';
import { cl } from 'lincd/lib/utils/ClassNames';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

interface GoToButtonProps {
  className?;
  endIcon?;
  color?;
  fullWidth?: boolean;
  type?;
  text;
  loading?: boolean;
  onClick?: (e) => void;
}
const GoToButton = ({
  className,
  color = 'secondary',
  fullWidth = false,
  endIcon = true,
  text,
  type = 'button',
  onClick,
  loading,
}: GoToButtonProps) => {
  return (
    <>
      <Button
        endIcon={
          endIcon && (
            <img src={asset('/images/icons/RightArrowIcon.svg')} alt="Button" />
          )
        }
        className={cl(style.nextButton, className)}
        fullWidth={fullWidth}
        color={color}
        onClick={onClick}
        type={type}
        disabled={loading}
      >
        {text} &nbsp;
      </Button>
    </>
  );
};

export default GoToButton;
