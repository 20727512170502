import { Dialog } from '@capacitor/dialog';
import { useTranslate } from '@tolgee/react';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { Team } from 'lincd-irlcg/lib/shapes/Team';
import { Button } from 'lincd-mui-base/lib/components/Button';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container } from '../layout/Container';
import { DefaultLayout } from '../layout/DefaultLayout';
import { ROUTES } from '../routes';
import './JoinTeam.scss';
import style from './JoinTeam.scss.json';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import Header from '../components/molecules/Header';
import JoinInputButton from '../components/molecules/JoinInputButton';

function JoinTeam() {
  let { t } = useTranslate();
  let prefix = 'joinTeam';
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  const user = useAuth().user as Player;

  const onSelectButton = (type: string) => {
    // add user to team trial / event
    Team.addUserToTeam(type, user, false)
      .then(async (result) => {
        if (result.team) {
          // add user to all action
          Team.addUserToAction(user).then((result) => {
            setLoading(false);

            // set user.profileSetupCompleted to true after the user is added to a team
            // to prevent the user from navigating back or manually entering the URL to the home page
            user.profileSetupCompleted = true;

            navigate(ROUTES.index.path);
          });
        } else if (result.error) {
          setLoading(false);
          await Dialog.alert({
            title: 'Something went wrong',
            message: result.error.message,
          });
        } else {
          setLoading(false);
          await Dialog.alert({
            title: t(prefix + '.teamNotFoundTitle', 'Team not found'),
            message: t(
              prefix + '.teamNotFoundMessage',
              `Team ${type} not found.`,
            ),
          });
        }
      })
      .catch((error) => {
        console.error(`Error joining  ${type}:`, error);
        alert(`Joining  ${type} failed`);
      });
  };

  // const joinGroup = async (teamID: number) => {
  //   const userUID = cometChat.getLoggedInUser()?.getUid();
  //   let GUID = teamID.toString(); // Convert teamID to string
  //   var password = '';
  //
  //   CometChat.joinGroup(GUID, CometChat.GroupType.Public, password).then(
  //     (group) => {
  //       console.log('Group joined successfully:', group);
  //     },
  //     async (error) => {
  //       console.log('Group joining failed with exception:', error);
  //       if (error.code === 'ERR_ALREADY_JOINED') {
  //         console.error('User already joined group:', error.message);
  //       }
  //       if (error.code === 'ERR_GUID_NOT_FOUND') {
  //         console.log('The group chat will created now for you.');
  //         await createGroupTeamChat(teamID, userUID);
  //         joinGroup(teamID);
  //         // navigate(-1);
  //         // alert(
  //         //   'Group does not exist, please ask your Team Leader to create a group first by starting the conversation',
  //         // );
  //       }
  //     },
  //   );
  // };

  // async function createGroupTeamChat(teams, member) {
  //   const GUID = teams;
  //   const groupName = 'Team ' + GUID;
  //   const options = {
  //     method: 'POST',
  //     headers: {
  //       accept: 'application/json',
  //       onBehalfOf: 'admin_peacegame',
  //       'content-type': 'application/json',
  //       apikey: process.env.COMET_CHAT_API_KEY,
  //     },
  //     body: JSON.stringify({
  //       guid: GUID,
  //       name: groupName,
  //       type: 'public',
  //       icon: 'https://www.peacegame.earth/images/peace-game-logo-spinning-2.gif',
  //       owner: 'admin_peacegame',
  //       members: {
  //         // admins: ['admin_peacegame'],
  //         participants: [member],
  //       },
  //       // Add other properties as needed (e.g., password, icon, description, etc.)
  //     }),
  //   };
  //   fetch('https://2517168ecce052aa.api-us.cometchat.io/v3/groups', options)
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response) {
  //         // alert('Team Chat with ID:' + teamChatID + ' has been created');
  //         console.log(response);
  //       }
  //     })
  //     .catch((err) => console.error(err));
  // }

  return (
    <DefaultLayout>
      <Header
        title={t(prefix + '.header', 'GET STARTED')}
        inverted={true}
        backButton={user.profileSetupCompleted}
      />
      <Container maxWidth={'sm'}>
        <div
          className={style.JoinTeamContainer}
          aria-labelledby={t(
            prefix + '.containerLabel',
            'Peace Game Container',
          )}
        >
          <JoinInputButton
            description={'Put in your Peace Team Number here.'}
          />

          {/* if user doesnt join to any team, show event button */}
          {!user.currentTeam && (
            <>
              <h2 className={style.subText}>
                {t(prefix + '.separator1', 'Or')}
              </h2>
              <Button
                variant={'filled'}
                className={style.buttonJoinTeam}
                color={'primary'}
                onClick={() => onSelectButton('event')}
                fullWidth={true}
                disabled={loading}
                aria-label={t(
                  prefix + '.peaceGameEventButton',
                  'Peace Game Event button',
                )}
              >
                {t(
                  prefix + '.peaceGameEventButtonText',
                  'Join Peace Day Event',
                )}
              </Button>
              <p
                aria-labelledby={t(
                  prefix + '.peaceGameEventInfo',
                  'Peace Game Event Info',
                )}
              >
                {t(
                  prefix + '.peaceGameEventInfoText',
                  'Choose this option if you are participating in a Peace Day event.',
                )}
              </p>
            </>
          )}

          {/* if user doesnt join to any team, show trial button */}
          {!user.currentTeam && (
            <>
              <h2 className={style.subText}>
                {t(prefix + '.separator2', 'Or')}
              </h2>
              <Button
                variant={'filled'}
                className={style.buttonJoinTeam}
                color={'primary'}
                onClick={() => onSelectButton('trial')}
                fullWidth={true}
                disabled={loading}
                aria-label={t(
                  prefix + '.peaceGameTrialButton',
                  'Peace Game Trial button',
                )}
              >
                {t(
                  prefix + '.peaceGameTrialButtonText',
                  'Browse Sample Peace Actions',
                )}
              </Button>
              <p
                aria-labelledby={t(
                  prefix + '.peaceGameTrialInfo',
                  'Peace Game Trial Info',
                )}
              >
                {t(
                  prefix + '.peaceGameTrialInfoText',
                  'Choose this option if you are not on a Peace Team yet and wish to learn more about the Peace Game App.',
                )}
              </p>
            </>
          )}
        </div>
      </Container>
    </DefaultLayout>
  );
}

export default JoinTeam;
