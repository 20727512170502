import { useEffect, useState } from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import './GlobalGameBoard.scss';
import style from './GlobalGameBoard.scss.json';
import { Gameboard } from '../components/molecules/Gameboard';
import { TopicScore } from 'lincd-irlcg/lib/shapes/TopicScore';
import { Topic } from 'lincd-irlcg/lib/shapes/Topic';
import { ImageObject } from 'lincd-schema/lib/shapes/ImageObject';
import Section from '../components/molecules/Section';
import MainMenu from '../components/molecules/MainMenu';
import { Container } from '../layout/Container';
import Card from '../components/molecules/Card';
import { getDesktopBreakpoints } from '../utils/desktopBreakpoint';
import { Spinner } from '../components/atoms/Spinner';
import { useTranslate } from '@tolgee/react';
import { useProfileCompleted } from '../hooks/useProfileCompleted';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { UserAccount } from 'profile-plus/lib/shapes/UserAccount';

function GlobalGameBoard() {
  const { t } = useTranslate();
  let prefix = 'globalGameBoard';
  const [gameboardTotals, setGameboardTotals] = useState<any>();
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 1024,
  );
  const [loading, setLoading] = useState(true);
  const { checkIsCompleted } = useProfileCompleted();
  const auth = useAuth<Player, UserAccount>();
  let user = auth.user;

  // check if user has completed profile setup
  useEffect(() => {
    checkIsCompleted();
  }, []);

  const desktopBreakpoint = 1024;

  useEffect(() => {
    TopicScore.getGameBoardTotals()
      .then((result) => {
        setGameboardTotals(result);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });

    //to get breakpoint of desktop
    getDesktopBreakpoints((width) => setWidth(width));
  }, []);

  // Reusable function to render Card
  const renderCard = (topic: Topic, score: number, medal: any) => {
    return (
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      <Card
        key={topic.identifier.toString()}
        identifier={'Action ' + topic.identifier}
        title={topic.name}
        description={topic.description}
        medal={medal}
        value={score}
        image={ImageObject.create({ contentUrl: topic.image.contentUrl })}
      />
    );
  };

  const isInCertainMode =
    user.isInCertainMode('event') || user.isInCertainMode('trial');

  return (
    <DefaultLayout>
      <div className={style.bigContainer}>
        <div className={style.container}>
          {loading ? (
            <div className={style.loading}>
              <Spinner color="white" />
            </div>
          ) : (
            gameboardTotals && (
              <Container maxWidth={width >= desktopBreakpoint ? 'md' : 'sm'}>
                <Gameboard totalScore={gameboardTotals} />
                <Section
                  title={t('word.globalPeaceActions', 'Global Peace Actions')}
                  data={gameboardTotals.globalTopicTotal.globalTopicTotal}
                  // medalion={peaceResult.globalTopicTotal.globalMedalTopic}
                  renderCardFunction={renderCard}
                />
                {isInCertainMode ? null : (
                  <>
                    <Section
                      title={t('word.teamPeaceActions', 'Team Peace Actions')}
                      data={gameboardTotals.teamTopicTotals.teamTopicTotals}
                      // medalion={peaceResult.teamTopicTotals.teamMedalTopic}
                      renderCardFunction={renderCard}
                    />
                    <Section
                      title={t('word.myPeaceActions', 'My Peace Actions')}
                      data={gameboardTotals?.myTopicTotals.myTopicTotals}
                      medalion={gameboardTotals?.myTopicTotals.myMedalTopic}
                      renderCardFunction={renderCard}
                    />
                  </>
                )}
              </Container>
            )
          )}
        </div>

        <MainMenu />
      </div>
    </DefaultLayout>
  );
}

export default GlobalGameBoard;
