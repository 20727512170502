import React from 'react';
import style from './3_UnityLearn.scss.json';
import './3_UnityLearn.scss';
import { cl } from 'lincd/lib/utils/ClassNames';
import GoToButton from '../../atoms/GoToButton';
import ActionContent from '../../molecules/ActionContent';
import { useTranslate } from '@tolgee/react';
import { topic3 } from '../../../data-references';
import { UnsortedList } from '../../atoms/UnsortedList';
import { Video } from '../../atoms/Video';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { ActionPurpose } from '../../molecules/ActionPurpose';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { getActiveLanguage } from '../../../utils/tolgee';

const UnityLearn = () => {
  let topic = topic3;
  let { t } = useTranslate();
  const navigate = useNavigate();
  const activeLanguage = getActiveLanguage();

  let prefix = 'topic' + topic.identifier;
  return (
    <>
      <div className={style.content}>
        <ActionPurpose
          topic={topic}
          purposeText={t(
            prefix + '.learn.purposeText',
            'The Unity action is to experience and celebrate that which we have in common with other human beings and cultures.',
          )}
          actionGuidanceText={
            <p>
              {t(
                prefix + '.learn.actionGuidesText',
                'Your life is the game, organize this action around it. Look for people, situations and opportunities to celebrate that which you have in common with others. Doing this requires us to transcend, value, and accept our differences. We call this a unitive experience.',
              )}
              <br />
              <br />
              <UnsortedList>
                <li>
                  {t(
                    prefix + '.learn.actionGuidesListItem1',
                    'People can include: family members, friends, co-workers and community members.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.actionGuidesListItem2',
                    'Situations can include: unitive experiences that celebrate and honor our diversity including food, music, dance, culture, spirituality, and worldviews. For example, creating a peace table where you invite people from different cultures to share their food and break bread together.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.actionGuidesListItem3',
                    'Opportunities can include: existing online or community unitive events in which you can participate and invite others to join you. And/or a unitive event you create. How you activate this action is only limited by your imagination. The whole world is your palette. ',
                  )}
                </li>
              </UnsortedList>
              <br />
              {t(
                prefix + '.learn.actionGuidesText2',
                'Make sure to do this action on your unity growing edge. Don’t just engage people with whom you are comfortable, use this action as an opportunity to learn, grow, and transform your relationships.',
              )}
            </p>
          }
        />

        <ActionContent
          title={t('word.unityOriginStory', 'Unity Origin Story')}
          content={
            <p>
              <div className={style.originImage}>
                <img
                  src={asset('/images/First-Earth-Run-collection-3.webp')}
                  alt='[1] Children talking with global runner from United States, Phil Wiengarner. Hiroshima, Japan [2] Dusk just outside Paris. This baby boy is now a young man. Paris, France [3] In Red Square, many torches were dispersed throughout the country. A strong message of support was delivered by President Mikhail Gorbachev, "The Soviet Union greets those who are selflessly and courageously carrying the torch of peace across the planet...strengthening the spirit of cooperation and mutual understanding among peoples." Moscow, Russia'
                />
                <p>
                  {t(
                    prefix + '.learn.originImageAlt1',
                    '[1] Children talking with global runner from United States, Phil Wiengarner. Hiroshima, Japan [2] Dusk just outside Paris. This baby boy is now a young man. Paris, France [3] In Red Square, many torches were dispersed throughout the country. A strong message of support was delivered by President Mikhail Gorbachev, "The Soviet Union greets those who are selflessly and courageously carrying the torch of peace across the planet...strengthening the spirit of cooperation and mutual understanding among peoples." Moscow, Russia',
                  )}
                </p>
              </div>
              {t(
                prefix + '.learn.originStory1',
                'One experience that was indelibly imprinted in my soul was watching the millions of people around the world respond to the fire. Whether they were Masai warriors from the East African savanna, Chinese children witnessing the flame ascend the Great Wall, or Prime Minister Maggie Thatcher receiving the flame from eleven ethnic neighborhoods in London at 10 Downing Street, each reacted with a palpable sense of awe. Witnessing the fire that was encircling the world provided each an emotionally uplifting experience of our shared humanity. I have come to call this experience the “unitive impulse.” It is the yearning for a connection with the other human beings with whom we share this planet. The quickest pathway to peace on earth is experiencing the unitive impulse that connects us all as human beings.',
              )}
              <div className={style.originImage}>
                <img
                  src={asset('/images/First-Earth-Run-collection-4.webp')}
                  alt="[1] Runners reach the top of the Great Wall of China after climbing thousands of feet. [2] Running having just lit cauldron on top of Pyramid Temple of the Sun. Teotihuacán, Mexico [3] More than 10,000 runners behind the torch. Leningrad (St Petersburg), Russia [4] Special Olympians carry the torch. Bahrain"
                />
                <p>
                  {t(
                    prefix + '.learn.originImageAlt2',
                    '[1] Runners reach the top of the Great Wall of China after climbing thousands of feet. [2] Running having just lit cauldron on top of Pyramid Temple of the Sun. Teotihuacán, Mexico [3] More than 10,000 runners behind the torch. Leningrad (St Petersburg), Russia [4] Special Olympians carry the torch. Bahrain',
                  )}
                </p>
              </div>
              {t(
                prefix + '.learn.originStory2',
                'The First Earth Run was coded with the unitive impulse. This included torch passing rituals, candle lighting ceremonies, musical and cultural events, poster competitions, following the torch of peace around the world through the media and in classrooms, even speeches of heads-of-state about our human connection and their aspirations for peace on earth. Several heads-of-state commented that they had never been asked to talk about such things and how ennobling it was for them. This action will help you experience and spread the common humanity we all share when we know where to look for it.',
              )}
              <br />
              <br />
              <span className={style.bold}>
                {t(
                  prefix + '.learn.originStory5',
                  'Watch Good Morning America’s weekly series tell the story of the torch of peace circumnavigating the earth over the course of the next 6 actions. Week 1 - Part 2.',
                )}
              </span>
            </p>
          }
        />

        <Video
          src={`https://poe.earth/hardLink-action-3-learn-os_1`}
          // src={`https://poe.earth/hardLink-action-3-learn-os_1_${activeLanguage}`}
        />

        <ActionContent
          title={t(
            'word.unityFrequencyMeditation',
            'Unity Frequency Meditation',
          )}
          content={t(
            prefix + '.learn.frequencyMeditation',
            'The Unity Frequency Meditation by David Gershon, with harp accompaniment by Tami Briggs, is designed to help you play the Peace Game at a deeper level.',
          )}
        />

        <Video
          src={`https://poe.earth/hardLink-action-3-learn-fm_${activeLanguage}`}
        />

        <GoToButton
          text={t('word.goToPlan', 'Go to Plan')}
          aria-label={t('word.goToPlan', 'Go to Plan')}
          onClick={() => {
            navigate(
              generatePath(ROUTES.peace_action.path, {
                id: topic.name.toLowerCase() + '?step=2',
              }),
            );
          }}
        />
      </div>
    </>
  );
};

export default UnityLearn;
