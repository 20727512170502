import React from 'react';
import './HowToStartPT.scss';
import { default as style } from './HowToStartPT.scss.json';
import { registerPackageModule, linkedComponent } from '../../../package';
import { useTranslate } from '@tolgee/react';
import { Video } from '../../atoms/Video';
import { UnsortedList } from '../../atoms/UnsortedList';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { OrderedList } from '../../atoms/OrderedList';
import Headline from '../../atoms/Headline';
import { cl } from 'lincd/lib/utils/ClassNames';

export function HowToStartPT() {
  let { t } = useTranslate();
  const navigate = useNavigate();

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'resource-HowToStartPT';
  return (
    <>
      <div className={style.content}>
        <Headline
          subtitle={t(prefix + '.introduction', 'How to Start a Peace Team')}
          lined="black"
        />
        <p>
          {t(
            prefix + '.paragraph01',
            '“Again and again in history some people wake up. They have no ground in the crowd and they move to broader, deeper laws. They carry strange customs with them and demand room for bold and audacious action. The future speaks ruthlessly through them. They change the world.”—Rainer Maria Rilke',
          )}
        </p>
        <p>
          <u>{t(prefix + '.paragraph02', 'Why Be a Peace Team Leader?')}</u>
        </p>
        <p>
          {t(
            prefix + '.paragraph03',
            'Here are a few reasons why people choose to start a Peace Team. What’s yours? Some examples:',
          )}
          <OrderedList type={1}>
            <li>
              {t(
                prefix + '.bullet01',
                'I care about the planet and want to do my part to make it better for the next generations.',
              )}
            </li>
            <li>
              {t(
                prefix + '.bullet02',
                'I wish to protect the safety and security of my family, neighbors and community members and wish to do everything I can to create peaceful resolutions of conflicts.',
              )}
            </li>
            <li>
              {t(
                prefix + '.bullet03',
                'I wish to experience a greater sense of community where I live.',
              )}
            </li>
            <li>
              {t(
                prefix + '.bullet04',
                'I would like to improve my leadership skills by learning empowerment coaching, group facilitation and community organizing.',
              )}
            </li>
            <li>
              {t(
                prefix + '.bullet05',
                'I wish to be part of a movement that is celebrating the oneness of humanity and creating peace on earth by 2030.',
              )}
            </li>
            <li>{t(prefix + '.bullet06', 'What’s yours?')}</li>
          </OrderedList>
        </p>
        <p>
          <u>{t(prefix + '.paragraph04', 'What Do I Need To Do?')}</u>
        </p>
        <p>
          {t(
            prefix + '.paragraph05',
            'As a Peace Team Leader you are responsible for starting a team of five to eight people and keeping your team on track to achieve their individual and collective goals. In broad terms, your work as a Peace Team Leader is to help people adopt new behaviors and practices that enable you and them to contribute to making peace on earth a possibility. Once the initial recruitment phase is over, leading the seven Peace Game meetings are shared amongst the team members. Following are the Peace Team Leader tasks and approximate times divided over four months, the average length of the In-Person Peace Game.',
          )}
          <UnsortedList>
            <li>
              <u>{t(prefix + '.p05.bullet01', 'Organizing')}</u>
              {': '}
              {t(
                prefix + '.p05.bullet01a',
                'This includes recruiting your Peace Team (3 hours), facilitating the Information Meeting including planning time (3 hours), and facilitating the Start-Up and DreamKeeper Initiation Meetings including planning time (3 hours per meeting x 2 = 6 hours). Total Time: 12 hours',
              )}
            </li>
            <li>
              <u>
                {t(prefix + '.p05.bullet02', 'Participation on a Peace Team')}
              </u>
              {': '}
              {t(
                prefix + '.p05.bullet02a',
                'This includes attending seven 2-hour meetings (14 hours). Investing 2 hours per topic taking the actions (14 hours). Total Time: 28 hours',
              )}
            </li>
            <li>
              <u>{t(prefix + '.p05.bullet03', 'Team Leader Management')}</u>
              {': '}
              {t(
                prefix + '.p05.bullet03a',
                'This includes supporting the team and addressing any team breakdowns. Total Time: 2 hours',
              )}
            </li>
            <li>
              <u>{t(prefix + '.p05.bullet04', 'Approximate Total Time')}</u>
              {': '}
              {t(
                prefix + '.p05.bullet04a',
                '42 hours over 5 months or an average of 2 hours per week.',
              )}
            </li>
          </UnsortedList>
        </p>
        <p>
          {t(
            prefix + '.paragraph06',
            'Investing 2 hours a week will improve your quality of life, transform your community, and help create peace on earth. It will also be a lot of fun as you do so much good for the world while enjoying the company of people you care about. Not many opportunities come along in life that provide such an extraordinary positive return on your investment of time.',
          )}
        </p>
        <p>
          <u>
            {t(
              prefix + '.Pre-Meeting',
              'Creating Your Vision For Peace on Earth by 2030',
            )}
          </u>
        </p>
        <p>
          {t(
            prefix + '.paragraph07',
            'From an empowerment point of view what motivates us to engage in something is a compelling vision of possibility. The stronger it is, the more powerfully it pulls us toward its realization. These questions will help you build your vision as a Peace Team leader. Take the time to reflect on them and write out your answers. As you do this step into your future and imagine having successfully implemented the 7 actions and the result it has had on your life, community, and world.',
          )}
          <OrderedList type={1}>
            <li>
              {t(
                prefix + '.p07.bullet08',
                'In my highest vision, what do I wish from being a Peace Team leader?',
              )}
            </li>
            <li>
              {t(
                prefix + '.p07.bullet09',
                'In my highest vision, what experience do I wish for my Peace Team?',
              )}
            </li>
            <li>
              {t(
                prefix + '.p07.bullet10',
                'In my highest vision, what experience do I wish for myself playing the Peace Game?',
              )}
            </li>
            <li>
              {t(
                prefix + '.p07.bullet11',
                'In my highest vision, what experience do I wish for the world?',
              )}
            </li>
            <li>
              {t(
                prefix + '.p07.bullet12',
                'In my highest vision, how will the Peace Game help me grow as a leader and change agent?',
              )}
            </li>
          </OrderedList>
        </p>
        <p>
          {t(
            prefix + '.paragraph13',
            'After you have answered these questions, take a moment to reflect on your answers and how they make you feel. This is the future you are able to create for yourself, teammates, and the world.',
          )}
        </p>
        <p>
          <u>{t(prefix + '.paragraph14', 'Manifesting a Vision')}</u>
        </p>
        <p>
          {t(
            prefix + '.paragraph15',
            'Sometimes when we create a compelling vision what follows are all the reasons we think it can’t happen. This is not a bad thing. It means we have really stretched our imagination and allowed ourselves to dream. They are also part of the human experience, particularly for individuals assuming leadership positions. In the empowerment vernacular, we call these “limiting beliefs.” These are like weeds in the garden. If they are not attended to, they can take over the garden. Likewise, if they are not attended to in our thinking, they can eventually take over and our visions are not realized.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph16',
            'We need to know how to work with our limiting beliefs. If we can identify the belief underlying a doubt, fear, or resistance, we can address it. If we can create a limiting belief unconsciously that stops us, we can consciously create another belief that empowers us to move toward our vision.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph17',
            'It’s also important to realize, that just like you, those whom you wish to empower to take on leadership responsibilities such as being an Action Leader may also have limiting beliefs. So, becoming skillful in working with any limiting beliefs you may have, will help both you and the others you are empowering, to make your visions a reality. Since this game is based on a proven empowerment methodology there is an effective way to address these doubts, fears, and resistance when they come.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph18',
            'Here are some limiting beliefs you might be feeling.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph19',
            'Included after each of them is a possible way you might transform it, or what is called a “turnaround.” A turnaround is simply moving from an either/or way of thinking to a both/and way of thinking. It does not deny the limiting belief but rather expands the context or reframes it so you can see it from a wider perspective and therefore take action. To do this requires opening up your imagination and creativity. Unlike the vision exercise where you were creating in a blue sky, this is visionary problem solving.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph20',
            'A note before you begin. If these limiting belief examples are not your limiting beliefs, please do not assume them!',
          )}
        </p>
        <div className={style.limitingBeliefs}>
          <p>
            <u>{t('word.limitingBelief', 'Limiting Belief')}</u>
            {': '}
            {t(
              prefix + '.limitingBelief01',
              'I don’t have enough interpersonal communications and facilitation skills to be a good Peace Team Leader.',
            )}
          </p>
          <p>
            <u>{t('word.turnaround', 'Turnaround')}</u>
            {': '}
            {t(
              prefix + '.turnaround01',
              'My ability to be a Peace Team Leader comes from my desire to improve my life, world, and create a better life for future generations. I ask for support when I need it.',
            )}
          </p>
          <p>
            <u>{t('word.limitingBelief', 'Limiting Belief')}</u>
            {': '}
            {t(
              prefix + '.limitingBelief02',
              ' I am afraid to reach out to my friends and colleagues to join my Peace Team and risk rejection.',
            )}
          </p>
          <p>
            <u>{t('word.turnaround', 'Turnaround')}</u>
            {': '}
            {t(
              prefix + '.turnaround02',
              'Overcoming my own fears and resistances and developing new skills and talents is one of the opportunities provided by becoming a Peace Team Leader.',
            )}
          </p>
          <p>
            <u>{t('word.limitingBelief', 'Limiting Belief')}</u>
            {': '}
            {t(
              prefix + '.limitingBelief03',
              'I won’t have the time needed to be a successful Peace Team Leader.',
            )}
          </p>
          <p>
            <u>{t('word.turnaround', 'Turnaround')}</u>
            {': '}
            {t(
              prefix + '.turnaround03',
              'I understand that the way my current life is structured I might need to reprioritize some of my discretionary activities to free up time for this. I value the meaning, purpose, and opportunity this experience can provide me, and I make a choice to do less of something else, so I have more time for this.',
            )}
          </p>
          <p>
            <u>{t('word.limitingBelief', 'Limiting Belief')}</u>
            {': '}
            {t(
              prefix + '.limitingBelief04',
              'My friends and colleagues will not be interested in playing the Peace Game.',
            )}
          </p>
          <p>
            <u>{t('word.turnaround', 'Turnaround')}</u>
            {': '}
            {t(
              prefix + '.turnaround04',
              'I focus my attention on those people who are ready—the early adopters—and encourage those who are not ready to consider participating in the future.',
            )}
          </p>
          <p>
            <u>{t('word.limitingBelief', 'Limiting Belief')}</u>
            {': '}
            {t(
              prefix + '.limitingBelief05',
              'I don’t know how to successfully motivate a person to carry out the action they have chosen if they hit an obstacle and wish to give up.',
            )}
          </p>
          <p>
            <u>{t('word.turnaround', 'Turnaround')}</u>
            {': '}
            {t(
              prefix + '.turnaround05',
              'I invite the person to rekindle their reason for participating in the Peace Game and committing to taking on this action. I let the pull of their vision do the heavy lifting.',
            )}
          </p>
          <p>
            <u>{t('word.limitingBelief', 'Limiting Belief')}</u>
            {': '}
            {t(
              prefix + '.limitingBelief06',
              'If my team doesn’t want to follow the meeting structure and agenda, I won’t be able to convince them to do it.',
            )}
          </p>
          <p>
            <u>{t('word.turnaround', 'Turnaround')}</u>
            {': '}
            {t(
              prefix + '.turnaround06',
              'I explain the rationale for the meeting structure and the risk we face of not achieving our goals if we let go of this proven empowerment framework. I then trust the process.',
            )}
          </p>
          <p>
            <u>{t('word.limitingBelief', 'Limiting Belief')}</u>
            {': '}
            {t(
              prefix + '.limitingBelief07',
              'It’s not empowering to request my teammates do something a particular way.',
            )}
          </p>
          <p>
            <u>{t('word.turnaround', 'Turnaround')}</u>
            {': '}
            {t(
              prefix + '.turnaround07',
              'My principal responsibility is to help my teammates be successful in achieving the actions they have chosen. My role as a Peace Team Leader is to provide real leadership when needed.',
            )}
          </p>
        </div>
        <p>
          {t(
            prefix + '.paragraph35',
            'You may have limiting beliefs that are not covered here or you may have variations on them. If so, write down these limiting beliefs and create turnarounds. This is well worth your time because it will help you become aware of beliefs that, if not addressed, can erode your progress as a Team Leader. In fact, if you notice yourself stalled at any point in time, more than likely a limiting belief is in play. So just pull out this trusty tool and put it to work for you.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph36',
            'Finally, here is a deeper dive into the larger agenda of a Peace Team Leader. With the help of the game’s empowerment tools, transforming a disparate group of people into a high-performing team capable of achieving substantive behavior change and much good in the world is readily available to you.',
          )}
        </p>
        <p>
          <u>
            {t(
              prefix + '.Pre-Meeting',
              'Five Practices for Creating a High Performing Team Capable of Achieving Behavior Change',
            )}
          </u>
        </p>
        <p>
          {t(
            prefix + '.paragraph37',
            'Our experience in creating high-performance teams has grown out of the need in society for certain prosocial behaviors to be adopted by citizens. We discovered early on that a peer support group was a critical success factor in motivating people to change. But to achieve the gold standard of behavior change required that the group operate at a high level of performance. Learning how to do this has been a decades long journey. The many thousands of teams with whom we have had the privilege to work have been our teachers. The fruit of this learning are the following five practices.',
          )}
        </p>
        <p>
          <u>{t(prefix + '.paragraph38', 'Create the right size group')}</u>
        </p>
        <p>
          {t(
            prefix + '.paragraph39',
            'The size of the group is very important. Too small, and there is not enough energy and diversity of ideas. Too large, and there is not enough time for each person to fully participate in the meeting. The ideal size for a team being used as a support group for behavior change is five to eight households. This allows each person a chance to personally share his or her accomplishments and needs and to get support from the group. This size also allows for groups to bond socially, as it is small enough for people to form personal relationships with each of the other people.',
          )}
        </p>
        <p>
          <u>{t(prefix + '.paragraph40', 'Build the group into a team')}</u>
        </p>
        <p>
          {t(
            prefix + '.paragraph41',
            'The first meeting needs to be devoted to building the group into a team. The most effective way is to ask each person what they want as the outcome for participating and to combine these statements into a group purpose statement. If all members see a piece of that team purpose statement reflecting their personal vision, they can own it. This can be done in about twenty minutes and is the quickest and most efficient way we have found for bonding the team.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph42',
            'The next step is for the group to divide up leadership responsibilities so everyone involved is invested in the process. This is done by having a different team member facilitate each meeting. This distributes the responsibility and ownership for the team’s success among the different members. This also increases the pleasure of participation for everyone because they experience a diversity of meeting styles and hospitality if held in people’s homes.',
          )}
        </p>
        <p>
          <u>
            {t(prefix + '.paragraph43', 'Establish a group process protocol')}
          </u>
        </p>
        <p>
          {t(
            prefix + '.paragraph44',
            'The primary rationale for individuals to participate in a support group is that it will help them accomplish their goals better than on their own. The more effectively a team functions the truer this will be. Part of this functionality comes from the design of carefully crafted meeting agendas. But the other part comes from having an effective process in place to address any breakdowns in the group’s performance.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph46',
            'It is important to explain the group process protocol during the Start Upq Meeting. We have found it effective to have a group check-in of up to ten minutes to air any issues and to see how the group is performing. Are people coming on time to the meetings? If not, what can be done either to renegotiate the starting time or motivate any latecomers to prioritize this as important? Are people taking the actions they told the group they would? If not, why not? How can the group help someone who’s having difficulty to be more successful in the future? Is the meeting following the agenda or veering off in many different directions? If so, how does the group wish to handle this?',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph47',
            'During this Start-Up Meeting it is also important to establish coaching guidelines for these communications, otherwise there is a good chance they won’t happen, or they’ll be poorly done, causing hurt feelings. The coaching process provides a means for the effective delivery of feedback. It starts by asking individuals on the team if they are open to both offering and receiving coaching from teammates when they see a breakdown in an agreement made by the team or an individual. Assuming people agree, which they generally do, then we need to ask people to share what type of feedback works best for them.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph48',
            'A regular time to check in and agreed-on guidelines for making difficult communications create the foundation for a team to achieve high-quality results.',
          )}
        </p>
        <p>
          <u>
            {t(
              prefix + '.paragraph49',
              'Structure meetings to produce tangible outcomes',
            )}
          </u>
        </p>
        <p>
          {t(
            prefix + '.paragraph50',
            'To get a group of people to achieve substantive results in a short amount of time requires structure. Underneath the softer process elements is the meeting skeleton. Like the skeleton in our bodies, this is what holds the meeting together. Each Peace Game meeting has a structured agenda with time allotments for each agenda item and a script for the facilitator. Some meeting elements may run longer, others shorter, and the facilitator will inevitably improvise on the script. But this clear structure enables the facilitator to know exactly what has to be accomplished and how to get it done.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph51',
            'The elements for a Peace Game action meeting, which lasts 2 hours, include a peace meditation, sharing of actions taken since the previous meeting with support from the team as needed, a sharing of individual action plans for their next topic with a request for support, check-in on team performance with interventions as needed, setup of support between the meetings by the facilitator to make sure teammates are on track, and review of next steps.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph52',
            'The structure of these meetings is an important component of the success and power behind this process. It is not uncommon for people to try to “empower” their teams by disregarding the structure itself. We have come to call this “pseudo empowerment.” In this situation, the meeting facilitator and group prefer not to structure the meeting or hold individuals or the team accountable for doing the things they say they will do. Inevitably, this approach quickly devolves into a discussion group or salon rather than a high-performing team committed to achieving the tangible behavior change of its members. At the end of the meetings people have not accomplished what they set out to and leave with a sense of dissatisfaction. Rather than being empowered by this approach, they often feel despondent, having spent so much time with nothing tangible to show for it.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph53',
            'The team meetings have been carefully crafted based on decades of research in how to manage an effective team. By carefully establishing the value and rationale behind each element of the meeting agenda people understand why it’s important to the outcomes the individual and group wish to achieve. This careful explanation helps the team appreciate the meeting design so that if and when someone wishes to deviate from the structure, the group has enough knowledge to make a clear-headed decision.',
          )}
        </p>
        <p>
          <u>
            {t(
              prefix + '.paragraph54',
              'Demonstrate an expectation of success',
            )}
          </u>
        </p>
        <p>
          {t(
            prefix + '.paragraph55',
            'The intangible of a high-performing team is the expectation of the person who initiates it—the Peace Team Leader. Our experience has been that the higher the expectation a Peace Team Leader places on a team, the higher the expectation the team places on itself and the better the results it achieves.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph56',
            'A Peace Team Leader needs to demonstrate an expectation of success by asking the team to take its investment of time seriously. That is, to do the things they say they’ll do. That’s why the group check-in and commitment to mutual accountability are the team’s backbone. When members of a team do not do what they say they will do, it’s essential that the Peace Team Leader intervenes even though he or she may be tempted to think, “It’s not the end of the world, I’ll let it slide.” If the Team Leader doesn’t speak up, the lack of accountability will quickly spread throughout the team, become the new social norm, and precipitate a downward spiral in performance.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph57',
            'Equally important, an effective Peace Team Leader should be constantly looking for opportunities to affirm excellent performance, belief in the team, and the importance of the goal. The combination of nipping poor habits in the bud and reinforcing positive achievements keeps a team on track to achieve outstanding results.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph58',
            'The Peace on Earth by 2030 Peace Team experience is designed around these five practices. Those teams that followed these practices have a very high completion rate with high levels of success in achieving their personal and collective goals.',
          )}
        </p>
        <p>
          <u>{t(prefix + '.paragraph59', 'Co-Peace Team Leader Option')}</u>
        </p>
        <p>
          {t(
            prefix + '.paragraph60',
            'If a Peace Team Leader wishes to share this responsibility with another team member it is important that the two people carefully divide up responsibilities. This enables them and everyone on the team to know who is accountable for which tasks. On the one hand it’s nice to share leadership, but on the other it demands very clear communication and delegation of responsibilities or things fall through the cracks.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph61',
            'The next step is to create your Peace Team. If you’re doing this as part of a Peace on Earth (POE) Zone, look for natural allies to help you grow this initiative within your community. See Section 5 for more information on creating a POE Zone. The next guide shows you how to host an Information Meeting for those you wish to join your team.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph62',
            'Note: If you are not ready to lead an in-person Peace Team, just invite your friends to join the Global Online Peace Game. If you select this option consider playing the game again with them.',
          )}
        </p>
      </div>
    </>
  );
}
