import React, { useEffect, useState } from 'react';
import style from './Contact.scss.json';
import './Contact.scss';
import Header from '../components/molecules/Header';
import { DefaultLayout } from '../layout/DefaultLayout';
import { Container } from '../layout/Container';
import { Spinner } from '../components/atoms/Spinner';

const Contact = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);
  return (
    <DefaultLayout>
      <Header title="Contact" inverted={true} fixed={true} backButton={true} />
      <Container>
        <Spinner active={isLoading} centered={true} />
        <iframe
          className={style.iframe}
          src="https://embed.wotnot.io/6yraDLPodMoq0621384426165jH5Qmhz/bot/5KW9jtAhavMJ062205947117YWmTauYL?display_header=false&history_retention=false"
          frameBorder="0"
        ></iframe>
      </Container>
    </DefaultLayout>
  );
};

export default Contact;
