import React, { useEffect } from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import './Abundance.scss';
import AbundanceLearn from '../components/organisms/ActionDetails/5_AbundanceLearn';
import AbundancePlan from '../components/organisms/ActionDetails/5_AbundancePlan';
import AbundanceShare from '../components/organisms/ActionDetails/5_AbundanceShare';
import Banner from '../components/molecules/Banner';
import ProgressTab from '../components/atoms/ProgressTab';
import { topic5 } from '../data-references';
import { useTranslate } from '@tolgee/react';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { useProfileCompleted } from '../hooks/useProfileCompleted';

const Abundance = () => {
  let topic = topic5;
  let { t } = useTranslate();
  const { checkIsCompleted } = useProfileCompleted();

  // check if user has completed profile setup
  useEffect(() => {
    checkIsCompleted();
  }, []);

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'topic' + topic.identifier;
  return (
    <DefaultLayout backgroundColor={'white'}>
      <Banner
        title={t(prefix + '.title', topic.name)}
        subtitle={t(prefix + '.subTitle', topic.description)}
        image={asset('/images/AbundanceBanner.webp')}
      />

      <ProgressTab
        firstPage={<AbundanceLearn />}
        secondPage={<AbundancePlan />}
        thirdPage={<AbundanceShare />}
      />
    </DefaultLayout>
  );
};

export default Abundance;
