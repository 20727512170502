import React from 'react';
import './2_OnenessLearn.scss';
import style from './2_OnenessLearn.scss.json';
import GoToButton from '../../atoms/GoToButton';
import { topic2 } from '../../../data-references';
import ActionContent from '../../molecules/ActionContent';
import { useTranslate } from '@tolgee/react';
import { UnsortedList } from '../../atoms/UnsortedList';
import { Video } from '../../atoms/Video';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { ActionPurpose } from '../../molecules/ActionPurpose';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { getActiveLanguage } from '../../../utils/tolgee';

let topic = topic2;
let prefix = 'topic' + topic.identifier;

const OnenessLearn = () => {
  let { t } = useTranslate();
  const navigate = useNavigate();
  const activeLanguage = getActiveLanguage();

  return (
    <div className={style.content}>
      <ActionPurpose
        topic={topic}
        purposeText={t(
          prefix + '.learn.purposeText',
          'The Oneness action is to befriend those who are different from you, especially those who you may have rejected, avoided, or ignored in the past.',
        )}
        actionGuidanceText={
          <p>
            {t(
              prefix + '.learn.actionGuidesText1',
              'Look for people and situations where you have opportunities to befriend the other. Decide which people you wish to befriend and what you will say to each person.',
            )}
            <br />
            <br />
            {t(
              prefix + '.learn.actionGuidesText2',
              `Opportunities can include`,
            )}
            {':'}
            <br />
            <br />
            <UnsortedList>
              <li>
                {t(
                  prefix + 'learn.actionGuidesText3',
                  'A family member you try to avoid.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'learn.actionGuidesText4',
                  'An acquaintance, friend, relative, or neighbor with whom you have a conflict.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'learn.actionGuidesText5',
                  'An unresolved issue from a past relationship(s).',
                )}
              </li>
              <li>
                {t(
                  prefix + 'learn.actionGuidesText6',
                  "People you are uncomfortable talking to or don't normally associate with such as someone of another ethnicity, race, culture, religion, or political worldview.",
                )}
              </li>
              <li>
                {t(
                  prefix + 'learn.actionGuidesText7',
                  'A homeless person or person in need.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'learn.actionGuidesText8',
                  'A person serving you in a restaurant or store you frequent you would usually not acknowledge.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'learn.actionGuidesText9',
                  "A person you see on a walk or a neighbor you pass but don't take the time to speak with.",
                )}
              </li>
              <li>
                {t(
                  prefix + 'learn.actionGuidesText10',
                  'A person you learn about from a different part of the world or different way of life you would like to connect with.',
                )}
              </li>
            </UnsortedList>
            <br />
            {t(
              prefix + '.learn.actionGuidesText11',
              `Make sure to do this action on your oneness growing edge. Don\'t just befriend those people whom you are comfortable engaging. If called for, invest the time to transform the relationship.`,
            )}
          </p>
        }
      />

      <ActionContent
        title={t('word.onenessOriginStory', 'Oneness Origin Story')}
        content={
          <p>
            <div className={style.originImage}>
              <img
                src={asset('/images/Burkina-crowd.webp')}
                alt="Thousands of villagers welcome the torch of peace to Burkina Faso."
              />
              <p>
                {t(
                  prefix + '.learn.originImageAlt1',
                  'Thousands of villagers welcome the torch of peace to Burkina Faso.',
                )}
              </p>
            </div>
            {t(
              prefix + '.learn.originStory1',
              'For the First Earth Run to succeed many people of different cultures, races, religions, and political ideologies had to work together. This was not easy because our differences at times caused us to clash. The simple act of organizing a torch ceremony in each country was such an experience. The Japanese wanted it highly choreographed, the Africans wanted it celebratory and more spontaneous, and for the Russians and Chinese it was a political statement. I had to expand beyond how I thought it should be and enter into each of these cultures with an open mind. Each time I was willing to enlarge my way of thinking and doing things, I felt myself evolving as a human being.',
            )}
            <div className={style.originImage}>
              <img
                src={asset('/images/DG_ChinaPres.webp')}
                alt="First Earth Run President David Gershon, giving peace torch to Li Xiuannian, President of China, at Great Hall of People. Bejing, China"
              />
              <p>
                {t(
                  prefix + '.learn.originImageAlt2',
                  'First Earth Run President David Gershon, giving peace torch to Li Xiuannian, President of China, at Great Hall of People. Beijing, China',
                )}
              </p>
            </div>
            {t(
              prefix + '.learn.originStory2',
              'I also discovered that although there were differences, there were also similarities. We each wanted to make a contribution with our lives. We each wanted to create a better future for the children of the world. We each were excited by the possibility to do something important. In the end, it was learning how to combine our differences and similarities that allowed us to succeed. We also discovered that by expanding our worldview it enabled us each to become a more whole human being.',
            )}
            <br />
            <br />
            {t(
              prefix + '.learn.originStory3',
              'Our world needs many people signing up for the great adventure of learning from each other. For the human experiment to thrive, it will require us to stretch beyond our comfort zones. We may not always agree with a person’s thinking or approach to life, but we and the world will become better for the effort.',
            )}
            <div className={style.originImage}>
              <img
                src={asset('/images/First-Earth-Run-collection-2.webp')}
                alt="[1] Children along the route between Washington DC and New York City. USA [2] Ceremony at Hiroshima Peace Memorial Park with Governor Takeshita and Mayor Araki. Runners laid a wreath at the Cenotaph for victims of the atomic bomb. Hiroshima, Japan [3] Passing of Peace Flame from Japan to Indonesia [4] First Earth Run global team at the foot of the Great Wall of China."
              />
              <p>
                {t(
                  prefix + '.learn.originImageAlt3',
                  '[1] Children along the route between Washington DC and New York City. USA [2] Ceremony at Hiroshima Peace Memorial Park with Governor Takeshita and Mayor Araki. Runners laid a wreath at the Cenotaph for victims of the atomic bomb. Hiroshima, Japan [3] Passing of Peace Flame from Japan to Indonesia [4] First Earth Run global team at the foot of the Great Wall of China.',
                )}
              </p>
            </div>
            {t(
              prefix + '.learn.originStory4',
              'This simple and profound action invites you to befriend and learn from the people who are different from you. Look for them throughout your life: they live on your block, you work or go to school with them, they are in your community, they show up in your email inbox, and if you are more adventurous, they live all over the planet.',
            )}
            <br />
            <br />
            <span className={style.bold}>
              {t(
                prefix + '.learn.originStory5',
                'Watch Good Morning America’s weekly series tell the story of the torch of peace circumnavigating the earth over the course of the next 6 actions. Week 1 - Part 1.',
              )}
            </span>
          </p>
        }
      />

      <Video
        src={`https://poe.earth/hardLink-action-2-learn-os_1`}
        // src={`https://poe.earth/hardLink-action-2-learn-os_1_${activeLanguage}`}
      />

      <ActionContent
        title={t(
          'word.onenessFrequencyMeditation',
          'Oneness Frequency Meditation',
        )}
        content={
          <p>
            {t(
              prefix + 'learn.onenesFrequencyMeditation',
              'The Oneness Frequency Meditation by David Gershon, with harp accompaniment by Tami Briggs, is designed to help you play the Peace Game at a deeper level.',
            )}
          </p>
        }
      />

      <Video
        src={`https://poe.earth/hardLink-action-2-learn-fm_${activeLanguage}`}
      />

      <GoToButton
        text={t('word.goToPlan', 'Go to Plan')}
        onClick={() => {
          navigate(
            generatePath(ROUTES.peace_action.path, {
              id: topic.name.toLowerCase() + '?step=2',
            }),
          );
        }}
      />
    </div>
  );
};

export default OnenessLearn;
