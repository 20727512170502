import React from 'react';
import './ProfileViewText.scss';
import { default as style } from './ProfileViewText.scss.json';
import { PlacePreview } from 'lincd-input/lib/components/PlacePreview';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

interface ProfileViewTextProps {
  title: string;
  of;
  valueProperty: string;
  onEditForm?: (value: boolean) => void;
}
export function ProfileViewText({
  title,
  of,
  valueProperty,
  onEditForm,
}: ProfileViewTextProps) {
  const onEditAction = () => {
    if (onEditForm) {
      onEditForm(true);
    }
  };

  return (
    <>
      <p className={style.title}>{title}</p>
      <div className={style.textFieldContainer}>
        {valueProperty !== 'areaServed' ? (
          <p>{of[valueProperty] || '-'}</p>
        ) : !of[valueProperty] ? (
          <p>-</p>
        ) : (
          <PlacePreview
            of={of[valueProperty]}
            property="areaServed"
            className={style.locationText}
          />
        )}
        <img
          src={asset('/images/icons/MdEditIcon.svg')}
          alt="Edit Icon"
          aria-lable="Edit Button"
          width="20px"
          className={style.icon}
          onClick={onEditAction}
        />
      </div>
    </>
  );
}
