import { topic6, topic7 } from '../../../data-references';
import ShareActionDetails from '../../molecules/ShareActionDetails';

const LoveShare = () => {
  let topic = topic6;

  return (
    <ShareActionDetails
      topic={topic}
      currentTopic={topic6}
      nextTopic={topic7}
    />
  );
};

export default LoveShare;
