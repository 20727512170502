import React from 'react';
import './Divider.scss';
import style from './Divider.scss.json';

interface DividerProps {
  title?: string;
}
function Divider({ title }: DividerProps) {
  return <div className={style.Divider}>{title && <h6>{title}</h6>}</div>;
}

export default Divider;
