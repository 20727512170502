import React, { useEffect, useState } from 'react';
import './ResourceDetail.scss';
import * as style from './ResourceDetail.scss.json';
import { ListItem, SingleListItem } from '../layout/List';
import Header from '../components/molecules/Header';
import MainMenu from '../components/molecules/MainMenu';
import { DefaultLayout } from '../layout/DefaultLayout';
import { useParams } from 'react-router-dom';
import { Resource } from 'lincd-irlcg/lib/shapes/Resource';
import { ResourceContent } from 'lincd-irlcg/lib/components/ResourceContent';
import { Container } from '../layout/Container';
import { Video } from '../components/atoms/Video';
import {
  resource1,
  resource2,
  resource3,
  resource4,
  resource4b,
  resource6,
  resource7,
  meetingGuide1,
  meetingGuide2,
  meetingGuide3,
  meetingGuide4,
  meetingGuide5,
  meetingGuide6,
  meetingGuide7,
  meetingGuide8,
  meetingGuide9,
  meetingGuide10,
} from '../data-references';
import ResourceCardWithNav from './ResourceCardWithNav';
import { Meeting01 } from '../components/organisms/Resources/Meeting01';
import { Meeting02 } from '../components/organisms/Resources/Meeting02';
import { Meeting03 } from '../components/organisms/Resources/Meeting03';
import { Meeting04 } from '../components/organisms/Resources/Meeting04';
import { Meeting05 } from '../components/organisms/Resources/Meeting05';
import { Meeting06 } from '../components/organisms/Resources/Meeting06';
import { Meeting07 } from '../components/organisms/Resources/Meeting07';
import { Meeting08 } from '../components/organisms/Resources/Meeting08';
import { Meeting09 } from '../components/organisms/Resources/Meeting09';
import { HowtoCreatePOEZ } from '../components/organisms/Resources/HowtoCreatePOEZ';
import { PeaceGameDebrief } from '../components/organisms/Resources/PeaceGameDebrief';
import PeaceGameVideos from '../components/organisms/Resources/PeaceGameVideos';
import { InformationMeetingGuide } from '../components/organisms/Resources/InfoMeetingGuide';
import { HowToStartPT } from '../components/organisms/Resources/HowToStartPT';
import RegisterYourPeaceTeam from '../components/organisms/Resources/RegisterYourPeaceTeam';
import PeaceTeamMemberRegistration from '../components/organisms/Resources/PeaceTeamMemberRegistration';
import { useTranslate } from '@tolgee/react';
import { getActiveLanguage } from '../utils/tolgee';

function ResourceDetail() {
  let { t } = useTranslate();
  let prefix = 'resourceDetail';
  const { id } = useParams();
  const activeLanguage = getActiveLanguage();

  let resource = Resource.getLocalInstances().find((r) => r.identifier === id);
  let hasChildren = resource.childItems.size > 0;
  let content;

  // resource5
  if (hasChildren) {
    let childResources = resource.childItems.sort((a, b) =>
      a.uri > b.uri ? 1 : -1,
    );
    content = (
      <ListItem
        of={childResources}
        as={ResourceCardWithNav}
        className={style.listItem}
      />
    );
  } else if (resource.equals(resource1)) {
    content = (
      <Container>
        <Video
          src={`https://poe.earth/hardLink-GetStarted`}
          // src={`https://poe.earth/hardLink-GetStarted_${activeLanguage}`}
        />
      </Container>
    );
  } else if (resource.equals(resource2)) {
    content = (
      <Container>
        <PeaceGameVideos />
      </Container>
    );
  } else if (resource.equals(resource3)) {
    content = (
      <Container>
        <HowToStartPT />
      </Container>
    );
  } else if (resource.equals(resource4)) {
    content = (
      <Container>
        <RegisterYourPeaceTeam />
      </Container>
    );
  } else if (resource.equals(resource4b)) {
    content = (
      <Container>
        <PeaceTeamMemberRegistration />
      </Container>
    );
  } else if (resource.equals(resource6)) {
    content = (
      <Container>
        <HowtoCreatePOEZ />
      </Container>
    );
  } else if (resource.equals(resource7)) {
    content = (
      <Container>
        <PeaceGameDebrief />
      </Container>
    );
  } else if (resource.equals(meetingGuide1)) {
    content = (
      <Container>
        <InformationMeetingGuide />
      </Container>
    );
  } else if (resource.equals(meetingGuide2)) {
    content = (
      <Container>
        <Meeting01 />
      </Container>
    );
  } else if (resource.equals(meetingGuide3)) {
    content = (
      <Container>
        <Meeting02 />
      </Container>
    );
  } else if (resource.equals(meetingGuide4)) {
    content = (
      <Container>
        <Meeting03 />
      </Container>
    );
  } else if (resource.equals(meetingGuide5)) {
    content = (
      <Container>
        <Meeting04 />
      </Container>
    );
  } else if (resource.equals(meetingGuide6)) {
    content = (
      <Container>
        <Meeting05 />
      </Container>
    );
  } else if (resource.equals(meetingGuide7)) {
    content = (
      <Container>
        <Meeting06 />
      </Container>
    );
  } else if (resource.equals(meetingGuide8)) {
    content = (
      <Container>
        <Meeting07 />
      </Container>
    );
  } else if (resource.equals(meetingGuide9)) {
    content = (
      <Container>
        <Meeting08 />
      </Container>
    );
  } else if (resource.equals(meetingGuide10)) {
    content = (
      <Container>
        <Meeting09 />
      </Container>
    );
  }
  //if content not found, render the default content
  if (!content) {
    content = (
      <Container>
        {/* <Video src="https://poe.earth/hardLink-GetStarted" /> */}
        <h1>Content is not available yet</h1>
      </Container>
    );
  }

  return (
    <DefaultLayout>
      {resource ? (
        <>
          <Header
            title={t(prefix + '.' + resource.name, resource.name)}
            inverted={true}
            backButton={true}
            fixed={true}
          />
          <div className={style.mainContainer}>
            <SingleListItem
              className={style.ResourceDetailSingleListItem}
              as={<ResourceContent of={resource} content={content} />}
            />
          </div>
        </>
      ) : (
        <span>...</span>
      )}
      {/* add custom style menu when resource detail is "peace-game-debrief" */}
      <MainMenu
        className={id === resource7.identifier ? style.customMenu : ''}
      />
    </DefaultLayout>
  );
}

export default ResourceDetail;
