import './EditProfileInput.scss';
import * as style from './EditProfileInput.scss.json';
import React from 'react';
import { ProfilePictureUploader } from 'profile-plus/lib/components/ProfilePictureUploader';
import LocationInput from 'lincd-input/lib/components/LocationInput';
import { Person } from 'profile-plus/lib/shapes/Person';
import { UserAccount } from 'profile-plus/lib/shapes/UserAccount';
import { InputField } from 'lincd-input/lib/components/InputField';
import { Place } from 'lincd-schema/lib/shapes/Place';
import { PostalAddress } from 'lincd-schema/lib/shapes/PostalAddress';
import { PhoneInputFlag } from '../atoms/PhoneInputFlag';
import { PostalAddressInputField } from '../atoms/PostalAddressInputField';

interface EditProfileInputProps {
  type: string;
  title: string;
  of: Person | UserAccount | PostalAddress;
  valueProperty: string;
  onBlur?;
}

export function EditProfileInput({
  title,
  type,
  of,
  valueProperty,
}: EditProfileInputProps) {
  return (
    <div className={style.container}>
      <p className={style.title}>{title}</p>
      <div className={style.inputContainer}>
        {type === 'avatar' ? (
          <ProfilePictureUploader
            className={style.profileUploaderWrapper}
            aspectRatio={3 / 2}
            // uploadIcon={
            //   <img
            //     src="images/icons/UserUploaderIcon.svg"
            //     alt="Uploader Icon"
            //   />
            // }
          />
        ) : type === 'tel' ? (
          <PhoneInputFlag
            value={of[valueProperty] as string}
            onBlur={(phone) => {
              const value = phone.target.value;

              // extract country code and phone number
              const matches = value.match(/^(\+\d{1,4})\s*(.*)$/);

              if (matches) {
                const countryCode = matches[1];
                let phoneNumber = matches[2];

                // remove special characters
                phoneNumber = phoneNumber.replace(/[^\d]/g, '');

                if (phoneNumber) {
                  of[valueProperty] = `${countryCode}${phoneNumber}`;
                } else {
                  of[valueProperty] = '';
                }
              } else {
                of[valueProperty] = '';
              }
            }}
          />
        ) : title === 'city' ? (
          <LocationInput
            of={of}
            property={valueProperty} // same like valueProperty on the EditProfileInput component
          />
        ) : title === 'zip code' ? (
          <PostalAddressInputField of={of} type="edit" />
        ) : (
          (type === 'text' || type === 'password' || type === 'email') && (
            <InputField
              of={of}
              type={type}
              valueProperty={valueProperty}
              className={style.inputField}
              placeholder={title}
            />
          )
        )}
      </div>
    </div>
  );
}
