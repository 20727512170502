import React, { useState, useEffect } from 'react';
import Select, { Props as SelectProps } from 'react-select';

interface SearchSelectProps extends SelectProps {
  options: any[];
  onChange: (selected: any) => void;
  value: any;
  defaultValue?: any;
  placeholder?: string;
}
const SearchSelect = ({
  options,
  onChange,
  value,
  defaultValue,
  placeholder = 'Search...',
  ...restProps
}: SearchSelectProps) => {
  const [selectedOption, setSelectedOption] = useState(value);
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (selected) => {
    setSelectedOption(selected);
    onChange(selected); // Pass the selected option to the parent component
  };

  const handleInputChange = (inputValue) => {
    setSearchTerm(inputValue);
  };

  // useEffect(() => {
  //   onSearchResultsChange(searchTerm); // Call onSearchResultsChange when searchTerm changes
  // }, [searchTerm, onSearchResultsChange]);

  return (
    <div>
      <Select
        value={selectedOption}
        defaultValue={defaultValue}
        options={options}
        onChange={handleChange}
        onInputChange={handleInputChange}
        placeholder={placeholder}
        {...restProps}
      />
    </div>
  );
};

export default SearchSelect;
