"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.N3 = void 0;
/*
 * This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/.
 */
const QuadSet_1 = require("lincd/lib/collections/QuadSet");
const Prefix_1 = require("lincd/lib/utils/Prefix");
const package_1 = require("../package");
const N3Lib = __importStar(require("n3"));
const NodeURIMappings_1 = require("lincd/lib/collections/NodeURIMappings");
const Datafactory_1 = require("lincd/lib/Datafactory");
let N3 = class N3 {
    static fromQuads(quads, extraContext = {}) {
        return new Promise((resolve, reject) => {
            let prefixes = Object.assign({}, extraContext);
            Prefix_1.Prefix.getPrefixToUriMap().forEach((uri, prefix) => {
                prefixes[prefix] = uri;
            });
            const writer = new N3Lib.Writer({ prefixes });
            quads.forEach((quad) => {
                writer.addQuad(quad);
            });
            writer.end((error, result) => {
                if (error) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            });
        });
    }
    static parse(content, setExplicit = true, nodeMap = new NodeURIMappings_1.NodeURIMappings(), targetGraph, overwriteData = false) {
	//console.log('N3 parsing content');
        return new Promise((resolve, reject) => {
            //TODO: add setExplicit
            const factory = new Datafactory_1.Datafactory({
                nodeMap,
                targetGraph,
                overwriteData,
            });
            const parser = new N3Lib.Parser({
                factory,
            });
            const quads = new QuadSet_1.QuadSet();
            parser.parse(content, (error, quad) => {
                if (error) {
                    console.error('Error parsing quads: ' + error);
                    reject();
                }
                else if (quad) {
                    quads.add(quad);
                }
                else {
                    resolve({
                        quads,
                        nodemap: nodeMap,
                        //TODO: do we still need this?
                        graphs: null,
                    });
                }
            });
        });
    }
};
N3 = __decorate([
    package_1.linkedUtil
], N3);
exports.N3 = N3;
