import React, { useEffect, useState } from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import './Register.scss';
import style from './Register.scss.json';
import { ProfilePictureUploader } from 'profile-plus/lib/components/ProfilePictureUploader';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { UserAccount } from 'profile-plus/lib/shapes/UserAccount';
import GoToButton from '../components/atoms/GoToButton';
import { PostalAddress } from 'lincd-schema/lib/shapes/PostalAddress';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../routes';
import StepHeader from '../components/molecules/StepHeader';
import { PhoneInputFlag } from '../components/atoms/PhoneInputFlag';
import LocationInput from 'lincd-input/lib/components/LocationInput';
import { TextField } from 'lincd-input/lib/components/TextField';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Capacitor } from '@capacitor/core';
import { DatePicker } from 'react-date-picker';
import { cl } from 'lincd/lib/utils/ClassNames';
import { useCometChat } from 'lincd-cometchat/lib/hooks/useCometChat';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { useTranslate } from '@tolgee/react';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from '../package';
import { CometChatAPI } from 'lincd-cometchat/lib/utils/CometChatAPI';

type RegisterForm = {
  email: string;
  firstname: string;
  lastname: string;
  dob: string;
  zipcode: string;
};

function Register() {
  let { t } = useTranslate();
  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'register';
  const navigate = useNavigate();

  const auth = useAuth<Player, UserAccount>();
  const user = auth.user;
  const account = auth.userAccount;

  // state for handle custom fields
  const [customForm, setCustomForm] = useState({
    phonenumber: user.telephone || '',
    dob: user.birthDate || '',
    city: '',
  });

  const [loading, setLoading] = useState(false);
  // custom error message for phone number
  const [customError, setCustomError] = useState({
    field: '',
    message: '',
  });

  // initial value for register form
  const formValues = {
    email: account.email,
    firstname: user.givenName,
    lastname: user.familyName,
    dob: user.birthDate ? user.birthDate.toISOString().split('T')[0] : '',
    zipcode: user?.address?.postalCode || '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<RegisterForm>({
    defaultValues: formValues,
  });

  const checkProfileCompleted = () => user?.profileSetupCompleted || false;

  useEffect(() => {
    // if user redirect to destination
    const checkIsCompleted = async () => {
      if (user) {
        const isCompleted = checkProfileCompleted();
        const routePath = isCompleted
          ? ROUTES.index.path
          : ROUTES.register.path;
        navigate(routePath);
      }
    };

    // get player data for phone number and address
    const getPlayerData = async () => {
      const response = await getPlayerAddressAndPhone();

      // set response to custom form
      if (response) {
        setCustomForm((prevData) => ({
          ...prevData,
          phonenumber: response.phone || '',
          city: response.address || '',
        }));
      }
    };

    getPlayerData();
    checkIsCompleted();
  }, []);

  // check email already exists from server
  const checkEmail = async (email: string) => {
    return await Server.call(packageName, 'checkEmailExists', email);
  };

  // check phone number already exists from server
  const checkPhoneNumber = async (phoneNumber: string) => {
    return await Server.call(packageName, 'checkTelephoneExists', phoneNumber);
  };

  const getPlayerAddressAndPhone = async () => {
    return await Server.call(packageName, 'getPlayerAddressAndPhone');
  };

  // handle submit form
  const onSubmit: SubmitHandler<RegisterForm> = async (data) => {
    setLoading(true);

    try {
      const { email, firstname, lastname, dob: dobForm, zipcode } = data;
      const { phonenumber, dob: dobDatePicker, city } = customForm;

      let birthDate: string | Date;
      if (Capacitor.getPlatform() === 'web') {
        birthDate = dobDatePicker;
      } else {
        birthDate = dobForm;
      }

      // check email already exists or not
      // if email exists then show error message on email field
      const emailExists = await checkEmail(email);
      if (!account.email && emailExists) {
        setError('email', {
          type: 'manual',
          message:
            'This email is already in use. Please login under your current profile. If you forgot your login, click on forgot password.',
        });
        return;
      }

      // check phone number already exists or not
      // if phone number exists then show error message on phone number field
      const phoneNumberExists = await checkPhoneNumber(phonenumber);
      if (!phonenumber && phoneNumberExists) {
        setCustomError({
          field: 'phonenumber',
          message:
            'This phone number is already in use. Please login under your current profile. If you used WhatsApp to login you can use the WhatsApp login. Otherwise you can use the forgot password link if you don’t remember your login information',
        });
        return;
      }

      // check field city or user address is empty, if yes show error message
      if (!city) {
        setCustomError({
          field: 'city',
          message: 'Please enter your city',
        });
        return;
      }

      user.givenName = firstname;
      user.familyName = lastname;
      user.telephone = phonenumber;
      user.birthDate = birthDate ? new Date(birthDate) : null;

      const address = new PostalAddress();
      if (zipcode) {
        address.postalCode = zipcode;
      }
      user.address = address;
      address.save();

      user.save();
      account.save();

      // update cometchat data user
      let userExists = await CometChatAPI.getUserFromAccount(account);

      // if user exists, update user cometchat name
      if (userExists) {
        userExists = await CometChatAPI.updateUserFromAccount(account);
      } else {
        userExists = await CometChatAPI.createUserFromAccount(account);
        console.log('create a new cometchat user: ', userExists.data.name);
      }

      navigate(ROUTES.join_team.path);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DefaultLayout>
      <div
        className={style.JoinTeamContainer}
        aria-labelledby={t(prefix + '.containerLabel', 'Register Container')}
      >
        <StepHeader step={1} title={t(prefix + '.register', 'REGISTER')} />
        <img
          className={style.banner}
          src={asset('/images/Register.webp')}
          alt={t(prefix + '.bannerAlt', 'Woman Praying')}
        />
        <div
          className={style.Form}
          aria-labelledby={t(prefix + '.formLabel', 'Form')}
        >
          <form
            className={style.ContactForm}
            onSubmit={handleSubmit(onSubmit)}
            aria-labelledby={t(prefix + '.formAriaLabel', 'Contact Form')}
          >
            <ProfilePictureUploader
              aspectRatio={3 / 2}
              thumbnailWidth={169}
              property={'profilePicture'}
              confirmText={t(prefix + '.save', 'save')}
              aria-label={t(
                prefix + '.profilePictureUploader',
                'Profile Picture Uploader',
              )}
            />
            <div className={style.FormItem}>
              <div
                className={style.inputFieldContainer}
                aria-labelledby={t(prefix + '.firstnameLabel', 'First Name')}
              >
                <label>{t(prefix + '.firstName', 'First Name')}</label>
                <div className={style.inputField}>
                  <img
                    src={asset('/images/icons/user-icon.svg')}
                    alt={t(prefix + '.userIconAlt', 'user icon')}
                  />
                  <TextField
                    name="firstname"
                    type="text"
                    className={style.Input}
                    aria-label={t(
                      prefix + '.firstNameInput',
                      'First Name input',
                    )}
                    {...register('firstname', {
                      required: t(
                        prefix + '.firstNameRequired',
                        'Please enter your first name',
                      ),
                    })}
                  />
                </div>
                {errors.firstname && (
                  <div className={style.errorValidation} aria-live="polite">
                    {errors.firstname.message}
                  </div>
                )}
              </div>

              <div
                className={style.inputFieldContainer}
                aria-labelledby={t(prefix + '.lastNameLabel', 'Last Name')}
              >
                <label>{t(prefix + '.lastname', 'Last Name')}</label>
                <div className={style.inputField}>
                  <img
                    src={asset('/images/icons/user-icon.svg')}
                    alt={t(prefix + '.userIconAlt', 'user icon')}
                  />
                  <TextField
                    name="lastname"
                    type="text"
                    className={style.Input}
                    aria-label={t(prefix + '.lastNameInput', 'Last Name input')}
                    {...register('lastname', {
                      required: t(
                        prefix + '.lastnameRequired',
                        'Please enter your last name',
                      ),
                    })}
                  />
                </div>
                {errors.lastname && (
                  <div className={style.errorValidation} aria-live="polite">
                    {errors.lastname.message}
                  </div>
                )}
              </div>

              <div
                className={style.inputFieldContainer}
                aria-labelledby={t(prefix + '.emailLabel', 'Email')}
              >
                <label>{t(prefix + '.email', 'Email')}</label>
                <div className={style.inputField}>
                  <img
                    src={asset('/images/icons/envelope.svg')}
                    alt={t(prefix + '.emailIconAlt', 'email icon')}
                  />
                  <TextField
                    name="email"
                    type="email"
                    className={style.Input}
                    aria-label={t(prefix + '.emailInput', 'Email input')}
                    {...register('email', {
                      required: user.telephone
                        ? false
                        : t(
                            prefix + '.emailRequired',
                            'Please enter your email',
                          ),
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t(
                          prefix + '.emailPattern',
                          'Entered value does not match email format',
                        ),
                      },
                    })}
                  />
                </div>
                {errors.email && (
                  <div className={style.errorValidation} aria-live="polite">
                    {errors.email.message}
                  </div>
                )}
              </div>

              {Capacitor.getPlatform() === 'web' ? (
                <div
                  className={style.inputFieldContainer}
                  aria-labelledby={t(prefix + '.dobLabel', 'Date of birth')}
                >
                  <label>
                    {t(prefix + '.dobOptional', 'Date of birth (Optional)')}
                  </label>
                  <div className={style.inputField}>
                    <div className={style.datepickerField}>
                      <DatePicker
                        className={style.datepicker}
                        calendarClassName={style.calendar}
                        defaultValue={user.birthDate}
                        value={customForm.dob}
                        onChange={(date: Date) => {
                          setCustomForm((prevDate) => ({
                            ...prevDate,
                            dob: date,
                          }));
                        }}
                        calendarIcon={
                          <img
                            src={asset('/images/icons/calendar-icon.svg')}
                            alt={t(
                              prefix + '.calendarIconAlt',
                              'calendar icon',
                            )}
                            className={style.calendarBig}
                          />
                        }
                        maxDate={new Date()}
                        format="dd/MM/yyyy"
                        dayPlaceholder="dd"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                        aria-label={t(prefix + '.datepicker', 'Date picker')}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={style.inputFieldContainer}
                  aria-labelledby={t(prefix + '.dobLabel', 'Date of birth')}
                >
                  <label>
                    {t(prefix + '.dobOptional', 'Date of birth (Optional)')}
                  </label>
                  <div className={style.inputField}>
                    <img
                      src={asset('/images/icons/calendar-icon.svg')}
                      alt={t(prefix + '.calendarIconAlt', 'calendar icon')}
                      className={cl(style.calendarBig, style.inAppIcon)}
                    />
                    <TextField
                      name="dob"
                      type="date"
                      className={cl(style.Input, style.inAppInput)}
                      aria-label={t(
                        prefix + '.dobInput',
                        'Date of birth input',
                      )}
                      {...register('dob')}
                    />
                  </div>
                </div>
              )}

              <div
                className={style.phoneNumberControl}
                aria-labelledby={t(
                  prefix + '.phoneNumberLabel',
                  'Phone Number',
                )}
              >
                <label>
                  {t(
                    prefix + '.phoneNumberOptional',
                    'Phone Number (Optional)',
                  )}
                </label>
                <div className={style.phoneNumberField}>
                  <PhoneInputFlag
                    value={user.telephone || ''}
                    name="phonenumber"
                    onChange={(phone) => {
                      // dont save phone number if only country code is entered
                      setCustomForm((prevFormData) => ({
                        ...prevFormData,
                        phonenumber: phone.length < 5 ? '' : phone,
                      }));
                    }}
                    aria-label={t(
                      prefix + '.phoneNumberInput',
                      'Phone number input',
                    )}
                  />
                </div>
                {customError.field === 'phonenumber' && (
                  <div
                    className={style.customErrorValidation}
                    aria-live="polite"
                  >
                    {customError.message}
                  </div>
                )}
              </div>

              <div
                className={style.inputLocation}
                aria-labelledby={t(prefix + '.locationInputLabel', 'Location')}
              >
                <LocationInput
                  of={user}
                  property={'areaServed'}
                  multiple={false}
                  onCallback={(searchTerm) => {
                    setCustomForm((prevFormData) => ({
                      ...prevFormData,
                      city: searchTerm,
                    }));
                  }}
                  aria-label={t(prefix + '.locationInput', 'Location input')}
                  onRemoveLocation={() => {
                    // remove city from custom form
                    setCustomForm((prevFormData) => ({
                      ...prevFormData,
                      city: '',
                    }));
                  }}
                />
                {customError.field === 'city' && (
                  <div
                    className={style.customErrorValidation}
                    aria-live="polite"
                  >
                    {customError.message}
                  </div>
                )}
              </div>

              <div
                className={style.inputFieldContainer}
                aria-labelledby={t(prefix + '.zipcodeLabel', 'Zip Code')}
              >
                <div className={style.inputField}>
                  <TextField
                    name="zipcode"
                    type="text"
                    placeholder={t(prefix + '.zipcodePlaceholder', 'Zip Code')}
                    className={style.BottomLinedInput}
                    aria-label={t(prefix + '.zipcodeInput', 'Zip code input')}
                    {...register('zipcode')}
                  />
                </div>
              </div>

              <GoToButton
                fullWidth={true}
                color="primary"
                text={t(prefix + '.next', 'Next')}
                type="submit"
                loading={loading}
                aria-label={t(prefix + '.nextButton', 'Next button')}
              />
            </div>
          </form>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default Register;
