import { cl } from 'lincd/lib/utils/ClassNames';
import React from 'react';
import './Card.scss';
import style from './Card.scss.json';
import { ImageObject } from 'lincd-schema/lib/shapes/ImageObject';
import { ImageView } from 'lincd-schema/lib/components/ImageView';
import { useTranslate } from '@tolgee/react';

// TODO: maybe later we will use irlcg component, this Card component is just temporary
const Card = ({
  medal,
  title,
  value,
  identifier,
  image,
}: {
  medal: string;
  title: string;
  identifier: string;
  description: string;
  value: number;
  image: ImageObject;
}) => {
  const medalColor = {
    1: 'bronze',
    2: 'silver',
    3: 'gold',
  };
  let { t } = useTranslate();
  let prefix = 'card';

  return (
    <div className={style.card}>
      <div className={style.cardImage}>
        <div className={cl(style.thumbnail, medal && style[medalColor[medal]])}>
          <>
            <ImageView of={image} alt={title} />
            <div className={style.thumbnailText}>
              <p>{t(prefix + '.' + identifier, identifier)}</p>
              <p>{t(prefix + '.' + title, title)}</p>
              {/* <p>{description}</p> */}
            </div>
          </>
        </div>
      </div>
      <p className={style.value}>{value}</p>
    </div>
  );
};

export default Card;
