import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import Header from '../components/molecules/Header';
import { InputField } from 'lincd-input/lib/components/InputField';
import GoToButton from '../components/atoms/GoToButton';
import './Admin.scss';
import style from './Admin.scss.json';
import SearchSelect from '../components/atoms/SearchSelect';
import { Team, AccountsResult } from 'lincd-irlcg/lib/shapes/Team';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Container } from '../layout/Container';
import Headline from '../components/atoms/Headline';
import { useCometChat } from 'lincd-cometchat/lib/hooks/useCometChat';
import { CometChat } from '@cometchat/chat-sdk-javascript';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { capitalizeFirstLetterEachWords } from '../utils/helper';
import { ROUTES } from '../routes';

type PersonOption = {
  label: string;
  value: AccountsResult;
};

const Admin = () => {
  const [teamStats, setTeamStats] = useState({
    countTeams: 0,
    lastTeamCreated: '',
  });
  let cometChat = useCometChat();
  const [teamID, setTeamID] = useState(null);
  const [leaderTeamID, setLeaderTeamID] = useState(null);
  const [teamChatID, setTeamChatID] = useState(0);
  const [newTeam, setNewTeam] = useState({
    teamLeaderName: '',
    teamLeaderEmail: '',
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTeamLeader, setSelectedTeamLeader] =
    useState<AccountsResult>(null);
  const [matchingPerson, setMatchingPerson] = useState<AccountsResult[]>([]);
  const [bool, setBool] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  //edit team name
  const [allTeams, setAllTeams] = useState([]);
  const [selectedTeamForEdit, setSelectedTeamForEdit] = useState(null);

  const getTeamNameOnly = (str) => {
    if (!str) return;
    return str.replace(/^(Team\s+)+/i, '');
  };

  // Convert the matching persons to options for the search select
  const optionsPerson: PersonOption[] = matchingPerson
    .filter(
      (person) =>
        person.email !== 'info@semantu.com' &&
        person.email !== 'admin@peacegame.earth',
    )
    .map((person) => ({
      label: `${person.firstName} ${person.lastName} (${person.email}) - Team: ${person.teamIdentifier}`,
      value: person,
    }));

  const optionsTeamLeader: PersonOption[] = matchingPerson
    .filter(
      (person) =>
        person.email !== 'info@semantu.com' &&
        person.email !== 'admin@peacegame.earth',
    )
    .map((person) => ({
      label: `${person.firstName} ${person.lastName} (${person.email}) - Team: ${person.teamIdentifier}`,
      value: person,
    }));

  async function createGroupTeamChat(teams) {
    customAlert('Are you sure? Please proceed with caution').then((result) => {
      if (result) {
        if (teams && teams.length > 0) {
          const fetchRequests = teams.map((teamIdentifier) => {
            const GUID = teamIdentifier.toString();
            const groupName = 'Team' + GUID;
            const options = {
              method: 'POST',
              headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                apikey: process.env.COMET_CHAT_API_KEY,
              },
              body: JSON.stringify({
                guid: GUID,
                name: groupName,
                type: 'public',
                icon: 'https://www.peacegame.earth/images/peace-game-logo-spinning-2.gif',
                // Add other properties as needed (e.g., password, icon, description, etc.)
              }),
            };
            return fetch(
              `https://${process.env.COMET_CHAT_APP_ID}.api-us.cometchat.io/v3/groups`,
              options,
            )
              .then((response) => response.json())
              .catch((err) => console.error(err));
          });

          Promise.all(fetchRequests)
            .then((responses) => {
              const lastResponse = responses[responses.length - 1]; // Get the last response
              alert(
                'All request completed, created are the team chat with ID: ' +
                  teams,
              );
              console.log('All requests completed');
              console.log(lastResponse); // Log the last response
            })
            .catch((err) => console.error('Error processing requests:', err));
        } else {
          const GUID = teamChatID.toString();
          const groupName = 'Team' + GUID;
          const options = {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              apikey: process.env.COMET_CHAT_API_KEY,
            },
            body: JSON.stringify({
              guid: GUID,
              name: groupName,
              type: 'public',
              icon: 'https://www.peacegame.earth/images/peace-game-logo-spinning-2.gif',
              // Add other properties as needed (e.g., password, icon, description, etc.)
            }),
          };
          fetch(
            `https://${process.env.COMET_CHAT_APP_ID}.api-us.cometchat.io/v3/groups`,
            options,
          )
            .then((response) => response.json())
            .then((response) => {
              if (response) {
                alert('Team Chat with ID:' + teamChatID + ' has been created');
                console.log(response);
              }
            })
            .catch((err) => console.error(err));
        }
      }
    });
  }

  function customAlert(message) {
    return new Promise((resolve, reject) => {
      const result = window.confirm(message);
      if (result) {
        resolve(true); // User clicked OK
      } else {
        reject(false); // User clicked Cancel
      }
    });
  }

  // // Search for matching persons | list of persons
  // const searchMatchingPersons = (searchTerm) => {
  //   const lowerCaseSearchTerm = searchTerm.toLowerCase();
  //   return matchingPerson.filter(
  //     (person) =>
  //       person.email.toLowerCase().includes(lowerCaseSearchTerm) ||
  //       person.firstName.toLowerCase().includes(lowerCaseSearchTerm) ||
  //       person.lastName.toLowerCase().includes(lowerCaseSearchTerm),
  //   );
  // };

  // Handle the select change
  const handleSelectChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  // Handle the team leader select change
  const onSelectTeamLeader = (selectedOption: PersonOption) => {
    setSelectedTeamLeader(selectedOption.value);

    // clear selected team from and to
    setSelectedMoveTeamFrom(null);
  };

  const onSelectTeam = async (selectedOption) => {
    setSelectedTeamForEdit(selectedOption);
  };

  useEffect(() => {
    let forceUpdate = () => setBool(!bool);
    forceUpdate();
  }, [selectedTeamLeader, selectedUser, allTeams, selectedTeamForEdit]);

  const generateAllTeamChat = () => {
    Team.getAllTeamsIdentifiers().then((teams) => {
      createGroupTeamChat(teams);
    });
  };

  // Handle the save button if needed to show is the user being selected or not
  const handleSave = () => {
    if (selectedUser && teamID) {
      // If the user is selected
      // Admin fix from @rene
      const player = Player.getFromURI(selectedUser.value.uri);
      Team.addUserToTeam(teamID, player, false).then((res) => {
        if (res) {
          alert('User added to team ' + teamID);
          // joinGroupAndKickMember(teamID);
        } else {
          alert('failed to add user to team');
        }
      });
    } else {
      alert('Please select a user and a team ID');
    }
  };

  //assign a user as team leader
  const onAssignAsTeamLeader = () => {
    Team.assignUserAsTeamLeader(leaderTeamID, selectedTeamLeader).then(
      (result) => {
        if (result.team) {
          alert(
            `successfully assigned ${result.user.name} as team leader of Team ${
              result.team?.name || result.team.identifier
            }`,
          );
        }
      },
    );
  };

  const joinGroupAndKickMember = async (teamID: number) => {
    try {
      const GUID = teamID.toString(); // Convert teamID to string
      var password = '';
      const previousGroupID = selectedUser.value.teamIdentifier.toString();
      // TODO: Get the previous / selected user UID
      let uid = '';

      // Join the new group
      const group = await CometChat.joinGroup(
        GUID,
        CometChat.GroupType.Public,
        password,
      );
      console.log('Group joined successfully:', group);

      // Kick the user from the previous group
      // await CometChat.kickGroupMember(previousGroupID, uid);
      // console.log('User kicked from previous group');
    } catch (error) {
      console.log('Error:', error);
      if (error.code === 'ERR_ALREADY_JOINED') {
        console.warn('User already joined group:', error.message);
      }
      if (error.code === 'ERR_GUID_NOT_FOUND') {
        navigate(-1);
        alert(
          'Group does not exist, please ask your Team Leader to create a group first by starting the conversation',
        );
      }
    }
  };
  const onHandleInput = (field) => (e) => {
    const value = e.target.value;
    switch (field) {
      case 'teamID':
        setTeamID(value);
        break;
      case 'teamChatID':
        setTeamChatID(value);
        break;
      case 'leaderTeamID':
        setLeaderTeamID(value);
        break;
      case 'newLeaderName':
        setNewTeam({ ...newTeam, teamLeaderName: value });
        break;
      case 'newLeaderEmail':
        setNewTeam({ ...newTeam, teamLeaderEmail: value });
        break;
      case 'teamForEdit':
        setSelectedTeamForEdit((prev) => ({
          ...prev,
          label: value,
        }));
        break;
    }
  };

  const createNewTeam = useCallback(() => {
    Team.createTeam().then((response) => {
      if (response.error) {
        alert(response.error);
        return;
      } else {
        console.log('Team created:', response);
        alert('Team created with ID: ' + response.team.identifier);
        setTeamStats({
          countTeams: teamStats.countTeams + 1,
          lastTeamCreated: response.team.identifier,
        });
      }
    });
  }, [teamStats]);

  useEffect(() => {
    // Get all the accounts
    Team.getAllAccounts().then((teams) => {
      setMatchingPerson(teams);
    });

    // Get the team property such as number of teams and last team created
    Team.getTeamStatistics().then(({ numTeams, lastTeamCreated }) => {
      setTeamStats({
        countTeams: numTeams,
        lastTeamCreated: lastTeamCreated,
      });
    });

    // Get all the teams id
    Team.getAllTeamsIdentifiers().then((teams) => {
      setAllTeamIds(teams);
    });

    // Get all teams
    Team.getAllTeams().then((teams) => {
      setAllTeams(teams);
    });
  }, []);

  // Protect the page from non-admin users
  useEffect(() => {
    if (
      process.env.ADMIN_EMAIL &&
      auth.userAccount.email !== process.env.ADMIN_EMAIL
    ) {
      navigate(ROUTES.index.path);
    }
  }, []);

  // move team
  const [moveTeams, setMoveTeams] = useState([]);
  const [selectedMoveTeamFrom, setSelectedMoveTeamFrom] = useState(null);
  const [selectedMoveTeamTo, setSelectedMoveTeamTo] = useState(null);
  const [allTeamIds, setAllTeamIds] = useState([]);

  // options move team from
  const optionsMoveTeamFrom = useMemo(() => {
    return moveTeams.map((team: Team) => ({
      label: `Team ${team?.name || team.identifier}`,
      value: team.identifier,
    }));
  }, [moveTeams]);

  // options move team to without selected team from
  const optionsMoveTeamTo = useMemo(() => {
    if (!allTeams) {
      return [];
    }

    return allTeams
      .map((team) => {
        return { label: `Team ${team?.name || team.id}`, value: team.id };
      })
      .filter((team) => team.value !== selectedMoveTeamFrom?.value);
  }, [allTeamIds, allTeams, selectedMoveTeamFrom?.value]);

  const getSelectedUserTeam = useCallback(async () => {
    const user = Player.getFromURI(selectedTeamLeader?.uri);
    const userTeams = await Team.getUserTeams(user);

    if (userTeams) {
      setMoveTeams(userTeams);
    }
  }, [selectedTeamLeader?.uri]);

  const optionsTeam = useMemo(() => {
    return allTeams.map((team) => ({
      label: team.name ? `Team ${team.name}` : `Team ${team.id}`,
      value: team.id,
    }));
  }, [allTeams, selectedTeamForEdit]);

  useEffect(() => {
    if (selectedTeamLeader) {
      getSelectedUserTeam();
    }
  }, [getSelectedUserTeam, selectedTeamLeader]);

  const onChangeMoveTeamFrom = (selectedTeam) => {
    setSelectedMoveTeamFrom(selectedTeam);

    // clear selected team to
    setSelectedMoveTeamTo(null);
  };

  const onChangeMoveTeamTo = (selectedTeam) => {
    setSelectedMoveTeamTo(selectedTeam);
  };

  const onSaveTeamName = async () => {
    const teamId = selectedTeamForEdit?.value;
    const updatedTeamName = capitalizeFirstLetterEachWords(
      selectedTeamForEdit?.label,
    );

    const response = await Team.getTeamById(teamId);

    if (response.error) {
      alert(response.error?.message);
    } else {
      //update the team name
      response.name = updatedTeamName;

      alert(
        `Successfully updated Team ${teamId} display name to Team ${response.name}`,
      );

      // update allTeams state
      setAllTeams((prevTeams) =>
        prevTeams.map((team) =>
          team.id === teamId ? { ...team, name: updatedTeamName } : team,
        ),
      );
      setSelectedTeamForEdit(null);
    }
  };

  const onMoveUserTeam = async () => {
    const user = Player.getFromURI(selectedTeamLeader?.uri);
    const teamId = selectedMoveTeamFrom?.value;
    const teamName = selectedMoveTeamFrom?.label;
    const newTeamId = selectedMoveTeamTo?.value;
    const newTeamName = selectedMoveTeamTo?.label;
    const response = await Team.moveTeamMembersToAnotherTeam(
      user,
      teamId,
      newTeamId,
    );

    if (response.error) {
      alert(response.error?.message);
    } else {
      alert(
        `Successfully moved ${response.player.givenName} from Team ${teamId} ${
          teamName && `(${teamName})`
        } to Team ${newTeamId} ${newTeamName && `(${newTeamName})`}`,
      );

      // clear all fields
      setSelectedTeamLeader(null);
      setSelectedMoveTeamFrom(null);
      setSelectedMoveTeamTo(null);
    }
  };

  return (
    <DefaultLayout backgroundColor="white">
      <Header title="ADMIN" inverted={true} backButton={true} />
      <Container>
        <div className={style.content}>
          <Headline title={'Teams overview'} lined="black" />

          <div className={style.row}>
            <div>
              <p>Number of teams:</p>
              <p>Team number of last team created: </p>
            </div>
            <div>
              <p>{teamStats.countTeams}</p>
              <p>{teamStats.lastTeamCreated}</p>
            </div>
          </div>
          <GoToButton
            text="Add a new team"
            endIcon={false}
            color="primary"
            fullWidth={true}
            onClick={createNewTeam}
          />
        </div>
        <div className={style.content}>
          <Headline title={'Add user to a new team'} lined="black" />

          <SearchSelect
            options={optionsPerson}
            onChange={handleSelectChange}
            value={selectedUser}
          />
          <InputField
            name="teamID"
            placeholder="Team ID"
            onChange={onHandleInput('teamID')}
            value={teamID}
          />
          <GoToButton
            text="Save"
            endIcon={false}
            color="primary"
            fullWidth={true}
            onClick={handleSave}
          />
        </div>
        {/* <div className={style.content}>
          <Headline title={'Create Group Chat'} lined="black" />
          <InputField
            name="teamChatID"
            placeholder="Team Chat ID"
            onChange={onHandleInput('teamChatID')}
            value={teamChatID.toString()}
          />
          <GoToButton
            text="Create"
            endIcon={false}
            color="primary"
            fullWidth={true}
            onClick={createGroupTeamChat}
          />
          <GoToButton
            text="Generate All Team Chat"
            endIcon={false}
            color="primary"
            className={style.generateAllButton}
            fullWidth={true}
            onClick={generateAllTeamChat}
          />
        </div> */}

        {/* Assign user as a team leader */}
        <Headline title="Assign Teamleader" lined="black" />
        <div className={style.content}>
          <SearchSelect
            options={optionsTeamLeader}
            onChange={onSelectTeamLeader}
            value={selectedTeamLeader}
          />

          <InputField
            placeholder="Team Number"
            name="leaderTeamID"
            onChange={onHandleInput('leaderTeamID')}
            value={leaderTeamID}
          />
          <GoToButton
            text="Submit"
            color="primary"
            fullWidth={true}
            onClick={onAssignAsTeamLeader}
          />
        </div>

        {/* Move user to another team */}
        <Headline title="Move Team" lined="black" />
        <div className={style.content}>
          <SearchSelect
            options={optionsTeamLeader}
            onChange={onSelectTeamLeader}
            value={selectedTeamLeader}
            placeholder="Select User"
          />
          <div className={style.teamRow}>
            <SearchSelect
              options={optionsMoveTeamFrom}
              onChange={onChangeMoveTeamFrom}
              value={selectedMoveTeamFrom}
              placeholder="Move team from..."
              isDisabled={!selectedTeamLeader}
            />
            <SearchSelect
              options={optionsMoveTeamTo}
              onChange={onChangeMoveTeamTo}
              value={selectedMoveTeamTo}
              placeholder="Move team to..."
              isDisabled={!selectedMoveTeamFrom}
            />
          </div>
          <GoToButton
            text="Submit"
            color="primary"
            fullWidth={true}
            onClick={onMoveUserTeam}
          />
        </div>

        {/* Edit Team Name */}
        <Headline title="Edit Team Name" lined="black" />
        <div className={style.content}>
          <SearchSelect
            options={optionsTeam}
            onChange={onSelectTeam}
            value={selectedTeamForEdit || null}
            placeholder="Select Team"
          />
          <InputField
            value={getTeamNameOnly(selectedTeamForEdit?.label) || ''}
            onChange={onHandleInput('teamForEdit')}
          />
          <GoToButton
            text="Submit"
            color="primary"
            fullWidth={true}
            onClick={onSaveTeamName}
          />
        </div>

        {/* Create a new team with a team leader */}
        {/* <Headline title="New team with teamleader" lined="black" />
        <div className={style.content}>
          <InputField
            placeholder="Name"
            name="name"
            onChange={onHandleInput('newLeaderName')}
            value={newTeam.teamLeaderName}
          />
          <InputField
            placeholder="E-mail"
            name="email"
            onChange={onHandleInput('newLeaderEmail')}
            value={newTeam.teamLeaderEmail}
          />
          <GoToButton
            text="Submit"
            color="primary"
            fullWidth={true}
            onClick={onCreateNewTeam}
          />
        </div> */}
      </Container>
    </DefaultLayout>
  );
};

export default Admin;
