import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import Header from '../components/molecules/Header';
import './Users.scss';
import style from './Users.scss.json';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from '../package';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'lincd-mui-base/lib/components/Button';
import { TableAccount } from '../components/molecules/TableAccount';
import { cl } from 'lincd/lib/utils/ClassNames';
import { SearchAccountAndAction } from '../components/molecules/SearchAccountAndAction';
import { AccountInAdmin } from '../types/account';
import Select from 'react-select';
import {
  MergeAccountForm,
  MergeAccountSuccess,
} from '../components/molecules/MergeAccountForm';
import { useDebounce } from 'use-debounce';
import { Team } from 'lincd-irlcg/lib/shapes/Team';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [filter, setFilter] = useState('');
  const [debouncedFilter] = useDebounce(filter, 1000);

  const [isSuccess, setIsSuccess] = useState(false);

  // get query params accountId
  const [searchParams, setSearchParams] = useSearchParams();
  const accountId = searchParams.get('accountId');
  const teamId = searchParams.get('teamId');

  // merge accounts
  const [mergeAccounts, setMergeAccounts] = useState<AccountInAdmin[]>([]);
  const [accountFrom, setAccountFrom] = useState({
    account: null,
    actions: null,
  });
  const [accountTo, setAccountTo] = useState({
    account: null,
    actions: null,
  });

  const [accountSelected, setAccountSelected] = useState<AccountInAdmin>(null);
  const [mergeSuccess, setMergeSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const mergeRef = useRef(null);

  // get the first div and set overflow to visible
  // to make the table thead is sticky
  useEffect(() => {
    const firstDiv = document.querySelector('div');
    if (firstDiv) {
      firstDiv.style.overflow = 'visible';
    }
  }, []);

  // get teams data
  useEffect(() => {
    fetchUsers();
    fetchTeams();
  }, []);

  const fetchUsers = async () => {
    try {
      const usersData = await Server.call(packageName, 'getUsers');
      setUsers(usersData);
    } catch (error) {
      console.error('failed to fetch users:', error);
    }
  };

  // get teams data
  const fetchTeams = async () => {
    try {
      const teamsData = await Team.getAllTeamsIdentifiers();
      setTeams(teamsData);
    } catch (error) {
      console.error('failed to fetch teams:', error);
    }
  };

  // handle search change
  const onHandleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value.toLowerCase());
  };

  // filter users by name, email, and telephone from search input
  // also, filter by teamId and accountId from query params
  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      const isMatched = [
        user.givenName,
        user.familyName,
        user.email,
        user.telephone,
      ].some((field) => field?.toLowerCase().includes(debouncedFilter));

      if (teamId) {
        return (
          user.teams.some((team) => team.identifier === teamId) && isMatched
        );
      }

      if (accountId) {
        return user.id === accountId && isMatched;
      }

      return isMatched;
    });
  }, [accountId, debouncedFilter, teamId, users]);

  // this call the server to update the duplicate accound id
  // example:
  // currently we build this feature for fix this issue.
  // there is accountId 1 more than 1, and when generate will start from the highest number accountId
  const handleDuplicateAccount = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // get value from input skipEmail
      const skipEmail = e.target.skipEmail.value;
      const response = await Server.call(
        packageName,
        'generateDuplicateAccountId',
        accountId,
        [skipEmail],
      );

      if (response) {
        setIsSuccess(true);
        alert(response.message);
      }
    } catch (error) {
      console.error('failed to generate duplicate account:');
      alert(
        'An error occurred while generating the duplicate account. Please try again.',
      );
    } finally {
      setLoading(false);
    }
  };

  // format account for SearchSelect
  const formatAccount = (account: AccountInAdmin) => {
    const accountId = account.id;
    const name = account.givenName + ' ' + account.familyName;
    const teamIds = account.teams.map((team) => team.identifier);

    return {
      label: `${accountId} - ${name} - Team ${teamIds.join(', ')}`,
      value: accountId,
    };
  };

  // mapping options for SearchSelect
  const options = useMemo(() => {
    return users.map((user) => {
      // return formatAccount(user);
      const accountId = user?.id;
      return {
        label: `${accountId}`,
        value: accountId,
      };
    });
  }, [users]);

  // mapping teams for SearchSelect
  const teamOptions = useMemo(() => {
    return teams
      .map((teamId) => {
        return {
          label: `Team ${teamId}`,
          value: teamId,
        };
      })
      .sort((a, b) => a.value - b.value);
  }, [teams]);

  // handle filter to add query params by accountId and teamId
  const onHandleFilterChange = (
    selected,
    filterType: 'accountId' | 'teamId',
  ) => {
    const value = selected?.value;
    const newSearchParams = new URLSearchParams(searchParams);

    if (!value) {
      newSearchParams.delete(filterType);
    } else {
      newSearchParams.set(filterType, value);
    }

    // set query params
    setSearchParams(newSearchParams);
  };

  // when reload page, reset the query params and fetch users data
  const onReloadPage = () => {
    setSearchParams({});
    window.location.reload();
  };

  // scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // handle merge account when user click the table row
  const onHandleMergeAccount = (accounts: AccountInAdmin[]) => {
    setMergeAccounts(accounts);
  };

  // scroll to merge section
  const goToMergeSection = () => {
    mergeRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  // get user action by accountId
  const getAction = async (accountId: string) => {
    const action = await Server.call(packageName, 'getUserAction', accountId);
    return action;
  };

  // handle change accountFrom when user change the select option
  const onChangeAccountFrom = async (selected: {
    label: string;
    value: string;
  }) => {
    try {
      const accountId = selected.value;
      const action = await getAction(accountId);

      setAccountFrom({
        account: selected,
        actions: action,
      });

      console.log('accountFrom:', accountFrom);
    } catch (error) {
      console.error('failed to fetch user data:', error);
    }
  };

  // handle change accountTo when user change the select option
  const onChangeAccountTo = async (selected: {
    label: string;
    value: string;
  }) => {
    try {
      const accountId = selected.value;
      const action = await getAction(accountId);

      setAccountFrom({
        account: selected,
        actions: action,
      });

      console.log('accountFrom:', accountFrom);
    } catch (error) {
      console.error('failed to fetch user data:', error);
    }
  };

  useEffect(() => {
    // get account from mergeAccounts for set the accountFrom and accountTo data
    // we need this to fill the Search and Action data every user click the table row
    const getAccountValue = async () => {
      if (mergeAccounts.length === 1) {
        const accountFrom = mergeAccounts[0];
        const accountFromId = mergeAccounts[0].id;
        const actionFrom = await getAction(accountFromId);

        setAccountFrom((prevState) => {
          return {
            ...prevState,
            account: formatAccount(accountFrom),
            actions: actionFrom,
          };
        });
      }

      if (mergeAccounts.length === 2) {
        const accountTo = mergeAccounts[1];
        const accountToId = mergeAccounts[1].id;
        const actionTo = await getAction(accountToId);

        setAccountTo((prevState) => {
          return {
            ...prevState,
            account: formatAccount(accountTo),
            actions: actionTo,
          };
        });
      }
    };

    getAccountValue();
  }, [mergeAccounts]);

  const onHandleMerge = async (data: string) => {
    console.log('starting merge process with data:', data);
    setLoading(true);

    if (!data) {
      alert('Please choose the primary account');
      setLoading(false);
      return;
    }

    const selectedAccountId = data;
    const { value: accountIdFrom } = accountFrom.account;
    const { value: accountIdTo } = accountTo.account;
    const accountIds = [accountIdFrom, accountIdTo];

    console.log('Selected Account ID:', selectedAccountId);
    console.log('Account IDs to merge:', accountIds);

    try {
      const response = await Server.call(
        packageName,
        'mergeAccount',
        accountIds,
        selectedAccountId,
      );

      if (response?.data) {
        console.log('merge successful:', response.data);
        setMergeSuccess(true);
        setAccountSelected(response.data.userAccount);
      } else if (response?.error) {
        console.error('Merge error:', response.error);
        alert(response.error);
      }
    } catch (error) {
      console.error('Unexpected error during merge:', error);
      alert('An unexpected error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DefaultLayout backgroundColor="white">
        <Header title="ADMIN USER LIST" inverted={true} backButton={true} />
        <div className={style.content}>
          <div className={style.block}>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <div className={style.searchFilter}>
                {options.length > 0 && (
                  <Select
                    options={options}
                    placeholder="Filter by Account ID"
                    onChange={(selected) =>
                      onHandleFilterChange(selected, 'accountId')
                    }
                    isClearable={true}
                  />
                )}
              </div>
              <div className={style.searchFilter}>
                {teamOptions.length > 0 && (
                  <Select
                    options={teamOptions}
                    placeholder="Filter by Team"
                    onChange={(selected) =>
                      onHandleFilterChange(selected, 'teamId')
                    }
                    isClearable={true}
                  />
                )}
              </div>
            </div>
            <input
              className={style.filterInput}
              type="text"
              placeholder="Search by Name, Email, or Telephone"
              onChange={onHandleSearchChange}
            />
          </div>
          <TableAccount
            data={filteredUsers}
            onSelectionChange={onHandleMergeAccount}
          />
          {options && mergeAccounts.length === 2 && (
            <div style={{ padding: '1rem' }}>
              <div ref={mergeRef}>
                <h4>Merge duplicate account</h4>
                <div className={style.searchAction}>
                  <SearchAccountAndAction
                    options={options}
                    onHandleChange={onChangeAccountFrom}
                    accountValue={accountFrom.account}
                    defaultAccountValue={accountFrom.account}
                    actionsValue={accountFrom.actions}
                  />
                  <SearchAccountAndAction
                    options={options}
                    onHandleChange={onChangeAccountTo}
                    accountValue={accountTo.account}
                    defaultAccountValue={accountTo.account}
                    actionsValue={accountTo.actions}
                  />
                </div>
              </div>
              {mergeSuccess ? (
                <MergeAccountSuccess account={accountSelected} />
              ) : (
                <MergeAccountForm
                  accounts={mergeAccounts.map((account) =>
                    formatAccount(account),
                  )}
                  name="selectedAccount"
                  onMergeAccount={onHandleMerge}
                  loading={loading}
                />
              )}
            </div>
          )}

          {accountId && users.length > 1 && (
            <div>
              <form
                className={style.formSkipEmail}
                onSubmit={handleDuplicateAccount}
              >
                <input
                  type="text"
                  name="skipEmail"
                  placeholder="Enter Skip Email"
                />
                <Button type="submit" disabled={loading}>
                  {loading ? `loading...` : `Generate Duplicate Account ID`}
                </Button>
              </form>
            </div>
          )}
          {isSuccess && (
            <div style={{ textAlign: 'center' }}>
              <Button onClick={onReloadPage}>Reload Page</Button>
            </div>
          )}
          <div
            className={cl(
              style.mergeAccountBtn,
              mergeAccounts.length === 2 ? style.show : '',
            )}
          >
            <Button onClick={goToMergeSection}>Go to Merge Section</Button>
          </div>
          <div style={{ textAlign: 'center', marginTop: '1rem' }}>
            <Button onClick={scrollToTop}>Scroll to Top</Button>
          </div>
        </div>
      </DefaultLayout>
    </>
  );
};

export default Users;
