import React from 'react';
import Headline from '../atoms/Headline';
import { Topic } from 'lincd-irlcg/lib/shapes/Topic';
import { useTranslate } from '@tolgee/react';
import './ActionPurpose.scss';
import style from './ActionPurpose.scss.json';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router-dom';
import { ROUTES } from '../../routes';

interface ActionPurposeProps {
  topic?: Topic;
  purposeText?: any;
  actionGuidanceText?: any;
}

export const ActionPurpose = ({
  topic,
  purposeText,
  actionGuidanceText,
}: ActionPurposeProps) => {
  let { t } = useTranslate();
  return (
    <>
      <p>
        <span className={style.blue}>{t('word.purpose', 'Purpose')}: </span>{' '}
        {purposeText}
      </p>

      <div>
        <p>
          <span className={style.blue}>
            {t('word.actionGuides', 'Action Guidance')}:
          </span>{' '}
          {actionGuidanceText}
        </p>
        <p>
          <span className={style.bold}>
            <p>
              {t(
                'actionPurpose.learnMore',
                'Learn more about this action below or go to',
              )}{' '}
              <Link
                className="(style.blue)"
                to={generatePath(ROUTES.peace_action.path, {
                  id: topic.name.toLowerCase() + '?step=2',
                })}
              >
                {t('word.learnMorePlanLink', 'Plan')}
              </Link>{' '}
              {t('actionPurpose.learnMore2', 'to get started now.')}
            </p>
          </span>
        </p>
      </div>
    </>
  );
};
