import React, { useEffect, useState } from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import './Resources.scss';
import style from './Resources.scss.json';
import { ListItem } from '../layout/List';
import { resources, resource4b } from '../data-references';
import Header from '../components/molecules/Header';
import MainMenu from '../components/molecules/MainMenu';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../routes';
import { Resource } from 'lincd-irlcg/lib/shapes/Resource';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { UserAccount } from 'profile-plus/lib/shapes/UserAccount';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import ResourceCardWithNav from './ResourceCardWithNav';
import { Team } from 'lincd-irlcg/lib/shapes/Team';
import { ShapeSet } from 'lincd/lib/collections/ShapeSet';
import { useTranslate } from '@tolgee/react';

function Resources() {
  let { t } = useTranslate();
  const auth = useAuth<Player, UserAccount>();
  const navigate = useNavigate();
  const [filteredResources, setFilteredResources] =
    useState<ShapeSet<Resource>>(null);
  let user = auth.user;
  const [isUserInTrialMode, setIsUserInTrialMode] = useState(false);

  const checkProfileCompleted = () => {
    let isCompleted = user.profileSetupCompleted;
    return isCompleted;
  };
  useEffect(() => {
    if (!checkProfileCompleted()) {
      navigate(ROUTES.signin.path);
    }
  }, [user]);

  const isInCertainMode =
    user.isInCertainMode('event') || user.isInCertainMode('trial');

  useEffect(() => {
    Team.getTeamLeader().then((response) => {
      // check if current user playing under correct current team and the user is team leader
      const isTeamLeader =
        response &&
        user?.currentTeam.identifier === response.identifier &&
        response.teamLeader;

      const filteredResources = resources.filter((resource) => {
        if (isTeamLeader) {
          // if user is team leader, show all resources except 'register-your-peace-team'
          return resource.identifier !== 'register-your-peace-team';
        } else {
          if (isInCertainMode) {
            // if user is not team leader & in trial mode they only can access to 'how-to-use-the-peace-game-app' and 'peace-game-videos'
            return (
              resource.identifier == 'how-to-use-the-peace-game-app' ||
              resource.identifier == 'peace-game-videos'
            );
          } else {
            // if user is not team leader & not in trial mode, skip both 'peace-team-member-registration' and 'register-your-peace-team'
            return (
              resource.identifier !== 'peace-team-member-registration' &&
              resource.identifier !== 'register-your-peace-team'
            );
          }
        }
      });
      setFilteredResources(filteredResources);
    });
  }, [, user, isUserInTrialMode]);

  return (
    <DefaultLayout>
      <Header
        title={t('word.resources', 'Resources')}
        inverted={true}
        fixed={true}
      />
      <div className={style.mainContainer}>
        <ListItem
          of={filteredResources}
          as={ResourceCardWithNav}
          className={style.listItem}
        />
        <MainMenu />
      </div>
    </DefaultLayout>
  );
}

export default Resources;
