import {useEffect, useState} from 'react';
import {CapacitorUpdater} from '@capgo/capacitor-updater';
import {Dialog} from '@capacitor/dialog';
import {SplashScreen} from '@capacitor/splash-screen';
import {Capacitor} from '@capacitor/core';

export const useAutomaticUpdates = () => {
  const [updateCounter, setUpdateCounter] = useState(0);

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      // set the channel based on the environment, now only works for production and staging
      if (process.env.NODE_ENV === 'production') {
        console.log('setting capgo channel to prod: ' + process.env.NODE_ENV);
        CapacitorUpdater.setChannel({
          channel: 'production',
          triggerAutoUpdate: true,
        });
      } else if (process.env.NODE_ENV === 'staging') {
        console.log('setting capgo channel to dev: ' + process.env.NODE_ENV);
        CapacitorUpdater.setChannel({
          channel: 'dev',

          // TODO: later after we have a better way to handle this, we can set this to true
          // for now don't want to trigger auto update in staging/dev.
          triggerAutoUpdate: false,
        });
      }

      const handleUpdateAvailable = async (res) => {
        try {
          if (updateCounter > 0) {
            // Event has already been handled, do nothing.
            return;
          }

          await Dialog.alert({
            title: 'Update Available',
            message: `Your app will be updated to the latest version (${res.bundle.version})`,
          });

          CapacitorUpdater.set(res.bundle);

          SplashScreen.show({
            showDuration: 2000,
            autoHide: true,
          });
          setUpdateCounter((prevCounter) => prevCounter + 1);
        } catch (error) {
          console.log(error);
        }
      };

      // Add the event listener
      CapacitorUpdater.addListener('updateAvailable', handleUpdateAvailable);

      // Notify app ready
      CapacitorUpdater.notifyAppReady();
      // Clean up by removing the event listener when the component unmounts
      return () => {
        CapacitorUpdater.removeAllListeners();
      };
    }
  }, [updateCounter]);
};
