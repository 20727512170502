import React from 'react';
import './ActionCard.scss';
import style from './ActionCard.scss.json';
import { cl } from 'lincd/lib/utils/ClassNames';
import { getMedalType } from '../../utils/medal';

interface ActionProps {
  data: any;
  type: 'actionPlan' | 'actionDebrief' | 'topicScore';
  showDetail?: boolean;
}

export const ActionCard = ({ data, type, showDetail }: ActionProps) => {
  if (type === 'topicScore') {
    return <TopicScoreComponent topicScore={data} showDetail={showDetail} />;
  } else if (type === 'actionDebrief') {
    return (
      <ActionDebriefComponent actionDebrief={data} showDetail={showDetail} />
    );
  } else if (type === 'actionPlan') {
    return <ActionPlanComponent actionPlan={data} showDetail={showDetail} />;
  }
};

// ActionPlanComponent.tsx
const ActionPlanComponent = ({ actionPlan, showDetail }) => (
  <div className={style.actionRow}>
    <h6>Action Plan</h6>
    <div
      className={cl(
        style.actionList,
        actionPlan.length === 0 ? style.noSpace : '',
        showDetail ? style.showDetail : '',
      )}
    >
      {actionPlan.map((plan, y) => (
        <div key={y}>
          <p>Topic: {plan.topic.name}</p>
          <p>
            planGrowingEdge: {getDisplayValue(plan.planGrowingEdge, showDetail)}
          </p>
          <p>
            planIntentionStatement:{' '}
            {getDisplayValue(plan.planIntentionStatement, showDetail)}
          </p>
          <p>
            planTeamSupport: {getDisplayValue(plan.planTeamSupport, showDetail)}
          </p>
          <p>intendedMedal: {plan.intendedMedal}</p>
        </div>
      ))}
    </div>
  </div>
);

// ActionDebriefComponent.tsx
const ActionDebriefComponent = ({ actionDebrief, showDetail }) => (
  <div className={style.actionRow}>
    <h6>Action Debrief</h6>
    <div
      className={cl(
        style.actionList,
        actionDebrief.length === 0 ? style.noSpace : '',
        showDetail ? style.showDetail : '',
      )}
    >
      {actionDebrief.map((debrief, x) => (
        <div key={x}>
          <p>Topic: {debrief.topic.name}</p>
          <p>
            debriefplanGrowingEdge:{' '}
            {getDisplayValue(debrief.debriefplanGrowingEdge, showDetail)}
          </p>
          <p>
            debriefactionsTaken:{' '}
            {getDisplayValue(debrief.debriefactionsTaken, showDetail)}
          </p>
          <p>
            debriefDescription:{' '}
            {getDisplayValue(debrief.debriefDescription, showDetail)}
          </p>
          <p>
            debriefLearnings:{' '}
            {getDisplayValue(debrief.debriefLearnings, showDetail)}
          </p>
          <p>
            debriefProblems:{' '}
            {getDisplayValue(debrief.debriefProblems, showDetail)}
          </p>
        </div>
      ))}
    </div>
  </div>
);

// TopicScoreComponent.tsx
const TopicScoreComponent = ({ topicScore, showDetail }) => (
  <div className={style.actionRow}>
    <h6>Topic Score</h6>
    <div
      className={cl(
        style.actionList,
        topicScore.length === 0 ? style.noSpace : '',
        showDetail ? style.showDetail : '',
      )}
    >
      {topicScore.map((ts, z) => (
        <div key={z}>
          <p>Topic: {ts.topic.name}</p>
          <p>score: {ts.score}</p>
          <p>medal: {getMedalType(ts.medal)}</p>
        </div>
      ))}
    </div>
  </div>
);

const getDisplayValue = (value: string, showDetail: boolean) => {
  return showDetail ? value : value?.length;
};
