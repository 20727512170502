import React from 'react';
import style from './1_EmpowermentLearn.scss.json';
import './1_EmpowermentLearn.scss';
import GoToButton from '../../atoms/GoToButton';
import ActionContent from '../../molecules/ActionContent';
import { useTranslate } from '@tolgee/react';
import { topic1 } from '../../../data-references';
import { Video } from '../../atoms/Video';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { ActionPurpose } from '../../molecules/ActionPurpose';
import { Button } from 'lincd-mui-base/lib/components/Button';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { getActiveLanguage } from '../../../utils/tolgee';

const EmpowermentLearn = () => {
  let topic = topic1;
  const navigate = useNavigate();
  let { t } = useTranslate();
  const activeLanguage = getActiveLanguage();

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'topic' + topic.identifier;
  return (
    <div className={style.content}>
      <ActionPurpose
        topic={topic}
        purposeText={t(
          prefix + '.learn.purposeText',
          'The Empowerment action increases your personal agency enabling you to be more successful in playing the Peace Game.',
        )}
        actionGuidanceText={
          <p>
            {t(
              prefix + '.learn.actionGuidesText1',
              'To be an effective agent of change we need to believe that change is possible and empower ourselves with tools to make it happen. In Action 1 you will listen to and read about ways to empower yourself and others.',
            )}
            <br />
            <br />
            {t(
              prefix + '.learn.actionGuidesText2',
              `Make sure to do this action on your ${topic1.name} growing edge. If you have done personal development work, take this to the next level by discovering and working on your growing edges in each of the areas of your life.`,
            )}
          </p>
        }
      />

      <ActionContent
        title={t(
          prefix + '.empowermentOriginStory',
          'Empowerment Origin Story',
        )}
        content={
          <p>
            {t(
              prefix + '.learn.originStory1',
              'The Peace Game’s 7 actions grow out of a remarkable journey to create Peace on Earth. David Gershon, architect of the First Earth Run and the Peace Game, shares the origin story of each action complimented by photos and videos of this remarkable journey.',
            )}
            <div className={style.originImage}>
              <img
                src={asset('/images/First-Earth-Run-Opening-Ceremony.webp')}
                alt={t(
                  prefix + '.learn.originImageAlt1',
                  '[1] Native American elders create the fire and offer an inspirational message at the sunrise ceremony on the grounds of the United Nations. New York City, USA [2] Eternal Flame lit at the United Nations. New York City, USA [3] Child reading her message to UN dignitaries at opening ceremony. New York City, USA [4] United Nations Secretary General, Javier Perez de Cuellar, with torch at opening ceremony. New York City, USA [5] Children singing in front of the United Nations New York City, USA',
                )}
              />
              <p>
                {t(
                  prefix + '.learn.originImageAlt1',
                  '[1] Native American elders create the fire and offer an inspirational message at the sunrise ceremony on the grounds of the United Nations. New York City, USA [2] Eternal Flame lit at the United Nations. New York City, USA [3] Child reading her message to UN dignitaries at opening ceremony. New York City, USA [4] United Nations Secretary General, Javier Perez de Cuellar, with torch at opening ceremony. New York City, USA [5] Children singing in front of the United Nations New York City, USA',
                )}
              </p>
            </div>
            {t(
              prefix + '.learn.originStory2',
              'Watch the First Earth Run video to experience hope, possibility, and a dream for our world.',
            )}
          </p>
        }
      />
      <Video
        // src={`https://poe.earth/hardLink-action-1-learn-os_1_${activeLanguage}`}
        src={`https://poe.earth/hardLink-action-1-learn-os_1`}
      />

      <ActionContent
        title={t(
          prefix + '.empowermentFrequencyMeditationTitle',
          'Empowerment Frequency Meditation',
        )}
        content={t(
          prefix + '.learn.frequencyMeditation',
          'The Empowerment Frequency Meditation by David Gershon, with harp accompaniment by Tami Briggs, is designed to help you play the Peace Game at a deeper level. ',
        )}
      />

      <Video
        src={`https://poe.earth/hardLink-action-1-learn-fm_${activeLanguage}`}
      />

      <GoToButton
        text={t('word.goToPlan', 'Go to Plan')}
        aria-label={t('word.goToPlan', 'Go to Plan')}
        onClick={() => {
          navigate(
            generatePath(ROUTES.peace_action.path, {
              id: topic.name.toLowerCase() + '?step=2',
            }),
          );
        }}
      />
    </div>
  );
};

export default EmpowermentLearn;
