import React from 'react';
import './Meeting09.scss';
import { default as style } from './Meeting09.scss.json';
import { registerPackageModule, linkedComponent } from '../../../package';
import { useTranslate } from '@tolgee/react';
import { Video } from '../../atoms/Video';
import { UnsortedList } from '../../atoms/UnsortedList';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { OrderedList } from '../../atoms/OrderedList';
import Headline from '../../atoms/Headline';
import { ListAlt, Sort } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { getActiveLanguage } from '../../../utils/tolgee';

export function Meeting09() {
  let { t } = useTranslate();
  const navigate = useNavigate();
  const activeLanguage = getActiveLanguage();

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'resource-Meeting09';
  return (
    <>
      <div className={style.content}>
        <Headline
          subtitle={t('word.Pre-Meeting', 'Pre-Meeting Preparation')}
          lined="black"
        />
        <p>
          {t(
            prefix + '.paragraph01',
            'Here are elements to help you prepare to lead the meeting. Ignore those that apply for in-person meetings if your team is meeting virtually.',
          )}
        </p>
        <UnsortedList>
          <li>
            <u>{t('word.roomSetup', 'Room Setup')}</u>
            {': '}
            {t(
              prefix + '.bullet01',
              'Arrange the room in a U shape with you in front. This allows people to see one another and you, as the facilitator, increasing the quality of the sharing and community building.',
            )}
          </li>
          <li>
            <u>{t('word.timing', 'Timing')}</u>
            {': '}
            {t(
              prefix + '.bullet02',
              'Tell the group at the start of the meeting what time you propose to end and check that everyone can stay until the end. Make sure you end on time. Each element in the meeting agenda has an approximate time associated with it. It is rare that any meeting element will go for exactly that amount of time, so use it as a guideline. If one meeting element runs longer, you will need to make up the time by shortening another. Give the team shared responsibility for timekeeping. If any point seems likely to take a lot longer than you planned, tell the team and ask them to decide whether to extend the meeting or hurry to finish the point.',
            )}
          </li>
          <li>
            <u>{t('word.ShareActionPlan', 'Share Action Plan')}</u>
            {': '}
            {t(
              prefix + '.bullet03',
              'As the meeting facilitator, share your Action Plan when you get to the “Plan” section to role model the process.',
            )}
          </li>
          <li>
            <u>{t('word.practice', 'Practice')}</u>
            {': '}
            {t(
              prefix + '.bullet04',
              'To get the full value from this script, take time to study it so you are fluent and understand what you are trying to achieve with each meeting element.',
            )}
          </li>
        </UnsortedList>
        <Headline
          subtitle={t(
            'word.AgendaHeader',
            'Agenda Summary and Approximate Times',
          )}
          lined="black"
        />
        <OrderedList type={1}>
          <li>
            {t('word.InnerPeaceMed', 'Inner Peace Meditation')} - 5{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t('word.checkin', 'Check In')} - 50 {t('word.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(prefix + '.AgendaItem02a', 'Answer Questions')} - 15{' '}
                {t('word.mintues', 'minutes')}
              </li>
              <li>
                {t(prefix + '.AgendaItem02b', 'Partner Share')} - 20{' '}
                {t('word.mintues', 'minutes')}
              </li>
              <li>
                {t(prefix + '.AgendaItem02c', 'Team Share')} - 15{' '}
                {t('word.mintues', 'minutes')}
              </li>
            </UnsortedList>
          </li>
          <li>
            {t(prefix + '.AgendaItem03', 'DreamKeeper Initiation Ceremony')} -
            30 {t('word.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(prefix + '.AgendaItem03a', 'Overview')} - 5{' '}
                {t('word.mintues', 'minutes')}
              </li>
              <li>
                {t(prefix + '.AgendaItem03b', 'Initiation Ritual')} - 20{' '}
                {t('word.mintues', 'minutes')}
              </li>
              <li>
                {t(prefix + '.AgendaItem03c', 'Large Group Share')} - 15{' '}
                {t('word.mintues', 'minutes')}
              </li>
            </UnsortedList>
          </li>
          <li>
            {t('word.reviewNextSteps', 'Review Next Steps')} - 5{' '}
            {t('word.mintues', 'minutes')}
          </li>
        </OrderedList>
        <p>{t('word.total2hours', 'Total Time 2 - hours')}</p>
        <Headline
          subtitle={t('word.meetingScript', 'Meeting Script')}
          lined="black"
        />
        <OrderedList type={1}>
          <li>
            <u>{t(prefix + '.AgendaItem01', 'Inner Peace Meditation')}</u> - 5{' '}
            {t(prefix + '.mintues', 'minutes')}{' '}
            <UnsortedList>
              <li>
                {t(
                  prefix + '.a1_bullet01',
                  'Inner Peace Meditation: Action Leader starts the meeting either through playing video or guiding it.',
                )}
                <Video
                  src={`https://poe.earth/hardLink-inner-peace`}
                  // src={`https://poe.earth/hardLink-inner-peace_${activeLanguage}`}
                />
              </li>
              <li>
                {t(prefix + '.a1_bullet02', 'Guided meditation')}
                {': '}
                <UnsortedList>
                  <li>
                    {t(
                      prefix + '.a1_bullet03',
                      'This inner peace work complements the outer peace work of the game.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet04',
                      'Take a few deep breaths to quiet your mind.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet05',
                      'Now become present to this moment of profound opportunity for rapid evolution of your life and planet Earth.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet06',
                      'Experience peace within yourself. Affirm: I am in harmony with self!',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet07',
                      'Experience yourself at peace with all the people in your life. Affirm: I am in harmony with others.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet08',
                      'Experience yourself at peace with the Earth and all living beings who inhabit it. Affirm: I am in harmony with the Earth and all living beings.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet09',
                      'Deepen this state of harmony with self, others and the Earth for the next few minutes.',
                    )}
                  </li>
                </UnsortedList>
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t(prefix + '.AgendaItem02', 'Check-In')}</u> - 15{' '}
            {t(prefix + '.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + '.a2_bullet01',
                  'Insights from last week and implementation of Faith Action.',
                )}
              </li>
              <li>
                {t(
                  prefix + '.a2_bullet02',
                  'Review team accomplishments from Scoreboard.',
                )}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t(prefix + '.AgendaItem03', 'Reflection Exercise')}</u> - 50{' '}
            {t('word.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(prefix + '.03Bullet01', 'Answer Questions')} - 15{' '}
                {t('word.mintues', 'minutes')}
                <OrderedList type={1}>
                  <li>
                    <u>{t(prefix + '.03Bullet01aLabel', 'Empowerment')}</u>
                    {': '}
                    {t(
                      prefix + '.03Bullet01a',
                      'What have you learned about the empowerment peace practice of becoming an agent of peace on the planet?',
                    )}
                  </li>
                  <li>
                    <u>{t(prefix + '.03Bullet02Label', 'Oneness')}</u>
                    {': '}
                    {t(
                      prefix + '.03Bullet02',
                      'What have you learned about the oneness peace practice of befriending the other?',
                    )}
                  </li>
                  <li>
                    <u>{t(prefix + '.03Bullet03Label', 'Unity')}</u>
                    {': '}
                    {t(
                      prefix + '.03Bullet03',
                      'What have you learned about the unity peace practice of celebrating our common humanity?',
                    )}
                  </li>
                  <li>
                    <u>{t(prefix + '.03Bullet04Label', 'Cooperation')}</u>
                    {': '}
                    {t(
                      prefix + '.03Bullet04',
                      'What have you learned about the cooperation peace practice of working with others for the common good?',
                    )}
                  </li>
                  <li>
                    <u>{t(prefix + '.03Bullet05Label', 'Abundance')}</u>
                    {': '}
                    {t(
                      prefix + '.03Bullet05',
                      'What have you learned about the abundance peace practice of supporting peacemakers?',
                    )}
                  </li>
                  <li>
                    <u>{t(prefix + '.03Bullet06Label', 'Love')}</u>
                    {': '}
                    {t(
                      prefix + '.03Bullet06',
                      'What have you learned about the love peace practice of praying for and visualizing Peace on Earth?',
                    )}
                  </li>
                  <li>
                    <u>{t(prefix + '.03Bullet07Label', 'Faith')}</u>
                    {': '}
                    {t(
                      prefix + '.03Bullet07',
                      'What have you learned about the faith peace practice of engaging others to play the Peace Game?',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.03Bullet08',
                      'What have you learned about yourself in playing the Peace Game?',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.03Bullet09',
                      'What have you learned about the possibility of Peace on Earth by 2030?',
                    )}
                  </li>
                </OrderedList>
              </li>
              <li>
                <u>{t(prefix + '.03Bullet02', 'Partner Share')}</u> - 25{' '}
                {t(prefix + '.mintues', 'minutes')}
              </li>
              <li>
                <u>{t(prefix + '.03Bullet03', 'Team Share')}</u> - 10{' '}
                {t(prefix + '.mintues', 'minutes')}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>
              {t(prefix + '.AgendaItem04', 'DreamKeeper Initiation Ceremony')}
            </u>{' '}
            - 45 {t(prefix + '.mintues', 'minutes')}
            <UnsortedList>
              <li>
                <u>{t(prefix + '.a4_bullet01', 'Introduction')}</u> - 5{' '}
                {t(prefix + '.mintues', 'minutes')}
                {<br></br>}
                <p>
                  {t(
                    prefix + '.a4_bullet02',
                    'This is the final formal part of the Peace Game. It is the moment when a player becomes initiated as one of the planet’s DreamKeepers holding the intention of Peace on Earth by 2030 and acting on that belief by practicing the 7 peace actions in their life. Dreamkeepers are the foundation for creating Peace on Earth by 2030.',
                  )}
                </p>
                <p>
                  {t(
                    prefix + '.a4_bullet03',
                    'In order to participate in the ceremony, the player needs to achieve at least a bronze medal in all 7 actions. If a player has not achieved at least a bronze medal in any of the seven actions and wishes to be initiated as a DreamKeeper the player needs to commit to do the missing action(s) within two weeks. You’re on your honor.',
                  )}
                </p>
                <p>
                  {t(
                    prefix + '.a4_bullet04',
                    'We will send out, via email, a DreamKeeper certificate for all those who have entered at least a bronze medal for all seven actions. Expect this within two weeks of ending the Peace Game.',
                  )}
                </p>
              </li>
              <li>
                <u>
                  {t(prefix + '.a4_bullet05', 'DreamKeeper Initiation Ritual')}
                </u>{' '}
                - 20 {t(prefix + '.mintues', 'minutes')}
                <p>
                  {t(
                    prefix + '.a4_bullet06',
                    'Timekeeper— Each person has approximately 2 minutes. Ask people to be succinct.',
                  )}
                </p>
                <OrderedList type={1}>
                  <li>
                    {t(
                      prefix + '.a4_bullet06a',
                      'Are you willing to hold the intention for Peace on Earth by 2030 and commit to implementing the 7 peace practices in your life?',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a4_bullet06b',
                      'What was your greatest learning and growth from playing the Peace Game?',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a4_bullet06c',
                      'What will you do to implement the peace practices in your life?',
                    )}
                  </li>
                </OrderedList>
              </li>
              <li>
                <u>{t(prefix + '.a4_bullet07', 'Large Group Share')}</u> - 15{' '}
                {t(prefix + '.mintues', 'minutes')}
                <p>
                  {t(
                    prefix + '.a4_bullet07a',
                    'Formalize their initiation as a DreamKeeper.',
                  )}
                </p>
                <p>
                  {t(
                    prefix + '.a4_bullet07b',
                    'Acknowledge their achievements.',
                  )}
                </p>
                <p>
                  {t(
                    prefix + '.a4_bullet07c',
                    'Invite reflections of Dreamkeeper Initiation ritual.',
                  )}
                </p>
                <p>
                  {t(
                    prefix + '.a4_bullet07d',
                    'After each player has achieved at least a bronze peace medal in each of the seven actions they will be able to download their DreamKeeper Certificate. ',
                  )}
                </p>
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>
              {t(
                prefix + '.AgendaItem05',
                'Review Next Steps and Opportunities',
              )}
            </u>{' '}
            - 10 {t(prefix + '.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + '.a4_bullet11',
                  'Registration for the Global Online Peace Game',
                )}
                {': '}
                <a href="https://poe.earth/events">poe.earth/events</a>
              </li>
              <li>
                {t(
                  prefix + '.a4_bullet12',
                  'Registration for Peace Game Information Meetings',
                )}
                {': '}
                <a href="https://poe.earth/events">poe.earth/events</a>
              </li>
              <li>
                {t(
                  prefix + '.a4_bullet13',
                  'Registration for Legion of Light Peace Peace Prayer Circle (First Tuesday of month 1-2 pm ET)',
                )}
                {': '}
                <a href="https://poe.earth/events">poe.earth/events</a>
              </li>
              <li>
                {t(
                  prefix + '.a4_bullet14',
                  'To learn more about creating a POE Zone visit',
                )}
                {': '}
                <a href="peacegame.earth/how-to-create-poe-zone">
                  How to Create a Peace on Earth Zone
                </a>
              </li>
              <li>
                {t(
                  prefix + '.a4_bullet15',
                  'Registration to become a Peace on Earth Partner',
                )}
                {': '}
                <a href="https://poe.earth/partner">poe.earth/partner</a>{' '}
                {<br></br>}
                {<br></br>}
                {t(
                  prefix + '.a4_bullet16',
                  'If you have any questions about becoming a Peace on Earth Partner, go to the',
                )}{' '}
                <Link className="(style.blue)" to={ROUTES.contact.path}>
                  {t('button.Contact', 'Contact')}
                </Link>{' '}
                {t(
                  prefix + '.a4_bullet17',
                  'page and select "Become a Peace on Earth Partner."',
                )}
              </li>
            </UnsortedList>
          </li>
        </OrderedList>
      </div>
    </>
  );
}
