import React from 'react';
import { Button } from 'lincd-mui-base/lib/components/Button';
import './Medal.scss';
import * as style from './Medal.scss.json';
import { useTranslate } from '@tolgee/react';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

// import { topic1 } from '../../dummy-data';

interface MedalProps {
  text?;
  image;
  title?;
  actions?;
  time?;
  onSelect?: () => void;
}

export const Medal = ({ text, image, title, actions, time }: MedalProps) => {
  return (
    <>
      <div className={style.center}>
        <img src={asset(`/images/medals/${image}`)} alt="medal" />
      </div>
      <p className={style.textCenter}>
        <span>
          <span className={style.bold}>{title} </span> {text}
        </span>
      </p>
      <div className={style.statusContainer}>
        {actions && (
          <div className={style.status}>
            <img src={asset('/images/icons/EarthIcon.svg')} alt="action" />
            <p>{actions}</p>
          </div>
        )}
        {time && (
          <div className={style.status}>
            <img src={asset('/images/icons/ClockIcon.svg')} alt="action" />
            <p>{time}</p>
          </div>
        )}
      </div>
    </>
  );
};
