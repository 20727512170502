import React, { useEffect, useState } from 'react';
import ActionInputField from '../atoms/ActionInputField';
import { ActionPlan } from 'lincd-irlcg/lib/shapes/ActionPlan';
import './ActionPlan.scss';
import { useTranslate } from '@tolgee/react';
import { Topic } from 'lincd-irlcg/lib/shapes/Topic';
import { ActionDebrief } from 'lincd-irlcg/lib/shapes/ActionDebrief';
import { Spinner } from '../atoms/Spinner';

interface ActionPlanProps {
  topic?: Topic;
  time?;
  action?;
}

const ActionDebriefComponent = ({ topic }: ActionPlanProps) => {
  const [actionDebrief, setActionDebrief] = useState<ActionDebrief>();
  let { t } = useTranslate();
  useEffect(() => {
    ActionDebrief.loadActionDebrief(topic)
      .then((loadedPlan) => {
        setActionDebrief(loadedPlan);
      })
      .catch((error) => {
        console.error('Error loading action debrief:', error);
      });
  }, [topic]);

  return actionDebrief ? (
    <>
      <ActionInputField
        text={'1. ' + t('word.actionsTaken', 'What action(s) did you take?')}
        of={actionDebrief}
        multiline={true}
        valueProperty={'debriefactionsTaken'}
      />
      <ActionInputField
        text={
          '2. ' +
          t('word.actionsDone', 'What did you learn from taking this action?')
        }
        valueProperty={'debriefLearnings'}
        multiline={true}
        of={actionDebrief}
      />
      <ActionInputField
        text={
          '3. ' +
          t('word.lessonLearned', 'How did you grow from taking this action?')
        }
        valueProperty={'debriefplanGrowingEdge'}
        multiline={true}
        of={actionDebrief}
      />
      <ActionInputField
        text={
          '4. ' +
          t(
            'word.problemEncountered',
            'Where, if anywhere, did you encounter a problem and how did you address it?',
          )
        }
        valueProperty={'debriefProblems'}
        multiline={true}
        of={actionDebrief}
      />
    </>
  ) : (
    <Spinner />
  );
};

export default ActionDebriefComponent;
