import React, { useState } from 'react';
import { InputField } from 'lincd-input/lib/components/InputField';
import { DateInputField } from 'lincd-input/lib/components/DateInputField';
import { cl } from 'lincd/lib/utils/ClassNames';
import './ActionInputField.scss';
import style from './ActionInputField.scss.json';
import { Topic } from 'lincd-irlcg/lib/shapes/Topic';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

interface ActionInputFieldProps {
  text;
  type?;
  className?;
  pointLabel?;
  of?;
  valueProperty?;
  topic?: Topic;
  multiline?: boolean;
  onCallBack?: (value: string) => void;
}

const ActionInputField = ({
  text,
  type,
  className,
  pointLabel,
  of,
  topic,
  valueProperty,
  multiline = false,
}: ActionInputFieldProps) => {
  let currentInputs = [1];
  if (type === 'date') {
    for (let i = 2; i <= 5; i++) {
      if (of[valueProperty + i] !== null) {
        currentInputs.push(i);
      }
    }
  }
  const [dateInputs, setDateInputs] = useState(currentInputs);

  const addAnotherDate = () => {
    // Create a new array with an additional element (e.g., another DateInputField)
    setDateInputs((prevInputs) => [...prevInputs, dateInputs.length + 1]);
  };

  const removeDate = (indexToRemove) => {
    of[valueProperty + indexToRemove] = null; //.splice(indexToRemove, 1);
    setDateInputs((prevInputs) =>
      prevInputs.filter((_) => _ !== indexToRemove),
    );
  };

  return (
    <div>
      <p className={style.unstyled}>{text}</p>
      {type !== 'date' ? (
        <div>
          {pointLabel}
          <InputField
            className={cl(style.textfield, className)}
            of={of}
            valueProperty={valueProperty}
            multiline={multiline}
          />
        </div>
      ) : (
        <div>
          {dateInputs.map((inputNumber) => (
            <>
              <DateInputField
                key={inputNumber}
                className={cl(style.textfield, className)}
                valueProperty={`${valueProperty + inputNumber}`}
                of={of}
              />
            </>
          ))}
          {/* Add a remove button, only allow to remove the last date added */}
          {dateInputs.length > 1 && (
            <img
              src={asset('/images/icons/RemoveIcon.svg')}
              alt="remove icon"
              onClick={() => removeDate(dateInputs.length)}
            />
          )}
          {/* add another DateInput Field if onClick on image being clicked */}
          {dateInputs.length < 5 && (
            <img
              src={asset('/images/icons/AddIcon.svg')}
              alt="add icon"
              onClick={addAnotherDate}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ActionInputField;
