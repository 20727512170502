import React, { InputHTMLAttributes } from 'react';
import './PhoneInputFlag.scss';
import { default as style } from './PhoneInputFlag.scss.json';
import { PhoneInput, PhoneInputProps } from 'react-international-phone';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { cl } from 'lincd/lib/utils/ClassNames';
import { Shape } from 'lincd/lib/shapes/Shape';

interface PhoneInputFlagProps extends PhoneInputProps {
  className?: string;
  defaultCountry?: string;
  value?: string;
}

/**
 * PhoneInputFlag component for rendering an international phone input with a flag
 */
export function PhoneInputFlag({
  className,
  defaultCountry = 'us', //  set a default region code
  value,
  ...restProps
}: PhoneInputFlagProps) {
  // retrieves the region code for a given phone number.
  const getRegionCodeForNumber = (phoneNumberString: string) => {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();

    try {
      const phoneNumber = phoneNumberUtil.parse(phoneNumberString, 'zz'); // ZZ for unknown region
      return phoneNumberUtil
        .getRegionCodeForNumber(phoneNumber)
        .toLocaleLowerCase();
    } catch (error) {
      return defaultCountry; // set a default region code
    }
  };

  const country = value ? getRegionCodeForNumber(value) : defaultCountry;

  return (
    <div className={cl(style.field, className)}>
      <PhoneInput
        className={style.phoneNumber}
        defaultCountry={country}
        value={value}
        {...restProps}
      />
    </div>
  );
}
