import React, { useEffect } from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import './InviteYourTeam.scss';
import style from './InviteYourTeam.scss.json';
import { Team } from 'lincd-irlcg/lib/shapes/Team';
import { Person } from 'lincd-schema/lib/shapes/Person';
import { UserAccount } from 'profile-plus/lib/shapes/UserAccount';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import StepHeader from '../components/molecules/StepHeader';
import { Button } from 'lincd-mui-base/lib/components/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../routes';
import { useTranslate } from '@tolgee/react';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

function Register() {
  // auth user by user account and organization
  let { t } = useTranslate();
  const auth = useAuth<Player, UserAccount>();
  const [teamID, setTeamID] = React.useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    Team.getTeamLeader().then((result) => {
      setTeamID(result.identifier);
    });
  }, []);

  const copyContent = () => {
    // copy team id to clipboard
    navigator.clipboard.writeText(
      `To join the team, please use Team ID:${teamID}, as your team number`,
    );
  };

  return (
    <DefaultLayout>
      <div className={style.InviteYourTeamContainer}>
        <StepHeader
          step={2}
          title={t('word.InviteYourTeam', 'INVITE YOUR TEAM')}
        />

        <div className={style.Content}>
          <img src={asset('/images/team-action.webp')} alt="team action" />
          <h3 className={style.ContentTitle}>Team Registration Link</h3>
          <div className={style.ContentMain}>
            <p>Join my team in the peace game!</p>
            <div className={style.contentCopied}>
              <ol>
                <li>Download the app: https://poe.earth/app</li>
                <li> Sign up and click “join a team”</li>
                <li> Use team number: {teamID}</li>
              </ol>
              <img
                src={asset('/images/copyButton.png')}
                alt="copy button"
                onClick={copyContent}
                className={style.copyButton}
              />
            </div>
          </div>

          <div className={style.ContentBottom}>
            <p>
              Share this registration link with anyone you want to join your
              team.{' '}
            </p>
            <p>
              <strong>Note:</strong> If you are playing the Global Online
              version of The Game the team size is 3 people. If you are playing
              the Peace on Earth Zone version of The Game the team size is 5-8
              people.
            </p>
          </div>
          <Button onClick={() => navigate(ROUTES.index.path)}>Next</Button>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default Register;
