import React from 'react';
import './Banner.scss';
import style from './Banner.scss.json';
import { Button } from 'lincd-mui-base/lib/components/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { useTranslate } from '@tolgee/react';

const Banner = ({ title, subtitle, image }) => {
  let { t } = useTranslate();
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(ROUTES.index.path);
  };
  return (
    <>
      <Button
        startIcon={
          <img
            src={asset('/images/icons/back.svg')}
            alt={t('icon.return', 'return')}
          />
        }
        className={style.returnButton}
        onClick={handleNavigation}
        aria-label={t('button.return', 'return button')}
      >
        &nbsp;{t('word.return', 'Return')}
      </Button>
      <img src={image} alt="banner" className={style.banner} />
      <div className={style.headerContainer}>
        <h2>{title}</h2>
        <h5>{subtitle}</h5>
      </div>
      {/* <Header title={title} subtitle={subtitle} className={style.header} /> */}
    </>
  );
};

export default Banner;
