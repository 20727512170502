import React, { useEffect } from 'react';
import style from './GameCompletion.scss.json';
import './GameCompletion.scss';
import CertificatePDF from '../molecules/CertificatePDF';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { Person } from 'profile-plus/lib/shapes/Person';
import { UserAccount } from 'profile-plus/lib/shapes/UserAccount';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

// getUserName function is a placeholder for the current user's full name
function getUserName() {
  const auth = useAuth<Person, UserAccount>();
  const user = auth.user;
  // If user is not authenticated, log an error
  if (!user) {
    console.error('User is not authenticated.');
    return;
  }
  // Combine the user's given name and family name to get the full name
  const userFullName = user.givenName + ' ' + user.familyName;

  return userFullName;
}

const GameCompletion = () => {
  return (
    <div className={style.container}>
      <img src={asset('/images/Fire_Orange.gif')} alt="fire" />
      <p>
        Congratulations on your completion of the Peace Game journey. The world
        is a better place for your efforts! Thank you!
      </p>
      <p>
        The long game now begins of practicing these peace actions ongoingly in
        your life. This is the path of the DreamKeeper.
      </p>
      <p>
        If you wish to deepen your experience, we invite you to play the Peace
        Game again with a new set of friends.
      </p>
      <p>Peace be with you!</p>
      <br></br>
      <div className={style.certificate}>
        <p>Here is your DreamKeeper Certificate:</p>
        <br></br>
        <div>
          <CertificatePDF
            fullName={getUserName()}
            certificateName="Dreamkeeper Certificate"
            templateSrc={
              process.env.SITE_ROOT + '/public/dreamkeeper-certificate.pdf'
            }
          />
        </div>
      </div>
    </div>
  );
};

export default GameCompletion;
