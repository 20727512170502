import React from 'react';
import './MergeAccountForm.scss';
import style from './MergeAccountForm.scss.json';
import { Button } from 'lincd-mui-base/lib/components/Button';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { AccountInAdmin } from '../../types/account';

interface MergeAccountFormProps {
  accounts: {
    label: string;
    value: string;
  }[];
  name: string;
  onMergeAccount: (value: string) => void;
  loading?: boolean;
}
export const MergeAccountForm = ({
  accounts,
  name,
  onMergeAccount,
  loading,
}: MergeAccountFormProps) => {
  const onMergeSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const formValue = formData.get(name) as string;

    if (onMergeAccount) {
      onMergeAccount(formValue);
    }
  };
  return (
    <div className={style.block}>
      <div className={style.wrapper}>
        <h4>Choose Primary Account</h4>
        <p>Other account will be removed</p>
        <form onSubmit={onMergeSubmit}>
          {accounts.map((account) => (
            <p key={account.value}>
              <label>
                <input type="radio" name={name} value={account.value} />
                {account.label}
              </label>
            </p>
          ))}
          <Button type="submit" disabled={loading}>
            {loading ? `Loading...` : `Merge Account`}
          </Button>
        </form>
      </div>
    </div>
  );
};

export const MergeAccountSuccess = ({
  account,
}: {
  account: AccountInAdmin;
}) => {
  const onReloadPage = () => {
    window.location.reload();
  };

  return (
    <div>
      <div className={style.alertSuccess}>
        <p>Success! The account has been merged to Account ID {account.id}</p>
        <Link
          target="_blank"
          to={generatePath(ROUTES.user.path, {
            accountId: account.id,
          })}
        >
          See Detail Account
        </Link>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Button onClick={onReloadPage}>Reload Page</Button>
      </div>
    </div>
  );
};
