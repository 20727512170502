import { topic1, topic2 } from '../../../data-references';
import ShareActionDetails from '../../molecules/ShareActionDetails';

const EmpowermentShare = () => {
  let topic = topic1;

  return (
    <ShareActionDetails
      topic={topic}
      currentTopic={topic1}
      nextTopic={topic2}
    />
  );
};

export default EmpowermentShare;
