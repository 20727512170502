import React from 'react';
import ShareActionDetails from '../../molecules/ShareActionDetails';
import { topic7 } from '../../../data-references';

const FaithShare = () => {
  let topic = topic7;

  return <ShareActionDetails topic={topic} currentTopic={topic7} />;
};

export default FaithShare;
