import React, { useEffect, useState } from 'react';
import ActionInputField from '../atoms/ActionInputField';
import { ActionPlan } from 'lincd-irlcg/lib/shapes/ActionPlan';
import './ActionPlan.scss';
import { Topic } from 'lincd-irlcg/lib/shapes/Topic';
import { Spinner } from '../atoms/Spinner';
import { useTranslate } from '@tolgee/react';
import { minutesToTimeString } from '../../utils/medal';

interface ActionPlanProps {
  time?: string | number; // Assuming time is optional and can be a number
  action: string;
  topic: Topic;
}

const ActionPlanComponent = ({ time, action, topic }: ActionPlanProps) => {
  const { t } = useTranslate();
  const [actionPlan, setActionPlan] = useState<ActionPlan>();

  useEffect(() => {
    ActionPlan.loadActionPlan(topic)
      .then((loadedPlan) => {
        setActionPlan(loadedPlan);
      })
      .catch((error) => {
        console.error('Error loading action plan:', error);
      });
  }, [topic]);

  console.log('Time:', time);

  const getTimeText = () => {
    let timeText;
    if (time) {
      timeText = time.toString().toLowerCase();
    } else {
      timeText = t('actionPlan.selectGoalAbove', '(Select Goal Above)');
    }
    return `3. ${t(
      'actionPlan.peaceGoalIntro',
      'My peace goal will take',
    )} ${timeText}. ${t(
      'actionPlan.peaceGoalSchedule',
      'I have scheduled it in my calendar at these times:',
    )}`;
  };

  return (
    <>
      {actionPlan ? (
        <>
          <ActionInputField
            text={t(
              'actionPlan.growThroughAction',
              '1. How do you wish to grow through taking this action?',
            )}
            valueProperty={'planGrowingEdge'}
            multiline={true}
            of={actionPlan}
            aria-label={t(
              'actionPlan.growThroughAction',
              'How do you wish to grow through taking this action?',
            )}
          />
          <ActionInputField
            text={t(
              'actionPlan.planToAchieveGoal',
              '2. What is your plan to achieve the Peace Action goal you selected?',
            )}
            valueProperty={'planIntentionStatement'}
            multiline={true}
            of={actionPlan}
            aria-label={t(
              'actionPlan.planToAchieveGoal',
              'What is your plan to achieve the Peace Action goal you selected?',
            )}
          />
          <ActionInputField
            text={getTimeText()}
            type="date"
            valueProperty={'scheduledTime'}
            of={actionPlan}
            topic={topic}
            aria-label={getTimeText()}
          />
          <ActionInputField
            text={t(
              'actionPlan.teamSupport',
              '4. What support do you wish from your team?',
            )}
            multiline={true}
            valueProperty={'planTeamSupport'}
            of={actionPlan}
            aria-label={t(
              'actionPlan.teamSupport',
              'What support do you wish from your team?',
            )}
          />
        </>
      ) : (
        <Spinner aria-label={t('loading', 'Loading')} />
      )}
    </>
  );
};

export default ActionPlanComponent;
