import React from 'react';
import Headline from '../atoms/Headline';
import { Topic } from 'lincd-irlcg/lib/shapes/Topic';
import { useTranslate } from '@tolgee/react';
import './SharePurpose.scss';
import style from './SharePurpose.scss.json';

interface SharePurposeProps {
  topic?: Topic;
  purposeText?: any;
  actionGuidanceText?: any;
}

export const SharePurpose = ({
  topic,
  purposeText,
  actionGuidanceText,
}: SharePurposeProps) => {
  let { t } = useTranslate();
  return (
    <>
      <p>
        <span className={style.blue}>{t('word.purpose', 'Purpose')}: </span>
        {t(
          'share.purposeText',
          'Take your Peace Action(s), submit your results, and debrief the action with your team.',
        )}
      </p>
    </>
  );
};
