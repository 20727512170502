import React, { useState } from 'react';
import Headline from '../../atoms/Headline';
import style from './1_EmpowermentPlan.scss.json';
import './1_EmpowermentPlan.scss';
import GoToButton from '../../atoms/GoToButton';
import ActionContent from '../../molecules/ActionContent';
import ActionPlan from '../../molecules/ActionPlan';
import { useTolgee, useTranslate } from '@tolgee/react';
import { topic1 } from '../../../data-references';

import { ROUTES } from '../../../routes';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  BronzeGoal,
  GoldGoal,
  SetYourGoal,
  SilverGoal,
} from '../../molecules/SetYourGoal';
import { Video } from '../../atoms/Video';
import { AudioPlayer } from 'react-audio-player-pro';
import { PlanPurpose } from '../../molecules/PlanPurpose';
// import {Capacitor} from '@capacitor/core';
import { getActiveLanguage } from '../../../utils/tolgee';

const EmpowermentPlan = () => {
  const [actionPlanTime, setActionPlanTime] = useState(0);
  let topic = topic1;
  const navigate = useNavigate();
  let { t } = useTranslate();
  const activeLanguage = getActiveLanguage();

  let prefix = 'topic' + topic.identifier;

  const handleTimeSelected = (minutes) => {
    setActionPlanTime(minutes);
  };

  const trackList = [
    {
      src: `https://poe.earth/hardLink-empowerment-01_${activeLanguage}`,
      mediaMetadata: {
        title: t('book.e.chapter01', '01 - Getting Ready'),
      },
    },
    {
      src: `https://poe.earth/hardLink-empowerment-02_${activeLanguage}`,
      mediaMetadata: {
        title: t('book.e.chapter02', '02 - Crafting Reality with Thought'),
      },
    },
    {
      src: `https://poe.earth/hardLink-empowerment-03_${activeLanguage}`,
      mediaMetadata: {
        title: t('book.e.chapter03', '03 - Personal Power'),
      },
    },
    {
      src: `https://poe.earth/hardLink-empowerment-04_${activeLanguage}`,
      mediaMetadata: {
        title: t('book.e.chapter04', '04 - Core Beliefs'),
      },
    },
    {
      src: `https://poe.earth/hardLink-empowerment-05_${activeLanguage}`,
      mediaMetadata: {
        title: t('book.e.chapter05', '05 - The Journey Emotions'),
      },
    },
    {
      src: `https://poe.earth/hardLink-empowerment-06_${activeLanguage}`,
      mediaMetadata: {
        title: t('book.e.chapter06', '06 - Money'),
      },
    },
    {
      src: `https://poe.earth/hardLink-empowerment-07_${activeLanguage}`,
      mediaMetadata: {
        title: t('book.e.chapter07', '07 - Work'),
      },
    },
    {
      src: `https://poe.earth/hardLink-empowerment-08_${activeLanguage}`,
      mediaMetadata: {
        title: t('book.e.chapter08', '08 - Spirituality'),
      },
    },
    {
      src: `https://poe.earth/hardLink-empowerment-09_${activeLanguage}`,
      mediaMetadata: {
        title: t('book.e.chapter09', '09 - Returning Home'),
      },
    },
    // Add more tracks as needed
  ];

  return (
    <>
      <div className={style.content}>
        <PlanPurpose topic={topic} />

        <Headline title={t('word.gameplay', 'Gameplay')} lined="black" />

        <ActionContent
          subtitle={t('word.howToKeepScore', 'How to Keep Score')}
          content={
            <>
              <p>
                <span className={style.underline}>
                  {t('word.bronzePeaceMedal', 'Bronze Peace Medal')}
                </span>
                :{' '}
                {t(
                  prefix + '.plan.bronzePeaceMedalText1',
                  'Empowerment Audio Program.',
                )}{' '}
                <span className={style.blue}>
                  ({t(prefix + '.plan.bronzePeaceMedalText2', '1 Peace Action')}
                  )
                </span>
                <br />
                <br />
                <span className={style.underline}>
                  {' '}
                  {t('word.silverPeaceMedal', 'Silver Peace Medal')}
                </span>
                :{' '}
                {t(
                  prefix + '.plan.silverPeaceMedalText1',
                  'This includes doing the Bronze Medal action and listening to the frequency meditations for each action.',
                )}{' '}
                <span className={style.blue}>
                  (
                  {t(prefix + '.plan.silverPeaceMedalText2', '2 Peace Actions')}
                  )
                </span>
                <br />
                <br />
                <span className={style.underline}>
                  {t('word.goldPeaceMedal', 'Gold Peace Medal')}
                </span>
                :{' '}
                {t(
                  prefix + '.plan.goldPeaceMedalText1',
                  'This includes doing the Bronze Medal action, Silver Medal action, and reading excerpts from the book Social Change 2.0.',
                )}{' '}
                <span className={style.blue}>
                  ({t(prefix + '.plan.goldPeaceMedalText2', '3 Peace Actions')})
                </span>
              </p>
              <p className={style.center}>
                <span className={style.bold}>
                  {t(
                    prefix + 'plan.instructionAboutInstructionMaterials',
                    'Action materials are at the bottom of this page.',
                  )}
                </span>
              </p>
            </>
          }
        />

        <SetYourGoal topic={topic} onTimeSelected={handleTimeSelected}>
          <BronzeGoal
            actions={1}
            minutes={70}
            text={t(prefix + '.goal.bronze', 'Empowerment Audio Program')}
          />
          <SilverGoal
            actions={2}
            minutes={100}
            text={t(
              prefix + '.goal.silver',
              'Empowerment Audio Program (70 minutes) and Frequency Meditations (30 minutes for each of the 7 actions)',
            )}
          />
          <GoldGoal
            actions={3}
            minutes={220}
            text={t(
              prefix + '.plan.gold',
              'Empowerment Audio Program (70 minutes), Frequency Meditations (30 minutes for each of the 7 actions) and Social Change 2.0 (120 minutes)',
            )}
          />
        </SetYourGoal>

        <Headline
          subtitle={t(
            'word.createPeaceActionPlan',
            'Create Your Peace Action Plan',
          )}
          lined="black"
        />
        <ActionPlan
          topic={topic}
          action={'empowerment'}
          time={actionPlanTime}
        />

        <ActionContent
          title={t(
            prefix + '.plan.empowermentActionMaterial',
            'Empowerment Action Material',
          )}
          content={
            <>
              <p>
                <span className={style.bold}>
                  {t(
                    prefix + '.plan.empowermentAudioProgram',
                    'Empowerment Audio Program',
                  )}
                  :{' '}
                </span>
                {t(
                  prefix + '.plan.empowermentAudioProgramText',
                  'These are excerpts from the book ',
                )}
                <i>
                  {t(
                    prefix + '.plan.empowermentAudioProgramText2',
                    'Empowerment: The Art of Creating your Life as You Want It',
                  )}
                </i>
                {t(
                  prefix + '.plan.empowermentAudioProgramText3',
                  ' by David Gershon and Gail Straub. The first four segments offer you empowerment fundamentals to use as you play the Peace Game. They are required for a Bronze Peace Medal. The next five segments are optional. They will assist you in deepening your personal growth.',
                )}
              </p>
              <AudioPlayer
                trackList={trackList}
                // Additional props specific to this component can be set here
              />
              <p>
                <span className={style.bold}>
                  {t(
                    'word.peaceOnEarthFrequencyMeditation',
                    'Peace on Earth Frequency Meditations',
                  )}
                  :{' '}
                </span>
                {t(
                  prefix + '.plan.peaceOnEarthFrequencyMeditationText2',
                  'These are designed to help you play the Peace Game at a deeper level by understanding and internalizing the essence of each action. We recommend listening to the frequency meditation before taking each action.',
                )}
              </p>
              <p>
                {t(
                  prefix + '.plan.peaceOnEarthFrequencyMeditationText3',
                  'You will also find the meditations in the “Learn” section of each action.',
                )}
              </p>
              <Video
                src={`https://poe.earth/hardLink-fq-playlist_${activeLanguage}`}
              />
              <p>
                <span className={style.bold}>
                  {' '}
                  {t('word.empowermentSocialChange', 'Social Change 2.0')}:{' '}
                </span>{' '}
                {t(
                  prefix + '.plan.empowermentSocialChangeText',
                  'These are three excerpted chapters from the book',
                )}{' '}
                <i>
                  {t(
                    prefix + '.plan.empowermentSocialChangeText2',
                    'Social Change 2.0: A Blueprint for Reinventing Our World',
                  )}{' '}
                </i>
                {t(
                  prefix + '.plan.empowermentSocialChangeText3',
                  'by David Gershon. The chapters provide insight into the second order change methodology upon which the Peace Game is built.',
                )}
              </p>
              <br />
              <iframe
                src={`https://poe.earth/hardLink-SC-2-0_${activeLanguage}`}
                width="100%"
                height="480"
                allow="autoplay"
              ></iframe>
            </>
          }
        />
        <GoToButton
          text={t('word.goToShare', 'Go to Share')}
          aria-label={t('word.goToShare', 'Go to Share')}
          onClick={() =>
            navigate(
              generatePath(ROUTES.peace_action.path, {
                id: topic.name.toLowerCase() + '?step=3',
              }),
            )
          }
        />
      </div>
    </>
  );
};

export default EmpowermentPlan;
