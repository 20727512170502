import React from 'react';
import './SearchAccountAndAction.scss';
import style from './SearchAccountAndAction.scss.json';
import { ActionCard } from '../atoms/ActionCard';
import { cl } from 'lincd/lib/utils/ClassNames';
import Select from 'react-select';

interface SearchAccountAndActionProps {
  options: any[];
  onHandleChange: (value: any) => void;
  accountValue: {
    label: string;
    value: string;
  };
  defaultAccountValue?: {
    label: string;
    value: string;
  };
  actionsValue;
  placeholder?: string;
}
export const SearchAccountAndAction = ({
  options,
  onHandleChange,
  accountValue,
  defaultAccountValue,
  actionsValue,
  placeholder = 'Select Account...',
}: SearchAccountAndActionProps) => {
  return (
    <div style={{ flex: '1' }}>
      <Select
        options={options}
        onChange={onHandleChange}
        value={accountValue}
        defaultValue={defaultAccountValue}
        placeholder={placeholder}
        isClearable={true}
      />
      <div>
        {actionsValue &&
          actionsValue?.map((action, i: number) => {
            return (
              <div key={i} className={style.teamRow}>
                <p className={cl(style.teamHeadline, style.sticky)}>
                  Team {action.identifier}
                </p>
                <ActionCard data={action.actionPlan} type={'actionPlan'} />
                <ActionCard
                  data={action.actionDebrief}
                  type={'actionDebrief'}
                />
                <ActionCard data={action.topicScore} type={'topicScore'} />
              </div>
            );
          })}
      </div>
    </div>
  );
};
