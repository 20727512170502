import React from 'react';
import style from './5_AbundanceLearn.scss.json';
import './5_AbundanceLearn.scss';
import GoToButton from '../../atoms/GoToButton';
import { topic5 } from '../../../data-references';
import ActionContent from '../../molecules/ActionContent';
import { useTranslate } from '@tolgee/react';
import { UnsortedList } from '../../atoms/UnsortedList';
import { Video } from '../../atoms/Video';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { ActionPurpose } from '../../molecules/ActionPurpose';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { getActiveLanguage } from '../../../utils/tolgee';

const AbundanceLearn = () => {
  let topic = topic5;
  let { t } = useTranslate();
  const navigate = useNavigate();
  const activeLanguage = getActiveLanguage();

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'topic' + topic.identifier;
  return (
    <>
      <div className={style.content}>
        <ActionPurpose
          topic={topic}
          purposeText={t(
            prefix + '.learn.purposeText',
            'The Abundance action is to support an organization, either financially or through volunteering.',
          )}
          actionGuidanceText={
            <p>
              {t(
                prefix + '.learn.actionGuidesText1',
                'Your life is the game, organize this action around it. Look for the organizations in your life, community or world that are practicing one or more of the Peace on Earth by 2030 values of befriending the other, celebrating our common humanity and cooperating for the common good.',
              )}
              <br />
              <br />
              {t(
                prefix + '.learn.actionGuidesText2',
                `Opportunities can include`,
              )}
              {': '}
              <br />
              <br />
              <UnsortedList>
                <li>
                  {t(
                    prefix + '.learn.opportunities1',
                    'Organizations that further mediation between conflicting parties.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.opportunities2',
                    'Organizations involved in peace building activities between conflicting parties.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.opportunities3',
                    'Organizations creating cross cultural events in your community or the world.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.opportunities4',
                    'Organizations creating interfaith or cross cultural dialogues in your community or the world.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.opportunities5',
                    'Organizations empowering the disenfranchised in your community or world.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.opportunities6',
                    'Organizations that facilitate cooperation for the common good through multi-organization impact initiatives, sometimes called “collective impact.”',
                  )}
                </li>
              </UnsortedList>
              <br />
              {t(
                prefix + '.learn.actionGuidesText3',
                'This action is not about contributing money or time to your favorite charity, albeit it could be, if it is doing some of the above.',
              )}
              <br />
              <br />
              {t(
                prefix + '.learn.actionGuidesText4',
                `The deeper personal growth opportunity of this action is discovering your growing edge on expressing abundance in the world. It's not about how much you contribute, but rather about cultivating an attitude of generosity and trust in an abundant universe.`,
              )}
            </p>
          }
        />

        <ActionContent
          title={t('word.abundanceOriginStory', 'Abundance Origin Story')}
          content={
            <p>
              <div className={style.originImage}>
                <img
                  src={asset('/images/First-Earth-Run-collection-8.webp')}
                  alt="[1] President Li Xiuannian in a light moment with First Earth Run International Director, Gail Straub. Bejing, China [2] Street kids carry torch. Rio de Janeiro, Brazil [3] Young and old running with joy. China"
                />
                <p>
                  {t(
                    prefix + '.learn.originImageAlt1',
                    '[1] President Li Xiuannian in a light moment with First Earth Run International Director, Gail Straub. Bejing, China [2] Street kids carry torch. Rio de Janeiro, Brazil [3] Young and old running with joy. China',
                  )}
                </p>
              </div>
              {t(
                prefix + '.learn.originStory1',
                'While there is a lot we can do personally to further peace on earth, some things require organizations dedicated to this undertaking. We had the good fortune during the First Earth Run to partner with the United Nations Children’s Fund and their visionary leader Jim Grant. It was very gratifying to contribute to their noble mission by raising millions of dollars for children suffering from the ravages of war. We were told that it was equally gratifying for them to experience our support. We brought fresh energy to UNICEF’s intrepid souls on the peacemaking front lines.',
              )}
              <br />
              <br />
              {t(
                prefix + '.learn.originStory2',
                'While peace on earth is humanity’s most noble aspiration, when push comes to shove most people on the planet feel it is just not attainable. But what if it was?',
              )}
              <p>
                <UnsortedList>
                  <li>
                    {t(
                      prefix + '.learn.whatiflist1',
                      'What if working for peace on earth was a natural way of life?',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.learn.whatiflist2',
                      'What if the peacemakers, supported by the rest of us, had abundant success in their mission?',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.learn.whatiflist3',
                      'What if mothers told their children that one day, they could be great peacemakers, and this became the definition of a superhero?',
                    )}
                  </li>
                </UnsortedList>
              </p>
              <div className={style.originImage}>
                <img
                  src={asset('/images/First-Earth-Run-collection-9.webp')}
                  alt="[1] Holy man with miner’s lamps transporting the fire. Mathura, India [2] Tibetan spiritual leader lights torch carried up Mt. Everest by American and Chinese mountaineers. Lhasa, Tibet [3] President Ibrahim Bababgida delighted 60,000 spectators by an impromptu run with torch. Lagos, Nigeria"
                />
                <p>
                  {t(
                    prefix + '.learn.originImageAlt3',
                    '[1] Holy man with miner’s lamps transporting the fire. Mathura, India [2] Tibetan spiritual leader lights torch carried up Mt. Everest by American and Chinese mountaineers. Lhasa, Tibet [3] President Ibrahim Bababgida delighted 60,000 spectators by an impromptu run with torch. Lagos, Nigeria',
                  )}
                </p>
              </div>
              {t(
                prefix + '.learn.originStory4',
                'The peacemakers are our planet’s healers. They are also some of the most selfless people on the planet as this is a calling without much financial remuneration. The more we help them, the more they can heal the differences in our world. The more they heal that which separates us; the better will be the world we inhabit. It is a virtuous spiral. This action invites you to support organizations in your community or around the world practicing the skills needed to create peace on earth.',
              )}
              <br />
              <br />
              <span className={style.bold}>
                {t(
                  prefix + '.learn.originStory5',
                  'Watch Good Morning America’s weekly series tell the story of the torch of peace circumnavigating the earth. Weeks 5, 6 & 7',
                )}
              </span>
            </p>
          }
        />

        <Video
          src={`https://poe.earth/hardLink-action-5-learn-os_1`}
          // src={`https://poe.earth/hardLink-action-5-learn-os_1_${activeLanguage}`}
        />

        <ActionContent
          title={t(
            'word.abundanceFrequencyMeditation',
            'Abundance Frequency Meditation',
          )}
          content={t(
            prefix + '.learn.frequencyMeditation',
            'The abundance Frequency Meditation by David Gershon, with harp accompaniment by Tami Briggs, is designed to help you play  the Peace Game at a deeper level.',
          )}
        />

        <Video
          src={`https://poe.earth/hardLink-action-5-learn-fm_${activeLanguage}`}
        />

        <GoToButton
          text={t('word.goToPlan', 'Go to Plan')}
          onClick={() => {
            navigate(
              generatePath(ROUTES.peace_action.path, {
                id: topic.name.toLowerCase() + '?step=2',
              }),
            );
          }}
        />
      </div>
    </>
  );
};

export default AbundanceLearn;
