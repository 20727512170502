import React, { useEffect, useState } from 'react';
import './ChatGroup.scss';
import style from './ChatGroup.scss.json';
import { Dialog } from '@capacitor/dialog';
import {
  CometChatMessages,
  CometChatUIKit,
  CometChatThreadedMessages,
  ListItemStyle,
  MessagesStyle,
  MessageComposerStyle,
  MessageComposerConfiguration,
  MessageListConfiguration,
  MessageListStyle,
  CometChatTheme,
  CometChatUIKitConstants,
} from '@cometchat/chat-uikit-react';
import { useParams } from 'react-router-dom';
import { useCometChat } from 'lincd-cometchat/lib/hooks/useCometChat';

import {
  CometChatMessageTemplate,
  fontHelper,
  CometChatActionsIcon,
} from '@cometchat/uikit-resources';
import { DefaultLayout } from '../layout/DefaultLayout';
import Header from '../components/molecules/Header';
import { Team } from 'lincd-irlcg/lib/shapes/Team';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { ErrorBoundary } from 'react-error-boundary';
import { Spinner } from '../components/atoms/Spinner';
import { useCapacitor } from 'lincd-capacitor/lib/hooks/useCapacitor';
import ReportMessageModal from 'lincd-cometchat/lib/components/MessageExtension';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { CometChatAPI } from 'lincd-cometchat/lib/utils/CometChatAPI';
import { cl } from 'lincd/lib/utils/ClassNames';
import { CometChat } from '@cometchat/chat-sdk-javascript';

const Chat = () => {
  const auth = useAuth();
  let cometChat = useCometChat();
  const { id } = useParams<{ id: string }>();

  const [chatWithGroup, setChatWithGroup] = React.useState<any>();
  const [teamNumber, setTeamNumber] = React.useState<number>(0);
  const [teamName, setTeamName] = React.useState<string>('');
  const [parentMessage, setParentMessage] = React.useState(null);
  const [sender, setSender] = React.useState<any>();
  const [onReport, setOnReport] = React.useState<boolean>(false);
  const [templates, setTemplates] = useState<CometChatMessageTemplate[]>([]);
  const [welcome, setWelcome] = React.useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [reasonReported, setReasonReported] = React.useState<string>('');
  const [messageSelected, setMessageSelected] = React.useState<any>(null);

  const player = auth.userAccount.accountOf as Player;

  useEffect(() => {
    // Trigger fetching team information and the custom templates when CometChat is ready
    if (cometChat.ready) {
      getCustomTemplates();
      fetchTeamInformation();
    }
  }, [cometChat.ready]);

  const getGroupDetails = async (groupID: string) => {
    try {
      const group = await cometChat.getGroup(groupID);
      setChatWithGroup(group);
      setWelcome(false);
    } catch (error) {
      console.error('Error fetching group details:', error);
    }
  };

  // get custom message templates with report message
  // https://www.cometchat.com/docs-beta/ui-kit/react/message-template#options
  const getCustomTemplates = () => {
    try {
      const definedTemplates =
        CometChatUIKit.getDataSource()?.getAllMessageTemplates() || [];

      // filter the templates and show only the category "message"
      const template = definedTemplates
        .filter(
          (template) =>
            template.category ===
            CometChatUIKitConstants.MessageCategory.message,
        )
        .map((template) => ({
          ...template,
          // add custom options to the message template
          options: (
            loggedInUser: CometChat.User,
            message: CometChat.BaseMessage,
            theme: CometChatTheme,
            group?: CometChat.Group,
          ) => getCustomOptions(loggedInUser, message, theme, group),
        }));

      setTemplates(template);
    } catch (error) {
      console.error('Error fetching custom templates:', error);
      return [];
    }
  };

  // create custom options for message template
  const getCustomOptions = (
    loggedInUser: CometChat.User,
    message: CometChat.BaseMessage,
    theme: CometChatTheme,
    group?: CometChat.Group,
  ) => {
    const defaultOptions = CometChatUIKit.getDataSource().getTextMessageOptions(
      loggedInUser,
      message,
      theme,
      group,
    );

    // add report option to the first option
    defaultOptions.unshift(createReportOption(message, theme));
    return defaultOptions;
  };

  // create a new report message option
  const createReportOption = (
    message: CometChat.BaseMessage,
    theme: CometChatTheme,
  ) => {
    return new CometChatActionsIcon({
      id: 'report',
      title: 'Report Message',
      iconURL: '/public/images/report.svg',
      onClick: () => {
        setOnReport(true);
        setMessageSelected(message);
      },
      iconTint: theme.palette.getAccent600(),
      backgroundColor: 'transparent',
      titleFont: fontHelper(theme.typography.subtitle1),
      titleColor: theme.palette.getAccent600(),
    });
  };

  // show dialog for reporting message
  const showDialog = async (title, message) => {
    await Dialog.alert({ title, message });
    closeModal();
    setIsModalOpen(false);
  };

  // handle delete and report message
  const deleteAndReportMessage = async () => {
    try {
      await cometChat.deleteMessage(messageSelected.id);
      await cometChat.callExtension('report-message', 'POST', 'v1/report', {
        msgId: messageSelected.id,
        reason: reasonReported,
      });
      await showDialog(
        'Message reported',
        `Message has been reported. Thanks!`,
      );
    } catch (error) {
      console.log('Error reporting message:', error);
      handleReportError(error);
    }
  };

  // handle join group
  const joinGroup = async (teamID: string) => {
    const GUID = teamID.toString();
    const password = '';

    try {
      // Attempt to join the group
      const group = await cometChat.joinGroup(GUID, 'public', password);
      console.log('Group joined successfully:', group);
      getGroupDetails(GUID);
    } catch (error) {
      switch (error.code) {
        case 'ERR_ALREADY_JOINED':
          console.warn('User already joined group:', error.message);
          getGroupDetails(GUID);
          break;
        case 'ERR_GUID_NOT_FOUND':
          // Create group if not found and recursively join
          await createGroupTeamChat(GUID).then((group) => {
            if (group) {
              joinGroup(GUID);
            }
          });
          break;
        case 'ERR_GROUP_NOT_JOINED':
          // Retry joining the group
          console.warn('Error joining group:', error);
          joinGroup(GUID);
          break;
        default:
          console.error('Error joining group:', error);
      }
    }
  };

  // fetch team information trigger when CometChat is ready on first load
  // some steps here when fetching team information
  // 1. get user's team information
  // 2. set team number based on user's team
  // 3. determine group ID based on action or team
  // 4. automatically join the group for action chat, otherwise fetch group details
  const fetchTeamInformation = async () => {
    let teamIdentifier = null;

    // Get user's team information
    const { team: userTeam } = await Team.getCurrentTeamOf(player);
    // (await Team.getTeamOf(user)) || (await Team.getTeamLeader());

    // set team number based on user's team
    if (userTeam) {
      teamIdentifier = userTeam.identifier;
      setTeamNumber(teamIdentifier);
      if (userTeam.name) {
        setTeamName(userTeam.name);
      }
    }

    // for team chat group, use "team-<team number>" for the route. example: team-1
    // and for action, use the action name. example: action-1, action-2
    // Determine group ID based on action or team
    const groupId = id.includes('action')
      ? id
      : 'team-' + teamIdentifier.toString();

    // since fix join team page flow and create migration to fix user to cometchat `1715756122154_migrate_users_to_chat_groups`
    // user can directly get group details
    getGroupDetails(groupId);

    // actually all the process user join group to cometchat group,
    // already setup in backend process on join team page
    // we also run migration script to fix user has team but not join in the cometchat group
    // so, here we just need to check if user already join the group or not
    // const getMembers = await cometChat.getGroupMembers(groupId);

    // // if user not join the group, automatically join the group
    // if (!getMembers) {
    //   await joinGroup(groupId);
    //   return;
    // }

    // const uid = `the_game-person-${user.identifier}`;
    // const userExists = getMembers.find((member) => member.getUid() === uid);

    // // check if user already join the group or not
    // if (userExists) {
    // getGroupDetails(groupId);
    // } else {
    //   // Automatically join the group for action chat, otherwise fetch group details
    //   await joinGroup(groupId);
    // }
  };

  // create group team chat with CometChat API from lincd-cometchat
  async function createGroupTeamChat(teamId: string) {
    try {
      // teamId example value: team-350, team-351,.. action-1, action-2..
      const GUID = teamId;

      // create group with team name
      // and later on createGroup method, groupName value will be sanitized
      const groupName: string = id.includes('action')
        ? capitalize(GUID).replace('-', ' ')
        : GUID;

      const icon = process.env.SITE_ROOT + '/public/favicon-144x144.png';
      const ownerID = process.env.COMET_CHAT_ADMIN_ID;
      const participantIDs = [];

      const group = await CometChatAPI.createGroup(
        groupName,
        ownerID,
        icon,
        participantIDs,
      );
      if (!group) {
        console.log('Failed to create group');
        return;
      }

      console.log('Group created successfully:', group);
      return group;
    } catch (error) {
      console.error(error);
    }
  }

  // const onBackGroup = () => {
  //   setGroupDetails(false);
  //   setGroupJoined(false);
  // };

  // cc-message-wrapper
  let messagesStyle = new MessagesStyle({
    width: '100%',
    border: '0px',
  });

  // let messageHeaderStyle = new MessageHeaderStyle({
  //   height: '0px',
  //   width: '100%',
  //   background: '#FFFFFF',
  // });

  // let headerConfig = new MessageHeaderConfiguration({
  //   menu: [''],
  //   // hideBackButton: true,
  //   onBack: onBackGroup,
  //   messageHeaderStyle,
  // });

  // composer style, currently use in chat message and treaded message
  let messageComposerStyle = new MessageComposerStyle({
    // background: '#F8F9FB',
    maxInputHeight: '90px',
    borderRadius: '0px',
    border: '1px solid #F8F9FB',
    dividerTint: '#F8F9FB',
  });

  // https://www.cometchat.com/docs-beta/ui-kit/react/message-composer
  let messageComposerConfig = new MessageComposerConfiguration({
    messageComposerStyle: messageComposerStyle,
    // attachmentIconURL: '/public/images/icon-attachment.svg',
  });

  // get title for thread
  // https://www.cometchat.com/docs-beta/ui-kit/react/threaded-messages#integration
  const getTitle = () => {
    return `Thread from ${sender.name}`;
  };

  // bubble view for threaded message
  // https://www.cometchat.com/docs-beta/ui-kit/react/threaded-messages#bubbleview
  const bubbleView = () => {
    const listItemStyle = new ListItemStyle({
      width: '200px',
      height: '36px',
      border: '1px solid #3399ff',
      borderRadius: '8px',
      titleColor: '#ffffff',
      background: '#3399ff',
      titleFont: '500 14px sans-serif,Inter',
      hoverBackground: '#3399ff',
    });
    return (
      <cometchat-list-item
        title={(parentMessage as CometChat.TextMessage).getText()}
        listItemStyle={JSON.stringify(listItemStyle)}
      />
    );
  };

  // handle when user click thread icon
  const toggleHeaderFunction = (message) => {
    cometChat.getMessageDetails(message.id).then((message) => {
      setParentMessage(message);
      setSender(message.getSender());
    });
  };

  // config is for message list
  // https://www.cometchat.com/docs-beta/web-shared/message-list-configuration
  let messageListConfiguration = new MessageListConfiguration({
    // TODO: how to import from lincd-cometchat?
    messagesRequestBuilder: new CometChat.MessagesRequestBuilder()
      .setCategories(['message'])
      .setLimit(30),
    templates: templates,
    scrollToBottomOnNewMessages: false,
    messageListStyle: new MessageListStyle({
      height:
        'calc(100vh - (175px + var(--safe-area-top, 0px) + var(--safe-area-bottom, 0px)))',
    }),
    // https://www.cometchat.com/docs-beta/ui-kit/react/message-list#1-onthreadrepliesclick
    onThreadRepliesClick: (message) => toggleHeaderFunction(message),
  });

  // thread style
  let messageThreadedConfiguration = new MessageListConfiguration({
    // messagesRequestBuilder: messageRequest,
    templates: templates,
    messageListStyle: new MessageListStyle({
      height:
        'calc(100vh - (330px + var(--safe-area-top, 0px) + var(--safe-area-bottom, 0px)))',
    }),
  });

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const blockUser = async (user) => {
    try {
      await cometChat.blockUsers([user.uid]);
      await showDialog('User blocked', `User has been blocked. Thanks!`);
    } catch (error) {
      console.log('Blocking user failed:', error);
    }
  };

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // const messageThreadList = new MessageListConfiguration({
  //   templates: templates,
  //   // messageListStyle: new MessageListStyle({
  //   //   height: 'calc(100vh - 152px)',
  //   // }),
  //   scrollToBottomOnNewMessages: true,
  // });

  // const threadedMessageConfig = new ThreadedMessagesConfiguration({
  //   threadedMessagesStyle: messagesStyle,
  //   messageComposerConfiguration: messageComposerConfig,
  //   messageListConfiguration: messageThreadList,
  //   closeIconURL: asset('/images/close.png'),
  // });

  const handleReportError = async (error) => {
    if (error.code === 'ERR_NOT_A_RECIVER') {
      await showDialog(
        "That's your own message!",
        `You can't report your own message.`,
      );
    }
  };

  let capacitor = useCapacitor();
  const reload = async () => {
    // navigate(ROUTES.teams_overview.path);
    // setTimeout(() => {
    let platform = capacitor.Capacitor.getPlatform();
    if (platform === 'android') {
      document.location.href = 'index.html';
    } else if (platform === 'ios') {
      await Dialog.alert({
        title: 'Oops!',
        message:
          'It looks like I made a little mistake. I have fixed it, but you need to close and restart the app again for it to work. Sorry for the inconvenience. May Peace be with you ✌️',
      });
    } else {
      window.location.reload();
    }
    // }, 300);
  };

  // then add this to the function that is called for re-rendering
  // const filterThreadedMessage = () => {};
  if (!cometChat.ready) {
    return (
      <DefaultLayout>
        <Spinner centered={true}></Spinner>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <ErrorBoundary
        fallbackRender={() => {
          if (capacitor.Capacitor.getPlatform() === 'ios') {
            return <div></div>;
          }
          return <Spinner />;
        }}
        onError={() => {
          // window.location.reload();
          reload();
        }}
      >
        <div className={style.wrapper}>
          <Header
            title={
              id.includes('action')
                ? capitalize(id).replace('-', ' ') + ' Discussion'
                : 'Team ' + teamName ||
                  (teamNumber !== 0 ? teamNumber.toString() : '')
            }
            inverted={true}
            backButton={true}
            className={style.chatHeader}
          />

          {/* <div className={style.chatContainer}>
        <div
          className={style.chatMember}
          onClick={() => (chatWithGroup ? setShowMember(!showMember) : '')}
        >
          <img src="/images/user.png" />
        </div>
      </div> */}

          {welcome && (
            <div className={style.chatWelcome}>
              <div className={style.chatWelcomeInner}>
                <img
                  src={asset('/images/peace-game-logo-spinning-white.gif')}
                  alt="Peace Game Logo"
                  width="120"
                  height="120"
                />
                <h2>Welcome!</h2>
                <h4>{'Loading chat...'}</h4>
              </div>
            </div>
          )}

          <div className={style.chatWrapper}>
            {chatWithGroup && (
              <div
                className={cl(
                  style.chatMessages,
                  parentMessage ? style.isHidden : '',
                )}
              >
                <CometChatMessages
                  group={chatWithGroup}
                  hideMessageHeader={true}
                  messagesStyle={messagesStyle}
                  messageComposerConfiguration={messageComposerConfig}
                  messageListConfiguration={messageListConfiguration}
                  // messageHeaderConfiguration={headerConfig}
                  // threadedMessagesConfiguration={threadedMessageConfig}
                />
              </div>
            )}

            {parentMessage && (
              <CometChatThreadedMessages
                parentMessage={parentMessage}
                bubbleView={bubbleView}
                title={getTitle()}
                onClose={() => setParentMessage(null)}
                threadedMessagesStyle={messageComposerStyle}
                messageListConfiguration={messageThreadedConfiguration}
                messageComposerConfiguration={messageComposerConfig}
              />
            )}

            {onReport && (
              <ReportMessageModal
                blockUser={blockUser}
                message={messageSelected}
                onClose={() => setOnReport(false)}
                getReason={(reason) => setReasonReported(reason)}
                deleteAndReportMessage={deleteAndReportMessage}
                handleReportError={handleReportError}
              />
            )}
            {/* {showMember && (
          <div
            className={`${style.chatMemberList} ${
              showMember ? style.active : ''
            }`}
          >
            {chatWithGroup && (
              <CometChatGroupMembers
                group={chatWithGroup}
                title="Team Members"
              />
            )}
            <div
              className={style.closeMember}
              onClick={() => setShowMember(false)}
            >
              <img src={asset('/images/close.png')} />
            </div>
          </div>
        )} */}
          </div>
        </div>
      </ErrorBoundary>
    </DefaultLayout>
  );
};

export default Chat;
