import React from 'react';
import ShareActionDetails from '../../molecules/ShareActionDetails';
import { topic4, topic5 } from '../../../data-references';

const CooperationShare = () => {
  let topic = topic4;

  return (
    <ShareActionDetails
      topic={topic}
      currentTopic={topic4}
      nextTopic={topic5}
    />
  );
};

export default CooperationShare;
