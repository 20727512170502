import React from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import './TeamsOverview.scss';
import style from './TeamsOverview.scss.json';
import { Button } from 'lincd-mui-base/lib/components/Button';
import Headline from '../components/atoms/Headline';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

function TeamsOverview() {
  return (
    <DefaultLayout>
      <div className={style.JoinTeamContainer}>
        <div className={style.Title}>
          <img src={asset('/images/the-game-logo.png')} alt="the-game-icon" />
          <h2>ADMIN</h2>
        </div>
        <Headline title={'Teams Overview'} lined="black" />
        <Headline title={'Move user to a different team'} lined="black" />
      </div>
    </DefaultLayout>
  );
}

export default TeamsOverview;
