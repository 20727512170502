import React from 'react';
import './OrderedList.scss';
import { default as style } from './OrderedList.scss.json';

interface OrderedListProps {
  children: React.ReactNode;
  type: any;
}

export function OrderedList({ children, type }: OrderedListProps) {
  return (
    <ol
      className={style.orderedList}
      type={type as '1' | 'a' | 'A' | 'i' | 'I'}
    >
      {children}
    </ol>
  );
}
