import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import Header from '../components/molecules/Header';
import './DuplicateAccount.scss';
import style from './DuplicateAccount.scss.json';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from '../package';
import { TableAccount } from '../components/molecules/TableAccount';
import { SearchAccountAndAction } from '../components/molecules/SearchAccountAndAction';
import {
  MergeAccountForm,
  MergeAccountSuccess,
} from '../components/molecules/MergeAccountForm';

const DuplicateAccount = () => {
  // delete duplicate accounts
  const [accounts, setAccounts] = useState([]);

  const [accountFrom, setAccountFrom] = useState(null);
  const [actionsFrom, setActionsFrom] = useState(null);

  const [accountTo, setAccountTo] = useState(null);
  const [actionsTo, setActionsTo] = useState(null);

  const [accountSelected, setAccountSelected] = useState(null);
  const [mergeSuccess, setMergeSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  // get the first div and set overflow to visible
  // to make the table thead is sticky
  useEffect(() => {
    const firstDiv = document.querySelector('div');
    if (firstDiv) {
      firstDiv.style.overflow = 'visible';
    }
  }, []);

  // fecth duplicate accounts data
  useEffect(() => {
    getDuplicateAccounts();
  }, []);

  // get duplicate accounts from the server
  const getDuplicateAccounts = async () => {
    const response = await Server.call(packageName, 'getDuplicateAccounts');
    console.log(response);
    setAccounts(response);
  };

  const getAction = async (accountId: string) => {
    const action = await Server.call(packageName, 'getUserAction', accountId);
    return action;
  };

  const options = useMemo(() => {
    return accounts.map((account) => {
      const email = account.accounts[0].email;
      return {
        label: account.accounts[0].email,
        options: account.accounts.map((account) => {
          const name = account.givenName + ' ' + account.familyName;
          const teamIds = account.teams.map((team) => team.identifier);

          return {
            value: account.id + '_' + email,
            label: `${account.id} - ${name} - Team ${teamIds.join(', ')}`,
          };
        }),
      };
    });
  }, [accounts]);

  const selectedOptions = useMemo(() => {
    if (!accountFrom) {
      return;
    }

    const email = accountFrom.value?.split('_')[1];
    const selectedOpt = options.find((opt) => opt.label === email);
    return selectedOpt
      ? selectedOpt.options.filter((opt) => opt.value !== accountFrom.value)
      : [];
  }, [accountFrom, options]);

  const onChangeAccountFrom = useCallback(async (selected) => {
    try {
      setAccountFrom(selected);
      const accountId: string = selected.value.split('_')[0];
      if (!accountId) {
        alert('Account ID not found');
      }

      const action = await getAction(accountId);
      setActionsFrom(action);
    } catch (error) {
      console.error('failed to fetch user data:', error);
    }
  }, []);

  const onChangeAccountTo = useCallback(async (selected) => {
    setAccountTo(selected);

    try {
      const accountId: string = selected.value.split('_')[0];
      if (!accountId) {
        alert('Account ID not found');
      }

      const action = await getAction(accountId);
      setActionsTo(action);
    } catch (error) {
      console.error('failed to fetch user data:', error);
    }
  }, []);

  const onHandleMerge = async (data: string) => {
    setLoading(true);

    try {
      if (!data) {
        alert('Please choose the primary account');
        setLoading(false);
        return;
      }

      const selectedAccountId = data.split('_')[0];

      if (!selectedAccountId) {
        alert('Account ID not found');
        setLoading(false);
        return;
      }

      // get account id from the select account
      const accountIdFrom = accountFrom.value.split('_')[0];
      const accountIdTo = accountTo.value.split('_')[0];
      const accountIds = [accountIdFrom, accountIdTo];

      const response = await Server.call(
        packageName,
        'mergeDuplicateAccount',
        accountIds,
        selectedAccountId,
      );

      if (response) {
        const { userAccount } = response.data;
        setMergeSuccess(true);
        setAccountSelected(userAccount);
      }
    } catch (error) {
      console.error('failed to merge account:', error);
      alert(
        'An error occurred while merging the duplicate account. Please try again.',
      );
    } finally {
      setLoading(false);
    }
  };

  // map accounts data to table
  const tableAccounts = useMemo(() => {
    if (!accounts) return [];

    return accounts.flatMap((accountGroup) =>
      accountGroup.accounts.map((account) => {
        return {
          id: account.id,
          identifier: account.identifier,
          givenName: account.givenName,
          familyName: account.familyName,
          email: account.email,
          telephone: account.telephone,
          hasGoogle: account.hasGoogle ? 'Yes' : '',
          hasPassword: account.hasPassword ? 'Yes' : '',
          hasApple: account.hasApple ? 'Yes' : '',
          currentTeam: account.currentTeam,
          teams: account.teams,
        };
      }),
    );
  }, [accounts]);

  return (
    <>
      <DefaultLayout backgroundColor="white">
        <Header title="ADMIN USER LIST" inverted={true} backButton={true} />
        <div className={style.content}>
          <TableAccount data={tableAccounts} />
          <div className={style.block}>
            <h4>Merge duplicate account</h4>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <SearchAccountAndAction
                options={options}
                onHandleChange={onChangeAccountFrom}
                accountValue={accountFrom}
                actionsValue={actionsFrom}
              />
              <SearchAccountAndAction
                options={selectedOptions}
                onHandleChange={onChangeAccountTo}
                accountValue={accountTo}
                actionsValue={actionsTo}
              />
            </div>
            <br />
            <br />
            {accountFrom && accountTo && !mergeSuccess && (
              <>
                <MergeAccountForm
                  accounts={[
                    { label: accountFrom.label, value: accountFrom.value },
                    { label: accountTo.label, value: accountTo.value },
                  ]}
                  name="selectedAccount"
                  onMergeAccount={onHandleMerge}
                  loading={loading}
                />
              </>
            )}
            {mergeSuccess && <MergeAccountSuccess account={accountSelected} />}
          </div>
        </div>
      </DefaultLayout>
    </>
  );
};

export default DuplicateAccount;
