import React from 'react';
import { default as style } from './StepHeader.scss.json';
import './StepHeader.scss';
import { useLocation } from 'react-router-dom';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { useTranslate } from '@tolgee/react';

interface StepHeaderProps {
  title: string;
  step: number;
  white?: boolean;
}

const StepHeader = ({ title, step, white }: StepHeaderProps) => {
  let { t } = useTranslate();
  const location = useLocation();
  const additionalParam = location.state
    ? location.state.additionalParam
    : null;

  return (
    <div
      className={style.Title}
      aria-labelledby={t('stepHeader.title', 'Step Header Title')}
    >
      <img
        src={
          white
            ? asset('/images/peace-game-logo-spinning-white.gif')
            : asset('/images/peace-game-logo-spinning-page-color.gif')
        }
        alt={t('stepHeader.iconAlt', 'Peace Game icon')}
      />
      <div
        className={style.Subtitle}
        aria-labelledby={t('stepHeader.subtitle', 'Step Header Subtitle')}
      >
        {additionalParam ? null : (
          <p>{t('stepHeader.step', `STEP 0${step}`, { step })}</p>
        )}
        <h2>{t('stepHeader.titleText', title)}</h2>
      </div>
    </div>
  );
};

export default StepHeader;
