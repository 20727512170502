import React, { useEffect } from 'react';
import './ResourceCardWithNav.scss';
import { ResourceCard } from 'lincd-irlcg/lib/components/molecules/ResourceCard';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../routes';
import { Resource } from 'lincd-irlcg/lib/shapes/Resource';

const ResourceCardWithNav = (props) => {
  const navigate = useNavigate();
  const resource = props.of as Resource;
  const goToResource = () => {
    navigate(
      generatePath(ROUTES.resource_detail.path, { id: resource.identifier }),
    );
  };
  return <ResourceCard onClick={goToResource} {...props} />;
};

export default ResourceCardWithNav;
