import React, { useState } from 'react';
import Headline from '../../atoms/Headline';
import style from './5_AbundancePlan.scss.json';
import './5_AbundancePlan.scss';
import GoToButton from '../../atoms/GoToButton';
import { topic5 } from '../../../data-references';
import ActionContent from '../../molecules/ActionContent';
import ActionPlan from '../../molecules/ActionPlan';
import { useTranslate } from '@tolgee/react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import {
  BronzeGoal,
  GoldGoal,
  SetYourGoal,
  SilverGoal,
} from '../../molecules/SetYourGoal';
import { PlanPurpose } from '../../molecules/PlanPurpose';

const AbundancePlan = () => {
  let topic = topic5;
  let { t } = useTranslate();
  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'topic' + topic.identifier;
  const navigate = useNavigate();

  const [actionPlanTime, setActionPlanTime] = useState(0);

  const handleTimeSelected = (minutes) => {
    setActionPlanTime(minutes);
  };
  return (
    <>
      <div className={style.content}>
        <PlanPurpose topic={topic} />

        <Headline title={t('word.gameplay', 'Gameplay')} lined="black" />

        <ActionContent
          subtitle={t('word.howToKeepScore', 'How to Keep Score')}
          content={
            <>
              <p>
                <p>
                  {t(
                    prefix + '.plan.howToKeepScoreText',
                    'Give a financial contribution or volunteer for an organization furthering one or more of the Peace on Earth by 2030 values of befriending the other, honoring the oneness of humanity and cooperating for the common good.',
                  )}{' '}
                </p>
                <br />
                <span className={style.underline}>
                  {t('word.bronzePeaceMedal', 'Bronze Peace Medal')}
                </span>
                : {t(prefix + '.plan.bronzePeaceMedalText1', 'Provide a')}{' '}
                <span className={style.bold}>
                  {t(
                    prefix + '.plan.bronzePeaceMedalText2',
                    'financial contribution to 1 organization',
                  )}
                </span>{' '}
                {t(prefix + '.plan.bronzePeaceMedalText3', 'or')}{' '}
                <span className={style.bold}>
                  {t(
                    prefix + '.plan.bronzePeaceMedalText4',
                    'volunteer 5 hours.',
                  )}
                </span>{' '}
                <span className={style.blue}>
                  ({t(prefix + '.plan.bronzePeaceMedalText5', '1 Peace Action')}
                  )
                </span>
                <br />
                <br />
                <span className={style.underline}>
                  {t('word.sliverPeaceMedal', 'Silver Peace Medal')}
                </span>
                : {t(prefix + '.plan.silverPeaceMedalText1', 'Provide a')}{' '}
                <span className={style.bold}>
                  {t(
                    prefix + '.plan.silverPeaceMedalText2',
                    'financial contribution to 2 organizations',
                  )}
                </span>{' '}
                {t(prefix + '.plan.silverPeaceMedalText3', 'or')}{' '}
                <span className={style.bold}>
                  {t(
                    prefix + '.plan.silverPeaceMedalText4',
                    'volunteer 10 hours.',
                  )}
                </span>{' '}
                <span className={style.blue}>
                  (
                  {t(prefix + '.plan.silverPeaceMedalText5', '2 Peace Actions')}
                  )
                </span>
                <br />
                <br />
                <span className={style.underline}>
                  {t('word.goldPeaceMedal', 'Gold Peace Medal')}
                </span>
                : {t(prefix + '.plan.goldPeaceMedalText1', 'Provide a')}{' '}
                <span className={style.bold}>
                  {t(
                    prefix + '.plan.goldPeaceMedalText2',
                    'financial contribution to 3 organizations',
                  )}
                </span>{' '}
                {t(prefix + '.plan.goldPeaceMedalText3', 'or')}{' '}
                <span className={style.bold}>
                  {t(
                    prefix + '.plan.goldPeaceMedalText4',
                    'volunteer 15 hours.',
                  )}
                </span>{' '}
                <span className={style.blue}>
                  ({t(prefix + '.plan.goldPeaceMedalText5', '3 Peace Actions')})
                </span>
              </p>
            </>
          }
        />

        <SetYourGoal topic={topic} onTimeSelected={handleTimeSelected}>
          <BronzeGoal actions={1} minutes={'20 - 300'} />
          <SilverGoal actions={2} minutes={'30 - 600'} />
          <GoldGoal actions={3} minutes={'45 - 900'} />
        </SetYourGoal>
        <Headline
          subtitle={t(
            'word.createPeaceActionPlan',
            'Create Your Peace Action Plan',
          )}
          lined="black"
        />
        <ActionPlan action="abundance" topic={topic} time={actionPlanTime} />

        <GoToButton
          text={t('word.goToShare', 'Go to Share')}
          onClick={() => {
            navigate(
              generatePath(ROUTES.peace_action.path, {
                id: topic.name.toLowerCase() + '?step=3',
              }),
            );
          }}
        />
      </div>
    </>
  );
};

export default AbundancePlan;
