import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import './PeaceAction.scss';
import style from './PeaceAction.scss.json';
import { TopicCard } from 'lincd-irlcg/lib/components/molecules/TopicCard';
import { ListItem } from '../layout/List';
import { topics } from '../data-references';
import Header from '../components/molecules/Header';
import MainMenu from '../components/molecules/MainMenu';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { UserAccount } from 'profile-plus/lib/shapes/UserAccount';
import { ROUTES } from '../routes';
import { Link, generatePath, useLocation, useNavigate } from 'react-router-dom';
import { Container } from '../layout/Container';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { useTranslate } from '@tolgee/react';

function PeaceAction() {
  let { t } = useTranslate();
  let prefix = 'peaceAction';
  const auth = useAuth<Player, UserAccount>();
  const navigate = useNavigate();
  let user = auth.user;

  const checkProfileCompleted = () => user?.profileSetupCompleted || false;

  const isInCertainMode =
    user?.isInCertainMode('trial') || user?.isInCertainMode('event');

  const topicShownInCertainMode = ['2', '3'];

  // redirect to join page if user has no team and profile setup is completed
  const checkCurrentTeam = () => {
    if (user?.profileSetupCompleted && !user?.currentTeam) {
      navigate(ROUTES.join_team.path);
    }
  };

  useEffect(() => {
    const checkIsCompleted = async () => {
      if (user) {
        const isCompleted = checkProfileCompleted();
        const routePath = isCompleted
          ? ROUTES.index.path
          : ROUTES.register.path;
        if (location.pathname === routePath) return;
        navigate(routePath);
      }
    };
    checkIsCompleted();
    checkCurrentTeam();
  }, [user, navigate]);

  let location = useLocation();

  useEffect(() => {
    // if url contains /:id remove the id
    let path = location.pathname;
    if (path.includes('/:id')) {
      navigate(ROUTES.index.path);
    }
  }, [location, navigate]);

  const handleNavigation = (event, props) => {
    event.preventDefault();
    navigate(
      generatePath(ROUTES.peace_action.path, {
        id: props.of.name.toLowerCase(),
      }),
    );
  };

  const MemoizedTopicCardWithNav = useCallback(
    (props) => {
      const { of } = props;

      return (
        <TopicCard
          cardTitle={t(prefix + '.action', 'Action')}
          onClick={(event) => handleNavigation(event, props)}
          {...props}
          aria-label={t(prefix + '.topicCard', 'Topic card')}
          className={style.Root}
          overlay={
            // if user is in team is trial/event user can access only Action 2
            isInCertainMode && of.identifier != 2 ? (
              <Link to={'https://peace2030.earth/'} target="_blank">
                <div className={style.overlay}>
                  <h4>{t(prefix + '.overlayTitle', 'Play the Peace Game')}</h4>
                  <p>
                    {t(
                      prefix + '.overlayDescription',
                      'To learn more visit: peace2030.earth',
                    )}
                  </p>
                </div>
              </Link>
            ) : null
          }
        />
      );
    },
    [handleNavigation, t, navigate],
  );

  const headerTitle = isInCertainMode
    ? t(`${prefix}.sampleHeaderTitle`, 'Sample Peace Action')
    : t(`${prefix}.headerTitle`, '7 Peace Actions');

  return (
    <DefaultLayout>
      <Header
        title={headerTitle}
        inverted={true}
        fixed={true}
        aria-label={t(prefix + '.header', 'Header')}
      />
      <div
        className={style.container}
        aria-labelledby={t(
          prefix + '.containerLabel',
          'Peace Actions Container',
        )}
      >
        <Container>
          {/* if user is in event/trial mode then pass only topic 2 and 3, otherwise pass all topics  */}
          <ListItem
            of={
              isInCertainMode
                ? topics.filter((topic) =>
                    topicShownInCertainMode.some((keyword) =>
                      topic.uri.includes(keyword),
                    ),
                  )
                : topics
            }
            as={MemoizedTopicCardWithNav}
            aria-label={t('word.peaceActionListItems', 'List of peace actions')}
          />
        </Container>
        <MainMenu aria-label={t(prefix + '.mainMenu', 'Main menu')} />
      </div>
    </DefaultLayout>
  );
}

export default PeaceAction;
