import React from 'react';
import './Video.scss';
import { default as style } from './Video.scss.json';

export function Video({ src }) {
  return (
    <div className={style.videoContainer}>
      <iframe
        className={style.video}
        src={src}
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        title="Video Player"
      ></iframe>
    </div>
  );
}
