import React from 'react';
import ShareActionDetails from '../../molecules/ShareActionDetails';
import { topic5, topic6 } from '../../../data-references';

const AbundanceShare = () => {
  let topic = topic5;

  return (
    <ShareActionDetails
      topic={topic}
      currentTopic={topic5}
      nextTopic={topic6}
    />
  );
};

export default AbundanceShare;
