import React, { forwardRef, ForwardedRef } from 'react';
import './MainMenu.scss';
import * as style from './MainMenu.scss.json';
import { Link, useLocation } from 'react-router-dom';
import { cl } from 'lincd/lib/utils/ClassNames';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { useTranslate } from '@tolgee/react';

interface MainMenuProps {
  url?: string;
  text?: string;
  icon?: string;
  exact?: true;
}

const MainMenuNav = ({ url, text, icon, exact }: MainMenuProps) => {
  let { t } = useTranslate();
  let location = useLocation();
  var isActive = location.pathname === url;

  return (
    <Link to={url}>
      <div
        className={cl(style.navContainer, isActive && style.active)}
        aria-label={t(`mainMenu.${text}.label`, text)}
      >
        <img
          src={asset(`/images/icons/${icon}.svg`)}
          alt={t(`mainMenu.${text}.iconAlt`, `${text} icon`)}
          width="30px"
        />
        <p>{t(`mainMenu.${text}`, text)}</p>
      </div>
    </Link>
  );
};

const MENU_DATA = [
  {
    icon: 'ActionsIcon',
    text: 'Play',
    url: '/',
    exact: true,
  },
  {
    icon: 'ChartIcon',
    text: 'Game Board',
    url: '/global-game-board',
  },
  {
    icon: 'TeamIcon',
    text: 'Team',
    url: '/team',
  },
  {
    icon: 'ResourcesIcon',
    text: 'Resources',
    url: '/resources',
  },
  {
    icon: 'ProfileIcon',
    text: 'Profile',
    url: '/profile',
  },
];

interface MainMenuProps {
  // Define any props if needed in the future
  className?: string;
}

const MainMenu = forwardRef(function (
  props: MainMenuProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { className } = props;
  let { t } = useTranslate();

  return (
    <>
      <div className={style.MenuSpacing}></div>
      <div className={cl(style.Menu, className)} ref={ref}>
        <div className={style.MenuContainer}>
          <div
            className={style.Logo}
            aria-label={t('mainMenu.logo.label', 'Peace Game logo')}
          >
            <img
              src={asset('/images/the-game-logo.png')}
              alt={t('mainMenu.logo.iconAlt', 'Peace Game icon')}
            />
            <h2>{t('mainMenu.logo.text', 'Peace Game')}</h2>
          </div>

          <div className={style.Nav}>
            {MENU_DATA.map((menu) => {
              const { icon, text, url, exact } = menu;
              return (
                <MainMenuNav
                  key={url}
                  icon={icon}
                  text={t(`mainMenu.${text}`, text)}
                  url={url}
                  exact={exact ? true : null}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
});

MainMenu.displayName = 'MainMenu';

export default MainMenu;
