import React, { useEffect, useState, useRef } from 'react';
import './Team.scss';
import * as style from './Team.scss.json';
import Header from '../components/molecules/Header';
import MainMenu from '../components/molecules/MainMenu';
import { DefaultLayout } from '../layout/DefaultLayout';
import { Container } from '../layout/Container';
import { TeamTopicParticipationScores } from '../components/molecules/TeamTopicParticipationScores';
import { TeamMedalOverview } from 'lincd-irlcg/lib/components/organisms/TeamMedalOverview';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { Person } from 'lincd-schema/lib/shapes/Person';
import { Team as TeamShape } from 'lincd-irlcg/lib/shapes/Team';
import { ActionThumbnail } from 'lincd-irlcg/lib/components/molecules/ActionThumbnail';
import { InputField } from 'lincd-input/lib/components/InputField';
import { DateInputField } from 'lincd-input/lib/components/DateInputField';
import Select from 'react-select';
import { Button } from 'lincd-mui-base/lib/components/Button';
import LocationInput from 'lincd-input/lib/components/LocationInput';
import { GoogleMap, LatLngBounds } from '@capacitor/google-maps';
import { ShowMeeting } from 'lincd-irlcg/lib/components/ShowMeeting';
import { ROUTES } from '../routes';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import {
  Link,
  useNavigate,
  generatePath,
  useLocation,
  useParams,
} from 'react-router-dom';
import TextArea from '../components/atoms/TextArea';
import { useTranslate } from '@tolgee/react';
import { getDesktopBreakpoints } from '../utils/desktopBreakpoint';
import FloatingChatButton from '../components/atoms/FloatingChatButton';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { Spinner } from '../components/atoms/Spinner';
import { useProfileCompleted } from '../hooks/useProfileCompleted';
import JoinInputButton from '../components/molecules/JoinInputButton';
import { ShapeSet } from 'lincd/lib/collections/ShapeSet';

const Team = () => {
  const [team, setTeam] = useState<TeamShape>(null);
  const [members, setMembers] = useState<ShapeSet<Person>>();
  const [formFounderVisible, setFormFounderVisible] = useState<boolean>(false);
  const auth = useAuth();
  const user = auth.user as Person;
  const player = auth.userAccount.accountOf as Player;
  // const [currentTeam, setCurrentTeam] = useState<TeamShape>(player.currentTeam);
  const [formMemberVisible, setFormMemberVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 1024,
  );
  const [loading, setLoading] = useState(true);
  const { checkIsCompleted } = useProfileCompleted();
  let prefix = 'peaceteam';

  // check if user has completed profile setup
  useEffect(() => {
    checkIsCompleted();
  }, []);

  useEffect(() => {
    TeamShape.getCurrentTeamOf(auth.userAccount.accountOf as Player).then(
      (response) => {
        if (response?.team) {
          setTeam(response?.team);
          setMembers(response?.members);
          setFormFounderVisible(true);
        } else {
          setFormMemberVisible(true);
        }

        setLoading(false);
      },
    );

    getDesktopBreakpoints((width) => setWidth(width));
    // reload chat user page when retrive params
  }, [user]);

  let { t } = useTranslate();

  // teamId for header
  const teamId = team ? team?.identifier : '';

  // get user as a team leader
  const teamLeader = team ? team?.teamLeader : null;

  // go to add team member page
  const goToTeammates = () => {
    navigate(
      generatePath(ROUTES.resource_detail.path, {
        id: 'peace-team-member-registration',
      }),
    );
  };

  const isInCertainMode =
    player?.isInCertainMode('event') || player?.isInCertainMode('trial');

  if (isInCertainMode) {
    return (
      <DefaultLayout backgroundColor={'gray'}>
        <Header
          title={t('word.peaceteam', 'Peace Team')}
          inverted={true}
          fixed={true}
        />
        <div className={style.trialContentContainer}>
          <Container maxWidth={'sm'}>
            <JoinInputButton
              description={
                'If you do not know your Peace Team Number ask the person who invited you for it OR visit peace2030.earth and join the next global online game. '
              }
            />
          </Container>
        </div>
        <MainMenu />
      </DefaultLayout>
    );
  } else {
    return (
      <DefaultLayout backgroundColor={'dark'}>
        {loading ? (
          <div className={style.loading}>
            <Spinner color="white" />
          </div>
        ) : (
          <>
            <Header
              title={t('word.peaceteam', 'Peace Team') + ' ' + teamId}
              inverted={true}
              fixed={true}
            />
            <div className={style.contentContainer}>
              {/*<div className={style.HundredLovingWayBlockContent}>*/}
              {/*  <div>*/}
              {/*    <Message />*/}
              {/*    {formMemberVisible ? <NextMeeting /> : <NextMeetingForm />}*/}
              {/*  </div>*/}
              {/*</div>*/}
              <Container maxWidth={'sm'}>
                {team && <TeamTopicParticipationScores of={team} />}
                {team && <TeamMedalOverview members={members} />}
                {teamLeader && (
                  <div className={style.buttonBlock}>
                    <Button onClick={goToTeammates}>
                      {t(prefix + '.addTeamMember', 'Add Team Member')}
                    </Button>
                  </div>
                )}
              </Container>
            </div>
            <FloatingChatButton
              id={team && team.identifier}
              label={t('word.teamChat', 'Team Chat')}
            />
          </>
        )}
        <MainMenu />
      </DefaultLayout>
    );
  }
};

// const ActionThumbnailMemoized = (props, topic) => (
//   <ActionThumbnail topic={topic} {...props} />
// );

// const Message = () => {
//   let { t } = useTranslate();
//   const [team, setTeamLoad] = useState<TeamShape>(null);
//   const user = useAuth().user as Player;
//   useEffect(() => {
//     // TeamShape.getTeamOf(user).then((team) => {
//     //   if (team) {
//     //     setTeamLoad(team);
//     //   }
//     // });

//     TeamShape.getCurrentTeamOf(user).then((team) => {
//       if (team) {
//         setTeamLoad(team);
//       }
//     });
//   }, []);
//   return (
//     <div className={style.Message}>
//       <TextArea
//         className={style.TextArea}
//         placeholder={t(
//           'peaceteam.messagePlacehoder',
//           'Message from the Team Leader...',
//         )}
//       />
//     </div>
//   );
// };

// const NextMeeting = () => {
//   let { t } = useTranslate();
//   let prefix = 'peaceteam';
//   const [isLoading, setLoading] = useState(false);
//   const [location, setLocation] = useState(true);
//   const [teamMeeting, setMeeting] = useState({
//     name: null,
//     startDate: null,
//     location: null,
//   });
//   const user = useAuth().user as Person;
//   const mapRef = useRef<HTMLElement>();
//   let newMap: GoogleMap;

//   const sortMeetingsByDate = (meetings, place) => {
//     return {
//       meetings: meetings
//         ? [...meetings].sort((a, b) => a.startDate - b.startDate)
//         : [],
//       place,
//     };
//   };

//   const getLatestMeeting = (meetingList) => {
//     const meeting = meetingList.meetings;
//     const place = meetingList.place;
//     if (meeting && meeting.length > 0) {
//       return {
//         meeting: meeting[meeting.length - 1],
//         place: place,
//       };
//     } else {
//       return null;
//     }
//   };

//   // createMap(latitude, longitude)
//   async function createMap(latestMeeting) {
//     if (!mapRef.current) return;
//     const coordinate = {
//       lat: latestMeeting?.meeting?.location?.latitude,
//       lng: latestMeeting?.meeting?.location?.longitude,
//     };

//     // const geoLocation = [];
//     // latestMeeting.meeting.map((meeting) => {
//     //   if (meeting.location.latitude && meeting.location.longitude) {
//     //     geoLocation.push({
//     //       lat: meeting.location.latitude,
//     //       lng: meeting.location.longitude,
//     //     });
//     //   } else {
//     //     setLocation(false);
//     //   }
//     // });

//     if (coordinate?.lat && coordinate?.lng) {
//       //create map
//       newMap = await GoogleMap.create({
//         id: 'meeting-location',
//         element: mapRef.current,
//         apiKey: process.env.GOOGLE_MAP_API_KEY,
//         config: {
//           center: {
//             lat: parseFloat(coordinate.lat),
//             lng: parseFloat(coordinate.lng),
//           },
//           zoom: 8,
//         },
//       });

//       //create pinpoint/marker at the location
//       if (newMap) {
//         const markerCoordinate = {
//           lat: parseFloat(coordinate.lat),
//           lng: parseFloat(coordinate.lng),
//         };

//         //to get the SW, NE, center points of location
//         const bounds = new LatLngBounds({
//           southwest: {
//             lat: coordinate.lat - 0.001,
//             lng: coordinate.lng - 0.001,
//           },
//           northeast: {
//             lat: parseFloat(coordinate.lat + 0.001),
//             lng: parseFloat(coordinate.lng + 0.001),
//           },
//           center: {
//             lat: parseInt(coordinate.lat) / 2,
//             lng: parseInt(coordinate.lng),
//           },
//         });

//         //IMPORTANT: dont delete this code. even though marker is not called, the location marker gets rendered(?)
//         //to add pinpoint/marker right on the selected location
//         const marker = await newMap.addMarker({
//           coordinate: markerCoordinate,
//         });

//         //to fit map to a bounding box
//         const locationBounds = new LatLngBounds(bounds);
//         locationBounds.extend(markerCoordinate);
//         await newMap.fitBounds(bounds, 100);
//       }
//     } else {
//       setLocation(false);
//     }
//   }

//   useEffect(() => {
//     setLoading(true);

//     TeamShape.getTeamMeeting().then(async (meeting) => {
//       if (meeting) {
//         const sortingMeeting = sortMeetingsByDate(
//           meeting.teamMeeting,
//           meeting.place,
//         );
//         const latestMeeting = getLatestMeeting(sortingMeeting);
//         const name = latestMeeting?.meeting?.name;
//         const startDate = latestMeeting?.meeting?.startDate;
//         const location = latestMeeting?.meeting?.location?.name;
//         setMeeting({
//           name: name,
//           startDate: startDate,
//           location: location,
//         });
//         await createMap(latestMeeting);
//       }
//       setLoading(false);
//     });
//   }, [user]);

//   const splitTimeString = (sentence, start, end) => {
//     return sentence.split(' ').slice(start, end).join(' ');
//   };

//   return (
//     <div className={style.NextMeeting}>
//       <div className={style.Header}>
//         <h3 className={style.Title}>
//           {t(
//             prefix + '.scheduleMeetingNo.NoMeetingScheduleNext',
//             'No Meeting: Schedule Next Meeting',
//           )}
//         </h3>
//       </div>
//       {!isLoading && teamMeeting.name ? (
//         <>
//           <div className={style.Content}>
//             <div className={style.TextContainer}>
//               <div className={style.TopicName}>
//                 <h3>{teamMeeting.name}</h3>
//               </div>
//               <div className={style.DateTime}>
//                 <h4>{teamMeeting.startDate?.toDateString()}</h4>
//                 <h4>
//                   {splitTimeString(teamMeeting.startDate?.toTimeString(), 0, 2)}
//                 </h4>
//                 <h4>
//                   {splitTimeString(teamMeeting.startDate?.toTimeString(), 2, 5)}
//                 </h4>
//               </div>
//             </div>
//             {location ? (
//               <capacitor-google-map
//                 ref={mapRef}
//                 style={{
//                   display: 'inline-block',
//                   width: '100%',
//                   height: '300px',
//                 }}
//               ></capacitor-google-map>
//             ) : (
//               // </div>
//               <div className={style.DateTime}>
//                 <div className={style.Link}>
//                   <img
//                     src={asset('/images/peace-game-logo-spinning-white.gif')}
//                     width="32"
//                   />
//                   <Link to={teamMeeting.location} target="_blank">
//                     {teamMeeting.location}
//                   </Link>
//                 </div>
//               </div>
//             )}
//           </div>
//         </>
//       ) : (
//         <div className={style.Content}>
//           <div className={style.noMeeting}>
//             <h3>
//               {t(prefix + '.scheduleMeetingNo', 'No Meeting Schedule Yet')}
//             </h3>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const NextMeetingForm = () => {
//   let { t } = useTranslate();
//   let prefix = 'peaceteam';
//   const auth = useAuth();
//   const user = auth.user as Player;
//   const [team, setTeam] = useState<TeamShape>(null);
//   const [formValues, setFormValues] = useState({
//     action: '',
//     meetingType: '',
//     date: '',
//     place: null,
//   });

//   const meetingSchedule = [
//     { value: 'PeaceMeeting1', label: 'Peace Meeting 1' },
//     { value: 'PeaceMeeting2', label: 'Peace Meeting 2' },
//     { value: 'PeaceMeeting3', label: 'Peace Meeting 3' },
//     { value: 'PeaceMeeting4', label: 'Peace Meeting 4' },
//     { value: 'PeaceMeeting5', label: 'Peace Meeting 5' },
//     { value: 'PeaceMeeting6', label: 'Peace Meeting 6' },
//     { value: 'PeaceMeeting7', label: 'Peace Meeting 7' },
//     { value: 'PeaceMeeting8', label: 'Peace Meeting 8' },
//     { value: 'AdditionalMeeting', label: 'Additional Meeting' },
//   ];

//   const handleChange = (field, value) => {
//     setFormValues({
//       ...formValues,
//       [field]: value,
//     });
//   };

//   const isFormValid = () => {
//     // Check if all required fields have values
//     return (
//       formValues.action !== '' &&
//       formValues.date !== '' &&
//       formValues.meetingType !== '' &&
//       formValues.place !== null
//     );
//   };

//   useEffect(() => {
//     // TeamShape.getTeamOf(user).then((res) => {
//     //   setTeam(res);
//     // });
//     TeamShape.getCurrentTeamOf(user).then((team) => {
//       if (team) {
//         setTeam(team);
//       }
//     });
//   }, []);

//   const handleSubmit = () => {
//     if (isFormValid()) {
//       // Backend Call To Save Meeting
//       TeamShape.createTeamMeeting(formValues).then((res) => {
//         if (res) {
//           alert('Meeting Scheduled Successfully');
//         } else {
//           alert('Error Scheduling Meeting');
//         }
//       });
//       // Clear form values after submission
//       setFormValues({
//         action: '',
//         meetingType: '',
//         date: '',
//         place: '',
//       });
//     } else {
//       alert('Please fill in all required fields before submitting.');
//     }
//   };

//   useEffect(() => {
//     isFormValid();
//   }, [formValues]);
//   const options = [
//     { value: 'chocolate', label: 'Chocolate' },
//     { value: 'strawberry', label: 'Strawberry' },
//     { value: 'vanilla', label: 'Vanilla' },
//   ];

//   return (
//     <div className={style.NextMeeting}>
//       <div className={style.Header}>
//         <h3 className={style.Title}>
//           {t(prefix + '.scheduleMeeting', 'Schedule a Meeting')}
//         </h3>
//       </div>
//       <div className={style.FormContainer}>
//         <div className={style.FormMeeting}>
//           <label htmlFor="action">{t('word.about', 'About')}:</label>
//           <Select
//             options={meetingSchedule}
//             onChange={(selectedOption) =>
//               handleChange('action', selectedOption.label)
//             }
//           />
//         </div>
//         <div className={style.FormMeeting}>
//           <label htmlFor="date">
//             {t(prefix + '.dateandtime', 'Date & Time')}:
//           </label>
//           <DateInputField
//             type="datetime-local"
//             className={style.inputDate}
//             value={formValues.date}
//             valueProperty={'startDate'}
//             of={team}
//             onChange={(e) => handleChange('date', e.target.value)}
//           />
//         </div>
//         <div className={style.FormMeeting}>
//           <label htmlFor="meetingType">
//             {t(prefix + '.typeofMeeting', 'Type of Meeting')}:
//           </label>
//           <Select
//             options={[
//               { value: 'in-person', label: 'In Person' },
//               { value: 'online', label: 'Online' },
//             ]}
//             onChange={(selectedOption) =>
//               handleChange('meetingType', selectedOption.label)
//             }
//           />
//         </div>
//         <div className={style.FormMeeting}>
//           <label htmlFor="location">Location:</label>
//           {formValues.meetingType === 'Online' ? (
//             <InputField
//               placeholder={'Enter Meeting Link'}
//               value={formValues.place}
//               onChange={(e) => handleChange('place', e.target.value)}
//             />
//           ) : (
//             <LocationInput
//               of={user}
//               property={'areaServed'}
//               multiple={false}
//               saveLocation={false}
//               onPlaceSelect={(selectedPlace) => {
//                 setFormValues((prevFormData) => ({
//                   ...prevFormData,
//                   place: selectedPlace,
//                 }));
//               }}
//             />
//           )}
//         </div>
//         <Button disabled={!isFormValid()} onClick={handleSubmit}>
//           {t('word.submit', 'Submit')}
//         </Button>
//       </div>
//     </div>
//   );
// };

export default Team;
