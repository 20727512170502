import React, { useState } from 'react';
import Headline from '../../atoms/Headline';
import style from './6_LovePlan.scss.json';
import './6_LovePlan.scss';
import GoToButton from '../../atoms/GoToButton';
import { topic6 } from '../../../data-references';
import ActionContent from '../../molecules/ActionContent';
import ActionPlan from '../../molecules/ActionPlan';
import { useTranslate } from '@tolgee/react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import {
  BronzeGoal,
  GoldGoal,
  SetYourGoal,
  SilverGoal,
} from '../../molecules/SetYourGoal';
import { PlanPurpose } from '../../molecules/PlanPurpose';

const LovePlan = () => {
  const navigate = useNavigate();
  let topic = topic6;
  let { t } = useTranslate();

  const [actionPlanTime, setActionPlanTime] = useState(0);

  const handleTimeSelected = (minutes) => {
    setActionPlanTime(minutes);
  };

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'topic' + topic.identifier;

  return (
    <>
      <div className={style.content}>
        <PlanPurpose topic={topic} />

        <Headline title={t('word.gameplay', 'Gameplay')} lined="black" />

        <ActionContent
          subtitle={t('word.howToKeepScore', 'How to Keep Score')}
          content={
            <>
              <p>
                <span className={style.underline}>
                  {t('word.bronzePeaceMedal', 'Bronze Peace Medal')}
                </span>
                :{' '}
                {t(
                  prefix + '.plan.bronzePeaceMedalText1',
                  'Commit to offering your peace prayer once a day for',
                )}{' '}
                <span className={style.bold}>
                  {t(prefix + '.plan.bronzePeaceMedalText2', '1 month.')}
                </span>{' '}
                <span className={style.blue}>
                  ({t(prefix + '.plan.bronzePeaceMedalText5', '1 Peace Action')}
                  )
                </span>
                <br />
                <br />
                <span className={style.underline}>
                  {t('word.sliverPeaceMedal', 'Silver Peace Medal')}
                </span>
                :{' '}
                {t(
                  prefix + '.plan.silverPeaceMedalText1',
                  'Commit to offering your peace prayer once a day for',
                )}{' '}
                <span className={style.bold}>
                  {t(prefix + '.plan.silverPeaceMedalText2', '3 months.')}
                </span>{' '}
                <span className={style.blue}>
                  (
                  {t(prefix + '.plan.silverPeaceMedalText5', '2 Peace Actions')}
                  )
                </span>
                <br />
                <br />
                <span className={style.underline}>
                  {t('word.goldPeaceMedal', 'Gold Peace Medal')}
                </span>
                :{' '}
                {t(
                  prefix + '.plan.goldPeaceMedalText1',
                  'Commit to offering your peace prayer once a day for',
                )}{' '}
                <span className={style.bold}>
                  {t(prefix + '.plan.goldPeaceMedalText2', '1 year.')}
                </span>{' '}
                {t(
                  prefix + '.plan.goldPeaceMedalText3',
                  'After that, determine if you wish to continue on an ongoing basis.',
                )}{' '}
                <span className={style.blue}>
                  ({t(prefix + '.plan.goldPeaceMedalText5', '3 Peace Actions')})
                </span>
                <p>
                  <span className={style.blue}>
                    {t('word.note', 'Note')}
                    {': '}
                  </span>
                  {t(
                    prefix + '.plan.noteText',
                    'Spend approximately 20 minutes creating your peace prayer and a minute or so each day to offer your prayer.',
                  )}
                </p>
              </p>
            </>
          }
        />

        <SetYourGoal topic={topic} onTimeSelected={handleTimeSelected}>
          <BronzeGoal actions={1} minutes={20} average={true} />
          <SilverGoal actions={2} minutes={20} average={true} />
          <GoldGoal actions={3} minutes={20} average={true} />
        </SetYourGoal>

        <Headline
          subtitle={t(
            prefix + '.plan.createPeaceActionPlan',
            'Create Your Peace Action Plan',
          )}
          lined="black"
        />
        <ActionPlan action="love" topic={topic} time={actionPlanTime} />

        <GoToButton
          text={t('word.goToShare', 'Go to Share')}
          onClick={() => {
            navigate(
              generatePath(ROUTES.peace_action.path, {
                id: topic.name.toLowerCase() + '?step=3',
              }),
            );
          }}
        />
      </div>
    </>
  );
};

export default LovePlan;
