import React from 'react';
import './PostalAddressInputField.scss';
import { default as style } from './PostalAddressInputField.scss.json';
import { registerPackageModule, linkedComponent } from '../../package';
import { PostalAddress } from 'lincd-schema/lib/shapes/PostalAddress';
import { TextField } from 'lincd-input/lib/components/TextField';
import { cl } from 'lincd/lib/utils/ClassNames';
import { ProfileViewText } from '../molecules/ProfileViewText';

interface PostalAddressInputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  type?: 'edit' | 'view';
  title?: string;
  onEditForm?: () => void;
}

export const PostalAddressInputField = linkedComponent<
  PostalAddress,
  PostalAddressInputFieldProps
>(
  PostalAddress.request((data) => ({
    postalCode: data.postalCode,
  })),
  ({
    source,
    linkedData: { postalCode },
    className,
    type = 'edit',
    title,
    onEditForm,
    ...restProps
  }) => {
    return (
      <>
        {type === 'edit' ? (
          <TextField
            className={cl(className)}
            defaultValue={postalCode || ''}
            onBlur={(e) => {
              source.postalCode = e.target.value;
            }}
            {...restProps}
          />
        ) : (
          <ProfileViewText
            of={source}
            title={title}
            valueProperty="postalCode"
            onEditForm={onEditForm}
          />
        )}
      </>
    );
  },
);

//register all components in this file
registerPackageModule(module);
