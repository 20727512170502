import React from 'react';
import './FloatingChatButton.scss';
import * as style from './FloatingChatButton.scss.json';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

interface FloatingChatButtonProps {
  id?;
  label?: string;
}

const FloatingChatButton = ({ id, label }: FloatingChatButtonProps) => {
  const navigate = useNavigate();
  return (
    <div
      className={style.container}
      onClick={() => {
        navigate(
          generatePath(ROUTES.team_chat.path, {
            id: id,
          }),
        );
      }}
    >
      <img src={asset('/images/chat.svg')} alt={label} />
      <p>{label}</p>
    </div>
  );
};

export default FloatingChatButton;
