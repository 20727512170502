import React from 'react';
import './PeaceGameVideos.scss';
import { default as style } from './PeaceGameVideos.scss.json';
import { Video } from '../../atoms/Video';
import { getActiveLanguage } from '../../../utils/tolgee';

const resourcePeaceGameVideo = () => {
  const activeLanguage = getActiveLanguage();

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'resource-peaceGameVideos';
  return (
    <>
      <div className={style.content}>
        {/* This is a hard link to a collection of videos. The src attribute is
        the URL of the collection. */}
        <Video
          src={`https://poe.earth/hardLink-peace-game-videos`}
          // src={`https://poe.earth/hardLink-peace-game-videos_${activeLanguage}`}
        />
      </div>
    </>
  );
};

export default resourcePeaceGameVideo;
