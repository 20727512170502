import React from 'react';
import style from './Gameboard.scss.json';
import './Gameboard.scss';
import { LogoSpinning } from '../atoms/LogoSpinning';
import { useTranslate } from '@tolgee/react';

export const Gameboard = ({ totalScore }: { totalScore? }) => {
  const { totalActions, totalPlayer } = totalScore;
  let { t } = useTranslate();
  let prefix = 'gameboard';
  return (
    <div className={style.titleContainer}>
      <h1>{t('word.GlobalGameBoard', 'Peace Gameboard')}</h1>
      <LogoSpinning />

      <div className={style.statusContainer}>
        <div className={style.status}>
          <h2 className={style.title}>{totalPlayer && totalPlayer}</h2>
          <p>{t('word.Players', 'Players')}</p>
        </div>
        <div className={style.status}>
          <h2 className={style.title}>{totalActions && totalActions}</h2>
          <p>{t('word.peaceActions', 'Peace Actions')}</p>
        </div>
        <div className={style.status}>
          <h2 className={style.title}>567</h2>
          <p>{t('word.cities', 'Cities')}</p>
        </div>
        <div className={style.status}>
          <h2 className={style.title}>59</h2>
          <p>{t('word.countries', 'Countries')}</p>
        </div>
      </div>
    </div>
  );
};
