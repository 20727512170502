import React from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import './StartTeam.scss';
import style from './StartTeam.scss.json';
import { Button } from 'lincd-mui-base/lib/components/Button';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

function StartTeam() {
  return (
    <DefaultLayout>
      <div className={style.JoinTeamContainer}>
        <div className={style.Title}>
          <img src={asset('/images/the-game-logo.png')} alt="the-game-icon" />
          <h2>START A TEAM</h2>
        </div>
        <div className={style.Form}>
          <div className={style.Signin}>
            <h3>Create Account</h3>
            <Button
              startIcon={
                <div className={style.OauthLogo + ' ' + style.EmailLogo} />
              }
              variant="outlined"
              fullWidth={true}
            >
              Sign up with Email
            </Button>
            {/* <SigninWithPasswordForm
              onCreateAccount={onCreateAccount}
              onLoggedIn={onLoggedIn}
              onForgotPassword={onForgotPassword}
            /> */}
          </div>
          <div className={style.SocialSignin}>
            <Button
              startIcon={
                <div className={style.OauthLogo + ' ' + style.GoogleLogo} />
              }
              variant="outlined"
              fullWidth={true}
            >
              Sign up with Google
            </Button>
          </div>
          {/* <div className={style.Signin}>
            <Button fullWidth={true} variant="outlined">
              <h3>Create Player Account</h3>
            </Button>
          </div> */}
        </div>
      </div>
    </DefaultLayout>
  );
}

export default StartTeam;
