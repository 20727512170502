import React from 'react';
import './Headline.scss';
import * as style from './Headline.scss.json';
import { cl } from 'lincd/lib/utils/ClassNames';

interface HeadlineProps {
  title?: string;
  subtitle?: string;
  inverted?: boolean;
  lined?: 'black' | 'blue';
  className?: string;
  // size?: 'small' | 'normal';
}

export default function Headline({
  title,
  subtitle,
  lined, // handle border top  & bottom color
  inverted,
  className,
}: // size = 'normal',
HeadlineProps) {
  return (
    <div
      className={cl(
        style.Headline,
        inverted && style.Inverted,
        style[lined],
        subtitle && style.isSubtitle,
        className && className,
        // style[size],
      )}
    >
      {title && <h2>{title}</h2>}
      {subtitle && <h3 className={style.subtitle}>{subtitle}</h3>}
      {/* {size === 'normal' ? <h2>{title}</h2> : <h3>{title}</h3>} */}
    </div>
  );
}

// function TopicActionsHeader({ title, subtitle }) {
//   const goBack = useGoBack();

//   return (
//     <div className={style.HeaderWrapper}>
//       <div className={style.HeaderTitle}>
//         {/* <Button className={style.BackButton} onClick={goBack}>
//                 <img src="/images/icons/UndoIcon.svg" alt="Back Button" width="26px" />
//             </Button> */}
//         <h2>{title}</h2>
//       </div>
//       <div className={style.HeaderSubtitle}>
//         <h4>{subtitle}</h4>
//       </div>
//     </div>
//   );
// }
