import React, { ReactNode } from 'react';
import './Header.scss';
import * as style from './Header.scss.json';
import useGoBack from '../../hooks/useGoBack';
import { cl } from 'lincd/lib/utils/ClassNames';

interface HeaderProps {
  title: React.ReactNode;
  textAlignment?: 'center' | 'left' | 'right';
  subtitle?: string;
  tabMenu?: ReactNode;
  inverted?: Boolean;
  fixed?: boolean;
  lined?: Boolean;
  className?;
  backButton?: boolean;
}

export default function Header({
  title,
  textAlignment = 'center',
  subtitle,
  inverted,
  fixed,
  backButton,
  className,
}: HeaderProps) {
  const goBack = useGoBack();

  return (
    <>
      <header
        className={cl(
          style.Header,
          inverted && style.Inverted,
          className,
          fixed && style.fixed,
          textAlignment && style[textAlignment],
        )}
      >
        {backButton && (
          <div onClick={goBack} className={style.BackButton}>
            &laquo;
          </div>
        )}
        {/* If title && subtitle props are available, then Topic Actions header becomes active */}
        {title && subtitle ? (
          <TopicActionsHeader title={title} subtitle={subtitle} />
        ) : (
          <MainHeader title={title} />
        )}
      </header>
      {fixed && <div className={style.HeaderSpacer} />}
    </>
  );
}

function MainHeader({ title }) {
  let header;

  if (typeof title === 'string') {
    header = <h1>{title}</h1>;
  } else if (React.isValidElement(title)) {
    header = <>{title}</>;
  } else {
    header = <p>Unsupported prop type</p>;
  }

  return header;
}

function TopicActionsHeader({ title, subtitle }) {
  const goBack = useGoBack();

  return (
    <div className={style.HeaderWrapper}>
      <div className={style.HeaderTitle}>
        {/* <Button className={style.BackButton} onClick={goBack}>
                <img src="/images/icons/UndoIcon.svg" alt="Back Button" width="26px" />
            </Button> */}
        <h2>{title}</h2>
      </div>
      <div className={style.HeaderSubtitle}>
        <h4>{subtitle}</h4>
      </div>
    </div>
  );
}
