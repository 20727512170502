import React, { useEffect } from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import './Unity.scss';
import Banner from '../components/molecules/Banner';
import ProgressTab from '../components/atoms/ProgressTab';
import { topic3 } from '../data-references';
import UnityLearn from '../components/organisms/ActionDetails/3_UnityLearn';
import UnityPlan from '../components/organisms/ActionDetails/3_UnityPlan';
import UnityShare from '../components/organisms/ActionDetails/3_UnityShare';
import { useTranslate } from '@tolgee/react';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { useProfileCompleted } from '../hooks/useProfileCompleted';

const Unity = () => {
  let topic = topic3;
  let { t } = useTranslate();
  const { checkIsCompleted } = useProfileCompleted();

  // check if user has completed profile setup
  useEffect(() => {
    checkIsCompleted();
  }, []);

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'topic' + topic.identifier;
  return (
    <DefaultLayout backgroundColor={'white'}>
      <Banner
        title={t(prefix + '.title', topic.name)}
        subtitle={t(prefix + '.subTitle', topic.description)}
        image={asset('/images/UnityBanner.webp')}
      />
      <ProgressTab
        firstPage={<UnityLearn />}
        secondPage={<UnityPlan />}
        thirdPage={<UnityShare />}
      />
    </DefaultLayout>
  );
};

export default Unity;
