import React, { useState } from 'react';
import Headline from '../../atoms/Headline';
import style from './7_FaithPlan.scss.json';
import './7_FaithPlan.scss';
import GoToButton from '../../atoms/GoToButton';
import { topic7 } from '../../../data-references';
import ActionContent from '../../molecules/ActionContent';
import ActionPlan from '../../molecules/ActionPlan';
import { useTranslate } from '@tolgee/react';
import { ROUTES } from '../../../routes';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  BronzeGoal,
  GoldGoal,
  SetYourGoal,
  SilverGoal,
} from '../../molecules/SetYourGoal';
import { PlanPurpose } from '../../molecules/PlanPurpose';

const FaithPlan = () => {
  let topic = topic7;
  let { t } = useTranslate();
  let prefix = 'topic' + topic.identifier;

  const [actionPlanTime, setActionPlanTime] = useState(0);

  const handleTimeSelected = (minutes) => {
    setActionPlanTime(minutes);
  };

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.

  const navigate = useNavigate();
  return (
    <>
      <div className={style.content}>
        <PlanPurpose topic={topic} />

        <Headline title={t('word.gameplay', 'Gameplay')} lined="black" />

        <ActionContent
          subtitle={t('word.howToKeepScore', 'How to Keep Score')}
          content={
            <>
              <p>
                <span className={style.bold}>
                  {t('word.enrollment', 'Enrollment')}
                  {': '}
                </span>
                {t(
                  prefix + '.plan.scoreText1',
                  'Each person who says “yes” to attending an information meeting or playing in the Peace Game counts as 1 Peace Action.',
                )}
                <br />
                <br />
                <span className={style.underline}>
                  {t('word.bronzePeaceMedal', 'Bronze Peace Medal')}
                </span>
                :{' '}
                <span className={style.bold}>
                  {t(prefix + '.plan.bronzePeaceMedalText2', '1 - 3')}{' '}
                </span>
                {t(
                  prefix + '.plan.bronzePeaceMedalText3',
                  'people who say “yes” to attending an information meeting or playing in the Peace Game.',
                )}{' '}
                <span className={style.blue}>
                  (
                  {t(
                    prefix + '.plan.bronzePeaceMedalText4',
                    '1-3 Peace Actions',
                  )}
                  )
                </span>
                <br />
                <br />
                <span className={style.underline}>
                  {t('word.sliverPeaceMedal', 'Silver Peace Medal')}
                </span>
                :{' '}
                <span className={style.bold}>
                  {t(prefix + '.plan.silverPeaceMedalText2', '4 - 8')}{' '}
                </span>
                {t(
                  prefix + '.plan.silverPeaceMedalText3',
                  'people who say “yes” to attending an information meeting or playing in the Peace Game.',
                )}{' '}
                <span className={style.blue}>
                  (
                  {t(
                    prefix + '.plan.silverPeaceMedalText4',
                    '4-8 Peace Actions',
                  )}
                  )
                </span>
                <br />
                <br />
                <span className={style.underline}>
                  {t('word.goldPeaceMedal', 'Gold Peace Medal')}
                </span>
                :{' '}
                <span className={style.bold}>
                  {t(prefix + '.plan.goldPeaceMedalText2', '9 or more')}
                </span>{' '}
                {t(
                  prefix + '.plan.goldPeaceMedalText3',
                  'people who say “yes” to attending an information meeting or playing in the Peace Game.',
                )}{' '}
                <span className={style.blue}>
                  ({t(prefix + '.plan.goldPeaceMedalText4', '9+ Peace Actions')}
                  )
                </span>
              </p>
              <p>
                <span className={style.blue}>
                  {t('word.note', 'Note')}
                  {': '}
                </span>
                {t(
                  prefix + '.plan.noteText',
                  'We estimate it will take up to 15 minutes per person to have a conversation in which you share your experience and invite them to come to an information meeting or, if they are ready, play the Peace Game.',
                )}
              </p>
            </>
          }
        />

        <SetYourGoal topic={topic} onTimeSelected={handleTimeSelected}>
          <BronzeGoal actions={'1-3'} minutes={45} average={true} />
          <SilverGoal actions={'4-8'} minutes={90} average={true} />
          <GoldGoal actions={'9+'} months={135} average={true} />
        </SetYourGoal>
        <Headline
          subtitle={t(
            'word.createPeaceActionPlan',
            'Create Your Peace Action Plan',
          )}
          lined="black"
        />
        <ActionPlan action="faith" time={actionPlanTime} topic={topic} />

        <GoToButton
          text={t('word.goToShare', 'Go to Share')}
          onClick={() => {
            navigate(
              generatePath(ROUTES.peace_action.path, {
                id: topic.name.toLowerCase() + '?step=3',
              }),
            );
          }}
        />
      </div>
    </>
  );
};

export default FaithPlan;
