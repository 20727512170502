import React from 'react';
import './HowtoCreatePOEZ.scss';
import { default as style } from './HowtoCreatePOEZ.scss.json';
import { registerPackageModule, linkedComponent } from '../../../package';
import { useTranslate } from '@tolgee/react';
import { Video } from '../../atoms/Video';
import { UnsortedList } from '../../atoms/UnsortedList';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { OrderedList } from '../../atoms/OrderedList';
import Headline from '../../atoms/Headline';

export function HowtoCreatePOEZ() {
  let { t } = useTranslate();
  const navigate = useNavigate();

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'resource-HowtoCreatePOEZ';
  return (
    <>
      <div className={style.content}>
        <Headline
          subtitle={t('word.introduction', 'Introduction')}
          lined="black"
        />
        <p>
          {t(
            prefix + '.paragraph01',
            'The purpose of a Peace on Earth (POE) Zone is to apply the seven peace practices developed by playing the Peace Game – empowerment, oneness, unity, cooperation, abundance, love and faith – to reweave the social fabric of a community through bringing people together and cooperating for the common good.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph02',
            'To be recognized as a Peace on Earth Zone requires a minimum of three Peace Teams of 3-people who have played the Global Online Peace Game and been certified as DreamKeepers. They then serve as the organizing team. Amongst them they need to possess the following:',
          )}
          <OrderedList type={1}>
            <li>
              {t(
                prefix + '.bullet01',
                'A desire to transform the differences that separate people in the community and create greater cooperation for the common good.',
              )}
            </li>
            <li>
              {t(prefix + '.bullet02', 'Experience in community organizing.')}
            </li>
            <li>
              {t(prefix + '.bullet03', 'Experience in group facilitation.')}
            </li>
            <li>
              {t(
                prefix + '.bullet04',
                'A community social network that embraces the civic, public and private sectors.',
              )}
            </li>
            <li>
              {t(
                prefix + '.bullet05',
                'Dedication and time to implement a long-term transformational vision for their community. ',
              )}
            </li>
          </OrderedList>
        </p>
        <p>
          {t(
            prefix + '.paragraph03',
            'The following seven Community Peace Actions are the foundation upon which a POE Zone is built. They create the fertile soil needed to optimize the good works of a community’s nonprofits, public sector agencies and businesses. Specifically they build a community’s cooperation and social impact capacity. Each Community Peace Action grows out of the experience, knowledge and teamwork acquired by Peace Teams playing the Peace Game.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph04',
            'Core to a POE Zone is the engagement of as many community members as possible playing the Peace Game. This establishes the seven peace practices as new social norms within the community. Establishing these peace practices among the community’s residents combined with empowering the civic, public and private sectors to cooperate for the common good is what transforms the community into a Peace on Earth Zone.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph05',
            'A POE Zone provides a pathway to the world humanity is deeply seeking. Who would not want to live in a community practicing empowerment, oneness, unity, cooperation, abundance, love and faith. Who would not wish to be around people and organizations operating from these peace practices? Who would not wish to benefit from the social good such a community is able to generate?',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph06',
            'When a model of a better world is built, others seek to emulate it. This is how change happens and humanity evolves. The POE Zone evolutionary intervention begins one community at a time. As POE Zones learn from one another, best practices emerge for creating greater social good.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph07',
            'A tipping point for diffusion of this social innovation will be achieved rather rapidly when communities begin demonstrating a greater social good. This is because communities are wired together and always looking to one another for social innovations that improve the quality of life of its residents. This combined with the morphogenetic field established by the peace practices is the mechanism for achieving',
          )}{' '}
          <a href="https://poe.earth/peace-plan">
            {t(prefix + '.paragraph07a', 'Peace on Earth by 2030')}
          </a>
          .
        </p>
        <Headline
          subtitle={t(prefix + '.Pre-Meeting', 'Community Peace Actions')}
          lined="black"
        />
        <p>
          <u>{t(prefix + '.paragraph08_header', 'Action 1: Empowerment')}</u>{' '}
          {' - '}
          {t(prefix + '.paragraph08', 'Create a')}{' '}
          <a href="https://rtp.earth/study-circles">
            {' '}
            {t(prefix + '.paragraph08a', 'Social Change 2.0 Study Circle')}
          </a>{' '}
          {t(
            prefix + '.paragraph08b',
            'to develop the community agency and second order change capacity of community members and organizations in your Peace on Earth Zone.',
          )}
        </p>

        <p>
          <u>{t(prefix + '.paragraph09_header', 'Action 2: Oneness')}</u>{' '}
          {' - '}
          {t(
            prefix + '.paragraph09',
            'Create an annual “befriending the other” event to develop oneness amongst community members and groups in your Peace on Earth Zone. This',
          )}{' '}
          <a href="https://poe.earth/hardLink-poez-inspration-video">
            {t(prefix + '.paragraph09a', 'video')}
          </a>{' '}
          {t(prefix + '.paragraph09b', 'will provide inspiration.')}
        </p>
        <p>
          {t(
            prefix + '.paragraph10',
            'This can either be part of an existing community event or you can organize your own. It can be combined with one or more of the other actions.',
          )}
        </p>
        <p>
          <u>{t(prefix + '.paragraph11_header', 'Action 3: Unity')}</u> {' - '}
          {t(
            prefix + '.paragraph11',
            'Create an annual “celebration of our common humanity” event to develop greater unity amongst community members and groups in your Peace on Earth Zone.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph12',
            'This can either be part of an existing community event or you can organize your own. It can be combined with one or more of the other actions.',
          )}
        </p>
        <p>
          <u>{t(prefix + '.paragraph13_header', 'Action 4: Cooperation')}</u>{' '}
          {' - '}
          {t(
            prefix + '.paragraph13',
            'Create an annual “cooperation for the common good” event to develop greater cooperation amongst community groups in your Peace on Earth Zone. The purpose of the event is to recognize cooperation for the common good between organizations within your community. Then to cull and share these best practices amongst organizations to help establish them as community social norms.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph14',
            'This can either be part of an existing community event or you can organize your own. It can be combined with one or more of the other actions.',
          )}
        </p>
        <p>
          <u>{t(prefix + '.paragraph15_header', 'Action 5: Abundance')}</u>{' '}
          {' - '}
          {t(
            prefix + '.paragraph15',
            'Create an annual recognition and fundraising event to generate financial and volunteer support for your Peace on Earth Zone and organizations that exemplify the Peace on Earth values of “befriending the other,” “celebrating our common humanity,” and “cooperating for the common good.” Then cull and share best practices amongst the organizations.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph16',
            'This can either be part of an existing community event or you can organize your own. It can be combined with one or more of the other actions.',
          )}
        </p>
        <p>
          <u>{t(prefix + '.paragraph17_header', 'Action 6: Love')}</u> {' - '}
          {t(
            prefix + '.paragraph17',
            'Create an annual peace walk culminating in a peace vigil to pray for peace in your community and for Peace on Earth by 2030. To build unity create this as an interfaith, intergenerational, inter-organizational event to initially plant and then commemorate your Peace on Earth Zone’s Peace Pole.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph18',
            'Consider making the site of the community’s Peace Pole into a Peace Sanctuary for prayer, meditation and contemplation. When you’ve built momentum in your Peace on Earth Zone, consider adding an eternal peace flame. As a model, see the',
          )}{' '}
          <a href="https://poe.earth/hardLink-eternal-peace-flame">
            {t(
              prefix + '.paragraph18a',
              'eternal peace flame in Lumbini, Nepal',
            )}
          </a>{' '}
          {t(
            prefix + '.paragraph18b',
            'at the birthplace of the Buddha which was lit by the Torch of Peace in 1986.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph21',
            'This can either be part of an existing community event or you can organize your own. It can be combined with one or more of the other actions. For vision, inspiration and ideas see',
          )}{' '}
          <a href="https://poe.earth/hardLink-activating-humanity">
            {t(
              prefix + '.paragraph21a',
              'Activating Humanity’s Will for Peace on Earth',
            )}
          </a>
          .
        </p>
        <p>
          <u>{t(prefix + '.paragraph22_header', 'Action 7: Faith')}</u> {' - '}
          {t(
            prefix + '.paragraph22',
            'Host regular Information Meetings to enroll community members in playing the Peace Game. At these events honor exemplary players of the Peace Game and Peace on Earth Partners who can also provide testimonials. Consider having an annual recognition event to honor the people who played the Peace Game that year and the Peace on Earth partners who helped engage their members and/or employees to play.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph23',
            'This can be combined with one or more of the other actions.',
          )}
        </p>
        <p>
          <u>{t('word.note', 'Note')}</u>
          {': '}
          {t(
            prefix + '.paragraph22',
            'If an annual event is organized consider weaving all these actions together to increase its learning potential and social impact.',
          )}
        </p>
        <Headline
          subtitle={t(prefix + 'NextSteps', 'Next Steps')}
          lined="black"
        />
        <p>
          {t(
            prefix + 'paragraph24',
            'To apply to become a POE Zone fill out this',
          )}{' '}
          <a href="https://poe.earth/hardLink-poez-application">
            {t(prefix + 'paragraph24a', 'application')}
          </a>
          {'. '}{' '}
          {t(
            prefix + 'paragraph24b',
            'We will then contact you to schedule an interview.',
          )}
        </p>
        <p>
          {t(
            prefix + 'paragraph25',
            'If accepted, your leadership team will be invited to participate in the September 2024 POE Zone Leadership Certification Program facilitated by David Gershon. This year-long certification program includes transformative leadership and Social Change 2.0 training, strategy development and project coaching, and a POE Zone leaders support system. It also qualifies you to apply for a POE Zone Start-Up Grant. Grants are based on funding availability. ',
          )}
        </p>
        <p>
          {t(
            prefix + 'paragraph26',
            'These are performance grants that require the achievement of specific metrics which include a minimum of 10 successful Peace Teams and implementation of at least one of the Community Peace Actions.',
          )}
        </p>
        <Headline
          subtitle={t(prefix + 'furtherinformation', 'Further Information')}
          lined="black"
        />
        <p>
          {t(
            prefix + 'paragraph27',
            'To learn more about POE Zones watch the Peace Game videos in the Resource section.',
          )}
        </p>
        <p>
          {t(
            prefix + 'paragraph28',
            'To learn more about Peace on Earth by 2030 visit:',
          )}{' '}
          <a href="https://peace2030.earth">peace2030.earth</a>
        </p>
        {/* <p>
          {t(
            prefix + 'paragraph29',
            'For POE Zone questions select "Peace on Earth Zones" in the contact form under Profile.',
          )}
        </p> */}
      </div>
    </>
  );
}
