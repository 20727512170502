import React from 'react';

import './LogoSpinning.scss';
import style from './LogoSpinning.scss.json';
import { cl } from 'lincd/lib/utils/ClassNames';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

export function LogoSpinning({ centered }: { centered?: boolean }) {
  return (
    <img
      className={cl(style.globe, centered && style.centered)}
      src={asset('/images/peace-game-logo-spinning-2.gif')}
      alt="the-game-icon"
    />
  );
}
