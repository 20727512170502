import React, { useEffect, lazy, Suspense, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Spinner } from './components/atoms/Spinner';
import { RequireAuth } from 'lincd-auth/lib/components/RequireAuth';
import PageNotFound from './pages/PageNotFound';
import { DefaultLayout } from './layout/DefaultLayout';

//In React 18 you can use 'lazy' to import pages only when you need them.
//This will cause webpack to create multiple bundles, and the right bundles are automatically loaded
interface RouteObj {
  path: string;
  component?: React.LazyExoticComponent<() => JSX.Element>;
  render?: () => JSX.Element;
  requireAuth?: boolean;
  excludeFromMenu?: boolean;
  label?: string;
}
export const ROUTES = {
  index: {
    path: '/',
    component: lazy(
      () => import('./pages/PeaceAction' /* webpackPrefetch: true */),
    ),
    label: 'Home',
    requireAuth: true,
  },
  signin: {
    path: '/signin',
    component: lazy(() => import('./pages/Signin' /* webpackPrefetch: true */)),
    label: 'Sign In',
  },
  team_action: {
    path: '/team-action',
    component: lazy(
      () => import('./pages/TeamAction' /* webpackPrefetch: true */),
    ),
    label: 'Team Action',
    requireAuth: true,
  },
  admin: {
    path: '/admin',
    component: lazy(() => import('./pages/Admin' /* webpackPrefetch: true */)),
    label: 'Admin',
    requireAuth: true,
  },
  users: {
    path: '/admin/users',
    component: lazy(() => import('./pages/Users' /* webpackPrefetch: true */)),
    label: 'Users',
    requireAuth: true,
  },
  user: {
    path: '/admin/users/:accountId',
    component: lazy(() => import('./pages/User' /* webpackPrefetch: true */)),
    label: 'User',
    requireAuth: true,
  },
  duplicate_account: {
    path: '/admin/duplicate-account',
    component: lazy(() => import('./pages/DuplicateAccount')),
    label: 'Duplicate Account',
    requireAuth: true,
  },
  peace_action: {
    path: '/:id',
    component: lazy(
      () => import('./pages/PeaceAction' /* webpackPrefetch: true */),
    ),
    label: 'Peace Action',
    requireAuth: true,
  },
  join_team: {
    path: '/join-team',
    component: lazy(
      () => import('./pages/JoinTeam' /* webpackPrefetch: true */),
    ),
    label: 'Join Team',
    requireAuth: true,
  },
  team_chat: {
    path: '/team-chat/:id',
    component: lazy(
      () => import('./pages/ChatGroup' /* webpackPrefetch: true */),
    ),
    label: 'Team Chat',
    requireAuth: true,
  },
  register: {
    path: '/register',
    component: lazy(
      () => import('./pages/Register' /* webpackPrefetch: true */),
    ),
    label: 'Register',
    requireAuth: true,
  },
  invite_your_team: {
    path: '/invite-your-team',
    component: lazy(
      () => import('./pages/InviteYourTeam' /* webpackPrefetch: true */),
    ),
    label: 'Invite Your Team',
    excludeFromMenu: true,
    requireAuth: true,
  },
  get_started: {
    path: '/get-started',
    component: lazy(
      () => import('./pages/GetStarted' /* webpackPrefetch: true */),
    ),
    label: 'Get Started',

    excludeFromMenu: true,
    requireAuth: true,
  },
  resources: {
    path: '/resources',
    component: lazy(
      () => import('./pages/Resources' /* webpackPrefetch: true */),
    ),
    label: 'Resources',
    preventSSR: true,
    requireAuth: true,
  },
  // action_leader_guides: {
  //   path: '/action-leader-guides',
  //   component: lazy(
  //     () => import('./pages/ActionLeaderGuides' /* webpackPrefetch: true */),
  //   ),
  //   label: 'Action Leader Guides',
  //   requireAuth: true,
  // },
  resource_detail: {
    path: '/resource/:id',
    component: lazy(
      () => import('./pages/ResourceDetail' /* webpackPrefetch: true */),
    ),
    label: 'Resource detail',
    excludeFromMenu: true,
    requireAuth: true,
  },
  poet: {
    path: '/team',
    component: lazy(() => import('./pages/Team' /* webpackPrefetch: true */)),
    label: 'Team',
    preventSSR: true,
    requireAuth: true,
  },
  start_team: {
    path: '/start-team',
    component: lazy(
      () => import('./pages/StartTeam' /* webpackPrefetch: true */),
    ),
    label: 'Start Team',
    excludeFromMenu: true,
    requireAuth: true,
  },
  oneness: {
    path: '/oneness',
    component: lazy(
      () => import('./pages/Oneness' /* webpackPrefetch: true */),
    ),
    label: 'Oneness',
    excludeFromMenu: true,
    requireAuth: true,
  },
  abundance: {
    path: '/abundance',
    component: lazy(
      () => import('./pages/Abundance' /* webpackPrefetch: true */),
    ),
    label: 'Abundance',
    excludeFromMenu: true,
    requireAuth: true,
  },
  faith: {
    path: '/faith',
    component: lazy(() => import('./pages/Faith' /* webpackPrefetch: true */)),
    label: 'Faith',
    excludeFromMenu: true,
    requireAuth: true,
  },
  love: {
    path: '/love',
    component: lazy(() => import('./pages/Love' /* webpackPrefetch: true */)),
    label: 'Love',
    excludeFromMenu: true,
    requireAuth: true,
  },
  profile: {
    path: '/profile',
    component: lazy(
      () => import('./pages/Profile' /* webpackPrefetch: true */),
    ),
    label: 'Profile',
    requireAuth: true,
  },
  globalGameBoard: {
    preventSSR: true,
    path: '/global-game-board',
    component: lazy(
      () => import('./pages/GlobalGameBoard' /* webpackPrefetch: true */),
    ),
    label: 'Global Game Board',

    excludeFromMenu: true,
    requireAuth: true,
  },
  // playerOverView: {
  //   path: '/player-overview',
  //   component: lazy(
  //     () => import('./pages/PlayerOverview' /* webpackPrefetch: true */),
  //   ),
  //   label: 'Player Overview',
  //
  //   requireAuth: true,
  //   excludeFromMenu: true,
  // },
  empowerment: {
    path: '/empowerment',
    component: lazy(
      () => import('./pages/Empowerment' /* webpackPrefetch: true */),
    ),
    label: 'Empowerment',
    excludeFromMenu: true,
    requireAuth: true,
  },
  unity: {
    path: '/unity',
    component: lazy(() => import('./pages/Unity' /* webpackPrefetch: true */)),
    label: 'Unity',
    excludeFromMenu: true,
    requireAuth: true,
  },
  cooperation: {
    path: '/cooperation',
    component: lazy(
      () => import('./pages/Cooperation' /* webpackPrefetch: true */),
    ),
    label: 'Cooperation',
    excludeFromMenu: true,
    requireAuth: true,
  },
  contact: {
    path: '/contact',
    component: lazy(
      () => import('./pages/Contact' /* webpackPrefetch: true */),
    ),
    label: 'Contact',
  },
  terms: {
    path: '/terms',
    component: lazy(
      () => import('./pages/TermsOfUse' /* webpackPrefetch: true */),
    ),
    label: 'Terms',
  },
  privacy_policy: {
    path: '/privacy-policy',
    component: lazy(
      () => import('./pages/PrivacyPolicy' /* webpackPrefetch: true */),
    ),
    label: 'Privacy Policy',
  },
  teams_overview: {
    path: '/teams-overview',
    component: lazy(
      () => import('./pages/TeamsOverview' /* webpackPrefetch: true */),
    ),
    label: 'Teams Overview',
    requireAuth: true,
  },
  reset_password_callback: {
    path: '/auth/reset-password',
    component: lazy(
      () =>
        import(
          'lincd-auth/lib/components/ForgotPasswordCallback' /* webpackPrefetch: true */
        ),
    ),
  },
};

export default function AppRoutes() {
  const navigate = useNavigate();
  // check deeplink
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // Example url: https://beerswift.com/tabs/tab2
      // slug = /tabs/tab2
      const slug = event.url.replace(process.env.SITE_ROOT, '');
      console.log('AppUrlListener: ', slug);

      if (slug) {
        navigate(slug);
      }
    });
  }, []);

  return (
    <Routes>
      {Object.keys(ROUTES).map((routeName) => {
        const route = ROUTES[routeName];

        //if a route is marked as requireAuth, wrap it in the RequireAuth component and pass the signinRoute
        const AuthGuard = route.requireAuth ? RequireAuth : React.Fragment;
        const authProps = route.requireAuth
          ? { signinRoute: ROUTES.signin.path }
          : {};

        return (
          <Route
            key={route.path}
            path={route.path}
            element={
              <AuthGuard {...authProps}>
                <Suspense fallback={<Spinner centered={true} />}>
                  <RenderRoute routeName={routeName} route={route} />
                </Suspense>
              </AuthGuard>
            }
          />
        );
      })}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
// define a render function that determines what to render based on the component and route.render
const RenderRoute = ({ route, routeName }) => {
  let navigate = useNavigate();
  const onPasswordIsReset = () => {
    navigate(ROUTES.index.path);
  };

  //added support to only render the spinner on the first render if the route is marked as preventSSR
  let [client, setClient] = useState<any>(null);
  useEffect(() => {
    setClient(true);
  }, []);

  if (route.preventSSR && !client) {
    return (
      <DefaultLayout>
        <Spinner centered={true} />
      </DefaultLayout>
    );
  }

  // if a Component is defined, render it using JSX syntax (<Component />)
  // if not, check if a route.render function is defined and call that render function if available.
  // if neither Component nor route.render is defined, return null
  const Component = route.component;
  return Component ? (
    <Component
      {...(routeName === 'reset_password_callback' && {
        onPasswordIsReset: onPasswordIsReset,
      })}
    />
  ) : route.render ? (
    route.render()
  ) : null;
};
