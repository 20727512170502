import React, { ReactNode } from 'react';
import './Container.scss';
import { default as style } from './Container.scss.json';
import { cl } from 'lincd/lib/utils/ClassNames';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  maxWidth?: 'sm' | 'md';
}

export function Container({ children, maxWidth, ...props }: ContainerProps) {
  return (
    <div
      className={cl(style.Container, maxWidth && style[maxWidth])}
      {...props}
    >
      {children}
    </div>
  );
}
