import React, { useEffect } from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import EmpowermentLearn from '../components/organisms/ActionDetails/1_EmpowermentLearn';
import EmpowermentPlan from '../components/organisms/ActionDetails/1_EmpowermentPlan';
import EmpowermentShare from '../components/organisms/ActionDetails/1_EmpowermentShare';
import Banner from '../components/molecules/Banner';
import ProgressTab from '../components/atoms/ProgressTab';
import { topic1 } from '../data-references';
import { useTranslate } from '@tolgee/react';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { useProfileCompleted } from '../hooks/useProfileCompleted';

const Empowerment = React.memo(() => {
  let topic = topic1;
  let { t } = useTranslate();
  const { checkIsCompleted } = useProfileCompleted();

  // check if user has completed profile setup
  useEffect(() => {
    checkIsCompleted();
  }, []);

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'topic' + topic.identifier;
  return (
    <DefaultLayout backgroundColor={'white'}>
      <Banner
        title={t(prefix + '.title', topic.name)}
        subtitle={t(prefix + '.subTitle', topic.description)}
        image={asset('/images/EmpowermentBanner.webp')}
      />

      <ProgressTab
        firstPage={<EmpowermentLearn />}
        secondPage={<EmpowermentPlan />}
        thirdPage={<EmpowermentShare />}
      />
    </DefaultLayout>
  );
});

Empowerment.displayName = 'Empowerment';

export default Empowerment;
