import React from 'react';
import './Meeting05.scss';
import { default as style } from './Meeting05.scss.json';
import { registerPackageModule, linkedComponent } from '../../../package';
import { useTranslate } from '@tolgee/react';
import { Video } from '../../atoms/Video';
import { UnsortedList } from '../../atoms/UnsortedList';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { OrderedList } from '../../atoms/OrderedList';
import Headline from '../../atoms/Headline';
import { getActiveLanguage } from '../../../utils/tolgee';

export function Meeting05() {
  let { t } = useTranslate();
  const navigate = useNavigate();
  const activeLanguage = getActiveLanguage();

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'resource-Meeting05';
  return (
    <>
      <div className={style.content}>
        <Headline
          subtitle={t('word.Pre-Meeting', 'Pre-Meeting Preparation')}
          lined="black"
        />
        <p>
          {t(
            prefix + '.paragraph01',
            'Here are elements to help you prepare to lead the meeting. Ignore those that apply for in-person meetings if your team is meeting virtually.',
          )}
        </p>
        <UnsortedList>
          <li>
            <u>{t('word.roomSetup', 'Room Setup')}</u>
            {': '}
            {t(
              prefix + '.bullet01',
              'Arrange the room in a U shape with you in front. This allows people to see one another and you, as the facilitator, increasing the quality of the sharing and community building.',
            )}
          </li>
          <li>
            <u>{t('word.timing', 'Timing')}</u>
            {': '}
            {t(
              prefix + '.bullet02',
              'Tell the group at the start of the meeting what time you propose to end and check that everyone can stay until the end. Make sure you end on time. Each element in the meeting agenda has an approximate time associated with it. It is rare that any meeting element will go for exactly that amount of time, so use it as a guideline. If one meeting element runs longer, you will need to make up the time by shortening another. Give the team shared responsibility for timekeeping. If any point seems likely to take a lot longer than you planned, tell the team and ask them to decide whether to extend the meeting or hurry to finish the point.',
            )}
          </li>
          <li>
            <u>{t('word.ShareActionPlan', 'Share Action Plan')}</u>
            {': '}
            {t(
              prefix + '.bullet03',
              'As the meeting facilitator, share your Action Plan when you get to the “Plan” section to role model the process.',
            )}
          </li>
          <li>
            <u>{t('word.practice', 'Practice')}</u>
            {': '}
            {t(
              prefix + '.bullet04',
              'To get the full value from this script, take time to study it so you are fluent and understand what you are trying to achieve with each meeting element.',
            )}
          </li>
        </UnsortedList>
        <Headline
          subtitle={t(
            'word.AgendaHeader',
            'Agenda Summary and Approximate Times',
          )}
          lined="black"
        />
        <OrderedList type={1}>
          <li>
            {t('word.InnerPeaceMed', 'Inner Peace Meditation')} - 5{' '}
            {t('word.minutes', 'minutes')}
          </li>
          <li>
            {t('word.checkin', 'Check In')} - 20 {t('word.minutes', 'minutes')}
          </li>
          <li>
            {t(
              prefix + '.shareActionsTaken',
              'Share Cooperation Actions Taken',
            )}{' '}
            - 30 {t('word.minutes', 'minutes')}
          </li>
          <li>
            {t(prefix + '.shareActionsPlan', 'Create Abundance Action Plan')} -
            45 {t('word.minutes', 'minutes')}
          </li>
          <li>
            {t('word.teamPerformance', 'Check in on Team Performance')} - 10{' '}
            {t('word.minutes', 'minutes')}
          </li>
          <li>
            {t('word.actionSupportCom', 'Action Support Communication')} - 5{' '}
            {t('word.minutes', 'minutes')}
          </li>
          <li>
            {t('word.reviewNextSteps', 'Review Next Steps')} - 5{' '}
            {t('word.minutes', 'minutes')}
          </li>
        </OrderedList>
        <p>{t('word.total2hours', 'Total Time 2 - hours')}</p>
        <Headline
          subtitle={t('word.meetingScript', 'Meeting Script')}
          lined="black"
        />
        <OrderedList type={1}>
          <li>
            <u>{t('word.InnerPeaceMed', 'Inner Peace Meditation')}</u> - 5{' '}
            {t('word.minutes', 'minutes')}{' '}
            <UnsortedList>
              <li>
                {t(
                  prefix + '.a1_bullet01',
                  'Inner Peace Meditation: Action Leader starts the meeting either through playing video or guiding it.',
                )}
                <Video
                  src={`https://poe.earth/hardLink-inner-peace`}
                  // src={`https://poe.earth/hardLink-inner-peace_${activeLanguage}`}
                />
              </li>
              <li>
                {t(prefix + '.a1_bullet02', 'Guided meditation')}
                {': '}
                <UnsortedList>
                  <li>
                    {t(
                      prefix + '.a1_bullet03',
                      'This inner peace work complements the outer peace work of the game.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet04',
                      'Take a few deep breaths to quiet your mind.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet05',
                      'Now become present to this moment of profound opportunity for rapid evolution of your life and planet Earth.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet06',
                      'Experience peace within yourself. Affirm: I am in harmony with self!',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet07',
                      'Experience yourself at peace with all the people in your life. Affirm: I am in harmony with others.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet08',
                      'Experience yourself at peace with the Earth and all living beings who inhabit it. Affirm: I am in harmony with the Earth and all living beings.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a1_bullet09',
                      'Deepen this state of harmony with self, others and the Earth for the next few minutes.',
                    )}
                  </li>
                </UnsortedList>
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t('word.checkin', 'Check In')}</u> - 20{' '}
            {t('word.minutes', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + '.a2_bullet01',
                  'Time for any questions or comments from team members about playing the Peace Game.',
                )}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>
              {t(prefix + '.AgendaItem03', 'Share Cooperation Actions Taken')}
            </u>{' '}
            - 30 {t('word.minutes', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + '.a3_bullet01',
                  'Have each person share the Peace Medal achieved and four Cooperation questions from the “Share” section of the Peace Game App.',
                )}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t(prefix + '.AgendaItem04', 'Create Abundance Action Plan')}</u>{' '}
            - 45 {t('word.minutes', 'minutes')}
            <UnsortedList>
              <li>
                <u>{t(prefix + '.a4_bullet01', 'Set up Action')}</u> - 10{' '}
                {t('word.minutes', 'minutes')}
                <OrderedList type={1}>
                  <li>
                    {t(
                      prefix + '.a4_bullet02',
                      'Explain that this action is about supporting peacemaker organizations.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a4_bullet03',
                      'Ask everyone to open their Peace Game App or website and review the “Learn” and “Plan” sections of the Abundance Action.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + '.a4_bullet04',
                      'In the “Plan” section share the bronze, silver, or gold Peace Medal you selected and why you chose it. Then share your action plan on how you will implement it.',
                    )}
                  </li>
                </OrderedList>
              </li>
              <li>
                <u>{t(prefix + '.a4_bullet05', 'Create Action Plan')}</u> - 15{' '}
                {t('word.minutes', 'minutes')}
                <p>
                  {t(
                    prefix + '.a4_bullet06',
                    'Invite them now to select their Abundance Action goal and create their Action Plan.',
                  )}
                </p>
              </li>
              <li>
                <u>{t(prefix + '.a4_bullet07', 'Share Action Plan')}</u> - 20{' '}
                {t('word.minutes', 'minutes')}
                <p>
                  {t(
                    prefix + '.a4_bullet08',
                    'Invite each team member to share their action plan.',
                  )}
                </p>
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t('word.teamPerformance', 'Check in on Team Performance')}</u>
            {':'} - 10 {t('word.minutes', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + '.a5_bullet01',
                  'If individuals did not take the actions they had planned, ask if the team can be helpful in supporting them to carry it out. If they did not get around to doing it, kindly ask if they will be able to take the actions before the next meeting.',
                )}
              </li>
              <li>
                {t(
                  prefix + '.a5_bullet02',
                  'If any team members came late, request that they come on time in the future so the meeting can be run without interruptions. If a number of people came late, requiring you to delay the start of the meeting, request that they come on time so you can end on time. If people can’t get there at the agreed-on time, determine if you should start the meeting later.',
                )}
              </li>
              <li>
                {t(
                  prefix + '.a5_bullet03',
                  'Communicate with any team members who did not attend the meeting and ask if they are still committed to participating in the Peace Game. If so, request that they attend the following meetings. Explain what happened at this meeting.',
                )}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t('word.actionSupportCom', 'Action Support Communication')}</u>{' '}
            - 5 {t('word.minutes', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + '.a6_bullet01',
                  'Let your team know that approximately halfway between meetings, as Action Leader you will be checking in with them via either text or email to see how they are doing in implementing their action plans.',
                )}
              </li>
              <li>
                {t(
                  prefix + '.a6_bullet02',
                  'Left on our own, our motivation can wane—these communications make a big difference in assisting team members to stay on track.',
                )}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t('word.reviewNextSteps', 'Review Next Steps')}</u> - 5{' '}
            {t('word.minutes', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + '.a7_bullet01',
                  'Take the Abundance action you planned, enter results and provide answers to 4 questions.',
                )}
              </li>
              <li>
                {t(
                  prefix + '.a7_bullet02',
                  'Complete any incomplete actions from the previous action areas and report on any additional actions taken.',
                )}
              </li>
              <li>
                {t(
                  prefix + '.a7_bullet03',
                  'Express your appreciation to team members for what they have accomplished. State in a sentence or two what was most meaningful for you.',
                )}
              </li>
            </UnsortedList>
          </li>
        </OrderedList>
      </div>
    </>
  );
}
