import React, { useState } from 'react';
import './RegisterYourPeaceTeam.scss';
import style from './RegisterYourPeaceTeam.scss.json';
import GoToButton from '../../atoms/GoToButton';
import { generatePath, useNavigate } from 'react-router-dom';
import { Team } from 'lincd-irlcg/lib/shapes/Team';
import AlertModal from '../../molecules/AlertModal';
import { ROUTES } from '../../../routes';
import { resource3, resource4b } from '../../../data-references';
import { useTranslate } from '@tolgee/react';

function RegisterYourPeaceTeam() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState('');

  const navigate = useNavigate();
  const { t } = useTranslate();
  let prefix = 'registerYourPeaceTeam';

  const onToggleModal = () => setIsModalOpen(!isModalOpen);

  const createTeam = () => {
    Team.createTeam().then((response) => {
      if (response) {
        if (response.error) {
          setIsModalOpen(true);
          setModalText(response.error);
        } else {
          navigate(
            generatePath(ROUTES.resource_detail.path, {
              id: resource4b.identifier,
            }),
          );
        }
      }
    });
  };

  return (
    <div className={style.content}>
      <p>
        <b>
          {t(
            prefix + '.inPersonTeams',
            'This is for in person Peace Teams only, i.e. those created as a part of POE Zones.',
          )}
        </b>
      </p>
      <p>
        {t(prefix + '.review', 'Please review')}{' '}
        <a
          className={style.hyperlink}
          href={generatePath(ROUTES.resource_detail.path, {
            id: resource3.identifier,
          })}
          aria-label={t(prefix + '.howToStart', 'How to Start a Peace Team')}
        >
          {t(prefix + '.howToStart', 'How to Start a Peace Team')}
        </a>{' '}
        {t(
          prefix + '.understand',
          'so you understand what you are committing to and your responsibilities.',
        )}
      </p>
      <p>
        {t(
          prefix + '.startJourney',
          'By clicking “Start” below you begin the journey of a Peace Team Leader. You will be given a registration code to provide your teammates.',
        )}
      </p>
      <GoToButton
        text={t(prefix + '.start', 'Start')}
        color="primary"
        onClick={createTeam}
        endIcon={false}
        className={style.startBtn}
        fullWidth={true}
        aria-label={t(prefix + '.aria.start', 'Start Button')}
      />
      <AlertModal
        isOpen={isModalOpen}
        onClose={onToggleModal}
        onAgree={onToggleModal}
        confirmationText={modalText}
      />
    </div>
  );
}

export default RegisterYourPeaceTeam;
