import React, { useEffect } from 'react';
import './ProgressTab.scss';
import { default as style } from './ProgressTab.scss.json';
import { Tab } from '@mui/base/Tab';
import { TabsList } from '@mui/base/TabsList';
import { TabPanel } from '@mui/base/TabPanel';
import { Tabs } from '@mui/base/Tabs';
import { useSearchParams } from 'react-router-dom';
import { cl } from 'lincd/lib/utils/ClassNames';
import { useTranslate } from '@tolgee/react';

const ProgressTab = ({ firstPage, secondPage, thirdPage }) => {
  let { t } = useTranslate();
  const [searchParams, setSearchParams] = useSearchParams();

  const tabActive = searchParams.get('step') || '1';

  useEffect(() => {
    // Check if the 'step' parameter is not present or explicitly set to null
    if (tabActive === null || tabActive === 'null') {
      setSearchParams('step=1');
    }
  }, [tabActive, setSearchParams]);

  return (
    <>
      <Tabs
        defaultValue={1}
        value={tabActive}
        onChange={(event, newValue) => {
          if (newValue == null) return;
          if (newValue.toString() !== tabActive) {
            setSearchParams(`step=${newValue}`, { replace: true });
          }
        }}
        aria-label={t('progressTab.tabs', 'Progress Tabs')}
      >
        <div className={style.tabsContainer}>
          <div
            className={style.stepsContainer}
            aria-labelledby={t('progressTab.stepsContainer', 'Steps Container')}
          >
            <p
              className={cl(tabActive == '1' && style.active)}
              aria-label={t('progressTab.learn', 'Learn')}
            >
              {t('progressTab.learn', 'Learn')}
            </p>
            <p
              className={cl(tabActive == '2' && style.active)}
              aria-label={t('progressTab.plan', 'Plan')}
            >
              {t('progressTab.plan', 'Plan')}
            </p>
            <p
              className={cl(tabActive == '3' && style.active)}
              aria-label={t('progressTab.share', 'Share')}
            >
              {t('progressTab.share', 'Share')}
            </p>
          </div>

          <TabsList
            className={style.tabs}
            aria-label={t('progressTab.tabsList', 'Tabs List')}
          >
            <div
              className={cl(
                style.line_1,
                (tabActive == '2' || tabActive == '3') && style.lineActive,
              )}
              aria-hidden="true"
            />
            <div
              className={cl(style.line_2, tabActive == '3' && style.lineActive)}
              aria-hidden="true"
            />
            <div
              className={cl(
                style.line_1a,
                (tabActive == '2' || tabActive == '3') && style.lineActive2,
              )}
              aria-hidden="true"
            />
            <div
              className={cl(
                style.line_2a,
                tabActive == '3' && style.lineActive2,
              )}
              aria-hidden="true"
            />
            <Tab
              value={1}
              className={cl(
                style.tab,
                (tabActive == '1' || tabActive == '2' || tabActive == '3') &&
                  style.tabActive,
              )}
              aria-label={t('progressTab.step1', 'Step 1')}
            >
              1
            </Tab>
            <Tab
              value={2}
              className={cl(
                style.tab,
                (tabActive == '2' || tabActive == '3') && style.tabActive,
              )}
              aria-label={t('progressTab.step2', 'Step 2')}
            >
              2
            </Tab>
            <Tab
              value={3}
              className={cl(style.tab, tabActive == '3' && style.tabActive)}
              aria-label={t('progressTab.step3', 'Step 3')}
            >
              3
            </Tab>
          </TabsList>
        </div>
        <TabPanel
          value={'1'}
          aria-labelledby={t('progressTab.tabPanel1', 'Tab 1')}
        >
          {firstPage}
        </TabPanel>
        <TabPanel
          value={'2'}
          aria-labelledby={t('progressTab.tabPanel2', 'Tab 2')}
        >
          {secondPage}
        </TabPanel>
        <TabPanel
          value={'3'}
          aria-labelledby={t('progressTab.tabPanel3', 'Tab 3')}
        >
          {thirdPage}
        </TabPanel>
      </Tabs>
    </>
  );
};

export default ProgressTab;
