import React from 'react';
import Headline from '../atoms/Headline';

interface ActionContentProps {
  title?;
  subtitle?;
  content?;
  children?;
  // teamChange: (data) => void;
}

const ActionContent = ({
  title,
  subtitle,
  content,
  children,
  // teamChange,
}: ActionContentProps) => {
  return (
    <div>
      {title && <Headline title={title} lined="black" />}
      {subtitle && <Headline subtitle={subtitle} lined="black" />}
      <div>{content || children}</div>
    </div>
  );
};

export default ActionContent;
