import React, { useEffect, useState } from 'react';
import './PeaceTeamMemberRegistration.scss';
import style from './PeaceTeamMemberRegistration.scss.json';
import Headline from '../../atoms/Headline';
import { InputField } from 'lincd-input/lib/components/InputField';
import { Button } from 'lincd-mui-base/lib/components/Button';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { UserAccount } from 'profile-plus/lib/shapes/UserAccount';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { Person } from 'lincd-schema/lib/shapes/Person';
import { Team } from 'lincd-irlcg/lib/shapes/Team';
import AlertModal from '../../molecules/AlertModal';
import { ShapeSet } from 'lincd/lib/collections/ShapeSet';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { useTranslate } from '@tolgee/react';
import { Modal } from 'lincd-mui-base/lib/components/Modal';
import { Spinner } from '../../atoms/Spinner';
import { cl } from 'lincd/lib/utils/ClassNames';

function PeaceTeamMemberRegistration() {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [targetTeam, setTargetTeam] = useState(null);
  const [errorForm, setErrorForm] = useState('');
  const [loading, setLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState<string>(
    'https://poe.earth/app',
  );
  const [webURL, setWebURL] = useState<string>('www.peacegame.earth');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState('');
  const [onAgree, setOnAgree] = useState<() => void | string | null>(null); // void for close modal, string for redirect
  const [members, setMembers] = useState<ShapeSet<Person>>(null);

  // state for remove modal
  const [isModalRemove, setIsModalRemove] = useState(false);
  const [member, setMember] = useState<Player>(null);

  const auth = useAuth<Player, UserAccount>();
  const account = auth.userAccount;
  const person = account.accountOf as Player;
  const { t } = useTranslate();
  let prefix = 'peaceTeamMemberRegistration';

  useEffect(() => {
    Team.getTeamLeader().then((result) => {
      if (result) {
        setTargetTeam(result);
      }
    });
  }, []);

  useEffect(() => {
    Team.getTeamMembersByTeamId(targetTeam?.identifier).then((response) => {
      if (response) {
        setMembers(response);
      }
    });
  }, [targetTeam, modalText]);

  const onToggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (firstName || lastName) {
      setFirstName('');
      setLastName('');
    }
  };

  const copyToClipboard = () => {
    const textToCopy = document.getElementById('peaceTeamMember').innerText;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert(t(prefix + '.textCopied', 'Text copied to clipboard'));
      })
      .catch((err) => {
        alert(t(prefix + '.unableToCopy', 'Unable to copy text'));
      });
  };

  const onHandleInput = (field) => (e) => {
    if (errorForm) {
      setErrorForm('');
    }
    const value = e.target.value;
    if (field === 'firstName') {
      setFirstName(value);
    }
    if (field === 'lastName') {
      setLastName(value);
    }
  };

  const addTeammate = () => {
    const teamId = targetTeam?.identifier;
    setLoading(true);
    Team.addTeamMate(firstName, lastName, teamId).then((response) => {
      if (response) {
        if (response.error) {
          setErrorForm(response.error);
        } else {
          setIsModalOpen(true);
          setModalText(
            `${t(prefix + '.yourTeammateAdded', 'Your teammate')} ${
              response.player?.givenName
            } ${response.player?.familyName} ${t(
              prefix + '.addedToTeam',
              'has been successfully added to the Peace Team',
            )} ${response.team?.name || response.team?.identifier}`,
          );
          setLoading(false);
        }
      }
    });
  };

  // close remove modal
  const closeRemoveModal = () => {
    setIsModalRemove(false);
  };

  // show modal remove teammate
  const removeTeammate = (member: Player) => {
    setMember(member);
    setIsModalRemove(true);

    setModalText(
      `${t(
        prefix + '.teammateRemovedQuestion',
        `Are you sure you want to remove`,
      )} ${member.givenName} ${member.familyName}?`,
    );
  };

  // call server to remove teammate
  const onRemoveTeamMate = () => {
    const teamId = targetTeam?.identifier;

    if (!member) {
      alert('No member selected');
      return;
    }

    Team.removeTeamMate(member, teamId).then((response) => {
      if (response) {
        if (response.error) {
          setErrorForm(response.error);
        } else {
          // close modal remove
          setIsModalRemove(false);

          // show modal success removed and delay 1s
          setTimeout(() => {
            setIsModalOpen(true);
            setModalText(
              `${t(prefix + '.yourTeammateRemoved', 'Your teammate')} ${
                member.givenName
              } ${member.familyName} ${t(
                prefix + '.removed',
                'has been removed from the Peace Team',
              )} ${teamId}`,
            );
          }, 1000);
        }
      }
    });
  };

  return (
    <div className={style.content}>
      <p>
        {t(
          prefix + '.invite',
          'After someone decides to join your Peace Team, click to copy the registration invite below into an email or text.',
        )}
      </p>
      <div className={style.blueArea}>
        <div className={style.title}>
          <p>{t(prefix + '.joinTeam', 'Join my Peace Team!')}</p>
          <div className={style.copyButton}>
            <img
              src={asset('/images/icons/copy.svg')}
              onClick={copyToClipboard}
              width={'20px'}
              alt={t('word.copy', 'Copy')}
              aria-label={t('word.copy', 'Copy')}
            />
            <p>{t(prefix + '.copy', 'Copy')}</p>
          </div>
        </div>
        <ol id="peaceTeamMember">
          <li>
            {t(prefix + '.downloadApp', 'Download the app:')}
            <br />
            &nbsp;&nbsp;{downloadLink}
            <br />
            <span>{t(prefix + '.orUseWebsite', 'Or use the website:')}</span>
            <br />
            &nbsp;&nbsp;{webURL}
          </li>
          <li>
            {t(prefix + '.teamNumber', 'Peace Team Number:')}
            <br />
            <div className={style.teamNumber}>
              {targetTeam
                ? targetTeam.identifier
                : t(prefix + '.loading', 'Loading...')}
            </div>
          </li>
        </ol>
      </div>
      <Headline
        title={t(prefix + '.directAdd', 'Direct Add')}
        lined="black"
        className={style.customHeadline}
      />
      <p>
        {t(
          prefix + '.teammateNoInternet',
          'If the teammate you are adding does not have access to the internet you can add them here.',
        )}
        <br />
        <strong>{t(prefix + '.note', 'Note')}:</strong>{' '}
        {t(
          prefix + '.responsible',
          'You will be responsible for entering Peace Actions on their behalf.',
        )}
      </p>
      {members && members.size > 0 && (
        <div>
          <p>{t(prefix + '.offlineMembers', 'Offline Members')}:</p>
          <div className={style.member}>
            <ul>
              {members.map((member: Player) => {
                return (
                  member.namedNode !== person.namedNode && (
                    <li key={member.uri}>
                      {member.givenName} {member.familyName}
                      <button
                        className={style.closeIcon}
                        aria-label={`remove ${member.givenName} ${member.familyName}`}
                        onClick={() => removeTeammate(member)}
                      >
                        <svg
                          width="9"
                          height="9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 .9 8.1 0 4.5 3.6.9 0 0 .9l3.6 3.6L0 8.1l.9.9 3.6-3.6L8.1 9l.9-.9-3.6-3.6L9 .9Z"
                            fill="#1F1D48"
                          />
                        </svg>
                      </button>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        </div>
      )}
      <div className={style.formSection}>
        <div className={style.inlineForm}>
          <div className={style.inputWrapper}>
            <InputField
              type={'text'}
              className={style.inputField}
              placeholder={t(prefix + '.firstname', 'Firstname')}
              value={firstName}
              onChange={onHandleInput('firstName')}
              aria-label={t(prefix + '.firstname', 'Firstname')}
            />
          </div>
          <div className={style.inputWrapper}>
            <InputField
              type={'text'}
              className={style.inputField}
              placeholder={t(prefix + '.lastname', 'Lastname')}
              value={lastName}
              onChange={onHandleInput('lastName')}
              aria-label={t(prefix + '.lastname', 'Lastname')}
            />
          </div>
        </div>
        {errorForm && (
          <p className={style.errorForm}>
            {t(prefix + '.formError', errorForm)}
          </p>
        )}
      </div>
      <Button
        className={cl(style.addTeammateButton, loading && style.loading)}
        variant="outlined"
        onClick={addTeammate}
        aria-label={t('word.addTeammate', 'Add Teammate')}
      >
        {loading ? <Spinner /> : <>{t('word.addTeammate', 'Add Teammate')}</>}
      </Button>
      <AlertModal
        isOpen={isModalOpen}
        onClose={onToggleModal}
        onAgree={onToggleModal}
        confirmationText={modalText}
        aria-label={t(prefix + '.modal', 'Alert Modal')}
      />
      <Modal isOpen={isModalRemove} onClose={closeRemoveModal}>
        <div className={style.modal}>
          <p>{modalText}</p>
          <div className={style.modalButtonContainer}>
            <Button variant="outlined" onClick={closeRemoveModal}>
              {t('word.cancel', 'Cancel')}
            </Button>
            <Button onClick={onRemoveTeamMate}>
              {t('word.delete', 'Delete')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PeaceTeamMemberRegistration;
