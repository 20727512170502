export const BRONZE_MEDAL_NUMBER: number = 1;
export const SILVER_MEDAL_NUMBER: number = 2;
export const GOLD_MEDAL_NUMBER: number = 3;

export function getMedalNumber(type: 'gold' | 'silver' | 'bronze' | 'none') {
  switch (type) {
    case 'bronze':
      return BRONZE_MEDAL_NUMBER;
    case 'silver':
      return SILVER_MEDAL_NUMBER;
    case 'gold':
      return GOLD_MEDAL_NUMBER;
    default:
      return 0;
  }
}
export function getMedalType(number: number) {
  switch (number) {
    case BRONZE_MEDAL_NUMBER:
      return 'bronze';
    case SILVER_MEDAL_NUMBER:
      return 'silver';
    case GOLD_MEDAL_NUMBER:
      return 'gold';
    default:
      return 'none';
  }
}
export function minutesToTimeString(t, minutes, months, average) {
  let toText = (num) => {
    if (num % 60 === 0) {
      return (
        Math.round(num / 60) +
        ' ' +
        (num === 60 ? t('word.hour', 'Hour') : t('word.hours', 'Hours'))
      );
    } else {
      return num + ' ' + t('word.minutes', 'Minutes');
    }
  };
  let timeStr;
  if (months) {
    timeStr = months + ' ' + t('word.months', 'Months');
  } else if (
    typeof minutes !== 'undefined' &&
    minutes.toString().indexOf('-') !== -1
  ) {
    timeStr = minutes
      .split('-')
      .map((s) => parseInt(s.trim()))
      .map(toText)
      .join(' - ');
  } else if (typeof minutes !== 'undefined') {
    timeStr = toText(minutes);
  } else {
    return '';
  }
  if (average) {
    timeStr = t('word.average-short', 'Avg.') + ' ' + timeStr;
  }
  return timeStr;
}
