import React from 'react';
import './List.scss';
import * as style from './List.scss.json';
import { useStyles } from 'lincd/lib/utils/Hooks';

interface ListItemProps {
  of;
  as;
  color?: string;
  className?;
  // 'aria-label'?: string; // for accessibility
}

export function ListItem({ of, as, ...restProps }: ListItemProps) {
  restProps = useStyles(restProps, style.ListItem);
  let ItemComponent = as;
  return (
    <div {...restProps}>
      {of?.map((singleItem) => {
        return <ItemComponent key={singleItem.toString()} of={singleItem} />;
      })}
    </div>
  );
}

export function SingleListItem({ as, ...restProps }) {
  restProps = useStyles(restProps, style.SingleListItem);
  let ItemComponent = as;

  return <div {...restProps}>{ItemComponent}</div>;
}

interface GridListItemProps {
  className?: string;
  as?;
  of: any;
  renderFn?: (item, key) => any;
}
export function GridListItem({
  as,
  of,
  renderFn,
  ...restProps
}: GridListItemProps) {
  restProps = useStyles(restProps, style.GridListItem);
  if (as) {
    let ItemComponent = as;
    renderFn = (source, key) => <ItemComponent of={source} key={key} />;
  }
  if (!renderFn) {
    throw Error('Please use renderFn or as props instead');
  }

  return (
    <div>
      <div {...restProps}>
        {of?.map((singleItem) => {
          return renderFn(singleItem, singleItem.toString());
        })}
      </div>
    </div>
  );
}
