import React, { useEffect } from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import './Oneness.scss';
import OnenessLearn from '../components/organisms/ActionDetails/2_OnenessLearn';
import OnenessPlan from '../components/organisms/ActionDetails/2_OnenessPlan';
import OnenessShare from '../components/organisms/ActionDetails/2_OnenessShare';
import Banner from '../components/molecules/Banner';
import ProgressTab from '../components/atoms/ProgressTab';
import { topic2 } from '../data-references';
import { useTranslate } from '@tolgee/react';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { useProfileCompleted } from '../hooks/useProfileCompleted';

const Oneness = () => {
  let topic = topic2;
  let { t } = useTranslate();
  const { checkIsCompleted } = useProfileCompleted();

  // check if user has completed profile setup
  useEffect(() => {
    checkIsCompleted();
  }, []);

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'topic' + topic.identifier;
  return (
    <DefaultLayout backgroundColor={'white'}>
      <Banner
        title={t(prefix + '.title', topic.name)}
        subtitle={t(prefix + '.subTitle', topic.description)}
        image={asset('/images/OnenessBanner.webp')}
      />

      <ProgressTab
        firstPage={<OnenessLearn />}
        secondPage={<OnenessPlan />}
        thirdPage={<OnenessShare />}
      />
    </DefaultLayout>
  );
};

export default Oneness;
