import React, { useCallback, useEffect, useState } from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import Header from '../components/molecules/Header';
import './Users.scss';
import style from './Users.scss.json';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from '../package';
import { useParams } from 'react-router-dom';
import { cl } from 'lincd/lib/utils/ClassNames';
import { Button } from 'lincd-mui-base/lib/components/Button';
import { ActionCard } from '../components/atoms/ActionCard';

const User = () => {
  const { accountId } = useParams();

  const [userData, setUserData] = useState({
    user: null,
    actions: null,
    isLoading: true,
  });

  const [showDetails, setShowDetails] = useState(false);

  // get the first div and set overflow to visible
  // to make the table thead is sticky
  useEffect(() => {
    const firstDiv = document.querySelector('div');
    if (firstDiv) {
      firstDiv.style.overflow = 'visible';
    }
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const usersData = await Server.call(packageName, 'getUser', accountId);
        const action = await Server.call(
          packageName,
          'getUserAction',
          accountId,
        );
        if (isMounted) {
          setUserData({ user: usersData, actions: action, isLoading: false });
        }
      } catch (error) {
        console.error('failed to fetch user data:', error);
        if (isMounted) {
          setUserData({ ...userData, isLoading: false });
        }
      }
    };
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [accountId]);

  const toggleDetails = () => {
    setShowDetails((prevShowDetails) => !prevShowDetails);
  };

  if (userData.isLoading) {
    return <div>Loading...</div>;
  }

  if (!userData.user) {
    return <div>User not found.</div>;
  }

  const { user, actions } = userData;

  return (
    <DefaultLayout backgroundColor="white">
      <Header title={`USER ${user?.id}`} inverted={true} backButton={true} />
      <div className={cl(style.content, style.modalContent)}>
        <div className={style.row}>
          <h4>User Detail</h4>
          <div className={style.profileUser}>
            <p>givenName: {user?.givenName}</p>
            <p>familyName: {user?.familyName}</p>
            <p>email: {user?.email}</p>
            <p>telephone: {user?.telephone}</p>
            <p>currentTeam: {user?.currentTeam}</p>
            <p>
              teams:{' '}
              {user?.teams?.map((team, i) => (
                <span key={i}>{team?.name || team?.identifier}, </span>
              ))}
            </p>
            <p>google: {user?.hasGoogle ? 'Yes' : 'No'}</p>
            <p>password: {user?.hasPassword ? 'Yes' : 'No'}</p>
            <p>apple: {user?.hasApple ? 'Yes' : 'No'}</p>
          </div>
        </div>
        <div className={style.row}>
          <h4>
            Data{' '}
            <Button
              variant="outlined"
              className={style.showDetailBtn}
              onClick={toggleDetails}
            >
              {showDetails ? 'Hide Detail' : 'Show Detail'}
            </Button>
          </h4>
          <div className={style.contentData}>
            {actions?.length === 0 && <p>No data</p>}
            {actions?.map((action, i: number) => {
              return (
                <div key={i} className={style.teamRow}>
                  <p className={cl(style.teamHeadline, style.sticky)}>
                    Team {action.identifier}
                  </p>
                  <ActionCard
                    data={action.actionPlan}
                    type={'actionPlan'}
                    showDetail={showDetails}
                  />
                  <ActionCard
                    data={action.actionDebrief}
                    type={'actionDebrief'}
                    showDetail={showDetails}
                  />
                  <ActionCard
                    data={action.topicScore}
                    type={'topicScore'}
                    showDetail={showDetails}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default User;
