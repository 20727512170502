import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Spinner } from './components/atoms/Spinner';
import { Error } from './components/atoms/Error';
import { AppRoot } from 'lincd-server-utils/lib/components/AppRoot';
import { Head } from 'lincd-server-utils/lib/components/Head';
import { Body } from 'lincd-server-utils/lib/components/Body';
import AppRoutes, { ROUTES } from './routes';
import { ProvideAuth } from 'lincd-auth/lib/hooks/useAuth';
import { CapacitorProvider } from 'lincd-capacitor/lib/hooks/useCapacitor';

//Note that by default LINCD apps are set up with support for SCSS (sass) and CSS Modules
//So any .scss file needs to be imported by itself
import './App.scss';
//and then the .scss.json file needs to be imported to access the class names (this file will be automatically generated)
import style from './App.scss.json';
//import our variable files last, so they overwrite any components imported so far (by routes also)
import './scss/global-overwrites.scss';
import './scss/variables-theme.scss';
import './scss/variables-components.scss';
import './scss/globals.scss';

import { TranslationProvider } from './utils/tolgee';
import { Person } from 'profile-plus/lib/shapes/Person';
import { UserAccount } from 'profile-plus/lib/shapes/UserAccount';
import './data-references';
import { useAutomaticUpdates } from 'lincd-capacitor/src/hooks/useAutomaticUpdates';
import { JSONLD } from 'lincd-jsonld/lib/utils/JSONLD';

import { FirebaseOptions, initializeApp } from 'firebase/app';

//load hardcoded data about topics and resources into memory both in backend and frontend
import generatedData from '../data/filestores/generated.json';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

var locallyLoaded = [Player];
JSONLD.parse(generatedData);

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};
export default function App() {
  initializeApp(firebaseConfig);
  useAutomaticUpdates();

  return (
    <AppRoot>
      <Head>
        {/*  Add tags to html <head> here, for example, a font <link href='https://fonts.someapi.com/...' />*/}
        {/* WHATEVER YOU ADD HERE NEEDS TO BE ADDED TO /web/index.html as well for ios/android */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <link
          href="
https://cdn.jsdelivr.net/npm/react-international-phone@4.0.4/build/index.min.css
"
          rel="stylesheet"
        />
        <link
          href="
https://cdn.jsdelivr.net/npm/react-date-picker@10.6.0/dist/DatePicker.min.css
"
          rel="stylesheet"
        />
        <link
          href="
https://cdn.jsdelivr.net/npm/react-calendar@4.8.0/dist/Calendar.min.css
"
          rel="stylesheet"
        />
      </Head>
      <Body routes={ROUTES} pageStyles={style} className={style.App}>
        <Suspense fallback={<Spinner centered={true} />}>
          <ErrorBoundary FallbackComponent={Error}>
            <ProvideAuth userType={Player} accountType={UserAccount}>
              <TranslationProvider>
                <CapacitorProvider>
                  <AppRoutes />
                </CapacitorProvider>
              </TranslationProvider>
            </ProvideAuth>
          </ErrorBoundary>
        </Suspense>
      </Body>
    </AppRoot>
  );
}
