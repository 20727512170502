import React from 'react';
import './CertificatePDF.scss';
import { default as style } from './CertificatePDF.scss.json';
import { PDFDocument } from 'pdf-lib';
import { Share } from '@capacitor/share';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory } from '@capacitor/filesystem';

interface CertificatePDFProps {
  fullName: string;
  templateSrc: string;
  certificateName: string;
}

class CertificatePDF extends React.Component<CertificatePDFProps> {
  replaceTextFieldAndDownload = async () => {
    const { fullName, templateSrc, certificateName } = this.props;

    const response = await fetch(templateSrc);
    const pdfBytes = await response.arrayBuffer();
    const pdfDoc = await PDFDocument.load(pdfBytes);

    const page = pdfDoc.getPages()[0];

    // Define the field name and the text to replace it with
    const fieldName = 'fullName';

    // Get all fields on the page
    const fields = pdfDoc.getForm().getFields();

    // Find the text field by its name
    const textField = fields.find((field) => field.getName() === fieldName);

    // Set the value of the text field
    (textField as any).setText(fullName);

    // Flatten the form
    const form = pdfDoc.getForm();
    form.flatten();

    // Serialize the modified PDF to a buffer
    const modifiedPdfBytes = await pdfDoc.save();

    // Function to convert Uint8Array to Base64
    function uint8ArrayToBase64(bytes) {
      let binary = '';
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }

    if (Capacitor.getPlatform() === 'web') {
      // Web platform logic
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = certificateName + ' ' + fullName + '.pdf';
      link.click();
      URL.revokeObjectURL(url);
    } else {
      // Native platform logic
      const base64Data = uint8ArrayToBase64(modifiedPdfBytes);
      const fileName = `${certificateName} ${fullName}.pdf`;

      // Write the file to the local filesystem
      await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Documents, // or Directory.ExternalStorage on Android
        recursive: true,
      });

      // Obtain the URI for the saved file
      const fileUri = await Filesystem.getUri({
        directory: Directory.Documents,
        path: fileName,
      });

      // Use the Share API to share the file
      await Share.share({
        title: 'Download Certificate',
        text: 'Congratulation, Here is your DreamKeeper Certificate',
        url: fileUri.uri,
        dialogTitle: 'Share your certificate',
      });
    }
  };

  render() {
    return (
      <button
        onClick={this.replaceTextFieldAndDownload}
        className={style.CertificateButton}
      >
        Download Certificate
      </button>
    );
  }
}

export default CertificatePDF;
