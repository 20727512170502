import React, { useCallback, useEffect, useState } from 'react';
import style from './ShareActionDetails.scss.json';
import './ShareActionDetails.scss';
import GoToButton from '../atoms/GoToButton';
import ActionContent from './ActionContent';
import { useTranslate } from '@tolgee/react';
import { TopicScore } from 'lincd-irlcg/lib/shapes/TopicScore';
import { PeaceMedalAndActions } from './PeaceMedalAndActions';
import { NumberActionInput } from 'lincd-irlcg/lib/components/NumberActionInput';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import ActionDebrief from './ActionDebrief';
import { SharePurpose } from './SharePurpose';
import FloatingChatButton from '../atoms/FloatingChatButton';
import { Team } from 'lincd-irlcg/lib/shapes/Team';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { UserAccount } from 'profile-plus/lib/shapes/UserAccount';
import TeamSelect from '../atoms/TeamSelect';
import { cl } from 'lincd/lib/utils/ClassNames';
import { Topic } from 'lincd-irlcg/lib/shapes/Topic';
import { GameAction } from 'lincd-irlcg/lib/shapes/GameAction';
import GameCompletion from './GameCompletion';
import { RadioActionInput } from 'lincd-irlcg/lib/components/RadioActionInput';

interface ActionDetailsProps {
  currentTopic: Topic;
  nextTopic?: Topic;
  topic;
}
const ShareActionDetails = ({
  topic,
  currentTopic,
  nextTopic,
}: ActionDetailsProps) => {
  const auth = useAuth<Player, UserAccount>();
  const account = auth.userAccount;
  let user = auth.user as Player;

  let { t } = useTranslate();
  let prefix = 'topic' + topic.identifier;
  let [topicScore, setTopicScore] = useState<TopicScore>(null);

  const navigate = useNavigate();
  const [userIsTeamLeader, setUserIsTeamLeader] = useState<boolean>(false);
  const [currentTeam, setCurrentTeam] = useState<Team>(user.currentTeam);

  // state for finished game to ALWAYS show "GameCompletion" component after user finished the game. state is gotten from server
  const [showGameCompletion, setShowGameCompletion] = useState<boolean>(false);

  const currentTopicId = currentTopic.identifier;

  useEffect(() => {
    // check if user has finished the game on the first visit to the page by getting the data from server
    const getFinishedGames = async () => {
      const result = await GameAction.hasFinishedGame(account);
      setShowGameCompletion(result);
    };

    // check user finished the game only on Action 7
    // and trigger `topicScore` to update the state for showing GameCompletion section
    if (currentTopicId === '7') {
      getFinishedGames();
    }
  }, [topicScore]);

  const onScoreUpdated = (newScore: TopicScore) => {
    //the whole topicScore number is just to pass updated data. In the next version of LINCD we won't need to do this
    setTopicScore(newScore);
  };

  // get user is in certain mode, need this for disabling chat button
  const isInCertainMode =
    user.isInCertainMode('event') || user.isInCertainMode('trial');

  //Actions with radio is Empowerment and Love
  const actionsWithRadio = ['1', '6'];

  const showButton = () => {
    if (currentTopicId === '7') return false;
    if (currentTopicId === '2') return !isInCertainMode;
    return true;
  };

  // handle team change
  // we need to re-fetch user is team leader to update the state of userIsTeamLeader
  const handleTeamChange = (team: Team) => {
    setCurrentTeam(team);
    fetchUserIsTeamLeader();
  };

  // fetch user is team leader, use callback to avoid re-render
  const fetchUserIsTeamLeader = useCallback(async () => {
    try {
      const result = await Team.isUserTeamLeaderOnCurrentTeam();
      setUserIsTeamLeader(result);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    fetchUserIsTeamLeader();
  }, []);

  return (
    <>
      <div
        className={cl(
          style.content,
          ((currentTopicId == '2' && isInCertainMode) ||
            (currentTopicId == '7' && !isInCertainMode)) &&
            style.certainMode,
        )}
        aria-label={t(
          `${currentTopic.name}Share.content`,
          `${currentTopic.name} Share Content`,
        )}
      >
        <TeamSelect onTeamChange={handleTeamChange} />

        <SharePurpose topic={topic} />

        {/* if the user is team leader then render the ActionContent */}
        {userIsTeamLeader && (
          <ActionContent
            title={t('word.peaceActionResults', 'Peace Action Results')}
            content={t(
              prefix + '.share.peaceActionResultsText',
              'As a Team Leader you can report on behalf of any of your team members if they do not have computer or online access. Select their name and fill out the submission form below.',
            )}
          />
        )}
        {/* if the action is either empowerment or love, show radio form, else show input form */}
        {actionsWithRadio.includes(currentTopicId as string) ? (
          <RadioActionInput
            of={topic}
            onScoreUpdated={onScoreUpdated}
            teamChange={currentTeam}
            aria-label={t('word.radioActionInput', 'Radio Action Input')}
          />
        ) : (
          <NumberActionInput
            of={currentTopic}
            onScoreUpdated={onScoreUpdated}
            teamChange={currentTeam}
            aria-label={t('word.numberActionInput', 'Number Action Input')}
          />
        )}

        <PeaceMedalAndActions
          of={currentTopic}
          topicScore={topicScore}
          player={user}
          aria-label={t('word.peaceMedalAndActions', 'Peace Medal and Actions')}
        />
        <ActionContent
          title={t('word.peaceActionDebrief', 'Peace Action Debrief')}
          content={
            <>
              <p>
                <span className={style.bold}>
                  {t('word.purpose', 'Purpose') + ': '}
                </span>
                {t(
                  prefix + '.share.purposeText',
                  'Share the following with your Peace Team in the debrief meeting.',
                )}
              </p>
              <ActionDebrief topic={topic} />
            </>
          }
        />
        <br />
        {showGameCompletion && currentTopicId == '7' && <GameCompletion />}
      </div>

      {/* Show the GoToButton if currentTopicId is not 7.
      If currentTopicId is 2, only show the button if user's not in trial or event team */}
      {showButton() && (
        <GoToButton
          text={t('word.goToNextAction', 'Next Action')}
          aria-label={t('word.aria.goToNextAction', 'Next Action Button')}
          onClick={() => {
            navigate(
              generatePath(ROUTES.peace_action.path, {
                id: nextTopic.name.toLowerCase(),
              }),
            );
          }}
        />
      )}

      {/* show FloatingButton in all topic, except if user currentTopic is 1 or 2 and user is in trial or event mode, hdie this component*/}
      {!(
        isInCertainMode &&
        (currentTopicId === '1' || currentTopicId === '2')
      ) && (
        <FloatingChatButton
          id={`action-${currentTopicId}`}
          label={t(
            prefix + '.actionDiscussion',
            `Action ${currentTopicId} Discussion`,
          )}
          aria-label={t(
            prefix + '.aria.actionDiscussion',
            `Action ${currentTopicId} Discussion Button`,
          )}
        />
      )}
    </>
  );
};

export default ShareActionDetails;
