import React from 'react';
import style from './6_LoveLearn.scss.json';
import './6_LoveLearn.scss';
import GoToButton from '../../atoms/GoToButton';
import { topic6 } from '../../../data-references';
import ActionContent from '../../molecules/ActionContent';
import { useTranslate } from '@tolgee/react';
import { Video } from '../../atoms/Video';
import { UnsortedList } from '../../atoms/UnsortedList';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { ActionPurpose } from '../../molecules/ActionPurpose';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { getActiveLanguage } from '../../../utils/tolgee';

const LoveLearn = () => {
  let topic = topic6;
  let { t } = useTranslate();
  const navigate = useNavigate();
  const activeLanguage = getActiveLanguage();

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'topic' + topic.identifier;
  return (
    <>
      <div className={style.content}>
        <ActionPurpose
          topic={topic}
          purposeText={t(
            prefix + '.learn.purposeText',
            'The Love action is to pray for and visualize peace in the world and Peace on Earth by 2030.',
          )}
          actionGuidanceText={
            <p>
              {t(
                prefix + '.learn.actionGuidesText1',
                'Your life is the game, organize this action around it. Identify the growth issues you wish to address through this action.',
              )}
              <br />
              <br />
              {t(
                prefix + '.learn.actionGuidesText2',
                `Opportunities can include`,
              )}
              {': '}
              <br />
              <br />
              <UnsortedList>
                <li>
                  {t(
                    prefix + '.learn.opportunities1',
                    'Developing a daily peace meditation and prayer practice to cultivate serenity and a deeper connection to the world.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.opportunities2',
                    'Developing or participating in a prayer circle to pray for Peace on Earth and sending prayers to people and places in need.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.opportunities3',
                    'However else you offer this prayer or visualization, make sure to include Peace on Earth by 2030 to strengthen the morphogenetic field we are building.',
                  )}
                </li>
              </UnsortedList>
              <br />
              {t(
                prefix + '.learn.actionGuidesText3',
                'This action represents love for humanity that the Greeks called “Agape.” Praying for and visualizing peace in the world automatically includes you.',
              )}
              <br />
              <br />
              {t(
                prefix + '.learn.actionGuidesText4',
                `Prayers and visualizations work the same way. The more specific you make the wording, the more impactful it can be. Visualize this prayer rippling out from your heart to the hearts of others across the world filling you and them with a feeling of deep peace, well-being and harmony.`,
              )}
              <br />
              <br />
              {t(
                prefix + '.learn.actionGuidesText5',
                `Make sure to do this action on your love growing edge. The more you imbue your prayer or visualization with belief in its efficacy, the more impactful it becomes.`,
              )}
            </p>
          }
        />

        <ActionContent
          title={t('word.loveOriginStory', 'Love Origin Story')}
          content={
            <p>
              <div className={style.originImage}>
                <img
                  src={asset('/images/First-Earth-Run-collection-10.webp')}
                  alt="[1] Peace candles being lit from the torch. Amsterdam, Netherlands [2] Touching the torch of peace. Kampala, Uganda [3] Crowds with candles lit from the torch of peace. Burkina Faso"
                />
                <p>
                  {t(
                    prefix + '.learn.originImageAlt1',
                    '[1] Peace candles being lit from the torch. Amsterdam, Netherlands [2] Touching the torch of peace. Kampala, Uganda [3] Crowds with candles lit from the torch of peace. Burkina Faso',
                  )}
                </p>
              </div>
              {t(
                prefix + '.learn.originStory1',
                'A number of people I approached to participate in the First Earth Run spontaneously began visualizing, holding intention for, and praying for our success. In the Netherlands a woman created thousands of peace candles with the First Earth Run torch logo and invited people to put them in their windows each night and pray for us. Many did this devotedly for the 86 days of the First Earth Run to show their support. Variations on this theme happened to our local event organizers around the world. I am convinced that the many people praying for us across the planet strongly contributed to the extraordinary good fortune and grace we experienced on a regular basis over those 86 days.',
              )}
              <div className={style.originImage}>
                <img
                  src={asset('/images/First-Earth-Run-collection-11.webp')}
                  alt="[1] Peace meditation with torch at Thammasat University. Bangkok, Thailand [2] Dean of St Paul’s Cathedral, Alan Webster, lighting peace candles with children of many ethnic backgrounds. London, England [3] Boy with peace candle. Amsterdam, Netherlands"
                />
                <p>
                  {t(
                    prefix + '.learn.originImageAlt2',
                    '[1] Peace meditation with torch at Thammasat University. Bangkok, Thailand [2] Dean of St Paul’s Cathedral, Alan Webster, lighting peace candles with children of many ethnic backgrounds. London, England [3] Boy with peace candle. Amsterdam, Netherlands',
                  )}
                </p>
              </div>
              {t(
                prefix + '.learn.originStory2',
                'A heartfelt visualization for peace within ourselves allows us to receive this blessing available to anyone who asks for it. Holding the intention for peace in our communities, countries and world expands these blessings to others. Meditating and praying for peace is one of the most powerful single acts any of us can do because it sends out a ripple that keeps expanding from person to person. It is through our intentions in cooperation with the intelligence of the universe that our world comes into existence. There is also a magic in prayer where whatever we wish for others immediately circles back to bless us with these sentiments.',
              )}
              <div className={style.originImage}>
                <img
                  src={asset('/images/38-Pope-2.webp')}
                  alt="Pope John Paul II honors torch of peace with young runners at gathering of 60,000 at Stade de Gerland. Lyons, France"
                />
                <p>
                  {t(
                    prefix + '.learn.originImageAlt3',
                    'Pope John Paul II honors torch of peace with young runners at gathering of 60,000 at Stade de Gerland. Lyons, France',
                  )}
                </p>
              </div>
              {t(
                prefix + '.learn.originStory3',
                'Everyday millions of people pray for peace on earth using the prayer “May Peace Prevail on Earth.” As a way to keep this prayer in our minds and hearts, my wife, Gail and I planted a peace pole in our yard with this prayer in English, Chinese, Arabic and Hopi. This action will help you be part of the prayer solution to peace on earth by 2030.',
              )}
              <br />
              <br />
              <span className={style.bold}>
                {t(
                  prefix + '.learn.originStory5',
                  'Watch Good Morning America’s weekly series tell the story of the torch of peace circumnavigating the earth. Weeks 8, 9 & 10',
                )}
              </span>
            </p>
          }
        />

        <Video
          src={`https://poe.earth/hardLink-action-6-learn-os_1`}
          // src={`https://poe.earth/hardLink-action-6-learn-os_1_${activeLanguage}`}
        />

        <ActionContent
          title={t('word.loveFrequencyMeditation', 'Love Frequency Meditation')}
          content={t(
            prefix + '.learn.frequencyMeditation',
            'The Love Frequency Meditation by David Gershon, with harp accompaniment by Tami Briggs, is designed to help you play  the Peace Game at a deeper level.',
          )}
        />

        <Video
          src={`https://poe.earth/hardLink-action-6-learn-fm_${activeLanguage}`}
        />

        <GoToButton
          text={t('word.goToPlan', 'Go to Plan')}
          onClick={() => {
            navigate(
              generatePath(ROUTES.peace_action.path, {
                id: topic.name.toLowerCase() + '?step=2',
              }),
            );
          }}
        />
      </div>
    </>
  );
};

export default LoveLearn;
