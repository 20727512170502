import React from 'react';
import style from './7_FaithLearn.scss.json';
import './7_FaithLearn.scss';
import GoToButton from '../../atoms/GoToButton';
import { topic7 } from '../../../data-references';
import ActionContent from '../../molecules/ActionContent';
import { useTranslate } from '@tolgee/react';
import { Video } from '../../atoms/Video';
import { UnsortedList } from '../../atoms/UnsortedList';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { ActionPurpose } from '../../molecules/ActionPurpose';
import { OrderedList } from '../../atoms/OrderedList';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { getActiveLanguage } from '../../../utils/tolgee';

const FaithLearn = () => {
  let topic = topic7;
  let { t } = useTranslate();
  const navigate = useNavigate();
  const activeLanguage = getActiveLanguage();

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'topic' + topic.identifier;
  return (
    <>
      <div className={style.content}>
        <ActionPurpose
          topic={topic}
          purposeText={t(
            prefix + '.learn.purposeText',
            'The Faith action is to create more teams to play the Peace Game.',
          )}
          actionGuidanceText={
            <p>
              {t(
                prefix + '.learn.actionGuidesText1',
                'Your life is the game, organize this action around it. Share your dream for Peace on Earth and why you are playing the Peace Game with people in your life. The best way to do this is through in-person conversations so that people can feel your passion and ask you questions.',
              )}
              <br />
              <br />
              {t(prefix + '.learn.actionGuidesText2', `Here are some tips`)}
              {': '}
              <br />
              <br />
              <UnsortedList>
                <li>
                  {t(
                    prefix + '.learn.tips1',
                    'Visualize Peace on Earth in 2030 as a reality and how your actions helped create it. The more you visualize it, the deeper your belief becomes as you speak with others.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.tips2',
                    'Create a compelling personal story for why you played the Peace Game. As part of this, identify the benefits you got from playing.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.tips3',
                    'Speak with the courage of your conviction as you invite your fellow human beings to embrace this daring goal for humanity trusting that some are called and ready to play and some are not. You offer immense value to them through just knowing that people like you and such a noble aspiration exists on the planet.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.tips4',
                    'Create an enrollment goal, plan and timeline for reaching out to your network.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.tips5',
                    'These are the types of people who are good candidates to play the Peace Game:',
                  )}
                  <br />
                  <br />
                  <OrderedList type={1}>
                    <li>
                      {t(
                        prefix + '.learn.tips5a',
                        'People who trust you and will listen to your invitation.',
                      )}
                    </li>
                    <li>
                      {t(
                        prefix + '.learn.tips5b',
                        'People you have befriended in the Oneness action.',
                      )}
                    </li>
                    <li>
                      {t(
                        prefix + '.learn.tips5c',
                        'People that you have celebrated your common humanity with in the Unity action. ',
                      )}
                    </li>
                    <li>
                      {t(
                        prefix + '.learn.tips5d',
                        'People you cooperated with in the Cooperation action.',
                      )}
                    </li>
                    <li>
                      {t(
                        prefix + '.learn.tips5e',
                        'People and organizations you have contributed to in the Abundance action.',
                      )}
                    </li>
                    <li>
                      {t(
                        prefix + '.learn.tips5f',
                        'People who want to create peace in our world and are looking for a way to take action.',
                      )}
                    </li>
                    <li>
                      {t(
                        prefix + '.learn.tips5g',
                        'People who want to shift the conversation from polarity and cynicism to unity and positivity.',
                      )}
                    </li>
                    <li>
                      {t(
                        prefix + '.learn.tips5h',
                        'People who have organizations that would benefit from making the Peace Game available to their members.',
                      )}
                    </li>
                  </OrderedList>
                </li>
              </UnsortedList>
              <br />
              {t(
                prefix + '.learn.actionGuidesText3',
                'Make sure to do this action on your faith growing edge. This includes, for example, how much time you are willing to devote engaging people to play the Peace Game. Another growing edge may be developing confidence in yourself to invite someone to play the Peace Game. The power of the Peace Game comes when we multiply our impact by inviting others to play the game.',
              )}
            </p>
          }
        />

        <ActionContent
          title={t('word.faithOriginStory', 'Faith Origin Story')}
          content={
            <p>
              <div className={style.originImage}>
                <img
                  src={asset('/images/First-Earth-Run-collection-12.webp')}
                  alt="[1] Torch bearer and runners. East Berlin, Germany [2] UNICEF Goodwill Ambassador and celebrity, Tetsuko Kuroyanagi running with the torch. Nagasaki, Japan [3] Delegates at closing ceremony in UN General Assembly Hall describing how the torch brought inspiration to their countries. New York City, USA"
                />
                <p>
                  {t(
                    prefix + '.learn.originImageAlt1',
                    '[1] Torch bearer and runners. East Berlin, Germany [2] UNICEF Goodwill Ambassador and celebrity, Tetsuko Kuroyanagi running with the torch. Nagasaki, Japan [3] Delegates at closing ceremony in UN General Assembly Hall describing how the torch brought inspiration to their countries. New York City, USA',
                  )}
                </p>
              </div>
              {t(
                prefix + '.learn.originStory1',
                'As I took my tentative first steps to create the First Earth Run I encountered my share of cynics who said there was no way we could pull this off, and even if we did, the world was too far gone to make any difference. It was painful to experience such hopelessness. Fortunately, that was not the norm. Most people told me the First Earth Run gave them hope for the future and many wished to help.',
              )}
              <div className={style.originImage}>
                <img
                  src={asset('/images/70-nepal_torch-2.webp')}
                  alt="Prince Gyanendra lights an eternal flame at birth place of the Buddha. Lumbini, Nepal"
                />
                <p>
                  {t(
                    prefix + '.learn.originImageAlt2',
                    'Prince Gyanendra lights an eternal flame at birth place of the Buddha. Lumbini, Nepal',
                  )}
                </p>
              </div>
              {t(
                prefix + '.learn.originStory2',
                'They did so in numerous ways from organizing torch lighting ceremonies in their communities, to contributing financially, to praying for our success. They were DreamKeepers. They believed in our future and were willing to take action to bring it into existence.',
              )}
              <br />
              <br />
              {t(
                prefix + '.learn.originStory3',
                'The First Earth Run is a planetary scale teaching story of how what seemed impossible, slowly became possible as more and more people around the world started believing in it. How will we ever get the world to believe that humanity’s greatest aspiration—peace on earth—could be possible? It requires DreamKeepers. To activate DreamKeepers they need to see how their dream can manifest. This action invites you to become a keeper of this great dream for humankind and provides you a means to help bring it into reality.',
              )}
              <div className={style.originImage}>
                <img
                  src={asset('/images/71-lumbini-2.webp')}
                  alt="Inscription describing the installation of an eternal peace flame at the pilgrimage site of Buddha's birth place. Lumbini, Nepal"
                />
                <p>
                  {t(
                    prefix + '.learn.originImageAlt3',
                    "Inscription describing the installation of an eternal peace flame at the pilgrimage site of Buddha's birth place. Lumbini, Nepal",
                  )}
                </p>
              </div>
              <span className={style.bold}>
                {t(
                  prefix + '.learn.originStory5',
                  'Watch Good Morning America’s weekly series tell the story of the torch of peace circumnavigating the earth. Weeks 11 & 12 (Closing Remarks)',
                )}
              </span>
            </p>
          }
        />

        <Video
          src={`https://poe.earth/hardLink-action-7-learn-os_1`}
          // src={`https://poe.earth/hardLink-action-7-learn-os_1_${activeLanguage}`}
        />

        <ActionContent
          title={t(
            'word.faithFrequencyMeditation',
            'Faith Frequency Meditation',
          )}
          content={t(
            prefix + '.learn.frequencyMeditation',
            'The Faith Frequency Meditation by David Gershon, with harp accompaniment by Tami Briggs, is designed to help you play the Peace Game at a deeper level.',
          )}
        />

        <Video
          src={`https://poe.earth/hardLink-action-7-learn-fm_${activeLanguage}`}
        />

        <GoToButton
          text={t('word.goToPlan', 'Go to Plan')}
          onClick={() => {
            navigate(
              generatePath(ROUTES.peace_action.path, {
                id: topic.name.toLowerCase() + '?step=2',
              }),
            );
          }}
        />
      </div>
    </>
  );
};

export default FaithLearn;
