import React from 'react';
import './InfoMeetingGuide.scss';
import { default as style } from './InfoMeetingGuide.scss.json';
import { registerPackageModule, linkedComponent } from '../../../package';
import { useTranslate } from '@tolgee/react';
import { Video } from '../../atoms/Video';
import { UnsortedList } from '../../atoms/UnsortedList';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { OrderedList } from '../../atoms/OrderedList';
import Headline from '../../atoms/Headline';

export function InformationMeetingGuide() {
  let { t } = useTranslate();
  const navigate = useNavigate();

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'resource-informationMeetingGuide';
  return (
    <>
      <div className={style.content}>
        <Headline
          subtitle={t('word.introduction', 'Introduction')}
          lined="black"
        />
        <p>
          {t(
            prefix + '.paragraph01',
            'This Information Meeting Guide will provide you with everything you need to conduct a successful meeting. What allows this meeting to be successful is the synergy among the various elements. The Peace Game’s benefits are fleshed out, so people see the value for them to participate. A human connection is made with others based on the authenticity and depth of the sharing that takes place. People experience the group process, so they see the care and thought that goes into a meeting design. And they get to meet the actual people who will be taking the journey with them.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph02',
            'To make leading this meeting as easy as possible, there is a script that walks you through all the steps from pre-meeting planning to saying good-bye. This script, along with all the other meeting scripts in this game, evolved through an iterative learning process based on carefully debriefing people who led meetings. We discovered what worked and built on it; and we learned what needed improvement and made the necessary adjustments. We observed that with a script people who were not experienced facilitating a group meeting were quite effective. Those who had group facilitation experience were relieved to know that they did not need to invest time in designing the meeting.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph03',
            'While the primary purpose of the meeting is to form a Peace Team an important secondary purpose is the building of social capital. People you care about are getting to know one another and purposefully exploring how to make their world better.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph04',
            'The Information Meeting serves to onboard people to the Peace Game, so they know what it is and what is expected of them as a player. If someone is considering joining the Peace Team but cannot attend this meeting it is important to speak with the person afterwards to brief him or her on the game and the meeting outcomes using the information in this guide. If the person chooses to participate, he or she will then come to the following Start-Up Meeting prepared to fully engage. In the case where team members are friends and you have already onboarded them, use the meeting to prepare them for playing the game.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph05',
            'Note: If you choose to enroll team members one-on-one, please make sure to cover all the relevant information in this guide so they come to the Start-Up Meeting prepared. Otherwise, that meeting tends to devolve into an Information Meeting.',
          )}
        </p>
        <p>
          {t(
            prefix + '.paragraph06',
            'Let’s now begin with how to prepare for the meeting.',
          )}
        </p>
        <Headline
          subtitle={t(prefix + '.Pre-Meeting', 'Pre-Meeting Preparation')}
          lined="black"
        />
        <p>
          {t(
            prefix + '.paragraph07',
            'Note: You can do these meetings virtually using Zoom, Google Meet, and Facebook Messenger Rooms.',
          )}
        </p>
        <UnsortedList>
          <li>
            <u>{t(prefix + 'BulletLabel01', 'Scheduling the Meeting')}</u>
            {': '}
            {t(
              prefix + 'bullet01',
              "If you're hosting the meeting locally the best time to host it tends to be a weekday evening from 7 to 9 pm or Sunday afternoon. If it is a work, school based, or virtual team just find out what works for the majority of people. Those who can’t make it you can brief one-on-one.",
            )}
          </li>
          <li>
            <u>{t(prefix + 'BulletLabel02', 'Beginning and Ending Times')}</u>
            {': '}
            {t(
              prefix + 'bullet02',
              'Start no more than a few minutes late. If you know some people will be coming late, say so in your introduction. As a sign of respect for people’s schedules, be sure you end at the scheduled time unless the whole group agrees to extend the meeting.',
            )}
          </li>
          <li>
            <u>{t(prefix + 'BulletLabel03', 'Refreshments')}</u>
            {': '}
            {t(
              prefix + 'bullet03',
              'If the meeting is in your home or held locally provide a fifteen-minute window at the beginning and again at the end of the meeting for socializing. While it may seem obvious, it is important to serve light refreshments. This increases the experience of community building. It is also an interesting phenomenon of human nature how such social niceties strengthen our bonding with others.',
            )}
          </li>
          <li>
            <u>{t(prefix + 'BulletLabel04', 'Room Setup')}</u>
            {': '}
            {t(
              prefix + 'bullet04',
              'If meeting in person, arrange the room in a U shape with you at the front. This allows people to see one another and increases the sense of intimacy. It also makes it easier for others to see you.',
            )}
          </li>
          <li>
            <u>{t(prefix + 'BulletLabel05', 'Attendance')}</u>
            {': '}
            {t(
              prefix + 'bullet05',
              'If meeting in person create a sign-in sheet with room for the person’s name, address, e-mail, and phone number.',
            )}
          </li>
          <li>
            <u>{t(prefix + 'BulletLabel06', 'Name Tags')}</u>
            {': '}
            {t(
              prefix + 'bullet06',
              'If meeting in person, unless everyone already knows one another by name, provide name tags so people can immediately begin to connect. It is so much more gracious to address someone by his or her first name.',
            )}
          </li>
          <li>
            <u>{t(prefix + 'BulletLabel07', 'Paper and Pens')}</u>
            {': '}
            {t(
              prefix + 'bullet07',
              'There is a visioning exercise in which participants envision the benefits of participating in the Peace Game. If you are meeting in person, have pens and paper available for people to do the exercise.',
            )}
          </li>
          <li>
            <u>{t(prefix + 'BulletLabel08', 'Timing')}</u>
            {': '}
            {t(
              prefix + 'bullet08',
              'Each element in the meeting agenda has an approximate time associated with it. It is rare that any meeting element will go for exactly that amount of time, so use it as a guideline. If one meeting element runs longer, you will need to make up the time by shortening another.',
            )}
          </li>
          <li>
            <u>{t(prefix + 'BulletLabel09', 'Practice')}</u>
            {': '}
            {t(
              prefix + 'bullet09',
              'To get the full value from this script, take time to study it so you are fluent and understand what you are trying to achieve with each meeting element. Each is designed to achieve a distinct outcome described in a commentary after the meeting element.',
            )}
            <br></br>
            {t(
              prefix + 'bullet09a',
              'So that you can answer questions about the Peace Game, if you have access to the online platform read the “About” page at',
            )}{' '}
            <a href="https://peace2030.earth/about">
              {t(prefix + 'bullet09b', 'https://peace2030.earth/about')}
            </a>
            .
          </li>
        </UnsortedList>
        <Headline
          subtitle={t(
            'word.AgendaHeader',
            'Agenda Summary and Approximate Times',
          )}
          lined="black"
        />
        <OrderedList type={1}>
          <li>
            {t(prefix + 'AgendaItem01', 'Social Time')} - 15{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t(prefix + 'AgendaItem02', 'Welcome and Purpose')} - 5{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t(prefix + 'AgendaItem03', 'Review Agenda')} - 2{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t(prefix + 'AgendaItem04', 'Introductions')} - 15{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t(prefix + 'AgendaItem05', 'How the Peace Game Works')} - 10{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t(prefix + 'AgendaItem06', 'Visioning Exercise')} - 45{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t(prefix + 'AgendaItem07', 'Questions and Answers')} - 10{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t(prefix + 'AgendaItem08', 'Invite People to Join Team')} - 10{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t(prefix + 'AgendaItem09', 'Meeting Close')} - 3{' '}
            {t('word.mintues', 'minutes')}
          </li>
          <li>
            {t(
              prefix + 'AgendaItem10',
              'Post Meeting Logistics for Team Formation',
            )}{' '}
            - 10 {t('word.mintues', 'minutes')}
          </li>
        </OrderedList>
        <p>{t(prefix + '.paragraph10', 'Total meeting time - 2 hours')}</p>
        <Headline
          subtitle={t('word.meetingScript', 'Meeting Script')}
          lined="black"
        />
        <OrderedList type={1}>
          <li>
            <u>{t(prefix + 'AgendaItem01', 'Social Time')}</u> - 15{' '}
            {t('word.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + 'a1_bullet01',
                  'If meeting in person, this provides people an opportunity to meet one another and buffer time for any latecomers. This allows for connections to be made and starts building the foundation for the group to cohere.',
                )}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t(prefix + 'AgendaItem02', 'Welcome and Purpose')}</u> - 5{' '}
            {t('word.mintues', 'minutes')}{' '}
            <UnsortedList>
              <li>
                {t(
                  prefix + 'a2_bullet01',
                  'Introduce yourself and why you volunteered to initiate this Peace Team.',
                )}
                {<br></br>}
                {<br></br>}
                {t(
                  prefix + 'a2_bullet01a',
                  'The “why” is important as it allows others to know what motivated you to dedicate your time to this endeavor. As you prepare, take time to think about all the reasons you are doing this. Along with the inspiration it provides, it also models for others how to reach for a similar level of depth in what motivates them.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a2_bullet02',
                  'Thank everyone for coming and if meeting in person confirm that their names, e-mails, and phone numbers are on the sign-in sheet.',
                )}
              </li>
              <li>
                {t(prefix + 'a2_bullet03', 'Meeting Purpose')}
                {': '}
                <UnsortedList>
                  <li>
                    {t(
                      prefix + 'a2_bullet03a',
                      'To learn about the Peace Game.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a2_bullet03b',
                      'For those interested, to start a Peace Team.',
                    )}
                  </li>
                  <p></p>
                </UnsortedList>
                {t(
                  prefix + 'a2_bullet04',
                  'This provides an immediate signal that the meeting has relevant outcomes and begins assuaging any concerns they may have about whether this will be a worthwhile investment of their time.',
                )}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t(prefix + 'AgendaItem03', 'Review Agenda')}</u> - 2{' '}
            {t('word.mintues', 'minutes')}
            {<br></br>}
            {t(prefix + 'a3_bullet01', 'Review meeting agenda:')}
            <OrderedList type={'i'}>
              <li>{t(prefix + 'a3_bullet02', 'Introductions')}</li>
              <li>
                {t(prefix + 'a3_bullet03', 'Learn About the Peace Game.')}
              </li>
              <li>{t(prefix + 'a3_bullet04', 'Questions and Answers')}</li>
              <li>{t(prefix + 'a3_bullet05', 'Form a Peace Team')}</li>
            </OrderedList>
            {t(
              prefix + 'a3_bullet06',
              'Having a well-thought-out agenda allows people to begin developing trust in your skill as a meeting facilitator, and as important, that the game they are being invited to join is likely also well-conceived.',
            )}
          </li>
          <li>
            <u>{t(prefix + 'AgendaItem04', 'Introductions')}</u> - 15{' '}
            {t('word.mintues', 'minutes')}
            {<br></br>}
            {t(
              prefix + 'a4_bullet01',
              'Invite participants answer these four questions.',
            )}
            <OrderedList type={'i'}>
              <li>{t(prefix + 'a4_bullet02', 'Name')}</li>
              <li>{t(prefix + 'a4_bullet03', 'Where they live')}</li>
              <li>
                {t(
                  prefix + 'a4_bullet04',
                  'What motivated them to come and learn about the Peace Game',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a4_bullet05',
                  'What they would like to get out of the meeting',
                )}
              </li>
            </OrderedList>
            {t(
              prefix + 'a4_bullet06',
              'This seemingly innocuous part of the meeting is very important. This is the formal beginning of the relationship-building aspect of the Peace Game. Ultimately, it is the social connection that people have with one another that motivates them to take the leap and participate in a game that aspires to change the world.',
            )}
          </li>
          <li>
            <u>{t(prefix + 'AgendaItem05', 'How the Peace Game Works')}</u> - 10{' '}
            {t('word.mintues', 'minutes')}
            <OrderedList type={'i'}>
              <li>
                <u>{t(prefix + 'a5Bullet05', 'Seven Actions')}</u>
                {':'}
                <UnsortedList>
                  <li>
                    {t(
                      prefix + 'a5Bullet05a',
                      'The seven actions of the Peace Game — empowerment, oneness, unity, cooperation, abundance, love and faith — are transformational practices that when collectively applied evolve a person into an emanation of peace and everything around them into manifestations of peace.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a5Bullet05b',
                      'They are practices which mean their impact deepens as one invests in them over time. There is also a meditation associated with each action to help internalize it.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a5Bullet05c',
                      "The Peace Game is divided into four parts that represent the different facets of creating peace in one's life and world. Action 1 on Empowerment focuses on capacity building, Actions 2 to 4 on Oneness, Unity and Cooperation focus on peace building, Actions 5 and 6 on Abundance and Love focus on field building, and Action 7 on Faith focuses on movement building.",
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a5Bullet05d',
                      'At its essence the Peace Game is an empowerment platform enabling people to envision and create a life of peace for themselves and influence those in their world to do the same. At scale it can reweave the social fabric of a community.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a5Bullet05e',
                      'There are three levels of play based on effort, time, or both. You’re awarded a Bronze, Silver or Gold Peace Medal commensurate with your level of play. This is complemented by the number of Peace Actions associated with your effort.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a5Bullet05f',
                      'Playing the Peace Game empowers, enlightens and transforms those players and communities who wholeheartedly engage with it.',
                    )}
                  </li>
                </UnsortedList>
              </li>
              <li>
                <u>{t(prefix + 'a5Bullet06', 'Game Structure')}</u>
                {':'}
                <UnsortedList>
                  <li>
                    <u>{t(prefix + 'a5Bullet06ai', 'Peace Team')}</u>
                    {': '}
                    {t(
                      prefix + 'a5Bullet06a',
                      'You play as part of a 5-8 person Peace Team which meets in person or on Zoom biweekly and serves as a support system.',
                    )}
                  </li>
                  <li>
                    <u>{t(prefix + 'a5Bullet06bi', 'Time Commitment')}</u>
                    {': '}
                    {t(
                      prefix + 'a5Bullet06b',
                      'The time commitment is 2-hours every two weeks to attend the meetings and approximately 2-hours to take the action.',
                    )}
                  </li>
                  <li>
                    <u>{t(prefix + 'a5Bullet06ci', 'Peace Game App')}</u>
                    {': '}
                    {t(
                      prefix + 'a5Bullet06c',
                      'You use a Peace Game app to take the actions.',
                    )}
                  </li>
                </UnsortedList>
              </li>
            </OrderedList>
          </li>
          <li>
            <u>{t(prefix + 'AgendaItem06', 'Visioning Exercise')}</u> - 45{' '}
            {t('word.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + 'a6Bullet06a',
                  'The purpose of this exercise is to inspire and empower people to participate in the Peace Game so that they can bring these extraordinary possibilities into their lives, community and the world.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a6Bullet06b',
                  'Explain to the group that we are now going to do a visioning exercise to help you understand what is possible for you and the world by playing this game. It is designed to expand your imagination, stimulate your social creativity, and envision the possibility of a radically different future for the world.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a6Bullet06c',
                  'From an empowerment point of view what motivates us to engage in something new is a compelling vision of possibility. The stronger it is, the more powerfully it pulls us toward its realization. These questions will help you build your vision as someone with the capacity to help create peace on earth.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a6Bullet06d',
                  'Provide them up to 15 minutes to answer the questions.',
                )}
                <OrderedList type={1}>
                  <li>
                    {t(
                      prefix + 'a6Bullet06e',
                      'What motivated you to consider playing the Peace Game?',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a6Bullet06f',
                      'What learning and growth would you like from playing the Peace Game?',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a6Bullet06g',
                      'What impact would you like to have in the community and world from playing the Peace Game?',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a6Bullet06h',
                      'What do you bring to the Peace Game?',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a6Bullet06i',
                      'What challenges – inner or outer – might you face in playing the Peace Game and how will you overcome them?',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a6Bullet06j',
                      'What is your vision for Peace on Earth?',
                    )}
                  </li>
                </OrderedList>
                <p>
                  {t(
                    prefix + 'a6Bullet06',
                    'Have each person briefly share their answers. – 30 minutes',
                  )}
                </p>
              </li>
              <li>
                {t(
                  prefix + 'a6Bullet06k',
                  'If a small group, divide the number of people on the team into 30 minutes to determine the per person time. i.e., 30 minutes divided by 6 = 5 minutes per person. You can also go question by question.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a6Bullet06l',
                  'If a lot of people invite a few people to share per question.',
                )}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t(prefix + 'AgendaItem07', 'Questions and Answers')}</u> - 10{' '}
            {t(prefix + 'mintues', 'minutes')}
            <p>
              {t(
                prefix + '.a7Bullet07',
                'Invite people to ask any questions about the game and their participation. Draw out any concerns that individuals might have about participation. The biggest concern for people, if there is one, is that they do not have enough time. The basic time commitment is 36 hours—9 bi-weekly 2-hour meetings plus approximately 2 hours for taking actions per topic, or 2 hours per week over 4 months. So, in fact, this is not really an issue, as there are very few people who do not have this amount of discretionary time. And even fewer can get this quality-of-life improvement and contribution to the world for a one-off time investment in comparison with other ways they could spend their time.',
              )}
            </p>
          </li>
          <li>
            <u>{t(prefix + 'AgendaItem08', 'Invite People to Join Team')}</u> -
            10 {t('word.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + 'a8Bullet08a',
                  'In some cases, this meeting will be with people who are already committed, and this serves as a briefing, so they come into The Game knowledgeable about how to play.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a8Bullet08b',
                  'In other cases, this meeting will be with people you don’t know well, and it serves as a means to enroll them into the Peace Game. If the latter, the following will help you do that.',
                )}
                <OrderedList type={1}>
                  <li>
                    {t(
                      prefix + 'a8Bullet08c',
                      'Ask for a show of hands of who would like to participate in playing The Game. Acknowledge those who raise their hands and draw out any concerns from those who didn’t.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a8Bullet08d',
                      'A simple technique is to raise your own hand to indicate the desired action as this provides a visual cue for others to follow. Most people will raise their hands. But some may not for various reasons. Often the issue holding them back is easily resolved but was not addressed in the question-and-answer period. This is why it is so important to bring everyone to choice. Making a conscious and intentional choice in the presence of others is the first step of building a high-performing team.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a8Bullet08e',
                      'To be clear, the purpose here is not to cajole people to participate if they are not inclined to, but rather not to let them fall through the cracks for lack of reaching out. They would very likely not have attended the Information Meeting if they were not predisposed to participate. So, do make the effort to draw out their concerns. Often the group itself comes up with solutions to the issue and this conversation turns into a team-building exercise.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a8Bullet08f',
                      'Propose a meeting time a week later for the Start-Up Meeting. Adjust the time if needed to accommodate people’s schedule.',
                    )}
                  </li>
                  <li>
                    {t(
                      prefix + 'a8Bullet08g',
                      'For those not wishing to participate at this time, let them know that they are welcome to join a future team.',
                    )}
                  </li>
                </OrderedList>
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>{t(prefix + 'AgendaItem09', 'Meeting Close')}</u> - 3{' '}
            {t('word.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + 'a9Bullet09a',
                  'Mention the values that have been furthered to the benefit of everyone and congratulate the participants for their commitment to improving their quality of life and that of the world.',
                )}
              </li>
            </UnsortedList>
          </li>
          <li>
            <u>
              {t(
                prefix + 'AgendaItem10',
                'Post Meeting Logistics for Team Formation',
              )}
            </u>{' '}
            - 10 {t('word.mintues', 'minutes')}
            <UnsortedList>
              <li>
                {t(
                  prefix + 'a10Bullet10a',
                  'Set dates and times for the nine Peace Game 2-hour-meetings. This can sometimes require a bit of give-and-take, which itself is an important skill to develop in building a team. Recommend that the Start-Up Meeting take place in the following week, but no later than 2 weeks. Otherwise the team loses momentum.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a10Bullet10b',
                  'If there are more than eight people (you can squeeze in up to ten) you will need to divide the group into multiple teams. If local, everyone can meet at one location for a potluck dinner and the team-building meeting—space permitting—and then divide up into sub-groups. If this is not feasible, you will need to divide up the group into separate teams and identify someone to serve as Team Leader of the other team.',
                )}
              </li>
              <li>
                {t(
                  prefix + 'a10Bullet10c',
                  'Guide them to download the Peace Game app by typing in one of the following links into their device web browser: ',
                )}
                {<br></br>}
                {<br></br>}
                {t(prefix + 'a10Bullet10e', 'Phone/Tablet App')}
                {': '}
                <a href="https://poe.earth/app">
                  {t(prefix + 'a10Bullet10d', 'poe.earth/app')}
                </a>
                {<br></br>}
                {t(prefix + 'a10Bullet10f', 'Website')}
                {': '}
                <a href="https://www.peacegame.earth">
                  {t(prefix + 'a10Bullet10d', 'www.peacegame.earth')}
                </a>
              </li>
              <li>
                {t(
                  prefix + 'a10Bullet10d',
                  'All Team Leaders need to log into the Team section of the Peace Game App/Website and put in the days and times of the team meetings. Have players also put these days and times in their regular calendars. This grounds the team and allows everyone to move forward with clarity.',
                )}
              </li>
            </UnsortedList>
          </li>
        </OrderedList>
      </div>
    </>
  );
}
