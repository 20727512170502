import React, { useEffect, useState } from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import './Profile.scss';
import style from './Profile.scss.json';
import Header from '../components/molecules/Header';
import { EditProfileInput } from '../components/molecules/EditProfileInput';
import MainMenu from '../components/molecules/MainMenu';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import Card from '../components/molecules/Card';
import { ImageObject } from 'lincd-schema/lib/shapes/ImageObject';
import { TopicScore } from 'lincd-irlcg/lib/shapes/TopicScore';
import { Topic } from 'lincd-irlcg/lib/shapes/Topic';
import { UserAccount } from 'lincd.org/modules/profile-plus/lib/shapes/UserAccount';
import GoToButton from '../components/atoms/GoToButton';
import SubMenu from '../components/molecules/SubMenu';
import { Container } from '../layout/Container';
import Section from '../components/molecules/Section';
import { PostalAddressInputField } from '../components/atoms/PostalAddressInputField';
import { ProfileViewText } from '../components/molecules/ProfileViewText';
import { Button } from 'lincd-mui-base/lib/components/Button';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { Team } from 'lincd-irlcg/lib/shapes/Team';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../routes';
import { useTranslate } from '@tolgee/react';
import TeamSelect from '../components/atoms/TeamSelect';
import { ShapeSet } from 'lincd/lib/collections/ShapeSet';

function Profile() {
  const auth = useAuth<Player, UserAccount>();
  const [userTotals, setUserTotals] = useState<any>();
  const userAccount = auth.userAccount;
  const user = userAccount.accountOf as Player;
  const navigate = useNavigate();
  const [userIsTeamLeader, setUserIsTeamLeader] = useState(false);

  const { t } = useTranslate();
  let prefix = 'profile';

  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [userTeams, setUserTeams] = useState<ShapeSet<Team>>(null);

  useEffect(() => {
    TopicScore.getMyTopicTotals(user, true)
      .then((result) => {
        setUserTotals(result);
      })
      .catch((error) => {
        console.log(error);
      });
    Team.getUserTeams().then((response) => {
      if (response) {
        setUserTeams(response);
      }
    });
    Team.getTeamLeader().then((response) => {
      if (response) {
        setUserIsTeamLeader(true);
      }
    });
  }, []);

  const startTeam = () => {
    userIsTeamLeader
      ? alert(t(prefix + '.oneTeamMessage', 'You can only create one team.'))
      : navigate(
          generatePath(ROUTES.resource_detail.path, {
            id: 'register-your-peace-team',
          }),
        );
  };

  const renderCard = (topic: Topic, score: number, medal: any) => {
    return (
      <Card
        key={topic.identifier.toString()}
        identifier={'Action' + topic.identifier}
        title={topic.name}
        description={topic.description}
        medal={medal}
        value={score}
        image={ImageObject.create({ contentUrl: topic.image.contentUrl })}
      />
    );
  };

  return (
    <DefaultLayout>
      <Header
        title={t(prefix + '.title', 'Profile')}
        inverted={true}
        fixed={true}
      />
      <div className={style.mainContainer}>
        <Header
          title={t(prefix + '.myProfile', 'My Profile')}
          inverted={false}
          className={style.scrollingHeader}
        />
        <section className={style.profileFormSection}>
          <Container maxWidth={'sm'}>
            {showEditForm ? (
              <EditProfileForm onEditForm={setShowEditForm} />
            ) : (
              <ViewProfile onEditForm={setShowEditForm} />
            )}
          </Container>
        </section>
        <Header
          title={t(prefix + '.currentTeam', 'Current Team')}
          inverted={false}
          className={style.scrollingHeader}
        />
        <section className={style.switchTeamSection}>
          <Container maxWidth={'sm'}>
            <div className={style.inputWrapper}>
              <p className={style.title}>
                {t(
                  prefix + '.currentlyPlayingUnder',
                  'Currently Playing Under',
                )}
              </p>
              <TeamSelect className={style.teamSelect} />
            </div>
            <div className={style.inputWrapper}>
              <p className={style.title}>
                {t(prefix + '.joinNewTeam', 'Join New Team')}
              </p>
              <div className={style.buttonWrapper}>
                <Button
                  className={style.teamActionButton}
                  variant="outlined"
                  onClick={() =>
                    navigate(ROUTES.join_team.path, {
                      state: { additionalParam: 'hide-step-title' },
                    })
                  }
                  aria-label={t(prefix + '.joinTeamButton', 'Join Team')}
                >
                  {t(prefix + '.joinTeamButton', 'Join Team')}
                </Button>
                <Button
                  className={style.teamActionButton}
                  variant="outlined"
                  onClick={startTeam}
                  aria-label={t(prefix + '.startTeamButton', 'Start Team')}
                >
                  {t(prefix + '.startTeamButton', 'Start Team')}
                </Button>
              </div>
            </div>
          </Container>
        </section>
        {userTotals && (
          <section className={style.peaceActionSection}>
            <Container maxWidth="sm">
              <div className={style.medalContainer}>
                <Section
                  title={t(
                    prefix + '.totalPeaceActions',
                    'Total Peace Actions',
                  )}
                  data={userTotals.myTopicTotals}
                  medalion={userTotals.myMedalTopic}
                  renderCardFunction={renderCard}
                  headlineStyle={style.resetHeadline}
                />
              </div>
            </Container>
          </section>
        )}
        <SubMenu />
        <MainMenu />
      </div>
    </DefaultLayout>
  );
}

export default Profile;

type EditProfileFormType = {
  onEditForm: (value) => void;
};

const EditProfileForm = ({ onEditForm }: EditProfileFormType) => {
  const auth = useAuth<Player, UserAccount>();
  const user = auth.user;
  const account = auth.userAccount;
  const { t } = useTranslate();
  let prefix = 'profile';

  const onEditAction = () => {
    onEditForm(false);
  };

  return (
    <div>
      {!account.avatar ? (
        <>
          <EditProfileInput
            type="avatar"
            title={t(prefix + '.photo', 'Profile Photo')}
            of={user}
            valueProperty="avatar"
            aria-label={t(prefix + '.photo', 'Profile Photo')}
          />
        </>
      ) : (
        <img src={account.avatar} alt={t(prefix + '.photo', 'Profile Photo')} />
      )}

      <EditProfileInput
        type="text"
        title={t(prefix + '.firstName', 'First Name')}
        of={user}
        valueProperty="givenName"
        aria-label={t(prefix + '.firstName', 'First Name')}
      />

      <EditProfileInput
        type="text"
        title={t(prefix + '.lastName', 'Last Name')}
        of={user}
        valueProperty="familyName"
        aria-label={t(prefix + '.lastName', 'Last Name')}
      />

      <EditProfileInput
        type="tel"
        title={t(prefix + '.phoneNumber', 'Phone Number')}
        of={user}
        valueProperty="telephone"
        aria-label={t(prefix + '.phoneNumber', 'Phone Number')}
      />

      <EditProfileInput
        type="text"
        title={t(prefix + '.city', 'City')}
        of={user}
        valueProperty="areaServed"
        aria-label={t(prefix + '.city', 'City')}
      />
      <EditProfileInput
        type="text"
        title={t(prefix + '.zipCode', 'Zip Code')}
        of={user['address']}
        valueProperty="postalCode"
        aria-label={t(prefix + '.zipCode', 'Zip Code')}
      />

      <GoToButton
        fullWidth={true}
        color="primary"
        onClick={onEditAction}
        text={t(prefix + '.save', 'Save')}
        aria-label={t(prefix + '.save', 'Save')}
      />
    </div>
  );
};

type ViewProfileType = {
  onEditForm: (value) => void;
};

const ViewProfile = ({ onEditForm }: ViewProfileType) => {
  const auth = useAuth<Player, UserAccount>();
  const user = auth.user;
  const { t } = useTranslate();
  let prefix = 'profile';

  return (
    <div className={style.subContainer}>
      <EditProfileInput
        type="avatar"
        title={t(prefix + '.photo', 'Profile Photo')}
        of={user}
        valueProperty="avatar"
        aria-label={t(prefix + '.photo', 'Profile Photo')}
      />
      <ProfileViewText
        of={user}
        valueProperty="givenName"
        title={t(prefix + '.firstName', 'First Name')}
        onEditForm={onEditForm}
        aria-label={t(prefix + '.firstName', 'First Name')}
      />
      <ProfileViewText
        of={user}
        valueProperty="familyName"
        title={t(prefix + '.lastName', 'Last Name')}
        onEditForm={onEditForm}
        aria-label={t(prefix + '.lastName', 'Last Name')}
      />
      <ProfileViewText
        of={user}
        valueProperty="telephone"
        title={t(prefix + '.phoneNumber', 'Phone Number')}
        onEditForm={onEditForm}
        aria-label={t(prefix + '.phoneNumber', 'Phone Number')}
      />
      <ProfileViewText
        of={user}
        valueProperty="areaServed"
        title={t(prefix + '.city', 'City')}
        onEditForm={onEditForm}
        aria-label={t(prefix + '.city', 'City')}
      />
      <PostalAddressInputField
        of={user['address']}
        title={t(prefix + '.zipCode', 'Zip Code')}
        type="view"
        onEditForm={onEditForm}
        aria-label={t(prefix + '.zipCode', 'Zip Code')}
      />
    </div>
  );
};
