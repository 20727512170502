import React from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import './TeamAction.scss';
import style from './TeamAction.scss.json';
import { Button } from 'lincd-mui-base/lib/components/Button';
import { ROUTES } from '../routes';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { Person } from 'profile-plus/lib/shapes/Person';
import { UserAccount } from 'profile-plus/lib/shapes/UserAccount';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

function TeamAction() {
  const navigate = useNavigate();
  const auth = useAuth<Player, UserAccount>();

  return (
    <DefaultLayout>
      <div className={style.TeamActionContainer}>
        <div className={style.Title}>
          <img src={asset('/images/the-game-logo.png')} alt="the-game-icon" />
          <h2>Peace Game</h2>
        </div>
        <div className={style.JoinTeam}>
          <img src={asset('/images/TeamAction.webp')} alt="team action" />
          <p>If you have been invited to join a team click below:</p>
          <Button
            fullWidth={true}
            variant="outlined"
            onClick={() =>
              navigate(ROUTES.register.path, { state: { from: 'Join Team' } })
            }
          >
            <h3>Join a Team</h3>
          </Button>
        </div>
        <div className={style.JoinTeam}>
          <img src={asset('/images/TeamAction.webp')} alt="team action" />
          <p>If you wish to start a team click below:</p>
          <Button
            fullWidth={true}
            variant="outlined"
            onClick={() =>
              navigate(ROUTES.register.path, { state: { from: 'Start Team' } })
            }
          >
            <h3>Start a Team</h3>
          </Button>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default TeamAction;
