import React from 'react';
import ShareActionDetails from '../../molecules/ShareActionDetails';
import { topic2, topic3 } from '../../../data-references';

const OnenessShare = () => {
  let topic = topic2;
  return (
    <ShareActionDetails
      topic={topic}
      currentTopic={topic2}
      nextTopic={topic3}
    />
  );
};

export default OnenessShare;
