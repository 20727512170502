import React, { useState } from 'react';
import Headline from '../../atoms/Headline';
import style from './1_EmpowermentPlan.scss.json';
import './1_EmpowermentPlan.scss';
import GoToButton from '../../atoms/GoToButton';
import { Medal } from '../../molecules/Medal';
import { useTranslate } from '@tolgee/react';
import { topic3 } from '../../../data-references';
import ActionContent from '../../molecules/ActionContent';
import ActionPlan from '../../molecules/ActionPlan';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import {
  BronzeGoal,
  GoldGoal,
  SetYourGoal,
  SilverGoal,
} from '../../molecules/SetYourGoal';
import { PlanPurpose } from '../../molecules/PlanPurpose';

const UnityPlan = () => {
  let topic = topic3;
  const navigate = useNavigate();
  let { t } = useTranslate();
  const [actionPlanTime, setActionPlanTime] = useState(0);

  const handleTimeSelected = (minutes) => {
    setActionPlanTime(minutes);
  };

  let prefix = 'topic' + topic.identifier;
  return (
    <>
      <div className={style.content}>
        <PlanPurpose topic={topic} />

        <ActionContent
          title={t('.word.gamePlay', 'Gameplay')}
          subtitle={t('word.howToKeepScore', 'How to Keep Score')}
          content={
            <>
              <p>
                <p>
                  <span className={style.bold}>
                    {t('word.peaceMedals', 'Peace Medals')}:{' '}
                  </span>
                  {t(
                    prefix + '.plan.peaceMedalsText',
                    'Although you only get one peace medal based on the type of effort required, you will be recognized for all of the peace actions you take.',
                  )}
                </p>
                <p>
                  <span className={style.underline}>
                    {t('word.bronzePeaceMedal', 'Bronze Peace Medal')}
                  </span>
                  :{' '}
                  {t(
                    prefix + '.plan.bronzePeaceMedalText1',
                    'Participate only',
                  )}{' '}
                  <span className={style.blue}>
                    ({t(prefix + '.plan.bronzePeaceAction', '1 Peace Action')})
                  </span>
                </p>
                <p>
                  <span className={style.underline}>
                    {t('word.silverPeaceMedal', 'Silver Peace Medal')}
                  </span>
                  :{' '}
                  {t(
                    prefix + '.plan.silverPeaceMedalText1',
                    'Invite and Participate',
                  )}{' '}
                  <span className={style.blue}>
                    {' '}
                    ({t(prefix + '.plan.silverPeaceAction', '2 Peace Actions')})
                  </span>
                </p>
                <p>
                  <span className={style.underline}>
                    {t('word.goldPeaceMedal', 'Gold Peace Medal')}
                  </span>
                  :{' '}
                  {t(
                    prefix + '.plan.goldPeaceMedalText1',
                    'Create, Invite, and Participate',
                  )}{' '}
                  <span className={style.blue}>
                    (
                    {t(
                      prefix + '.plan.goldPeaceAction',
                      '3 Peace Actions per event',
                    )}
                    )
                  </span>
                </p>
                <p>
                  <span className={style.blue}>
                    {t('word.example', 'Example')}
                    {': '}
                  </span>
                  {t(
                    prefix + '.plan.exampleText',
                    'If you create a unitive event (3 peace actions), participate in another unitive event and invite friends (2 peace actions), and participate in 4 more unitive events on your own (4 peace actions). This would represent 9 peace actions.',
                  )}
                </p>
              </p>
            </>
          }
        />

        <SetYourGoal topic={topic} onTimeSelected={handleTimeSelected}>
          <BronzeGoal
            actions={1}
            minutes={60}
            average={true}
            text={t(
              prefix + '.goal.bronze',
              'Participate in a unitive experience in which people discover their common humanity.',
            )}
          />
          <SilverGoal
            actions={2}
            minutes={90}
            average={true}
            text={t(
              prefix + '.goal.silver',
              'Invite and Participate in a unitive experience in which people discover their common humanity.',
            )}
          />
          <GoldGoal
            actions={3}
            minutes={120}
            average={true}
            text={t(
              prefix + '.goal.gold',
              'Create, Invite, and Participate in a unitive experience in which people discover their common humanity.',
            )}
          />
        </SetYourGoal>

        <Headline
          subtitle={t(
            'word.createPeaceActionPlan',
            'Create Your Peace Action Plan',
          )}
          lined="black"
        />
        <ActionPlan action="unity" topic={topic} time={actionPlanTime} />

        <GoToButton
          text={t('word.goToShare', 'Go to Share')}
          aria-label={t('word.goToShare', 'Go to Share')}
          onClick={() => {
            navigate(
              generatePath(ROUTES.peace_action.path, {
                id: topic.name.toLowerCase() + '?step=3',
              }),
            );
          }}
        />
      </div>
    </>
  );
};

export default UnityPlan;
