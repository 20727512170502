import React from 'react';
import Headline from '../atoms/Headline';
import { Topic } from 'lincd-irlcg/lib/shapes/Topic';
import { useTranslate } from '@tolgee/react';
import './PlanPurpose.scss';
import style from './PlanPurpose.scss.json';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router-dom';
import { ROUTES } from '../../routes';

interface PlanPurposeProps {
  topic?: Topic;
  purposeText?: any;
  actionGuidanceText?: any;
}

export const PlanPurpose = ({
  topic,
  purposeText,
  actionGuidanceText,
}: PlanPurposeProps) => {
  let { t } = useTranslate();
  return (
    <>
      <p>
        <span className={style.blue}>{t('word.purpose', 'Purpose')}: </span>
        {t(
          'plan.purposeText',
          'Set your goal and create your peace action plan to achieve it.',
        )}
      </p>
      <p>
        <span className={style.blue}> {t('word.pace', 'Pace')}: </span>
        {t(
          'plan.paceText',
          'Depending on your level of play, this action is usually accomplished in one to three weeks.',
        )}
      </p>
    </>
  );
};
