import { cl } from 'lincd/lib/utils/ClassNames';
import './Spinner.scss';
import * as style from './Spinner.scss.json';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

interface SpinnerProps {
  active?: boolean;
  centered?: boolean;
  color?: 'white' | 'grey' | 'blue';
}
export function Spinner({
  active = true,
  centered = false,
  color = 'grey',
}: SpinnerProps) {
  // get the color of the spinner
  let source: string = '';
  switch (color) {
    case 'white':
      source = 'white';
      break;
    case 'grey':
      source = 'gray';
      break;
    case 'blue':
      source = 'blue';
      break;
    default:
      source = 'gray';
      break;
  }

  return (
    <div
      className={cl(style.spinner, centered && style.centered)}
      role="progressbar"
      aria-busy={active ? 'true' : 'false'}
    >
      <img
        src={asset(`/images/icons/spinner-${source}.svg`)}
        alt="loading..."
      />
    </div>
  );
}
