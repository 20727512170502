import React from 'react';
import ShareActionDetails from '../../molecules/ShareActionDetails';
import { topic3, topic4 } from '../../../data-references';

const UnityShare = () => {
  let topic = topic3;

  return (
    <ShareActionDetails
      topic={topic}
      currentTopic={topic3}
      nextTopic={topic4}
    />
  );
};

export default UnityShare;
