import React from 'react';
import style from './4_CooperationLearn.scss.json';
import './4_CooperationLearn.scss';
import GoToButton from '../../atoms/GoToButton';
import ActionContent from '../../molecules/ActionContent';
import { useTranslate } from '@tolgee/react';
import { topic4 } from '../../../data-references';
import { UnsortedList } from '../../atoms/UnsortedList';
import { Video } from '../../atoms/Video';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { ActionPurpose } from '../../molecules/ActionPurpose';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { getActiveLanguage } from '../../../utils/tolgee';

const CooperationLearn = () => {
  let topic = topic4;
  let { t } = useTranslate();
  const navigate = useNavigate();
  const activeLanguage = getActiveLanguage();

  //with t(key,defaultValue) the key will show up on in the dev context app (hover text and hold Option) and can be added and translated in tolgee.
  let prefix = 'topic' + topic.identifier;
  return (
    <>
      <div className={style.content}>
        <ActionPurpose
          topic={topic}
          purposeText={t(
            prefix + '.learn.purposeText',
            'The Cooperation action is to work with one or more people to create an outcome for the greater good.',
          )}
          actionGuidanceText={
            <p>
              {t(
                prefix + '.learn.actionGuidesText1',
                'Your life is the game. Organize this action around it. Look for individuals, groups or organizations you are associated with and ask: “What benefits might be achieved if people cooperated for the common good?” Most groups or people don’t think in these ways but when offered an opportunity see the value and tend to seize it.',
              )}
              <br />
              <br />
              {t(
                prefix + '.learn.actionGuidesText2',
                `This action requires us to determine what that greater good is and what cooperative action(s) can enable the individuals or groups involved to achieve it. It is a creative act to merge strengths and talents and enable a greater good to come from them. `,
              )}
              <br />
              <br />
              {t(
                prefix + '.learn.actionGuidesText3',
                `Opportunities can include:`,
              )}
              <br />
              <br />
              <UnsortedList>
                <li>
                  {t(
                    prefix + '.learn.opportunities1',
                    'People at your work where there is an opportunity to cooperate or cooperate better to achieve a common good.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.opportunities2',
                    'Organizations you deal with at work where there is an opportunity to cooperate or cooperate better to achieve a common good.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.opportunities3',
                    'Groups or organizations you interact with in a community or social change context where you could achieve more social good if you cooperated or cooperated better.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.opportunities4',
                    'Neighbors you could cooperate with to address a neighborhood issue and/or improve the neighborhood through cooperation actions such as tool sharing or carpooling. ',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.opportunities5',
                    'Family members with whom you could cooperate on a project or  cooperate better to achieve a common good.',
                  )}
                </li>
                <li>
                  {t(
                    prefix + '.learn.opportunities6',
                    'Friends with whom you could cooperate on a project or cooperate better to achieve a common good.',
                  )}
                </li>
              </UnsortedList>
              <br />
              {t(
                prefix + '.learn.actionGuidesText4',
                'Make sure to do this action on your Cooperation growing edge. If you have done personal development work, take this to the next level by discovering and working on your growing edges in each of the areas of your life.',
              )}
            </p>
          }
        />

        <ActionContent
          title={t('word.cooperationOriginStory', 'Cooperation Origin Story')}
          content={
            <p>
              <div className={style.originImage}>
                <img
                  src={asset('/images/First-Earth-Run-collection-5.webp')}
                  alt="[1] David Gershon and Russian Olympic Runner Lioudmila Bragina interviewed by a reporter. Moscow, Russia [2] Torch runner and flag holder on snowy afternoon. Hokkaido, Japan [3] Global runner, Phil Wiengarner fueling the minersU+0027 lamps that maintain integrity of peace flame."
                />
                <p>
                  {t(
                    prefix + '.learn.originImageAlt1',
                    '[1] David Gershon and Russian Olympic Runner Lioudmila Bragina interviewed by a reporter. Moscow, Russia [2] Torch runner and flag holder on snowy afternoon. Hokkaido, Japan [3] Global runner, Phil Wiengarner fueling the minersU+0027 lamps that maintain integrity of peace flame.',
                  )}
                </p>
              </div>
              {t(
                prefix + '.learn.originStory1',
                'Peace on earth is about more than avoiding war; it is achieved through the active skills of cooperation. Otherwise, our differences can devolve into unhealthy conflict. Further, to create the world we want requires us to learn how to cooperate for the common good. To catalyze this skill set during the First Earth Run, we honored the groups inventing cooperative solutions for the common good in each community we visited. We shed light on these initiatives to increase the community’s collective intelligence around the often unheralded, but hugely important, skillset of cooperation.',
              )}
              <div className={style.originImage}>
                <img
                  src={asset('/images/First-Earth-Run-collection-6.webp')}
                  alt="[1] Prince Khalifa bin Salman Al Khalifa lighting the torch with Gail Straub in Bahrain [2] Young boy with UNICEF and Chinese Flags at Great Wall of China ceremony. [3] Runner with children. Nairobi, Kenya [4] President Daniel Moi receives the torch personally at Nairobi International Airport. Kenya"
                />
                <p>
                  {t(
                    prefix + '.learn.originImageAlt2',
                    '[1] Prince Khalifa bin Salman Al Khalifa lighting the torch with Gail Straub in Bahrain [2] Young boy with UNICEF and Chinese Flags at Great Wall of China ceremony. [3] Runner with children. Nairobi, Kenya [4] President Daniel Moi receives the torch personally at Nairobi International Airport. Kenya',
                  )}
                </p>
              </div>
              {t(
                prefix + '.learn.originStory2',
                'They ranged from women in a West African village hand-building a dam to irrigate the farms of their community; to mothers in a drug-ridden inner-city Philadelphia neighborhood turning their homes into recreation facilities and safe havens for their sons; to honoring the cease fire between Nicaraguan President, Daniel Ortega and the head of the rebel Contras who had their children carry the torch of peace together through the capital city of Managua at the height of their civil war.',
              )}
              <div className={style.originImage}>
                <img
                  src={asset('/images/First-Earth-Run-collection-7.webp')}
                  alt="[1] Arab and Jewish children from the Neva Shalom community carry the torch of peace together on the way to Jerusalem. Israel [2] President Corazon Aquino with torch after delivering a stirring speech about the need for world cooperation to an estimated crowd of 500,000 people. Manila, Philippines [3] Peace candles being lit from the torch. Amsterdam, Netherlands"
                />
                <p>
                  {t(
                    prefix + '.learn.originImageAlt3',
                    '[1] Arab and Jewish children from the Neva Shalom community carry the torch of peace together on the way to Jerusalem. Israel [2] President Corazon Aquino with torch after delivering a stirring speech about the need for world cooperation to an estimated crowd of 500,000 people. Manila, Philippines [3] Peace candles being lit from the torch. Amsterdam, Netherlands',
                  )}
                </p>
              </div>
              {t(
                prefix + '.learn.originStory3',
                'Each required vision, determination, and cooperation skills. What it took to succeed was rarely known by anyone other than those directly involved. Turning this hard-won knowledge into a community, national and planetary legacy was one of the First Earth Run’s greatest sources of satisfaction. This action allows you to do the same for your life and community.',
              )}
              <br />
              <br />
              <span className={style.bold}>
                {t(
                  prefix + '.learn.originStory5',
                  'Watch Good Morning America’s weekly series tell the story of the torch of peace circumnavigating the earth. Weeks 2, 3 & 4',
                )}
              </span>
            </p>
          }
        />

        <Video
          src={`https://poe.earth/hardLink-action-4-learn-os_1`}
          // src={`https://poe.earth/hardLink-action-4-learn-os_1_${activeLanguage}`}
        />

        <ActionContent
          title={t(
            'word.cooperationFrequencyMeditation',
            'Cooperation Frequency Meditation',
          )}
          content={t(
            prefix + '.learn.frequencyMeditation',
            'The Cooperation Frequency Meditation by David Gershon, with harp accompaniment by Tami Briggs, is designed to help you play  the Peace Game at a deeper level.',
          )}
        />

        <Video
          src={`https://poe.earth/hardLink-action-4-learn-fm_${activeLanguage}`}
        />

        <GoToButton
          text={t('word.goToPlan', 'Go to Plan')}
          onClick={() => {
            navigate(
              generatePath(ROUTES.peace_action.path, {
                id: topic.name.toLowerCase() + '?step=2',
              }),
            );
          }}
        />
      </div>
    </>
  );
};

export default CooperationLearn;
