import React from 'react';
import { Modal } from 'lincd-mui-base/lib/components/Modal';
import { Button } from 'lincd-mui-base/lib/components/Button';
import './AlertModal.scss';
import style from './AlertModal.scss.json';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '@tolgee/react';

interface AlertModalProps {
  isOpen: boolean;
  onClose: () => void;
  confirmationText?: string;
  agreeText?: string;
  onAgree: () => void | string;
}

const AlertModal = ({
  isOpen,
  onClose,
  confirmationText,
  agreeText,
  onAgree,
}: AlertModalProps) => {
  const navigate = useNavigate();
  const { t } = useTranslate();

  const handleOnAgree = () => {
    if (onAgree && typeof onAgree === 'string') {
      navigate(onAgree);
    } else {
      onAgree();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={style.modal}>
        <p>{confirmationText}</p>
        <div className={style.modalButtonContainer}>
          {/* <Button color={'secondary'} onClick={onClose}>
              Cancel
            </Button>{' '} */}
          <Button onClick={handleOnAgree}>
            {agreeText || t('alert.ok', 'Got it')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AlertModal;
