import { BackendFetch, DevTools, Tolgee, TolgeeProvider } from '@tolgee/react';
import { FormatIcu } from '@tolgee/format-icu';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { Person } from 'profile-plus/lib/shapes/Person';

export const AVAILABLE_LANGUAGES = ['en', 'es', 'fr'];
let tolgee;

export function initTolgee(userLanguagePreference: string) {
  if (!tolgee) {
    //browser language will be for example 'en-US'. We currently just use the 'en' part
    const browserLanguage =
      typeof navigator !== 'undefined'
        ? (navigator.language || navigator['userLanguage']).split('-').shift()
        : '';

    //use the users` language preference if it is available, otherwise use the browser language
    //if that's also not available, use 'en' (english) as the default language
    let defaultLanguage =
      userLanguagePreference &&
      AVAILABLE_LANGUAGES.includes(userLanguagePreference)
        ? userLanguagePreference
        : browserLanguage && AVAILABLE_LANGUAGES.includes(browserLanguage)
          ? browserLanguage
          : 'en';

    console.log('The default language is: ' + defaultLanguage);

    tolgee = Tolgee()
      // check if production use backend fetch CDN.
      // See: https://tolgee.io/platform/projects_and_organizations/content_delivery
      .use(
        process.env.NODE_ENV === 'production'
          ? BackendFetch({
              prefix: process.env.REACT_APP_TOLGEE_CDN,
            })
          : DevTools(),
      )
      .use(FormatIcu())
      .init({
        defaultLanguage: defaultLanguage,
        availableLanguages: AVAILABLE_LANGUAGES,

        // for development, to get translations from the tolgee server
        apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
        apiKey: process.env.REACT_APP_TOLGEE_API_KEY,

        // set default namespace
        defaultNs: 'common',

        // define which namespaces should be fetched initially
        ns: ['common'],
        //
        // for production
        // staticData: {
        // }
      });
  }
  return tolgee;
}

export function TranslationProvider({ children }) {
  const auth = useAuth<Person>();
  let [params] = useSearchParams();
  //use the language from the search params if it is available (this will override all other settings)
  //otherwise send the users preferred language
  const tolgee = initTolgee(
    params.get('lang') || auth.user?.languagePreference,
  );
  const fallback = children;
  return (
    <TolgeeProvider tolgee={tolgee} fallback={fallback}>
      {children}
    </TolgeeProvider>
  );
}

/**
 * get the active language from the tolgee instance
 *
 * @returns
 */
export function getActiveLanguage() {
  return tolgee.getLanguage();
}
