import ActionContent from './ActionContent';
import { Medal } from './Medal';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslate } from '@tolgee/react';
import style from './SetYourGoal.scss.json';
import './SetYourGoal.scss';
import { Button } from 'lincd-mui-base/lib/components/Button';
import { ActionPlan } from 'lincd-irlcg/lib/shapes/ActionPlan';
import { getMedalNumber, minutesToTimeString } from '../../utils/medal';
import { Topic } from 'lincd-irlcg/lib/shapes/Topic';
import { Spinner } from '../atoms/Spinner';

interface MedalGoalProps {
  text?;
  actions;
  minutes?;
  months?;
  average?: boolean;
}

export const MedalGoal = ({
  type,
  text,
  actions,
  minutes,
  average,
  months,
  onSelected,
  onDeselect,
  selected,
  topic,
}: {
  type: 'silver' | 'gold' | 'bronze';
  selected?: boolean;
  onDeselect?: () => void;
  onSelected?: (intendedMedal: number, minutes: number) => void;
  topic?: Topic;
} & MedalGoalProps) => {
  let { t } = useTranslate();
  let medalType = type.charAt(0).toUpperCase() + type.slice(1);

  //convert minutes to hours if over 60
  //also handle the case where minutes is a range, given as a string like "60 - 90"
  let time = minutesToTimeString(t, minutes, months, average);

  const onSelect = () => {
    onSelected(getMedalNumber(type), time);
  };

  return (
    <>
      <Medal
        title={t(`word.${type}PeaceMedal`, `${medalType} Peace Medal`) + ': '}
        image={`${topic.name.toLowerCase()}-${type}.webp`}
        text={text}
        actions={
          actions +
          ' ' +
          (actions === 1
            ? t('word.peaceAction', 'Peace Action')
            : t('word.peaceActions', 'Peace Actions'))
        }
        time={time}
      />
      <div className={style.center}>
        {selected ? (
          <Button
            variant="outlined"
            onClick={onDeselect}
            aria-label={t('word.change', 'Change')}
          >
            {t('word.change', 'Change')}
          </Button>
        ) : (
          <Button
            variant="outlined"
            onClick={onSelect}
            aria-label={t('word.select', 'Select')}
          >
            {t('word.select', 'Select')}
          </Button>
        )}
      </div>
    </>
  );
};

export const BronzeGoal = (props: MedalGoalProps) => {
  return <MedalGoal type={'bronze'} {...props} />;
};
export const SilverGoal = (props: MedalGoalProps) => {
  return <MedalGoal type={'silver'} {...props} />;
};
export const GoldGoal = (props: MedalGoalProps) => {
  return <MedalGoal type={'gold'} {...props} />;
};

export const SetYourGoal = ({ topic, children, onTimeSelected }) => {
  let { t } = useTranslate();
  const [actionPlan, setActionPlan] = useState<ActionPlan>();
  const [intendedMedal, setIntendedMedal] = useState<number>();

  useEffect(() => {
    ActionPlan.loadActionPlan(topic)
      .then((loadedPlan: ActionPlan) => {
        setActionPlan(loadedPlan);
        setIntendedMedal(loadedPlan.intendedMedal);

        // Pass the time of the PREVIOUSLY selected option to the callback function
        if (typeof onTimeSelected === 'function') {
          let minutes, months, average;
          React.Children.forEach(children, (child) => {
            // Check if this child is a BronzeGoal, SilverGoal, or GoldGoal
            let childType = (child as ReactElement).type;
            if (
              (loadedPlan.intendedMedal === 1 && childType === BronzeGoal) ||
              (loadedPlan.intendedMedal === 2 && childType === SilverGoal) ||
              (loadedPlan.intendedMedal === 3 && childType === GoldGoal)
            ) {
              minutes = (child as ReactElement).props.minutes;
              months = (child as ReactElement).props.months;
              average = (child as ReactElement).props.average;
            }
          });
          let time = minutesToTimeString(t, minutes, months, average);
          onTimeSelected(time);
        }
      })
      .catch((error) => {
        console.error('Error loading action plan set your goal:', error);
      });
  }, [topic, children, onTimeSelected, t]);

  const onSelected = (intendedMedal: number, minutes: number) => {
    actionPlan.intendedMedal = intendedMedal;
    setIntendedMedal(intendedMedal);

    // Pass the selected minutes to a callback function (e.g., onTimeSelected)
    if (typeof onTimeSelected === 'function') {
      onTimeSelected(minutes);
    }
  };

  const onDeselect = () => {
    actionPlan.intendedMedal = 0;
    setIntendedMedal(0);
  };
  let selectedMedal;
  if (intendedMedal) {
    selectedMedal = React.Children.map(children, (child) => {
      // Check if this child is a BronzeGoal, SilverGoal, or GoldGoal
      let childType = (child as ReactElement).type;
      if (
        (intendedMedal === 1 && childType === BronzeGoal) ||
        (intendedMedal === 2 && childType === SilverGoal) ||
        (intendedMedal === 3 && childType === GoldGoal)
      ) {
        return React.cloneElement(child, { selected: true, onDeselect, topic });
      }
      return null;
    });
  }
  return (
    <>
      {actionPlan ? (
        <ActionContent
          subtitle={t('word.setYourGoal', 'Set Your Goal')}
          content={
            selectedMedal
              ? selectedMedal
              : React.Children.map(children, (child, index) => (
                  <>
                    {index !== 0 && <hr className={style.divider} />}
                    {React.cloneElement(child, { topic, onSelected })}
                  </>
                ))
          }
        />
      ) : (
        <Spinner aria-label={t('loading', 'Loading')} />
      )}
    </>
  );
};
