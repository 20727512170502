import React from 'react';
import './TopicMedal.scss';
import { linkedComponent, registerPackageModule } from '../../package';
import { TopicScore } from 'lincd-irlcg/lib/shapes/TopicScore';
import { Medal } from './Medal';
import { useTranslate } from '@tolgee/react';
import { getMedalType } from '../../utils/medal';

//NOTE: topic score is added as a fix for rerendering on updates of the score, we dont use it but currently its needed
export const TopicMedal = linkedComponent<
  TopicScore,
  { topicScore: TopicScore }
>(
  TopicScore.request((s) => ({
    topicName: s.topic.name,
    score: s.score,
    medal: s.medal,
  })),
  ({ linkedData, topicScore, source }) => {
    let { t } = useTranslate();
    let { topicName, score, medal } = linkedData;
    let scoreText =
      t('word.peaceActions', score === 1 ? 'Peace Action' : 'Peace Actions') +
      ': ' +
      (topicScore?.score || score);
    if (medal) {
      let image = `${topicName.toLowerCase()}-${getMedalType(medal)}.webp`;
      return <Medal text={scoreText} image={image} />;
    }
    return <p>{scoreText}</p>;
  },
);

//register all components in this file
registerPackageModule(module);
