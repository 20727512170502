import { ShapeSet } from 'lincd/lib/collections/ShapeSet';
import { Topic } from 'lincd-irlcg/lib/shapes/Topic';
import { Resource } from 'lincd-irlcg/lib/shapes/Resource';

export const resource1 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/how-to-use-the-peace-game-app',
);
export const resource2 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/peace-game-videos',
);
export const resource3 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/how-to-start-a-peace-team',
);
export const resource4 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/register-your-peace-team',
);
export const resource4b = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/peace-team-member-registration',
);

export const resource5 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/peace-game-meeting-guides',
);
export const resource6 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/how-to-start-a-peace-on-earth-zone',
);
export const resource7 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/peace-game-debrief',
);

export const meetingGuide1 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/meetingGuide1',
);
export const meetingGuide2 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/meetingGuide2',
);
export const meetingGuide3 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/meetingGuide3',
);
export const meetingGuide4 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/meetingGuide4',
);
export const meetingGuide5 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/meetingGuide5',
);
export const meetingGuide6 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/meetingGuide6',
);
export const meetingGuide7 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/meetingGuide7',
);
export const meetingGuide8 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/meetingGuide8',
);
export const meetingGuide9 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/meetingGuide9',
);
export const meetingGuide10 = Resource.getFromURI(
  process.env.DATA_ROOT + '/resource/meetingGuide_10',
);

export const resources = new ShapeSet<Resource>([
  resource1,
  resource2,
  resource3,
  resource4,
  resource4b,
  resource5,
  resource6,
  resource7,
]);

//this data is generated in scripts/generate-data.js - with yarn generate:data[-prod]
export const topic1 = Topic.getFromURI(process.env.DATA_ROOT + '/topic1'); // Empowerment
export const topic2 = Topic.getFromURI(process.env.DATA_ROOT + '/topic2'); // Oneness
export const topic3 = Topic.getFromURI(process.env.DATA_ROOT + '/topic3'); // Unity
export const topic4 = Topic.getFromURI(process.env.DATA_ROOT + '/topic4'); // Cooperation
export const topic5 = Topic.getFromURI(process.env.DATA_ROOT + '/topic5'); // Abundance
export const topic6 = Topic.getFromURI(process.env.DATA_ROOT + '/topic6'); // Love
export const topic7 = Topic.getFromURI(process.env.DATA_ROOT + '/topic7'); // Faith

export const topics = new ShapeSet<Topic>([
  topic1,
  topic2,
  topic3,
  topic4,
  topic5,
  topic6,
  topic7,
]);
